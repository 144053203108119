import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import DealPopUp from './Dealprofile/Deal_popup';
import BI from './Dashboard/Dashboard';
import Configurator from './Configurator/Configurator';
import MasterTemplateConfigurator from './SuperAdmin/SuperAdmin';
import IOJ from './IOJ/IOJ';
import UserList from './User/userList';
import DealList from './Deal/dealList';
import OfferingList from './Offering/offeringList';
import LocationList from './Location/locationList';
import MasterList from './MasterScreen/MasterList';
import ConnectorList from './ConnectorScreen/ConnectorList';
import UserTrendsContainer from './UserTrends/UserTrendsContainer';
import MORContainer from './MonthlyOperationalReview/MORAliasContainer';
import IOJConf from './IOJConf/IOJConf';
import { PageNotFound } from './Shared/PageNotFound';
import QuickLinks from './Dashboard/QuickLinks';
import Report from './Report/Report2';
import ChartReport from './Report/ChartReport';
import InitiativeDetails from './JourneyInitiative/InitiativeDetails';
import GOContainer from './GOCAdmin/GOContainer';
import noFilter from './Images/funnel-icon.svg';

class RoutesList extends PureComponent {
  render() {
    const {
      tabDetails, SODisplay, IOJDisplay, CurrentFilter, filterDetails,
    } = this.props;
    const showMTC = !!(filterDetails && filterDetails.offering && filterDetails.process);
    const showFd = !!(CurrentFilter
      && (CurrentFilter.ProcessId  || tabDetails.title === 'IOJ Scorecard')
      && CurrentFilter.OfferingId
      && (CurrentFilter.LocationId || tabDetails.title === 'IOJ Scorecard')
      && (CurrentFilter.Month || (tabDetails.title === 'Journey Initiatives' && CurrentFilter.LocationOMID.length) || tabDetails.title === 'IOJ Scorecard'));      
    return (
      <Switch>
        <Route exact path="/" />
        <Route
          path="/Dashboard"
          render={() => {
            if (showFd) {
              return (
                <>
                  {SODisplay && (
                    <BI
                      compDetails={tabDetails}
                      isRelatorBlock={this.props.isRelatorBlock}
                      SODetails={this.props.SODetails}
                      menu="Dashboard"
                      headerText={this.props.headerText}
                      filterDetails={this.props.filterDetails}
                      roledata={this.props.roledata}
                    />
                  )}
                  {IOJDisplay && !this.props.isTabChanged && (
                    <IOJ
                      compDetails={tabDetails}
                      isRelatorBlock={this.props.isRelatorBlock}
                      isIojBlock={this.props.isIojBlock}
                      SODeatails={this.props.SODetails}
                      filterDetails={this.props.filterDetails}
                      roledata={this.props.roledata}
                      isIOJLoaded={this.props.isIOJLoaded}
                    />
                  )}
                </>
              );
            }
            return (
              (!this.props.isorgapiloading && (
              <div className="pulse-dashboard">
                <div className="select-filter-msg">
                  <img src={noFilter} alt={i18n.t('Use_the_filters_message')} />
                  <p className="mb-0">{i18n.t('Use_the_filters_message')}</p>
                </div>
              </div>
              ))
            );
          }}
        />

        <Route path="/Dealpopup" component={DealPopUp} />
        <Route path="/admin/Dealpopup" component={DealPopUp} />
        <Route
          path="/QL"
          component={() => <QuickLinks />}
        />
        <Route
          path="/admin/Configurator"
          render={() => {
            if (showFd) {
              return (
                <>
                  {SODisplay && !this.props.isTabChanged && (
                    <Configurator
                      getConfiguratorapicall={this.props.getConfiguratorapicall}
                      compDetails={tabDetails}
                      SODetails={this.props.SODetails}
                      attributes={this.props.attributes}
                      isNewDeal={this.props.isNewDeal}
                      isRelatorBlock={this.props.isRelatorBlock}
                      filterDetails={this.props.filterDetails}
                      roledata={this.props.roledata}
                      onTabChange = {this.props.onTabChange}
                      isBoLoaded={this.props.isBoLoaded}
                      isHMLoaded={this.props.isHMLoaded}
                      isWOLoaded={this.props.isWOLoaded}
                      isIILoaded={this.props.isIILoaded}
                      isDDLoaded={this.props.isDDLoaded}
                      isMPLoaded={this.props.isMPLoaded}
                    />
                  )}
                  {IOJDisplay && !this.props.isTabChanged && (
                    <IOJConf
                      getConfiguratorapicall={this.props.getConfiguratorapicall}
                      isRelatorBlock={this.props.isRelatorBlock}
                      compDetails={tabDetails}
                      isIojBlock={this.props.isIojBlock}
                      SODeatails={this.props.SODetails}
                      filterDetails={this.props.filterDetails}
                      isNewDeal={this.props.isNewDeal}
                      roleReducers={this.props.roledata}
                    />
                  )}
                </>
              );
            }
            return (
              (!this.props.isorgapiloading && (
              <div className="pulse-dashboard">
                <div className="select-filter-msg">
                  <img src={noFilter} alt={i18n.t('Use_the_filters_message')} />
                  <p className="mb-0">{i18n.t('Use_the_filters_message')}</p>
                </div>
              </div>
              ))
            );
          }}
        />
        <Route
          path="/admin/MasterTemplateConfigurator"
          render={() => {
            if (showMTC) {
              return (!this.props.isTabChanged
                && (
                <MasterTemplateConfigurator
                  compDetails={tabDetails}
                  SODetails={this.props.SODetails}
                  filterDetails={this.props.filterDetails}
                  roledata={this.props.roledata}
                />
                )
              );
            }
            return (
              (!this.props.isorgapiloading && (
              <div className="pulse-dashboard">
                <div className="select-filter-msg">
                  <img src={noFilter} alt={i18n.t('Use_mtc_filters_message')} />
                  <p className="mb-0">{i18n.t('Use_mtc_filters_message')}</p>
                </div>
              </div>
              ))
            );
          }}
        />

        <Route
          path="/initiativedetails"
          component={() => <InitiativeDetails />}
        />
        <Route
          path="/admin/User"
          component={() => <UserList />}
        />
        <Route
          path="/admin/Deal"
          component={() => <DealList />}
        />
        <Route
          path="/admin/Master"
          component={() => <MasterList />}
        />
        <Route
          path="/admin/Location"
          component={() => <LocationList />}
        />
        <Route
          path="/admin/Offering"
          component={() => <OfferingList />}
        />
        <Route
          path="/ChartReport"
          component={() => <ChartReport />}
        />
        <Route
          path="/Report"
          component={() => <Report filterDetails={this.props.filterDetails} clientId={this.props.clientId} />}
        />

        <Route
          path="/admin/Report"
          component={() => <Report />}
        />
        <Route
          path="/admin/UserTrends"
          component={() => <UserTrendsContainer />}
        />
        <Route
          path="/OperationalReviews"
          component={() => <MORContainer />}
        />
        <Route
          path="/admin/GOCForm"
          component={() => <GOContainer />}
        />
        <Route
          path="/admin/Connector"
          component={() => <ConnectorList />}
        />

        <Route component={PageNotFound} />

      </Switch>
    );
  }
}
const mapStateToProps = (state) => ({
  CurrentFilter: state.CurrentFilter.SyncFilter,
});

export default connect(
  mapStateToProps,
  '',
)(RoutesList);
