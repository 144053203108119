import React, { Component } from "react";
import PropTypes from "prop-types";

import { withRouter } from "react-router-dom";
import _ from "lodash";
import { connect } from "react-redux";
import LoadingOverlay from "react-loading-overlay";
import classNames from "classnames";
import { Modal } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
// IMPORT CUSTOM COMPONENTS/FUNCTIONS
import SynopsLoader from "../Shared/SynopsLoader";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import { LocalApiBaseUrl, ROUTE_CONSTANTS, BATOfferingIcons } from "../Shared/Constant";
import {
  BATSetClientSimulationFlowInitiatedFromScreen,
  BATUnsetClientSimulationFlowInitiatedFromScreen,
  BATSetOfferingsData,

} from "../actions/BATActions";

// IMPORT STYLES
import "./CSS/batstyle.css";

axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;


class Bat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterOffering: "All",
      isSubOfferingModal: false,
      industryOfferings: [],
      functionalOfferings: [],
      searchText: "",
      isVisibleOfferingSelectionModal: false,
      isAPILoading: false,
      clientSummaryData: {},
      offeringSummaryData: [],
      offerings: [],
      clientCount: 5, // BY DEFAULT SET IT TO 5, THEN LATER UPDATE THE VALUE FROM THE API CALL TO OBTAIN SETTINGS
      valueInsightsPlatformLink:"",
    };
  }

  componentDidMount() {
    const { BATUnsetClientSimulationFlowInitiatedFromScreen } = this.props;
    BATUnsetClientSimulationFlowInitiatedFromScreen();

    // FETCH THE DATA FROM THE APIs ON SCREEN LOAD
    this.fetchScreenData();
  }

  fetchScreenData = () => {
    let { clientCount,valueInsightsPlatformLink } = this.state;

    this.setState({ isAPILoading: true });
    // FETCH THE USER SETTINGS FROM THE API
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetAppConfig`)
      .then((response) => {
        const responseData = response.data;
        const clientCountFromSettings = _.get(
          _.find(responseData, (eachSetting) => eachSetting.ConfigKey === "BenchmarkAnalyticsMinClientCount"),
          "ConfigValue",
          ""
        );
        const valueinsightsplatform = responseData.filter(each=>each.ConfigKey === "valueInsightsPlatformLink").length == 1?
        responseData.filter(each=>each.ConfigKey === "valueInsightsPlatformLink")[0].ConfigValue:"";
       
        if (!_.isEmpty(String(clientCountFromSettings))) {
          clientCount = clientCountFromSettings;
        }
        if (!_.isEmpty(String(valueinsightsplatform))) {
          valueInsightsPlatformLink = valueinsightsplatform;
        }

        this.setState(
          {
            clientCount,
            valueInsightsPlatformLink
          },
          () => {
            // FETCH THE DATA FROM THE API
            Promise.all([this.fetchClientSummary(false), this.fetchOfferingSummary(false)])
              .then(() => {
                this.setState({ isAPILoading: false });
              })
              .catch(() => {
                this.setState({ isAPILoading: false });
              });
          }
        );
      })
      .catch((error) => {
        this.setState({ isAPILoading: false }, () => {
          trycatchAlertPopup(error);
        });
      });
  };

  // EXECUTES THE API REQUEST TO FETCH CLIENT SUMMARY DATA
  fetchClientSummary = (showHideLoader = true) => {
    const { clientCount, filterOffering, industryOfferings, functionalOfferings } = this.state;
    const industryOfferingNames = _.map(industryOfferings, "offering");
    const functionalOfferingNames = _.map(functionalOfferings, "offering");
    let offeringNames = _.concat(industryOfferingNames, functionalOfferingNames);

    if (filterOffering === "Functional") {
      offeringNames = functionalOfferingNames;
    } else if (filterOffering === "Industrial") {
      offeringNames = industryOfferingNames;
    }

    const requestData = {
      clientCount: _.toNumber(clientCount),
      offeringNames,
    };

    showHideLoader && this.setState({ isAPILoading: true });
    return new Promise((resolve, reject) => {
      // FETCH THE DATA FROM THE API
      axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetClientSummary`, requestData)
        .then((response) => {
          const responseData = response.data;

          this.setState(
            {
              clientSummaryData: responseData,
            },
            () => {
              showHideLoader && this.setState({ isAPILoading: false });
              resolve(responseData);
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
          this.setState({ isAPILoading: false });
        });
    });
  };

  // EXECUTES THE API REQUEST TO FETCH OFFERING SUMMARY DATA
  fetchOfferingSummary = (showHideLoader = true) => {
    const { BATSetOfferingsData } = this.props;
    const { clientCount } = this.state;
    const requestData = {
      clientCount: _.toNumber(clientCount),
    };

    showHideLoader && this.setState({ isAPILoading: true });
    return new Promise((resolve, reject) => {
      // FETCH THE DATA FROM THE API
      axiosInstance
        .post(`${LocalApiBaseUrl}BAT/GetOfferingSummary`, requestData)
        .then((response) => {
          const responseData = response.data;

          // SAVE OFFERINGS DATA IN REDUX
          BATSetOfferingsData(responseData);

          const modifiedOfferingSummaryData = _.map(responseData, (eachRecord) => {
            let logo = eachRecord.logo;
            let subOfferings = eachRecord.towerNames;

            if (!_.isEmpty(subOfferings)) {
              subOfferings = _.map(subOfferings, (eachItem, index) => {
                const subOfferingName = eachItem.tower;
                return {
                  id: eachItem.towerID,
                  name: subOfferingName,
                  key: subOfferingName,
                  isEnabled: eachItem.isEnabled,
                  isSubOfferingTVEEnabled:eachItem.hasTVEEnabledMetrics,
                };
              });
            }

            return {
              ...eachRecord,
              logo,
              subOfferings,
            };
          });
         
          // FILTER OUT UNIQUE OFFERINGS
          const offerings = _.map(modifiedOfferingSummaryData, (eachRecord) => ({
            ...eachRecord,
            id: eachRecord.offeringId,
            name: eachRecord.offering,
          })).filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
        
          const functionalOfferings = offerings.filter(function(eachOffering) {
            return eachOffering.offeringType === "Functional";
          });
         
          const industryOfferings = offerings.filter(function(eachOffering) {
            return eachOffering.offeringType === "Industrial";
          });
         

          this.setState(
            {
              offeringSummaryData: modifiedOfferingSummaryData,
              offerings,
              functionalOfferings,
              industryOfferings,
            },
            () => {
              showHideLoader && this.setState({ isAPILoading: false });
              resolve(responseData);
            }
          );
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  };



  // Start region for Click on Tower/SubOffering Name then batView page will open
  handleClickTowerName = (TowerName, OfferingName) => {
    const { history } = this.props;
    let subOfferingName = TowerName;
    let offeringName = OfferingName;
    let offeringData = this.state.currentlySelectedOfferings;
    const { offerings, clientCount, functionalOfferings, industryOfferings } = this.state;
    let redirectScreenData = {
      offerings,
      functionalOfferings,
      industryOfferings,
      clientCount: _.toNumber(clientCount),
      subOfferingName,
      offeringName,
      offeringType: offeringData.offeringType,
      offering: offeringData.offering,
      offeringId: offeringData.offeringId,
    };
    const location = {
      pathname: `/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`,
      state: { batViewScreenData: redirectScreenData },
    };
    history.push(location);
    
  };
  // End region for batView page Open

  // Start Filter Change for Functional and Industry Offerings
  handleRadioChange = (e) => {
    const selectedOfferingType = e.target.value;

    this.setState(
      {
        filterOffering: selectedOfferingType,
      },
      () => {
        this.fetchClientSummary();
      }
    );
  };
  // End Filter for Functional and Industry Offerings

  // Start Open SubOffering Modal Popup
  displaySubOfferingModalPopup = (subOfferingData, logo) => {
   
    this.setState({
      currentlySelectedOfferings: subOfferingData,
      currentlogo: logo,
      isSubOfferingModal: true,
    });
  };
  // End Suboffering Modal Popup

  handleSubOfferingModalHide = () => {
    this.setState({
      isSubOfferingModal: false,
    });
  };

  handleUpdateSearch(event) {
    this.setState({ searchText: event.target.value.substr(0, 20) });
  }

  handleOfferingSelectionModalShow = () => {
    this.setState({
      isVisibleOfferingSelectionModal: true,
    });
  };

  handleOfferingSelectionModalHide = () => {
    this.setState({
      isVisibleOfferingSelectionModal: false,
    });
  };

  handleAssessmentOptionClick = (selectedScreenName) => {
    const { history, BATSetClientSimulationFlowInitiatedFromScreen } = this.props;
    const { offeringData, offerings, clientCount } = this.state;
    const flowInitiatedScreen = "BatLandingScreen";
    let location = {};
    if (selectedScreenName === "takeAssessment") {
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_TAKE_ASSESSMENT}`,
        state: { offeringData, offerings, clientCount },
      };
    } else if (selectedScreenName === "viewAssessment") {
      const viewAssessmentScreenData = {
        flowInitiatedScreen,
        clientCount: _.toNumber(clientCount),
      };
      location = {
        pathname: `/${ROUTE_CONSTANTS.BAT_VIEW_ASSESSMENT}`,
        state: { viewAssessmentScreenData },
      };
    }

    BATSetClientSimulationFlowInitiatedFromScreen("BATLandingScreen", {});
    history.push(location);
  };

  render() {
    const {
      clientSummaryData,
     
      currentlySelectedOfferings,
      currentlogo,
      isSubOfferingModal,
      isAPILoading,
     
      searchText,
      valueInsightsPlatformLink,
    } = this.state;
    const diffClientCount = _.get(clientSummaryData, "diffClientCount", "");
    const diffInternalMetricsCount = _.get(clientSummaryData, "diffInternalMetricsCount", "");
    const diffExternalBenchmarkCount = _.get(clientSummaryData, "diffExternalBenchmarkCount", "");

    const isNegativeDiffClientCount = diffClientCount.indexOf("-") !== -1;
    const isNegativeDiffInternalMetricsCount = diffInternalMetricsCount.indexOf("-") !== -1;
    const isNegativeDiffExternalBenchmarkCount = diffExternalBenchmarkCount.indexOf("-") !== -1;

    let filteredFunctionalOfferings = this.state.functionalOfferings.filter((eachOffering) => {
      return eachOffering.offering.toLowerCase().indexOf(searchText) !== -1;
    });

    let filteredIndustryOfferings = this.state.industryOfferings.filter((eachOffering) => {
      return eachOffering.offering.toLowerCase().indexOf(searchText) !== -1;
    });

    return (
      <>
        {/* IF API IS LOADING THEN SHOW THE LOADER */}
        {isAPILoading && <LoadingOverlay spinner={<SynopsLoader />} active />}

        <div className="container-fluid simulationView">
          {/* START SECTION - CLIENT SUMMARY */}
          {!_.isEmpty(clientSummaryData) && (
            <div className="row">
              <div className="col-lg-12">
                <div className="px-40">
                  <div className="outcome-highlight analysisReport">
                    <div className="row">
                      <div className="col-lg-4">
                        <div className="analysisList">
                          <h4>Clients</h4>
                          <div className="analysis-bottom">
                            <h2 className="analysisUnit">{_.get(clientSummaryData, "clientCount", "")}</h2>
                            <p>{isNegativeDiffClientCount ? diffClientCount : `+${diffClientCount}`} from last month</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="analysisList">
                          <h4>Available Internal Metrics</h4>
                          <div className="analysis-bottom">
                           
                            <h2 className="analysisUnit">{_.get(clientSummaryData, "internalMetricsCount", "")}</h2>
                            <p>
                              {isNegativeDiffInternalMetricsCount
                                ? diffInternalMetricsCount
                                : `+${diffInternalMetricsCount}`}{" "}
                              from last month
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-4">
                        <div className="analysisList noAfter">
                          <h4>Available Industry Benchmarks</h4>
                          <div className="analysis-bottom">
                           
                            <h2 className="analysisUnit">{_.get(clientSummaryData, "externalBenchMarkCount", "")}</h2>
                            <p>
                              {isNegativeDiffExternalBenchmarkCount
                                ? diffExternalBenchmarkCount
                                : `+${diffExternalBenchmarkCount}`}{" "}
                              from last month
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* END SECTION - CLIENT SUMMARY */}

          {/* START SECTION - OFFERING SELECTION */}
          <div className="row">
            <div className="col-lg-12">
              <div className="px-40">
                <div className="batOffering-category categoryView ">
                  <div className="batOffering-category-containerA">
                    <div className="batOffering-category-label">View by:</div>
                    <div className="batOffering-category-list">
                      <label className="form-check mr-3">
                        {" "}
                        <input
                          className="form-check-input"
                          type="radio"
                          name="offerings"
                          value="All"
                          onChange={this.handleRadioChange}
                          checked={this.state.filterOffering === "All"}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label-active">All</span>
                      </label>

                      <label className="form-check mr-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="offerings"
                          value="Functional"
                          onChange={this.handleRadioChange}
                          checked={this.state.filterOffering === "Functional"}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Functional Offerings</span>
                      </label>

                      <label className="form-check mr-3">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="offerings"
                          value="Industrial"
                          onChange={this.handleRadioChange}
                          checked={this.state.filterOffering === "Industrial"}
                        />
                        <span className="checkmark"></span>
                        <span className="form-check-label">Industry Offerings</span>
                      </label>
                    </div>
                  </div>

                  <div className="assessment-dropdown-btn">
                    <Dropdown>
                      <Dropdown.Toggle id="assessment-dropdown">Assessment</Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="takeAssessment"
                          onSelect={() => this.handleAssessmentOptionClick("takeAssessment")}
                        >
                          <i class="fal fa-plus-circle"></i>Take Assessment
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="viewAssessment"
                          onSelect={() => this.handleAssessmentOptionClick("viewAssessment")}
                        >
                          <i class="fal fa-list-ul"></i>View Assessment
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <a
                      className="viplink-btn"
                      href={valueInsightsPlatformLink}
                      target="_blank"
                    >
                      Value Insights Platform
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END SECTION - OFFERING SELECTION */}

          {/* START SECTION - OFFERING SUMMARY */}
          <div className="row">
            <div className="col-lg-12">
              <div className="px-40 pt-0">
                <div className="offering-search">
                  <div className="searchBtn">
                    <div className="form-group">
                      <span className="fa fa-search form-control-feedback"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                        value={searchText}
                        onChange={this.handleUpdateSearch.bind(this)}
                      />
                    </div>
                  </div>
                </div>

                <div className="batOffering-list-container">
                  {(this.state.filterOffering === "All" || this.state.filterOffering === "Functional") &&
                    filteredFunctionalOfferings.length > 0 && (
                      <span>
                        {" "}
                        <div className="offering-name">Functional Offerings</div>
                      </span>
                    )}
                  <div className="row">
                    {(this.state.filterOffering === "All" || this.state.filterOffering === "Functional") &&
                      filteredFunctionalOfferings.length > 0 &&
                      _.map(filteredFunctionalOfferings, (eachOfferingSummary, index) => {
                        let logo = _.get(
                          _.find(BATOfferingIcons, (eachLogo) => eachLogo.key === eachOfferingSummary.logo),
                          "iconBlue",
                          ""
                        );
                        const isEnabled = eachOfferingSummary.isEnabled && eachOfferingSummary.internalMetricsCount>0;

                        return (
                          <div
                            className="col-lg-3"
                            key={index}
                            onClick={() => {
                              if (isEnabled) {
                                //this.handleClickOfferingTile(eachOfferingSummary);
                                this.displaySubOfferingModalPopup(eachOfferingSummary, logo);
                              }
                            }}
                          >
                            <div
                              className={classNames("analysisFull-wrap", {
                                "grayed-out": !isEnabled,
                              })}
                            >
                              <div className="analysisFull">
                                <div className="analysisIcon">
                                  {!_.isEmpty(logo) ? (
                                    <img src={logo} height="48px" width="48px" alt={eachOfferingSummary.offering} />
                                  ) : (
                                    <i className={classNames("far")}></i>
                                  )}
                                </div>
                                <div className="analysisTitle">
                                  <h4>{eachOfferingSummary.offering}</h4>
                                  <p>{eachOfferingSummary.subOfferingCount} Sub Offerings</p>
                                </div>
                                <div className="analysisHr">
                                  <div className="analysisHrList">
                                    <h5>{eachOfferingSummary.clientCount}</h5>
                                    <p>Clients</p>
                                  </div>
                                  <div className="analysisHrList">
                                    <h5>{eachOfferingSummary.internalMetricsCount}</h5>
                                    <p>Internal Metrics</p>
                                  </div>
                                  <div className="analysisHrList">
                                    <h5>{eachOfferingSummary.externalBenchmarkCount}</h5>
                                    <p>Industry Benchmarks</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  {(this.state.filterOffering === "All" || this.state.filterOffering === "Industrial") &&
                    filteredIndustryOfferings.length > 0 && (
                      <span>
                        {" "}
                        <div className="offering-name">Industry Offerings</div>
                      </span>
                    )}
                  <div className="row">
                    {(this.state.filterOffering === "All" || this.state.filterOffering === "Industrial") &&
                      filteredIndustryOfferings.length > 0 &&
                      _.map(filteredIndustryOfferings, (eachOfferingSummary, index) => {
                        let logo = _.get(
                          _.find(BATOfferingIcons, (eachLogo) => eachLogo.key === eachOfferingSummary.logo),
                          "iconBlue",
                          ""
                        );
                        const isEnabled = eachOfferingSummary.isEnabled;

                        return (
                          <div
                            className="col-lg-3"
                            key={index}
                            onClick={() => {
                              if (isEnabled) {
                                // this.handleClickOfferingTile(eachOfferingSummary);
                                this.displaySubOfferingModalPopup(eachOfferingSummary, logo);
                              }
                            }}
                          >
                            <div
                              className={classNames("analysisFull-wrap", {
                                "grayed-out": !isEnabled,
                              })}
                            >
                              <div className="analysisFull">
                                <div className="analysisIcon">
                                  {!_.isEmpty(logo) ? (
                                    <img src={logo} height="48px" width="48px" alt={eachOfferingSummary.offering} />
                                  ) : (
                                    <i className={classNames("far")}></i>
                                  )}
                                </div>
                                <div className="analysisTitle">
                                  <h4>{eachOfferingSummary.offering}</h4>
                                  <p>{eachOfferingSummary.subOfferingCount} Sub Offerings</p>
                                </div>
                                <div className="analysisHr">
                                  <div className="analysisHrList">
                                    <h5>{eachOfferingSummary.clientCount}</h5>
                                    <p>Clients</p>
                                  </div>
                                  <div className="analysisHrList">
                                    <h5>{eachOfferingSummary.internalMetricsCount}</h5>
                                    <p>{eachOfferingSummary.offeringId === 50047?"External Metrics" :"Internal Metrics"}</p>
                                  </div>
                                  <div className="analysisHrList">
                                    <h5>{eachOfferingSummary.externalBenchmarkCount}</h5>
                                    <p>Industry Benchmarks</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* NEW OFFERING SUMMARY END */}
        </div>
        {isSubOfferingModal && (
          <div className="">
            <Modal
              show
              centered
              onHide={() => this.handleSubOfferingModalHide()}
              backdrop="static"
              dialogClassName="modal-90w"
              className="subOfferingSelectionModal"
            >
              <Modal.Header closeButton className="justify-content-center">
                <Modal.Title>Select a Sub offering …</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="subOffering-name">
                  <div>
                    {!_.isEmpty(currentlogo) ? (
                      <img src={currentlogo} height="48px" width="48px" alt={currentlySelectedOfferings.offering} />
                    ) : (
                      <i className={classNames("far")}></i>
                    )}
                  </div>
                  <div>
                    <p>{currentlySelectedOfferings.offering}</p>
                  </div>
                  {!_.isEmpty(currentlySelectedOfferings.towerNames) && (
                    <div className="subOffering-options">
                      {currentlySelectedOfferings.towerNames.map((twr, i) => {
                        return (
                          <span
                            className="optionLabel-wrap"
                            onClick={() => this.handleClickTowerName(twr.tower, currentlySelectedOfferings.offering)}
                            key={i}
                          >
                            <span className={this.state.towerIndex === i ? "optionLabel active" : "optionLabel"}>
                              <span className={this.state.towerIndex === i ? "" : "optionLabel-text"}>{twr.tower}</span>
                            </span>
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    BATSetClientSimulationFlowInitiatedFromScreen: (screenName, screenData) =>
      dispatch(BATSetClientSimulationFlowInitiatedFromScreen(screenName, screenData)),
    BATUnsetClientSimulationFlowInitiatedFromScreen: () => dispatch(BATUnsetClientSimulationFlowInitiatedFromScreen()),
    BATSetOfferingsData: (offeringsData) => dispatch(BATSetOfferingsData(offeringsData)),
  };
};




export default withRouter(connect(null, mapDispatchToProps)(Bat));
