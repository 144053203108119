import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, NavLink } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { compose } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import LoadingOverlay from 'react-loading-overlay';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import _ from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import $ from 'jquery';
import Moment from 'moment';
import navbarLogo from '../Images/Synops-navbar-logo.svg';
import English from '../Images/lang_icons/english.svg';
import Japanese from '../Images/lang_icons/japanese.svg';
import Chinese from '../Images/lang_icons/chinese.svg';
import French from '../Images/lang_icons/french.svg';
import German from '../Images/lang_icons/german.svg';
import { setUserFeatures } from './Common';
import {
  trycatchAlertPopup,
  LocalApiBaseUrl,
  DomainBaseUrl,
  LocalApiBaseUrlImage,
  ROUTE_CONSTANTS,
  idTokenstring
} from './Constant';
import SynopsLoader from './SynopsLoader';
import {
  screeNamesForUserTracking, logUserTrackingData, HasValue, ExtractSrcLink,
} from './commonFunctions';
import 'bootstrap/dist/js/bootstrap.bundle.min';

import Notification from './Notification';
import DealPopUp from '../Dealprofile/Deal_popup';
import DealConfiguratorPopUp from '../Deal/dealPopup';
import CaseStudyLinkPoup from './CaseStudyLinkPopup';
import { store } from '../reducers/configureStore';
import Supportimage from '../Images/support-image.svg';
import Client from '../Images/client-0.svg';
import axiosInstance from './interceptor';
import {
  apiload,
} from '../actions/JourneyActions';
import ReactTooltip from "react-tooltip";
import { MsalContext } from "@azure/msal-react";
import { acquireNewTokenSilent } from "../Authorization/AcquireTokenApi";
import { TRACKING_MODULES, PARAM_NAMES, userTracker, createParamObject } from '../Shared/Common';
axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;


let linkPush = '/Dashboard';
$(document).ready(() => {
  const trigger = $('.hamburger');
  const overlay = $('.overlay');
  let isClosed = false;
  function buttonSwitch() {
    if (isClosed === true) {
      trigger.removeClass('is-open').addClass('is-closed');
      isClosed = false;
    } else {
      trigger.removeClass('is-closed').addClass('is-open');
      isClosed = true;
    }
  }
  trigger.click(() => {
    buttonSwitch();
  });
  $('[data-toggle="offcanvas"]').click(() => {
    $('.wrapper').toggleClass('toggled');
  });
  $(document).on('click', (e) => {
    if ($(e.target).closest(".main-sidebar, [data-toggle='offcanvas']").length === 0) {
      trigger.removeClass('is-open').addClass('is-closed');
      $('.wrapper').removeClass('toggled');
      isClosed = false;
    }
  });
});

const samp = {};
class Header extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      CityOptions: [],
      flag: false,
      supportModalStatus: false,
      referencesListStatus: false,
      viewHelpFeaturesStatus: false,
      leftPanelModalStatus: false,
      clientListData: [],
      filterClientData: [],
      roleData: [],
      ProfileSrc: '',
      selvalue: '',
      headerText: '',
      selvalueDdl: 'Select Client',
      pulseDataExist: true,
      linkPush: '/Dashboard',
      languages: [{ LanguageID: 1, LanguageCode: 'EN', LanguageDescription: 'English' }],
      selectedLanguage: 'EN',
      clientSelection: { abbr: '', id: 0, name: '' },
      isClientDropdownStatus: false,
      isVisibleNotification: false,
      isAnyNewNotification: false,
      NavMenuDropdown: false,
      newNotificationCount: 0,
      SynopsOption: false,
      ConfigOption: false,
      titleValue: 'SynOps Applied Now', // i18n.t("SynOps Applied Now"), //(this.props.history.location.pathname === "/Dashboard" ? i18n.t("SynOps Applied Now") : i18n.t("Configurator")),
      activeDDId: 'fdLink',
      featureData: [],
      viewmore: false,
      viewDealConfig: false,
      userName: sessionStorage.getItem('EnterpriseID'),
      date: sessionStorage.getItem('lastLoginTime'),
      addEditCaseStudyLinkStatus: false,
      caseStudypopupStatus: '',
      caseStudylinkData: {},
      configuratorEditAccess: false,
      OrgEntityID: 0,
      ClientRegions: [],
      MaturityPhase: [],
    };
    this.OrgEntityImage = '';
    this.currentLanguageData = store.getState().CurrentFilter.languageData;
    this.currentlangjson = this.currentLanguageData.messages;
    this.updatedsubscribedata = '';
    this.supportModalShow = this.supportModalShow.bind(this);
    this.supportModalClose = this.supportModalClose.bind(this);
    this.referencesListModalShow = this.referencesListModalShow.bind(this);
    this.referencesListModalClose = this.referencesListModalClose.bind(this);
    this.leftPanelModalShow = this.leftPanelModalShow.bind(this);
    this.leftPanelModalClose = this.leftPanelModalClose.bind(this);
  }
  static contextType = MsalContext;
  handleMenuToggle = () => {
    this.setState({ SynopsOption: true, ConfigOption: true }, () => {
      if (this.props.history.location.pathname === '/Dashboard') {
        this.showSynopsOption();
      } else if (this.props.history.location.pathname.startsWith('/admin/Configurator')) {
        this.showConfigOption();
      }
    });
  };

  toggleMenuOpen = () => {
    if (this.state.featureData.includes('Dashboard') && this.state.featureData.includes('Configurator')) {
      this.setState({ NavMenuDropdown: true });
      this.handleMenuToggle();
    }
  };

  toggleMenuClose = () => {
    this.setState({ NavMenuDropdown: false });
  };

  showSynopsOption = () => {
    this.setState({
      SynopsOption: true,
      titleValue: i18n.t('SynOps_Applied_Now'),
      ConfigOption: false,
      activeDDId: 'fdLink',
    });
  };

  showConfigOption = () => {
    this.setState({
      ConfigOption: true,
      titleValue: i18n.t('Configurator'),
      SynopsOption: false,
      activeDDId: 'configLink',
    });
  };

  dropnav = (e) => {
    const id = e.target.id === 'synopsapplied-button' ? this.state.activeDDId : e.target.id;
    const { history } = this.props;
    const location = {
      pathname: id === 'fdLink' ? '/Dashboard' : '/admin/Configurator',
    };
    history.push(location);
  };

  showNotification = () => {
    this.setState({ isVisibleNotification: true });
  };

  handleCallbackNotificationHide = () => {
    this.setState({
      isVisibleNotification: false,
      isAnyNewNotification: false,
    });
  };

  handleCallbackOnNewNotification = (isAnyNewNotification, newNotificationCount) => {
    this.setState({
      isAnyNewNotification,
      newNotificationCount,
    });
  };

  leftPanelModalShow() {
    this.setState({ leftPanelModalStatus: true });
  }

  leftPanelModalClose() {
    this.setState({ leftPanelModalStatus: false });

    store.dispatch({
      type: 'ADMIN_REDIRECT_FROM',
      payload: '',
    });
  }

  referencesListModalShow() {
    this.setState({ referencesListStatus: true });
  }

  referencesListModalClose() {
    this.setState({ referencesListStatus: false });
  }

  supportModalShow() {
    this.setState({ supportModalStatus: true }, () => {
      this.leftPanelModalClose();
    });
  }

  supportModalClose() {
    this.setState({ supportModalStatus: false });
  }

  viewHelpTopic = () => {
    const { history } = this.props;
    const location = {
      pathname: '/helptopics',
    };
    history.push(location);
    this.setState({
      viewHelpTopicStatus: true,
    });
  };

  viewNotificationRequest = () => {
    const { history } = this.props;
    const location = {
      // pathname: `/notificationrequest`,
      pathname: `/${ROUTE_CONSTANTS.BAT_ACCESS_MANAGEMENT}`,
    };
    history.push(location);
    this.setState({
      viewNotificationRequest: true,
      isVisibleNotification: false,
    });
  };

  viewHelpFeatures = () => {
    const { history } = this.props;
    const location = {
      pathname: '/helpfeatures',
    };
    history.push(location);
    this.setState({
      viewHelpFeaturesStatus: true,
      referencesListStatus: false,
    });
  };

  isClientDropdown = () => {
    this.setState({ isClientDropdownStatus: true, filterClientData: this.state.clientListData });
  };

  isClientDropdownOverlay = () => {
    this.setState({ isClientDropdownStatus: false });
  };

  GetProfilePic = () => {
    axiosInstance.post(`${LocalApiBaseUrl}User/DownloadPeopleImage`, null, {}).then((response) => {
      if (this._isMounted === true) {
        const ProfileSrc = `data:image/jpeg;base64,${response.data}`;
        this.setState({ ProfileSrc });
      }
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

    // }
  };

  TrackUser = (PageName) => {
    userTracker(
      PageName,
      false,
      null,
      null,
    );
  }

  componentDidMount() {
    const {
      OperatingGropus, languageData, selectedClientData, notificationData, history
    } = this.props;
    const ClientData = [];
    if (OperatingGropus && OperatingGropus.length) {
      OperatingGropus.forEach((client) => {
        ClientData.push({
          isFavourite: client.IsFavourite,
          name: client.OrgEntityName,
          id: client.OrgEntityID,
          abbr: client.OrgEntityName.substr(0, 2),
          imageName: client.OrgEntityImage,
        });
      });
    }
    this.setState({
      clientListData: ClientData,
      filterClientData: ClientData,
      languages: languageData.languageDropDown.length
        ? languageData.languageDropDown
        : [{ LanguageID: 1, LanguageCode: 'EN', LanguageDescription: 'English' }],
      selectedLanguage: languageData.langCode,
      isAnyNewNotification: notificationData.newNotificationsData.hasNewNotification,
      newNotificationCount: notificationData.newNotificationsData.notificationCount,
    }, () => {
      if (this.state.filterClientData.length === 1 && history.location.pathname !== "/viewclientproject" &&  history.location.pathname !== "/SynOpsDemo") {
        let value = this.state.filterClientData[0];
        this.onSelectHandle(value.name,value)
        this.setState({
          flag: true
        })
      }
    });

    this._isMounted = true;
    this.GetProfilePic();
    this.GetAllClientRegion();
    this.GetAllMaturityPhase();
    this.getFeatureDataModified();
    store.subscribe(() => {
      if (this._isMounted === true) {
        this.handleMenuToggle();
        const data = store.getState();
        const confEditAccess = data.roleReducers.roleReducers.filter(
          (data) => data.FeatureName == 'Configurator' && data.Operations.includes('Submit'),
        ).length > 0;
        this.setState(
          {
            roleData: data.roleReducers.roleReducers,
            configuratorEditAccess: confEditAccess,
          },
          () => {
            this.getFeatureDataModified();
          },
        );
      }
    });

    const orgEntityid = selectedClientData ? (selectedClientData.id ? selectedClientData.id : 0) : 0;
    this.setState(
      {
        OrgEntityID: orgEntityid,
      },
      () => {
        if (orgEntityid > 0) {
          this.getCaseStudyLink();
        }
      },
    );
  }

  getFeatureDataModified = () => {
    let data = store.getState();
    data = this.state.roleData.length === 0 ? data.roleReducers.roleReducers : this.state.roleData;
    const featureDetails1 = data;
    const masterMenu = [];
    featureDetails1.forEach((ele) => {
      if (ele.Operations != null && ele.Operations.count != 0) {
        masterMenu.push(ele.FeatureName);
      }
    });

    let uniqueMenu = [];
    if (masterMenu != null) {
      uniqueMenu = masterMenu.filter((item, pos) => masterMenu.indexOf(item) == pos);
    }

    const filtered = uniqueMenu.filter((el) => el != null);

    const featureData = featureDetails1.map((el) => el.FeatureName);
    this.setState({ featureData: filtered }, () => {
      if (this.state.featureData.includes('Configurator') && !this.state.featureData.includes('Dashboard')) {
        this.showConfigOption();
      }
      if (!this.state.featureData.includes('Configurator') && this.state.featureData.includes('Dashboard')) {
        this.showSynopsOption();
      }
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  
  onClientClick = async() => {
    if (this.state.CityOptions.length == 0) {
      var oldToken = sessionStorage.getItem(idTokenstring);
      var oldBase64Url = oldToken.split('.')[1];
      var oldBase64 = oldBase64Url.replace('-', '+').replace('_', '/');
      var tokendetails = JSON.parse(window.atob(oldBase64));
    var expiry = tokendetails["exp"];
    var expTime = new Date(expiry*1000);
    var currentTime = new Date();
    if (currentTime > expTime) {  
      if(isDevEnvironment){
        alert("Token Expired!! please get the new token")//this message will throw only in case of Local Dev Environment
        }
        else{
        await acquireNewTokenSilent(true);
        }//Acquiring new token and setting to the authorization headers
    }
    }
    // Reset the Synops Sync Filters
  }

  componentDidUpdate(prevProps, prevState) {
    const { OperatingGropus, selectedClientData, notificationData } = this.props;
    const { selvalue,filterClientData } = this.state;
    const currentURLPath = this.props.history.location.pathname;
    const adminFromConfig = !!(this.props.history.location.state && this.props.history.location.state.showConfiguratorFilterForAdminView);
    let isActiveBATScreen = false;
    if (prevProps.notificationData !== notificationData) {
      this.setState({
        isAnyNewNotification: notificationData.newNotificationsData.hasNewNotification,
        newNotificationCount: notificationData.newNotificationsData.notificationCount,
      });
    }
    if (
      currentURLPath === `/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_TAKE_ASSESSMENT}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_VIEW_ASSESSMENT}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_ACCESS_MANAGEMENT}`
    ) {
      isActiveBATScreen = true;
    }
    const isMasterAdminPage = !!(currentURLPath.startsWith('/admin/Home')
      || currentURLPath.startsWith('/admin/Master')
      || currentURLPath.startsWith('/admin/MasterTemplateConfigurator')
      || currentURLPath.startsWith('/admin/UserTrends')
      || currentURLPath.startsWith('/admin/Approval')
      || currentURLPath.startsWith('/admin/Report')
      || currentURLPath.startsWith('/admin/GOCForm'));
    if (
      (OperatingGropus.length && prevProps.OperatingGropus !== OperatingGropus && !isActiveBATScreen && !isMasterAdminPage)
      || (!isActiveBATScreen
        && !isMasterAdminPage
        && OperatingGropus.length
        && JSON.parse(sessionStorage.getItem('clientSelection'))
        && JSON.parse(sessionStorage.getItem('clientSelection')).id
        && selvalue !== JSON.parse(sessionStorage.getItem('clientSelection')).id)
      || (adminFromConfig
        && OperatingGropus.length
        && JSON.parse(sessionStorage.getItem('clientSelection'))
        && JSON.parse(sessionStorage.getItem('clientSelection')).id
        && selvalue !== JSON.parse(sessionStorage.getItem('clientSelection')).id
        && selvalue !== JSON.parse(sessionStorage.getItem('clientSelection')).id)
    ) {
      if (
        JSON.parse(sessionStorage.getItem('clientSelection'))
        && JSON.parse(sessionStorage.getItem('clientSelection')).id
        && (selvalue !== JSON.parse(sessionStorage.getItem('clientSelection')).id
          || prevProps.OperatingGropus !== this.props.OperatingGropus)
      ) {
        this.onSelectHandle('', JSON.parse(sessionStorage.getItem('clientSelection')));
        this.setState({
          selvalue: JSON.parse(sessionStorage.getItem('clientSelection')).id,
          selvalueDdl: JSON.parse(sessionStorage.getItem('clientSelection')).name,
        });
      }
      const arrData = [];
      OperatingGropus.forEach((client) => {
        arrData.push({
          isFavourite: client.IsFavourite,
          name: client.OrgEntityName,
          id: client.OrgEntityID,
          abbr: client.OrgEntityName.substr(0, 2),
          imageName: client.OrgEntityImage,
        });
      });
      this.setState({ clientListData: arrData, filterClientData: arrData }, () => {
        if (this.state.OrgEntityID > 0 && selvalue) {
          this.getCaseStudyLink();
        }
      });
    }

    if (JSON.stringify(prevProps.roleData) !== JSON.stringify(this.props.roleData)) {
      this.setState({
        roleData: this.props.roleData,
      }, () => {
        this.getFeatureDataModified();
      });
    }
    if(currentURLPath.includes("/Dashboard"))
    {
      if(!sessionStorage.getItem('clientSelection') && filterClientData.length === 1)
      {
        this.onSelectHandle(filterClientData[0].name, filterClientData[0]);
      }
    }
  }

  getUserFeatureDetails = (clientID) => {
    setUserFeatures(clientID,undefined,undefined,true) //clientID, offeringID, callback,IsMajorClick
  }

  matchCleintsToTerm(state, value) {
    return (
      state.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      || state.abbr.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  sortClients(a, b, value) {
    const aLower = a.name.toLowerCase();
    const bLower = b.name.toLowerCase();
    const valueLower = value.toLowerCase();
    const queryPosA = aLower.indexOf(valueLower);
    const queryPosB = bLower.indexOf(valueLower);
    if (queryPosA !== queryPosB) {
      return queryPosA - queryPosB;
    }
    return aLower < bLower ? -1 : 1;
  }

  getSnapshot = (value) => {
    const client = value.id;
    const isPulsePageAccessAllowed = this.checkIfPulsePageAccessAllowed();
    store.dispatch(apiload(true));
    return new Promise((resolve, reject) => {
      if (client !== undefined && client > 0 && client !== '') {
        axiosInstance
          .get(`${LocalApiBaseUrl}Snapshot/GetSnapshotsByUserID`, {
            params: { OrgEntityID: client, LanguageID: this.props.languageData.id },
          })
          .then((response) => {
            store.dispatch(apiload(false));
            store.dispatch({
              type: 'SNAPSHOT',
              payload: response.data,
            });
            if (!(this.props.history.location.pathname === '/Dashboard' || this.props.history.location.pathname === '/landing')) {
              this.callbackFunctionSetClientID(value);
            }

            if (response.data != null && response.data.length > 0) {
              const defaultSS = response.data.filter((f) => f.IsDefault === true);
              if (defaultSS.length > 0) {
                if (defaultSS[0].PageName !== 'Pulse') {
                  const { pathname } = this.props.history.location;
                  const path = pathname === '/Dashboard' ? '/Dashboard' : pathname === '/admin/Configurator' ? '/admin/Configurator' : '/Dashboard'; // From Master Screen to Dashboard
                  this.props.history.push({
                    pathname: path,
                    state: {
                      Tab: defaultSS[0].PageName,
                      ClientID: client,
                      pulseMenu: false,
                    },
                  });
                  // onTabChange(defaultSS[0].PageName)
                  resolve();
                } else // If Snapshot is Pulse
                  if (isPulsePageAccessAllowed) { // Check if User has Pulse Access
                    this.props.history.push({
                      pathname: '/landing',
                      state: { pulseMenu: true },
                    });
                  } else // If No Pulse Access Navigate to Dashboard
                  {
                    this.props.onTabChange('Business Outcomes');
                    this.props.history.push({
                      pathname: '/Dashboard',
                      state: { pulseMenu: false },
                    });
                  }
                store.dispatch({
                  type: 'SYNC_CURRENTFILTER',
                  payload: {
                    OfferingId: defaultSS[0].OfferingID,
                    ProcessId: defaultSS[0].OfferingID,
                    LocationId: defaultSS[0].LocationID,
                    Month: '',
                    LocationOMID: defaultSS[0].PageName === 'Journey Initiatives' ? ['All'] : [],
                    IsDataPublishForLocation: false,
                    isOMIDAvailable: false,
                    OfferingName: defaultSS[0].Offering_En,
                  },
                });
              } else {
                const emptySyncFilter = {
                  OfferingId: '',
                  ProcessId: '',
                  LocationId: '',
                  Month: '',
                  LocationOMID: [],
                  IsDataPublishForLocation: false,
                  isOMIDAvailable: false,
                  OfferingName: '',
                };
                store.dispatch({
                  type: 'SYNC_CURRENTFILTER',
                  payload: { ...emptySyncFilter },
                });
                if (isPulsePageAccessAllowed) {
                  this.props.history.push({
                    pathname: '/landing',
                    state: { pulseMenu: true },
                  });
                } else {
                  this.props.onTabChange('Business Outcomes');
                  this.props.history.push({
                    pathname: '/Dashboard',
                    state: { pulseMenu: false },
                  });
                }
              }
            } else {
              const emptySyncFilter = {
                OfferingId: '',
                ProcessId: '',
                LocationId: '',
                Month: '',
                LocationOMID: [],
                IsDataPublishForLocation: false,
                isOMIDAvailable: false,
                OfferingName: '',
              };
              store.dispatch({
                type: 'SYNC_CURRENTFILTER',
                payload: { ...emptySyncFilter },
              });
              if(!this.state.flag){
                if (isPulsePageAccessAllowed) {
                  this.props.history.push({
                    pathname: '/landing',
                    state: { pulseMenu: true },
                  });
                } else {
                  this.props.onTabChange('Business Outcomes');
                  this.props.history.push({
                    pathname: '/Dashboard',
                    state: { pulseMenu: false },
                  });
                }
              }
            }
            this.setState({ flag: false })
            resolve();
          })
          .catch((err) => {
            store.dispatch(apiload(false));
            this.callbackFunctionSetClientID(value);
            if (isPulsePageAccessAllowed) {
              this.props.history.push({
                pathname: '/landing',
                state: { pulseMenu: true },
              });
            } else {
              this.props.history.push({
                pathname: '/Dashboard',
                state: { pulseMenu: false },
              });
            }
            reject();
          });
      } else {
        this.callbackFunctionSetClientID(value);
        store.dispatch(apiload(false));
        reject();
      }
    });
  };

  onSelectHandle = async (event, value) => {
    const { reduxSetClientDetails } = this.props;
    const selectedClientId = value.id;
    setTimeout(() => {
      this.isClientDropdownOverlay();
    }, 100);
    sessionStorage.setItem('clientSelection', JSON.stringify(value));
    this.setState({ selvalueDdl: event, OrgEntityImage: selectedClientId === '0' ? '' : value.imageName, selvalue: selectedClientId });

    store.dispatch({
      type: 'SELECTED_METRIC_COMMENT_HISTORY',
      payload: { SOCategoryID: '', MetricDataID: '', showHistory: false },
    });
    const clientsData = this.props.OperatingGropus;
    let languageDropDown = [];
    let LanguageID = 1;
    let LanguageCode = 'en';
    clientsData.filter((each) => {
      if (String(each.OrgEntityID) === String(selectedClientId)) {
        languageDropDown = each.LanguageMasterModels;
        LanguageID = each.DefaultLanguage;
        languageDropDown.filter((e) => {
          if (e.LanguageID === LanguageID) {
            LanguageCode = e.LanguageCode;
          }
        });
      }
    });

    // Render Role Data
    this.getUserFeatureDetails(selectedClientId);
    axiosInstance
      .get(`${LocalApiBaseUrl}TranslatedLiterals/GetTranslatedText`, {
        params: { LanguageID, OrgEntityID: parseInt(selectedClientId), setDefault: false },
      })
      .then((response) => {
        if (response.data) {
          store.dispatch({
            type: 'LANGUAGE_DATA',

            payload: {
              id: parseInt(LanguageID),
              langCode: LanguageCode,
              messages: response.data,
              languageDropDown,
            },
          });
          const literals = {};
          literals[LanguageCode] = { translation: response.data };
          i18n.use(initReactI18next).init({
            resources: literals,
            lng: LanguageCode,
            fallbackLng: 'eng',

            interpolation: {
              escapeValue: false,
            },
          });

          this.setState(
            {
              languages: languageDropDown.length
                ? languageDropDown
                : [{ LanguageID: 1, LanguageCode: 'EN', LanguageDescription: 'English' }],
              selectedLanguage: LanguageCode,
            },
            () => {
              this.continueHandling(value, parseInt(LanguageID));
            },
          );
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        store.dispatch({
          type: 'LANGUAGE_DATA',

          payload: {
            id: parseInt(LanguageID),
            langCode: LanguageCode,
            messages: {},
            languageDropDown,
          },
        });
        const literals = {};
        literals.eng = { translation: {} };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: 'eng',
          fallbackLng: 'eng',

          interpolation: {
            escapeValue: false,
          },
        });
        this.setState(
          {
            languages: languageDropDown.length
              ? languageDropDown
              : [{ LanguageID: 1, LanguageCode: 'EN', LanguageDescription: 'English' }],
            selectedLanguage: LanguageCode,
          },
          () => {
            this.continueHandling(value, parseInt(LanguageID));
          },
        );
      });
    if (this.props.history.location.pathname === '/Dashboard' || this.props.history.location.pathname === '/landing') {
      store.dispatch({
        type: 'SELECTED_CLIENT',
        payload: value,
      });
      this.props.selectedFilter(value);
    }
    if (HasValue(event)) {
      this.handleUserNavigation(value.name, LanguageCode);
      await this.getSnapshot(value);
    } else {
      store.dispatch({
        type: 'SELECTED_CLIENT',
        payload: value,
      });
      this.props.selectedFilter(value);
    }
    this.onClientClick();
  };

  callbackFunctionSetClientID = (value) => {
    store.dispatch({
      type: 'SELECTED_CLIENT',
      payload: value,
    });
    this.props.selectedFilter(value);
  }

  continueHandling = (value, langid) => {
    this.props.selectedFilter(value);
    const isPulsePageAccessAllowed = this.checkIfPulsePageAccessAllowed();

    // IF PULSE PAGE ACCESS IS NOT ALLOWED THEN REDIRECT TO "SynOps Applied Now" (Dashboard)
    if (isPulsePageAccessAllowed) {
      store.dispatch(apiload(true));
      let pulsePageConfigure = false;
      this.setState({ selvalue: value.id, clientSelection: value });
      store.getState().roleReducers.roleReducers.map((each) => {
        if (each.FeatureName === 'PulsePage') {
          pulsePageConfigure = each.Operations.includes('Edit');
        }
      });
      if (!pulsePageConfigure) {
        axiosInstance
          .get(`${LocalApiBaseUrl}PulseDashBoard/GetPulseOfferings`, {
            params: { OrgEntityID: value.id, LanguageID: store.getState().CurrentFilter.languageData.id },
          })
          .then((response) => {
            store.dispatch({
              type: 'PULSE_DATA',

              payload: { offerings: response.data, data: [] },
            });
            /* if (response.data.length) {
              this.props.history.push({
                pathname: '/landing',
                state: { pulseMenu: true },
              });
            } else {
              this.props.history.push({
                pathname: '/Dashboard',
                state: { pulseMenu: false },
              });
            } */
          })
          .catch((error) => {
            trycatchAlertPopup(error);
            /* this.props.history.push({
              pathname: "/Dashboard",
              state: { pulseMenu: false },
            }); */
          });
      } else {
        axiosInstance
          .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
            params: { OrgEntityID: value.id, LanguageID: store.getState().CurrentFilter.languageData.id, featureName: 'PulsePage' },
          })
          .then((response) => {
            if (response.data.length) {
              store.dispatch({
                type: 'PULSE_DATA',

                payload: { offerings: response.data, data: [] },
              });
              /* this.props.history.push({
                pathname: "/landing",
                state: { pulseMenu: true },
              }); */
            } else {
              store.dispatch({
                type: 'PULSE_DATA',

                payload: { offerings: [], data: [] },
              });
              /* this.props.history.push({
                pathname: "/Dashboard",
                state: { pulseMenu: false },
              }); */
            }
            store.dispatch(apiload(false));
          })
          .catch((error) => {
            store.dispatch({
              type: 'PULSE_DATA',
              payload: { offerings: [], data: [] },
            });
            trycatchAlertPopup(error);
            /* this.props.history.push({
              pathname: "/Dashboard",
              state: { pulseMenu: false },
            }); */
          });
      }
    }
  };

  redirectbase() {
    window.location.href = DomainBaseUrl;
  }

  onLanguageChange = (event) => {
    store.dispatch(apiload(true));
    const selectedValue = event;
    if (selectedValue) {
      axiosInstance
        .get(`${LocalApiBaseUrl}TranslatedLiterals/GetTranslatedText`, {
          params: { LanguageID: parseInt(selectedValue), OrgEntityID: this.state.selvalue, setDefault: true },
        })
        .then((response) => {
          if (response.data) {
            this.setState({
              selectedLanguage: parseInt(selectedValue),
            });
            store.dispatch({
              type: 'LANGUAGE_DATA',

              payload: { id: parseInt(selectedValue), messages: response.data },
            });
            store.dispatch(apiload(false));
          }
          window.location.reload();
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          window.location.reload();
        });
    }
  };

  handleClick = () => {
    const currentURLPath = this.props.history.location.pathname;
    this.toggleMenuClose();
    store.dispatch({
      type: 'PULSE_PAGEDATA',

      payload: {
        SubOffering: '', Offering: '', offeringId: 0, pulseData: [],
      },
    });
  };

  filterClients = (event) => {
    const filteredClients = [];
    const clients = this.state.clientListData.filter((data) => {
      if (data.name.toUpperCase().includes(event.target.value.toUpperCase())) {
        filteredClients.push(data);
      }
    });
    this.setState({ filterClientData: filteredClients });
  };

  handleUserNavigation = (client, languageCode) => {
    const data = {
      PageName: screeNamesForUserTracking.ClientSelect,
      Client: client,
      Language: languageCode,
    };
    logUserTrackingData(data);
  };

  // EVENT HANDLER FOR "Benchmark Analytics (BAT)" MENU LINK CLICK. WILL EXECUTE THE API TO LOG USER TRACKING DATA
  handleClickBATMenuLink = () => {
    const { languageData } = this.props;
    const data = {
      PageName: screeNamesForUserTracking.BAT_LANDING_SCREEN,
      Language: languageData.langCode,
    };
    logUserTrackingData(data);
    this.leftPanelModalClose();
  };

  checkIfPulsePageAccessAllowed = () => {
    const { roleData } = this.props;
    const foundPermissionObj = _.find(roleData, { FeatureName: 'PulsePage' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (allowedOperations.indexOf('View') || allowedOperations.indexOf('Edit')) {
        hasAccessPermission = true;
      }
    }

    return hasAccessPermission;
  };

  handleImageError = (e) => {
    e.target.src = Client;
  };

  hasClientStoryAccess = () => {
    const { roleData } = this.props;
    const foundPermissionObj = _.find(roleData, { FeatureName: 'Client Story' });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      const allowedOperations = foundPermissionObj.Operations;

      // ACCESS WOULD ONLY BE ALLOWED IF THE USER HAS EITHER "VIEW" OR "EDIT" PERMISSION
      if (allowedOperations.indexOf('Edit') || allowedOperations.indexOf('View')) {
        hasAccessPermission = true;
      }
    }
    return hasAccessPermission;
  };

  dealpopup = (e) => {
    e.preventDefault();
    if (this.props.location.pathname.startsWith('/admin/Configurator')) {
      this.setState({ viewDealConfig: true }, () => {
        this.leftPanelModalClose();
      });
    } else {
      this.setState({ viewmore: true }, () => {
        this.leftPanelModalClose();
      });
    }
  };

  onClose = (e) => {
    this.setState({ viewmore: false, viewDealConfig: false });
  };

  // case study
  addEditCaseStudyLinkModalShow = (status) => {
    this.setState(
      {
        caseStudypopupStatus: status,
        addEditCaseStudyLinkStatus: true,
      },
      () => {
        this.leftPanelModalClose();
      },
    );
  };

  closeCaseStudy = () => {
    this.setState({
      caseStudypopupStatus: '',
      addEditCaseStudyLinkStatus: false,
    });
  };

  saveCaseStudy = (data) => {
    if (Object.entries(data).length > 0) {
      data.URL = ExtractSrcLink(data.URL);
    }

    axiosInstance.post(`${LocalApiBaseUrl}CaseStudy/AddEditClientCaseStudy`, data).then((response) => {
      this.setState(
        {
          caseStudylinkData: data,
          caseStudypopupStatus: '',
          addEditCaseStudyLinkStatus: false,
        },
        () => {
          this.getCaseStudyLink();
        },
      );
      alert(response.data.StatusMessage);
    })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };

  getCaseStudyLink = () => {
    store.dispatch(apiload(true));
    const {
      InitializeCaseStudyData,
    } = this.props;
    if (this.props.clientID) {
      axiosInstance
        .get(`${LocalApiBaseUrl}CaseStudy/GetClientCaseStudy`, {
          params: {
            OrgEntityID: this.props.clientID,
          },
        })
        .then((response) => {
          setTimeout(() => {
            store.dispatch(apiload(false));
          }, 2000);
          const responseData = response.data;
          InitializeCaseStudyData(responseData);
          this.setState({
            caseStudylinkData: responseData,
          });
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  deleteCaseStudyLink = () => {
    this.closeCaseStudy();
    this.getCaseStudyLink();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    // CALLING CASESTUDYLINK API WHEN THE USER CHANGES THE CLIENT IN THE DROP DOWN
    if (nextProps.clientID != undefined && nextProps.clientID != 0) {
      if (this.props.clientID != nextProps.clientID && nextProps.filterDetails) {
        this.setState(
          {
            clientName: nextProps.filterDetails.Client,
            OrgEntityID: nextProps.clientID,
          },
          () => {
            this.getCaseStudyLink();
          },
        );
      }
    }
    if (this.props.CaseStudyData !== nextProps.CaseStudyData) {
      this.setState({
        caseStudylinkData: nextProps.CaseStudyData,
      });
    }
  }

  GetAllClientRegion() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetAllClientRegion`, null, {
        params: { LanguageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        this.setState({ ClientRegions: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  GetAllMaturityPhase() {
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetMaturityPhase`, null, {
        params: {},
      })
      .then((response) => {
        this.setState({ MaturityPhase: response.data });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  handleHomePage = () => {
    // Clearing old Redux data on Navigating to Home Page
    setUserFeatures();
    store.dispatch({
      type: 'SELECTED_CLIENT',
      payload: {},
    });
    store.dispatch({
      type: 'SYNC_CURRENTFILTER',
      payload: {},
    });
    store.dispatch({
      type: 'SNAPSHOT',
      payload: [],
    });
    const pulsePagedata = {
      SubOffering: '',
      offeringId: '',
      Offering: '',
      pulseData: [],
    };
    const pulseData = { offerings: [], data: [] };
    store.dispatch({ type: 'PULSE_PAGEDATA', payload: pulsePagedata });
    store.dispatch({ type: 'PULSE_DATA', payload: pulseData });

    sessionStorage.removeItem('clientSelection');
  };

  modifylink=(caseStudylink) => {
    let url = caseStudylink;
    const testurl = caseStudylink.toLowerCase();

    if (testurl.startsWith('www')) {
      url = `https://${url}`;
    } else if (!testurl.startsWith('www') && !testurl.startsWith('https://') && !testurl.startsWith('http://')) {
      url = `https://${url}`;
    }
    return url;
  }

  subHeaderOpen = (event) => {
    this.props.subHeaderOpenCallback(true);
    event.preventDefault();
  }

  subHeaderClose = (event) => {
    this.props.subHeaderCloseCallback(false);
    event.preventDefault();
  }
logOut=()=>{
  const {instance}=this.context;
  const{userLoginData} =this.props;
  console.log("USER DATA PROPS:",userLoginData);
   console.log("Instances:",instance);
   instance.logoutRedirect({
     account: instance.getAccountByHomeId(userLoginData.account.homeAccountId)
 });
 this.TrackUser(TRACKING_MODULES.EXPIRED_SESSION);
}
showHideToolTip=(isShow)=>{
if(isShow){
  ReactTooltip.show();
}
else{
  ReactTooltip.hide();
}
}

  render() {
    const {
      isVisibleNotification,
      isAnyNewNotification,
      caseStudylinkData,
      configuratorEditAccess,
      caseStudypopupStatus,
      addEditCaseStudyLinkStatus,
      OrgEntityImage,
      selvalue,
      newNotificationCount,
    } = this.state;
    const { browserHistory, history } = this.props;
    const adminSectionFrom = store.getState().CurrentFilter.adminFrom;
    const adminFromConfig = !!(this.props.history.location.state && this.props.history.location.state.showConfiguratorFilterForAdminView);
    const isRetainConfigHeader = adminSectionFrom == '/admin/Configurator' || adminFromConfig;
    const isRetainAdminHeader = (adminSectionFrom == '' || adminSectionFrom == '/admin/Home') && !adminFromConfig;

    const currentURLPath = this.props.history.location.pathname;

    let isActiveBATScreen = false;
    if (
      currentURLPath === `/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_TAKE_ASSESSMENT}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_VIEW_ASSESSMENT}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`
      || currentURLPath === `/${ROUTE_CONSTANTS.BAT_ACCESS_MANAGEMENT}`
    ) {
      isActiveBATScreen = true;
    }

    const arr = [];
    const s = this.state.CityOptions.map((cl) => {
      arr.push({ name: cl.props.children, id: cl.props.value, abbr: cl.props.children.substr(0, 2) });
    });
    if (this.props.history.location.pathname === '/admin/Configurator') {
      linkPush = '/admin/Configurator';
    } else if (this.props.history.location.pathname === '/Dashboard') {
      linkPush = '/Dashboard';
    }
    const selectbar = (
      <>
        {this.state.selvalue == '0' || this.state.selvalue == '' ? (
          <select
            style={{ display: 'none' }}
            ref="ClientDropdown"
            onClick={() => {
              this.onClientClick();
            }}
            onChange={(event) => {
              this.selectedFilter(event);
            }}
            value={this.state.selvalue}
          >
            <option value="0">{i18n.t('Select_Client')}</option>
            {this.state.CityOptions}
          </select>
        ) : (
          <select
            style={{ display: 'none' }}
            ref="ClientDropdown"
            onClick={() => {
              this.onClientClick();
            }}
            onChange={(event) => {
              this.selectedFilter(event);
            }}
            value={this.state.selvalue}
          >
            <option value="0">{i18n.t('Select_Client')}</option>
            {this.state.CityOptions}
          </select>
        )}
      </>
    );

    const isPulsePageAccessAllowed = this.checkIfPulsePageAccessAllowed();
    const isMasterAdminPage = !!((currentURLPath.startsWith('/admin/Home')
      || currentURLPath.startsWith('/admin/Master')
      || currentURLPath.startsWith('/admin/MasterTemplateConfigurator')
      || currentURLPath.startsWith('/admin/Connector')
      || currentURLPath.startsWith('/admin/UserTrends')
      || currentURLPath.startsWith('/admin/Approval')
      || currentURLPath.startsWith('/admin/Report')
      || currentURLPath.startsWith('/admin/GOCForm'))
      && isRetainAdminHeader);

    const isDemoPage = currentURLPath.startsWith('/SynOpsDemo');
    const isOperationPage = currentURLPath.startsWith('/OperationalReviews');
    const isDashboardPage = currentURLPath.startsWith('/Dashboard');
    const isPulsePage = currentURLPath.startsWith('/landing');
    const isHelpSection = currentURLPath.startsWith('/helpfeatures');
    const isViewClientPage = currentURLPath.startsWith('/viewclientproject');
    const isFrontdoorAnalytics = currentURLPath.startsWith('/FrontdoorAnalytics');
    const isClientStory = currentURLPath.startsWith('/ClientStory');

    const isShowPulsePage = this.state.pulseDataExist && isPulsePageAccessAllowed && !isDemoPage;
    const noCaretClass = this.state.featureData.findIndex((f) => f == 'Dashboard') > -1
      && this.state.featureData.findIndex((f) => f == 'Configurator') > -1
      ? ''
      : ' nocaret';
    const configSectionFrom = !!(history.location.state && history.location.state.showConfiguratorFilterForAdminView);
    let activeClass = '';
    activeClass = this.props.history.location.pathname.startsWith('/landing') ? ''
      : (this.props.history.location.pathname.startsWith('/admin/Configurator')
        || this.props.history.location.pathname == '/Dashboard' || configSectionFrom)
        ? 'active'
        : '';

    const isShowMyProfile = (!isPulsePage
      && !isMasterAdminPage
      && !isOperationPage
      && !isHelpSection
      && this.state.featureData.includes('My Deal Profile'));
    const isShowCaseStudy = ((!isMasterAdminPage || isRetainConfigHeader)
      && (this.state.featureData.filter((data) => data == 'Configurator').length > 0 || this.props.userRole.IsGuest));
    const isShowOR = (this.state.featureData.filter((data) => data == 'Dashboard')
      && this.props.userRole
      && !isMasterAdminPage
      && !this.props.userRole.IsGuest);
    return (
      <>
        <LoadingOverlay
          active={this.props.apiload}
          spinner={<SynopsLoader />}
        />
        <div className={this.props.apiload ? 'apiload' : ''} />

        <nav className="navbar navbar-expand-lg fixed-top navbar-default navbarBat newBanner" onMouseEnter={this.subHeaderOpen} onMouseLeave={this.subHeaderClose}>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#topmenuDrop"
            aria-controls="topmenuDrop"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <FontAwesomeIcon icon={faBars} />
            </span>
          </button>
          <a className={this.props.history.location.pathname === '/FrontdoorAnalytics' ? "navbar-brand leftpanelmodal-link synops-analytics" : "navbar-brand leftpanelmodal-link"}>
            <img
              loading="lazy"
              onClick={this.leftPanelModalShow}
              src={navbarLogo}
              alt="Synops-logo"
              width="32"
              height="32"
            />
            {this.props.history.location.pathname === '/FrontdoorAnalytics'?
              <span>Synops Experience Analytics</span>:
              <span>SynOps Frontdoor</span>
            }
          </a>

          {/* HIDE THE CLIENT SELECTION DROPDOWN (AUTOCOMPLETE) ON BAT SCREENS */}
          {!isActiveBATScreen && selectbar}

          {/* <ChangeLang /> */}
          <ul className="navbar-nav search-nav mr-auto synops-header">
            <>
              {((!isDemoPage
                && !isFrontdoorAnalytics
                && !isActiveBATScreen
                && !isViewClientPage
                && (!isMasterAdminPage || currentURLPath.startsWith('/admin/Connector')))
                || isRetainConfigHeader) && (
                  <li className="header-selectclient select-data">
                    <div className="client-select-dropdown" onClick={this.isClientDropdown}>
                      {OrgEntityImage !== '' ? (
                        <img
                          loading="lazy"
                          src={`${LocalApiBaseUrlImage}${OrgEntityImage}`}
                          width="32"
                          height="32"
                          className="client-logo"
                          alt=""
                          onError={this.handleImageError}
                        />
                      ) : (
                        <img loading="lazy" src={Client} alt="" className="client-logo" width="32" height="32" />
                      )}
                      <span className="client-name">
                        {this.state.selvalueDdl === 'Select Client' && this.currentlangjson.Select_Client
                          ? this.currentlangjson.Select_Client
                          : this.state.selvalueDdl}
                      </span>
                      {this.state.isClientDropdownStatus ? (
                        <div className="client-select-list">
                          <p className="select-client-text">
                            <span>{i18n.t('Select_Client')}</span>
                            {/* <span className="clear">CLEAR</span> */}
                          </p>
                          <div className="searchFilter">
                            <input type="search" placeholder="Search ..." onChange={this.filterClients} />
                          </div>
                          <ul>
                            {this.state.filterClientData.sort((x, y) => Number(y.isFavourite) - Number(x.isFavourite) || x.name.trim().localeCompare(y.name.trim())).map((clientLists, index) => (
                              <li
                                onClick={this.onSelectHandle.bind(this, clientLists.name, clientLists)}
                                className={this.state.selvalueDdl === clientLists.name ? 'active' : ''}
                              >
                                <LazyLoadComponent>
                                  <div className='d-flex justify-content-between w-100'>
                                    <div>
                                      {clientLists.imageName ? (
                                        <img
                                          loading="lazy"
                                          src={`${LocalApiBaseUrlImage}${clientLists.imageName}`}
                                          width="32"
                                          height="32"
                                          alt=""
                                          onError={this.handleImageError}
                                        />
                                      ) : (
                                        <img loading="lazy" src={Client} alt="" width="32" height="32" />
                                      )}
                                      {' '}
                                      <span>{clientLists.name}</span>
                                    </div>
                                    {clientLists.isFavourite && (
                                      <div>
                                        <i className="fas fa-star active-star float-right" aria-hidden="true"></i>
                                      </div>
                                    )}
                                  </div>
                                </LazyLoadComponent>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    {this.state.isClientDropdownStatus ? (
                      <div className="client-select-list-overlay" onClick={this.isClientDropdownOverlay} />
                    ) : (
                      ''
                    )}
                  </li>
                )}
              {/* client select dropdown ends here */}

              {/* IF PULSE PAGE ACCESS IS NOT ALLOWED THEN DON'T DISPLAY THE "PULSE" MENU LINK */}
              {isShowPulsePage
                && !isFrontdoorAnalytics
                && !isActiveBATScreen
                && !isViewClientPage
                && (!isMasterAdminPage || isRetainConfigHeader) ? (
                <li className="nav-item">
                  <NavLink
                    to={{
                      pathname: '/landing',
                      state: { pulseMenu: true },
                    }}
                    className="nav-link"
                  >
                    {' '}
                    {i18n.t('Pulse')}
                  </NavLink>
                </li>
              ) : (
                ''
              )}
              {/* ADMIN */}
              {isMasterAdminPage && isRetainAdminHeader && (
                <li className="nav-item appliednow-dropdown disabled-cursor">
                  <a className="nav-link disabled-headernavlink">{i18n.t('Administration')}</a>
                </li>
              )}
              {isActiveBATScreen && (
                <li className="nav-item appliednow-dropdown disabled-cursor">
                  <a className="nav-link disabled-headernavlink">{i18n.t('Benchmark_Analytics')}</a>
                </li>
              )}
              {isDemoPage && (
                <li className="nav-item appliednow-dropdown disabled-cursor">
                  <a className="nav-link disabled-headernavlink">{i18n.t('Demo')}</a>
                </li>
              )}
              {isViewClientPage && (
                <li className="nav-item appliednow-dropdown disabled-cursor">
                  <a className="nav-link disabled-headernavlink">{i18n.t('Client Profiles')}</a>
                </li>
              )}
            </>
            {!isDemoPage
              && !isFrontdoorAnalytics
              && !isActiveBATScreen
              && !isViewClientPage
              && (!isMasterAdminPage || isRetainConfigHeader)
              && (this.state.featureData.includes('Dashboard') || this.state.featureData.includes('Configurator')) && (
                <li className="nav-item appliednow-dropdown">
                  <DropdownButton
                    id="synopsapplied-button"
                    title={this.state.titleValue}
                    className={`custombg-headerdropdown mr-3 ${activeClass}${noCaretClass}`}
                    onMouseEnter={() => {
                      this.toggleMenuOpen();
                    }}
                    onMouseLeave={() => {
                      this.toggleMenuClose();
                    }}
                    // href={linkPush}
                    onClick={(e) => {
                      this.handleClick();
                      this.dropnav(e);
                    }}
                    show={this.state.NavMenuDropdown}
                  >
                    {this.state.activeDDId == 'configLink' && this.state.featureData.includes('Dashboard') && (
                      <NavLink
                        id="fdLink"
                        to=""
                        onClick={() => {
                          this.handleClick();
                          this.showSynopsOption(); // true -- SynopsOption
                        }}
                      >
                        {i18n.t('SynOps_Applied_Now')}
                      </NavLink>
                    )}
                    {this.state.activeDDId == 'fdLink' && this.state.featureData.includes('Configurator') && (
                      <NavLink
                        id="configLink"
                        to=""
                        onClick={() => {
                          this.handleClick();
                          this.showConfigOption(); // true -- ConfigOption
                        }}
                      >
                        {i18n.t('Configurator')}
                      </NavLink>
                    )}
                  </DropdownButton>
                </li>
              )}
          </ul>
          <ul className="navbar-nav flex-row ml-auto synops-header navbar-right-section">
            {/* HIDE THE CLIENT SELECTION DROPDOWN ON BAT SCREENS */}

            <li className="language-select">
              <DropdownButton
                menuAlign="right"
                id="language-button"
                title={this.state.selectedLanguage.toUpperCase()}
                onSelect={this.onLanguageChange}
                className="custom-headerdropdown mr-3"
              >
                <Dropdown.ItemText>{i18n.t('Select Language')}</Dropdown.ItemText>
                {this.state.languages.map((each) => {
                  let icon_src = English;
                  switch (each.LanguageDescription) {
                    case 'Japanese':
                      icon_src = Japanese;
                      break;
                    case 'Chinese':
                      icon_src = Chinese;
                      break;
                    case 'French':
                      icon_src = French;
                      break;
                    case 'German':
                      icon_src = German;
                      break;
                  }
                  return (
                    <Dropdown.Item eventKey={each.LanguageID} value={each.LanguageID}>
                      <img loading="lazy" src={icon_src} alt="" width="32" height="32" />
                      <span>
                        {each.LanguageDescription}
                        {' '}
                        (
                        {each.LanguageCode.toUpperCase()}
                        )
                        {' '}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </li>

            {/* ON BAT SCREENS DISPLAY NOTIFICATIONS ICON IN HEADER */}
            {/* {currentURLPath === "/landing" ||
            currentURLPath === "/admin/Configurator" ||
            currentURLPath === "/Dashboard" ||
            currentURLPath === "/admin/MasterTemplateConfigurator" ||
            isActiveBATScreen ? ( */}
            <>
              <li className="nav-item assessment-notifylink">
                <a
                  href="javascript:void(0)"
                  className="nav-link notification-link"
                  onClick={() => this.showNotification()}
                >
                  <span className={classNames({ 'active-notification': isAnyNewNotification })}>
                    {isAnyNewNotification && <span className="notification-count">{newNotificationCount > 9 ? '9+' : newNotificationCount}</span>}
                    <i className="fal fa-bell" />
                  </span>
                </a>
              </li>
              <li className="nav-item log-out-select">
                {/* <span className="log-out" onClick={() => this.TrackUser(TRACKING_MODULES.EXPIRED_SESSION, this.logOut)}> */}
                <span className="log-out" onClick={() => {
                  this.TrackUser(TRACKING_MODULES.EXPIRED_SESSION);
                  setTimeout(() => {
                    this.logOut();
                  }, 2000);                 
                }}>
                  <i className="fal fa-sign-out"
                    data-tip
                    data-for="sign-out"
                    onMouseEnter={this.showHideToolTip(true)}
                    onMouseLeave={this.showHideToolTip(false)}
                  />
                </span>
                <ReactTooltip
                  place="bottom"
                  effect="solid"
                  id={`sign-out`}
                  className="sign-out-tooltip"
                >
                  <span>{i18n.t('Sign_Out')}</span>
                </ReactTooltip>
              </li>
            </>
          </ul>
        </nav>
        {/* Support Moda Popoup */}
        <Modal show={this.state.supportModalStatus} onHide={this.supportModalClose} backdrop="static" className="support-pop" centered>
          <Modal.Header closeButton>
            <Modal.Title>Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="support-content">
              <div className="support-data">
                <p>
                  For any queries or issues, please raise a ticket on the
                  {' '}
                  <a href="https://bps-portal.accenture.com/ohrcc/pages/Accenture/home​" target="_blank">
                    BPS Portal
                  </a>
                </p>
                <p>Please refer Help Topics for detailed steps for raising a ticket</p>
              </div>
              <div className="support-image">
                <img loading="lazy" src={Supportimage} alt="SupportImage" />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {isVisibleNotification && (
          <Notification
            show={isVisibleNotification}
            onHide={() => this.handleCallbackNotificationHide()}
            browserHistory={browserHistory}
            onNewNotification={(isAnyNewNotification, newNotificationCount) => this.handleCallbackOnNewNotification(isAnyNewNotification, newNotificationCount)}
          />
        )}
        {/* Left Moda Popoup */}
        <Modal
          show={this.state.leftPanelModalStatus}
          onHide={this.leftPanelModalClose}
          className="leftpanel-modal left"
        >
          <Modal.Header closeButton>
            <div className="leftpanelheader-block">
              <div className="userprofile-info">
                <div className="userprofile-img mr-2">
                  <a href="https://people.accenture.com" target="_blank"><img src={this.state.ProfileSrc} /></a>
                </div>
                <div className="userprofile-data">
                  {this.state.userName && (
                    <h6>
                      {i18n.t('Welcome')}
                      ,
                      {' '}
                    </h6>
                  )}
                  {/* {this.state.userName}@accenture.com */}
                  {this.state.userName && <span>{this.state.userName}</span>}
                </div>
              </div>
              {this.state.date != null && this.state.date !== '0001-01-01T00:00:00' ? (
                <p>
                  {i18n.t('Last visited on')}
                  {' '}
                  {Moment.utc(this.state.date)
                    .local()
                    .format('MMMM Do YYYY, h:mm A')}
                </p>
              ) : null}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="leftmenu-navlinks">
              <ul className="custom-screen-scroll">
                <li className="leftmenu-listlinks">
                  <ul>
                    <li>
                      <NavLink onClick={this.handleHomePage} to="/HomePage">
                        {i18n.t('Home')}
                      </NavLink>
                    </li>
                    {isShowPulsePage && (
                      <li>
                        <NavLink
                          to="/landing"
                          onClick={this.leftPanelModalClose}
                          className={currentURLPath.startsWith('/landing') ? 'active' : ''}
                        >
                          {i18n.t('Pulse')}
                        </NavLink>
                      </li>
                    )}
                    {/* className="active" */}
                    {!isDemoPage && this.state.featureData.includes('Dashboard') && (
                      <li>
                        <NavLink
                          to="/Dashboard"
                          onClick={this.leftPanelModalClose}
                          className={currentURLPath.startsWith('/Dashboard') ? 'active' : ''}
                        >
                          {i18n.t('SynOps_Applied_Now')}
                        </NavLink>
                      </li>
                    )}
                    {(isActiveBATScreen || (!isHelpSection && !isViewClientPage)) && (
                      <li>
                        <NavLink
                          to={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`}
                          onClick={() => {
                            this.handleClickBATMenuLink();
                            this.TrackUser(TRACKING_MODULES.BENCHMARK_ANALYTICS)
                          }}
                          className={currentURLPath.startsWith('/bat') ? 'active' : ''}
                        >
                          {i18n.t('Benchmark_Analytics')}
                        </NavLink>
                      </li>
                    )}
                    {( //(isDashboardPage) &&
                      <li>
                        <NavLink
                          to="/FrontdoorAnalytics"
                          onClick={() => {
                            this.leftPanelModalClose();
                            this.TrackUser(TRACKING_MODULES.SYNOPS_FRONTDOOR_ANALYTICS);
                          }}
                          className={currentURLPath.startsWith('/FrontdoorAnalytics') ? 'active' : ''}
                        >
                          {i18n.t('SynOps Analytics')}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
                {!isDemoPage && !isFrontdoorAnalytics && !isMasterAdminPage && !isActiveBATScreen && !isViewClientPage && (isShowOR || isShowCaseStudy || isShowMyProfile) && (
                  <li className="leftmenu-listlinks">
                    <ul>
                      {
                        this.hasClientStoryAccess()
                        && (
                          <li>
                            <NavLink
                              to="/ClientStory"
                              onClick={this.leftPanelModalClose}
                              className={currentURLPath.startsWith('/ClientStory') ? 'active' : ''}
                            >
                              Client Story
                            </NavLink>
                          </li>
                        )
                      }
                      {isShowOR && (
                        <li>
                          <NavLink
                            to="/OperationalReviews"
                            onClick={() => {
                              this.leftPanelModalClose();
                              this.TrackUser(TRACKING_MODULES.OPERATIONAL_REVIEWS);
                            }}
                            className={currentURLPath.startsWith('/OperationalReviews') ? 'active' : ''}
                          >
                            {i18n.t('Operational Review')}
                          </NavLink>
                        </li>
                      )}
                      {isShowCaseStudy && (
                        <li
                          className={
                            this.props.clientID
                              && (caseStudylinkData.URL
                                || (configuratorEditAccess && currentURLPath === '/admin/Configurator'))
                              ? 'casestudy-linknav '
                              : 'casestudy-linknav disabled'
                          }
                        >
                          <a
                            href={caseStudylinkData.URL ? this.modifylink(caseStudylinkData.URL) : '#'}
                            target={caseStudylinkData.URL ? '_blank' : ''}
                            onClick={() => {
                              this.leftPanelModalClose();
                              this.TrackUser(TRACKING_MODULES.CASE_STUDY);
                            }}
                          >
                            <span>{i18n.t('Case_Study')}</span>
                          </a>
                          {// User with Configurator Edit Access- Save and Submit Access
                            this.props.clientID && configuratorEditAccess && currentURLPath === '/admin/Configurator' && (
                              <div className="casestudy-hoverlinks">
                                {caseStudylinkData.URL ? (
                                  <span
                                    onClick={() => {
                                      this.addEditCaseStudyLinkModalShow('Edit');
                                    }}
                                  >
                                    <i className="far fa-edit" />
                                  </span>
                                ) : (
                                  <span
                                    to="#"
                                    onClick={() => {
                                      this.addEditCaseStudyLinkModalShow('Add');
                                    }}
                                  >
                                    <i className="fas fa-plus" />
                                  </span>
                                )}
                              </div>
                            )
                          }
                        </li>
                      )}
                      {isShowMyProfile && !this.props.userRole.IsGuest && (
                        <li>
                          <a
                            href="javscript:void(0);"
                            onClick={(e) => {
                              this.dealpopup(e);
                              this.TrackUser(TRACKING_MODULES.DEAL_PROFILE)
                            }}
                          >
                            {i18n.t('Deal Profile')}
                          </a>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
                {!isPulsePage
                  && !isFrontdoorAnalytics
                  && !isDashboardPage
                  && !isMasterAdminPage
                  && !isActiveBATScreen
                  && !isOperationPage
                  && !isDemoPage
                  && !isClientStory
                  && !this.props.userRole.IsGuest && (
                    <li className="leftmenu-listlinks">
                      <ul>
                        {!isViewClientPage
                          && this.state.featureData.includes('Configurator')
                          && this.state.featureData.includes('Help') && (
                            <li>
                              <NavLink
                                to="/helpfeatures"
                                onClick={this.leftPanelModalClose}
                                className={currentURLPath.startsWith('/helpfeatures') ? 'active' : ''}
                              >
                                {i18n.t('Help')}
                              </NavLink>
                            </li>
                          )}
                        {this.state.featureData.includes('Configurator') && (
                          <li>
                            <a href="javascript:void(0);" onClick={this.supportModalShow}>
                              {i18n.t('Support')}
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
                <li className="leftmenu-listlinks">
                  <ul>
                    {(!this.state.featureData.includes('Configurator')
                      && (this.state.featureData.includes('Reports')
                        || this.state.featureData.includes('Approval')
                        || this.state.featureData.includes('Connector')))
                      || this.state.featureData.includes('User Trends')
                      || this.state.featureData.includes('Master')
                      || this.state.featureData.includes('Master Template Configurator') ? (
                      <li>
                        <NavLink
                          to="/admin/Home"
                          onClick={() => {
                            this.leftPanelModalClose();
                            setUserFeatures() // Calling GetUserFeatures without Client ID
                          }}
                          className={
                            currentURLPath !== '/admin/Configurator'
                              && isRetainAdminHeader
                              && currentURLPath.startsWith('/admin')
                              ? 'active'
                              : ''
                          }
                        >
                          {i18n.t('Administration')}
                        </NavLink>
                      </li>
                    ) : null}
                    {/* <li>
                      <a
                        target="_blank"
                        href="https://in.accenture.com/accentureoperations/sell-business-process-services/"
                        onClick={this.leftPanelModalClose}
                      >
                        {i18n.t('About_SynOps')}
                      </a>
                    </li> */}
                    <li>
                      <a
                        target="_blank"
                        href="javascript:void(0);"
                        onClick={this.logOut}
                      >
                        {i18n.t('Sign_Out')}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
        {this.state.viewmore ? (
          <DealPopUp
            show={this.state.viewmore}
            onClose={this.onClose}
            opsgrp={this.props.opsgrp}
            filterDetails={this.props.filterDetails}
          />
        ) : null}
        {this.state.viewDealConfig ? (
          <DealConfiguratorPopUp
            show={this.state.viewDealConfig}
            profile={this.state.viewDealConfig}
            onClose={this.onClose}
            opsgrp={this.props.opsgrp}
            filterDetails={this.props.filterDetails}
            ClientRegions={this.state.ClientRegions}
            MaturityPhase={this.state.MaturityPhase}
          />
        ) : null}
        {addEditCaseStudyLinkStatus && (
          <CaseStudyLinkPoup
            data={caseStudylinkData}
            status={caseStudypopupStatus}
            show={addEditCaseStudyLinkStatus}
            saveCaseStudy={this.saveCaseStudy}
            deleteCaseStudyLink={this.deleteCaseStudyLink}
            OrgEntityID={this.state.OrgEntityID}
            closeCaseStudy={this.closeCaseStudy}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  languageData: state.CurrentFilter.languageData,
  selectedClientData: state.CurrentFilter.ClientData,
  roleData: state.roleReducers.roleReducers,
  clientID: state.CurrentFilter.ClientData.id,
  CaseStudyData: state.PulseMORCaseStudyReducers.CaseStudyData,
  apiload: state.JourneyReducer.apiload,
  notificationData: { ...state.NotificationReducer },
  userLoginData:{...state.userLoginDataReducer}
});

const mapDispatchToProps = (dispatch) => ({
  InitializeCaseStudyData: (data) => {
    dispatch({ type: 'PULSE_CASESTUDY_DATA_INITIALIZE', payload: data });
  },
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true },
  ),
)(Header);
