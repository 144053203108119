import React, { useEffect, useState } from "react";
import { Tabs, Tab, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import i18n from "i18next";
import AccessRequestForm from "./AccessRequestForm";
import ClientDropdown from "./ClientDropdown";
import FillDetails from "./FillDetails";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import axiosInstance from "../Shared/interceptor";
import { withRouter } from "react-router-dom";
import { LocalApiBaseUrl } from "../Shared/Constant";

const RequestDealAccess = (props) => {
  const formInputInit = {
    requestDate: "",
    offering: "",
    suboffering: "",
    role: "",
    userEnterpriseId: "",
    action: "",
  };

  const selectedClientInit = {
    client: "",
    clientID: -1,
  };
  const [formDataAccenture, setFormDataAccenture] = useState(new Array(25).fill(formInputInit));
  const [tab, setTab] = useState("AccentureUser");
  const [selectedClientAccenture, setSelectedClientAccenture] = useState(selectedClientInit);
  const [remarksAccenture, setRemarksAccenture] = useState("");
  const [cxlIdAccenture, setCxlIdAccenture] = useState("");
  const [oalIdAccenture, setOalIdAccenture] = useState("");
  const [offerings, setOfferings] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [allClients, setAllClients] = useState([]);
  const [showSuccessMsg, setShowSuccessMsg] = useState('');

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    setShowLoader(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetViewProjectsClientList`, {
          params: {isDemo:false},
        })
      .then((response) => {
        setAllClients(response.data);
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleDropdownChangeAccenture = (client) => {
    setSelectedClientAccenture(client);

    getCslOalIdsAccenture(client);

    getOfferings(client);

    const newFormData = [...formDataAccenture].map((row) => {
      return {
        ...row,
        offering: "",
        suboffering: "",
      }
    });

    setFormDataAccenture(newFormData);
  };

  const getOfferings = (client) => {
    setShowLoader(true);
    axiosInstance
    .get(`${LocalApiBaseUrl}SOFilter/GetOffering?OrgEntityID=${client.OrgEntityID}&LanguageID=1&featureName=PulsePage`)
    .then((response) => {
      setOfferings(response.data);
    })
    .catch(() => {
      setOfferings([]);
    })
    .finally(() => {
      setShowLoader(false);
    });
  };

  const getCslOalIdsAccenture = (client) => {
    setShowLoader(true);
    axiosInstance
      .get(`${LocalApiBaseUrl}DealOnboarding/GetProjectsByClientID?orgEntityID=${client.OrgEntityID}`)
      .then((response) => {
        setCxlIdAccenture(response.data[0].Cxl.split(",")[0] || "");
        setOalIdAccenture(response.data[0].Oal.split(",")[0] || "");
      })
      .finally(() => {
        setShowLoader(false);
      });
  };

  const handleTabChange = (tabId) => {
    setTab(tabId);
  };

  const handleGridInputAccenture = (e, i, field) => {
    const newFormData = [...formDataAccenture];
    newFormData[i] = {
      ...formDataAccenture[i],
      [field]: e.target.value,
    };
    setFormDataAccenture(newFormData);
  };

  const handleGridDropdownAccenture = (e, i, field) => {
    const newFormData = [...formDataAccenture];
    newFormData[i] = {
      ...formDataAccenture[i],
      [field]: e,
    };
    setFormDataAccenture(newFormData);
  };

  const handleOfferingChangeAccenture= (e, i, field) => {
    const newFormData = [...formDataAccenture];
    newFormData[i] = {
      ...formDataAccenture[i],
      [field]: e,
      suboffering: "",
    };
    setFormDataAccenture(newFormData);
  };

  const handleSubofferingChangeAccenture= (e, i, field) => {
    const newFormData = [...formDataAccenture];
    newFormData[i] = {
      ...formDataAccenture[i],
      [field]: e,
    };
    setFormDataAccenture(newFormData);
  };

  const handleDateInputAccenture = (e, i) => {
    const newFormData = [...formDataAccenture];
    newFormData[i] = {
      ...formDataAccenture[i],
      requestDate: new Date().toISOString().split("T")[0],
    };
    setFormDataAccenture(newFormData);
  };

  let isAccentureFormEmpty = true;

  formDataAccenture.forEach((row) => {
    Object.values(row).forEach((col) => {
      if (col !== "") {
        isAccentureFormEmpty = false;
      }
    });
  });

  const isAccentureFormValid = formDataAccenture.every(
    (row) =>
      Object.values(row).every((col) => col === "") || Object.values(row).every((col) => col !== "")
  );

  const isAccetureSubmitDisabled = 
    remarksAccenture.length === 0 ||
    !isAccentureFormValid ||
    isAccentureFormEmpty ||
    !cxlIdAccenture ||
    !oalIdAccenture;

  const removeEmptyRows = (formData) => {
    const newFormData = [...formData];
    const emptyRows = newFormData.filter((row) => Object.values(row).some((col) => col === ""));
    emptyRows.forEach((row) => {
      const index = newFormData.indexOf(row);
      newFormData.splice(index, 1);
    });
    return newFormData;
  };

  const handleSubmitAccenture = () => {
    const payload = {
      screenName: tab,
      clients: [selectedClientAccenture.OrgEntityID.toString()],
      cxlId: cxlIdAccenture,
      oalId: oalIdAccenture,
      remarks: remarksAccenture,
    };
    payload.requestDetails = removeEmptyRows(formDataAccenture).map((row) => {
      return {
        ...row,
        requestDate: row.requestDate + "T00:00:00.000Z",
      };
    });

    console.log(payload);
    setShowLoader(true);
    axiosInstance
      .post(`${LocalApiBaseUrl}User/AddUserRequest`, payload)
      .then(() => {
        setShowSuccessMsg('Request submitted successfully.');
        setTimeout(() => {
          props.history.push('/HomePage');
        }, 700);
      })
      .finally(() => {
        setShowLoader(false);        
      });
  };

  return (
    <div className="main-component ioj-component">
      <div className="container-fluid">
        <h3 className="mb-3">
          <div className="fal fa-arrow-circle-left cursor-pointer" onClick={() => {
            const params = new URLSearchParams(window.location.search);
            const fromParam = params.get("from");
            const tabParam = params.get("tab");
            if (fromParam === "ContactUs") {
              props.history.push(`/HomePage?isSupportOpen=true&tab=${tabParam}`);
            }
            else {
              props.history.push("/HomePage");
            }
          }}></div> {i18n.t("Access Request Form")}
        </h3>

        <div className="common-tablayout">
          <Tabs activeKey={tab} id="uncontrolled-tab-example" onSelect={handleTabChange}>
            <Tab
              eventKey="AccentureUser"
              // className="survey-report"
              title={i18n.t("Accenture User")}
              // activekey="Repository"
            >
              <ClientDropdown
                selectedClients={selectedClientAccenture}
                handleDropdownChange={handleDropdownChangeAccenture}
                allClients={allClients}
              />
              <AccessRequestForm
                formData={formDataAccenture}
                handleGridInput={handleGridInputAccenture}
                handleGridDropdown={handleGridDropdownAccenture}
                handleDateInput={handleDateInputAccenture}
                filledRowsCount={removeEmptyRows(formDataAccenture).length}
                offerings={offerings}
                handleOfferingChange={handleOfferingChangeAccenture}
                handleSubofferingChange={handleSubofferingChangeAccenture}
              />
              <FillDetails
                handleSubmit={handleSubmitAccenture}
                isSubmitDisabled={isAccetureSubmitDisabled}
                remarks={remarksAccenture}
                setRemarks={setRemarksAccenture}
                cxlId={cxlIdAccenture}
                oalId={oalIdAccenture}
                showSuccessMsg={showSuccessMsg}
              />
            </Tab>
          </Tabs>
        </div>
        {showLoader ? <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active /> : ""}
      </div>
    </div>
  );
};

export default withRouter(RequestDealAccess);