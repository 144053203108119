import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import { LocalApiBaseUrl } from "../Shared/Constant";
import { store } from "../reducers/configureStore";
import _ from "lodash";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup } from "../Shared/Constant";
import $ from "jquery";
import i18n from "i18next";
import LoadingOverlay from "react-loading-overlay";
import SynopsLoader from "../Shared/SynopsLoader";
import SubOfferingDropdowns from "../Shared/SubOfferingDropdowns";
import { filterSelectedSubOffering } from "../Shared/commonFunctions";


const defaultSourceFilterOptions = {
  SourceClientOptions: [],
  selectedClientOption: [],
  SourceOfferingOptions: [],
  selectedOfferingOption: [],
  SourceSubOfferingOptions: [],
  selectedSubOfferingOption: [],
  SourceLocationOptions: [],
  selectedLocationsOption: [],
};
const defaultFilterDetails = {
  filterDisplay: { cl: false, off: false, sub: false, loc: false},
  friendlyNames: { cl: "", off: "", sub: "", loc: ""},
  multiselect: { cl: false, off: false, sub: false, loc: false },
  isApiCalled: { cl: false, off: false, sub: false, loc: false },
};
const overrideStrings = {
  selectSomeItems: "Select",
  allItemsAreSelected: "All items selected.",
  selectAll: "Select All ",
  search: "Search",
};
const statusColors = {
  Completed: "mapping-status status-completed",
  "In Progress": "mapping-status status-inprogress",
  Rejected: "mapping-status status-rejected",
  Approved: "mapping-status status-approved",
  "Pending Approval": "mapping-status status-awaitingApproval",
  Pending: "mapping-status status-rejected",
};

export default class AddMappingForm extends React.Component {
  constructor(props) {
    super(props);
    this.nextTabChangeEvent = this.nextTabChangeEvent.bind(this);
    this.state = {
      show: true,
      isApiLoading: false,
      selectedTabIndex: 1,
      showNotification: false,
      showMapping: this.props.showMapping,
      showDrafts: this.props.showDrafts,
      toggleStatus: false,
      isSubmitValid: false,
      completedStatusCount: 0,
      destinationModel: {
        OrgEntityID: 0,
        OrgEntityName: "",
        OfferingId: 0,
        Offering: "",
        SubOfferingId: 0,
        SubOffering: "",
        LocationId: 0,
        DeliveryCenter: "",
      },
      mappingDetails: [],
      modifiedMappingDetails: [],
      TotalsetupCompleted: 0,
      TotalsetupNotCompleted: 0,
      ToolsSentForApproval: 0,
      isMappingApiCalled: false,
      // deal level info
      OfferingOptions: [],
      LocationOptions: [],
      processOptions: [],
      DestinationOfferingValueID: 0,
      programDetailsForSVM:{
        programList:[],
        selectedProgramOption:[],
      }
    };
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.dealInfo)) {
      let destinationModel = this.props.dealInfo;
      this.setState({
        destinationModel: destinationModel,
      });
    } else {
      let Client = store.getState().CurrentFilter.ClientData;
      let destinationModel = this.state.destinationModel;
      destinationModel.OrgEntityName = Client.name;
      destinationModel.OrgEntityID = Client.id;
      this.getOffering(Client.id);
      this.setState({
        destinationModel: destinationModel,
      });
    }
  }

  ///------------ON Deal side Get Methods
  getLocation(processValueID) {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetLocation`, {
        params: { OrgEntityID: this.state.destinationModel.OrgEntityID, OfferingID: processValueID },
      })
      .then((response) => {
        this.setState({
          LocationOptions: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getOffering(OrgEntityID) {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetOffering`, {
        params: { OrgEntityID: OrgEntityID, featureName: "One-Time Setup" },
      })
      .then((response) => {
        this.setState({
          OfferingOptions: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  ///------------ON Deal side Change Methods
  onDestinationOfferingsChange(event) {
    this.setState({ errors: {} });
    let DestinationOfferingValueID = event.target.value;
    let processOptions = parseInt(event.target.value)
      ? this.state.OfferingOptions.filter((data) => data.OfferingID == event.target.value)[0].ChildOfferings
      : [];
    let destinationModeldup = this.state.destinationModel;
    destinationModeldup.SubOffering = '';
    destinationModeldup.OfferingId = 0;
    this.getLocation(destinationModeldup.OfferingId);
    this.setState({
      DestinationOfferingValueID: DestinationOfferingValueID,
      processOptions: processOptions,
      destinationModel: destinationModeldup
    });
  }
  onDestinationProcessChange(event) {
    this.setState({ errors: {} });
    let destinationModel = this.state.destinationModel;
    let selectedSuboffering = [];
    if (event.target.value != 0) {
      const filtersSubofferingData = filterSelectedSubOffering([...this.state.processOptions], event.target.value);
      selectedSuboffering = filtersSubofferingData.subofferingData[0];
    }

    destinationModel.OfferingId = event.target.value;
    destinationModel.SubOffering = selectedSuboffering.Process ? selectedSuboffering.Process : '';
    this.getLocation(destinationModel.OfferingId);
    this.setState({
      destinationModel: destinationModel,
    });
  }
  onDestinationLocationChange(event) {
    this.setState({ errors: {} });
    let destinationModel = this.state.destinationModel;
    var event = document.getElementById("Location");
    destinationModel.LocationId = event.options[event.selectedIndex].value;
    destinationModel.DeliveryCenter = event.options[event.selectedIndex].label;
    this.setState({
      destinationModel: destinationModel,
    });
  }
  validateDestinationModel() {
    let destinationModel = this.state.destinationModel;

    if (destinationModel.OrgEntityID && destinationModel.OfferingId && destinationModel.LocationId) {
      return true;
    } else {
      return false;
    }
  }

  nextTabChangeEvent = (tabIndex) => {
    if (tabIndex === 2) {
      if (this.validateDestinationModel()) {
        //api call to import the data
        this.getMappingInfoApiCall();
        this.setState({ selectedTabIndex: tabIndex });
      }
    } else {
      this.setState({ selectedTabIndex: tabIndex });
    }
  };



  getMappingInfoApiCall() {
    if (!this.state.isMappingApiCalled) {
      const { isClientOnboarding } = this.props;
      this.setState({
        isApiLoading: true,
      });
      let destinationModel = this.state.destinationModel;
      let apiUrl = isClientOnboarding ? "DealOnboarding/GetDealOnboardOneTimeSetupConnectorsData" : "ConnectorMapping/GetOneTimeSetupConnectorsData";
      axiosInstance
        .get(`${LocalApiBaseUrl}${apiUrl}`, {
          params: {
            OrgEntityID: destinationModel.OrgEntityID,
            OfferingId: destinationModel.OfferingId,
            LocationId: destinationModel.LocationId,
          },
        })

        .then((response) => {

          const {programDetailsForSVM} = this.state;
          const responseProgramData = response.data.filter((each)=>
            each.ConnectorName === "SVM"
          )

          if(responseProgramData[0].OneTimeSetupData.ProgramName && responseProgramData[0].OneTimeSetupData.ProgramID){
              
            responseProgramData[0].OneTimeSetupData.sourceClientName = responseProgramData[0].OneTimeSetupData.ProgramName.split("_")[0];
            
            programDetailsForSVM.selectedProgramOption = [
                { 
                  "label":responseProgramData[0].OneTimeSetupData.ProgramName,
                  "value":responseProgramData[0].OneTimeSetupData.ProgramID
              }
            ]
          }
          
          
          this.setState({ isMappingApiCalled: true, mappingDetails: response.data,programDetailsForSVM }, (e) => {
            this.modifiedMappingDetails(this.state.mappingDetails);
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoading: false,
          });
          trycatchAlertPopup(error);
        });
    }
  }

  modifiedMappingDetails(mappingInfo) {
    let modifiedMappingDetails = _.map(mappingInfo, (eachRecord, key) => {
      let filterDisplay = _.cloneDeep(defaultFilterDetails.filterDisplay);
      let friendlyNames = _.cloneDeep(defaultFilterDetails.friendlyNames);
      let multiselect = _.cloneDeep(defaultFilterDetails.multiselect);
      let isApiCalled = _.cloneDeep(defaultFilterDetails.isApiCalled);
      let setupCompleted = false;
      let ConnectorName = "";
      let ConnectorId = 0;
      let OneTimeSetupModel = _.cloneDeep(eachRecord.OneTimeSetupData);
      let mappingIndex = key;
      if (!_.isEmpty(OneTimeSetupModel)) {
        OneTimeSetupModel.Status = OneTimeSetupModel.isActive ? OneTimeSetupModel.Status : "Pending";
        setupCompleted =
          OneTimeSetupModel.Status === "Approved" ||
            OneTimeSetupModel.Status === "Pending Approval" ||
            OneTimeSetupModel.Status === "Rejected"
            ? true
            : false;

        OneTimeSetupModel.sourceClientName = OneTimeSetupModel.sourceClientName.split("|");
        OneTimeSetupModel.sourceOfferingName = OneTimeSetupModel.sourceOfferingName.split("|");
        OneTimeSetupModel.sourceSubOfferingName = OneTimeSetupModel.sourceSubOfferingName.split("|");
        OneTimeSetupModel.sourceLocationName = OneTimeSetupModel.sourceLocationName.split("|");
        ConnectorName = eachRecord.ConnectorName;
        ConnectorId = eachRecord.ConnectorID;
      }

      if (!_.isEmpty(eachRecord.FilterResult)) {
        let FilterResult = eachRecord.FilterResult.response;
        if (!_.isEmpty(FilterResult)) {
          let Client = _.find(FilterResult, function (clientDetails) {
            return clientDetails.FieldName === "Client";
          });
          filterDisplay.cl = Client.isVisible;
          friendlyNames.cl = Client.friendlyName;
          multiselect.cl = Client.multiSelection;

          let Offering = _.find(FilterResult, function (OfferingDetails) {
            return OfferingDetails.FieldName === "Offering";
          });
          filterDisplay.off = Offering.isVisible;
          friendlyNames.off = Offering.friendlyName;
          multiselect.off = Offering.multiSelection;

          let SubOffering = _.find(FilterResult, function (SubOfferingDetails) {
            return SubOfferingDetails.FieldName === "SubOffering";
          });
          filterDisplay.sub = SubOffering.isVisible;
          friendlyNames.sub = SubOffering.friendlyName;
          multiselect.sub = SubOffering.multiSelection;

          let Location = _.find(FilterResult, function (LocationDetails) {
            return LocationDetails.FieldName === "Location";
          });
          filterDisplay.loc = Location.isVisible;
          friendlyNames.loc = Location.friendlyName;
          multiselect.loc = Location.multiSelection;
        }
      }
      return {
        ConnectorName,
        ConnectorId,
        OneTimeSetupModel,
        filterDisplay,
        friendlyNames,
        multiselect,
        isApiCalled,
        setupCompleted,
        ...defaultSourceFilterOptions,
        mappingIndex,
      };
    });

    let TotalsetupCompleted = modifiedMappingDetails.filter((obj) => obj.setupCompleted === true).length;

    this.setState(
      {
        modifiedMappingDetails: modifiedMappingDetails,
        TotalsetupCompleted: TotalsetupCompleted,
        TotalsetupNotCompleted: modifiedMappingDetails.length - TotalsetupCompleted,
      },
      () => {
        this.submitHandler(modifiedMappingDetails);
        let allPromises = [];
        _.forEach(this.state.modifiedMappingDetails, (each, index) => {
          if (each.OneTimeSetupModel.isActive) {
            const eachPromise = this.MultiFilterSelectionControl(each);
            allPromises.push(eachPromise);
          }
        });

        Promise.all(allPromises)
          .then((allValues) => {
            let modifiedMappingDetails = _.cloneDeep(this.state.modifiedMappingDetails);
            _.forEach(allValues, (eachMapping, index) => {
              modifiedMappingDetails[eachMapping.mappingIndex] = eachMapping;
            });
            this.setState({ isApiLoading: false, modifiedMappingDetails: modifiedMappingDetails }, () => {
              _.forEach(this.state.modifiedMappingDetails, (eachMapping, index) => {
                if (eachMapping.OneTimeSetupModel.isActive) {
                  let id = eachMapping.mappingIndex;
                  this.showAcco(id);
                }
              });
            });
          })
          .catch((error) => {
            this.setState({ isApiLoading: false });
            trycatchAlertPopup(error);
          });
      }
    );
  }

  async MultiFilterSelectionControl(eachMapping) {
    return new Promise(async (resolve) => {
      let modifiedMappingDetails = _.cloneDeep(this.state.modifiedMappingDetails);
      let updatedMapping = _.cloneDeep(eachMapping);

      if (eachMapping.filterDisplay.cl && !eachMapping.isApiCalled.cl) {
        const clientResponse = await this.getSourceClients(eachMapping);
        updatedMapping.isApiCalled.cl = true;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;

        let SourceClientOptions = [];
        let selectedClientOption = [];
        let sortedClients = clientResponse.data.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedClients.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceClientOptions.push(dummy);

            if (eachMapping.OneTimeSetupModel.sourceClientName.includes(row.name)) {
              selectedClientOption.push(dummy);
            }
          }
        });

        updatedMapping.SourceClientOptions = SourceClientOptions;
        updatedMapping.selectedClientOption = selectedClientOption;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;
      }
      if (eachMapping.filterDisplay.off && !eachMapping.isApiCalled.off) {
        const offeringResponse = await this.getSourceOfferings(eachMapping);
        updatedMapping.isApiCalled.off = true;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;

        let SourceOfferingOptions = [];
        let selectedOfferingOption = [];
        let sortedOffering = offeringResponse.data.offeringList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedOffering.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            dummy.id = row.OrgEntityID;
            SourceOfferingOptions.push(dummy);

            if (eachMapping.OneTimeSetupModel.sourceOfferingName.includes(row.name)) {
              selectedOfferingOption.push(dummy);
            }
          }
        });

        updatedMapping.SourceOfferingOptions = SourceOfferingOptions;
        updatedMapping.selectedOfferingOption = selectedOfferingOption;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;
      }
      if (eachMapping.filterDisplay.sub && !eachMapping.isApiCalled.sub) {
        const subOfferingResponse = await this.getSourceSubOfferings(eachMapping);
        updatedMapping.isApiCalled.sub = true;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;

        let SourceSubOfferingOptions = [];
        let selectedSubOfferingOption = [];
        let sortedSubOffering = subOfferingResponse.data.subOfferingList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedSubOffering.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceSubOfferingOptions.push(dummy);

            if (eachMapping.OneTimeSetupModel.sourceSubOfferingName.includes(row.name)) {
              selectedSubOfferingOption.push(dummy);
            }
          }
        });

        updatedMapping.SourceSubOfferingOptions = SourceSubOfferingOptions;
        updatedMapping.selectedSubOfferingOption = selectedSubOfferingOption;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;
      }
      if (eachMapping.filterDisplay.loc && !eachMapping.isApiCalled.loc) {
        const locationResponse = await this.getSourceLocations(eachMapping);
        updatedMapping.isApiCalled.loc = true;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;

        let SourceLocationOptions = [];
        let selectedLocationsOption = [];
        let sortedlocation = locationResponse.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        sortedlocation.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceLocationOptions.push(dummy);
            if (eachMapping.OneTimeSetupModel.sourceLocationName.includes(row.name)) {
              selectedLocationsOption.push(dummy);
            }
          }
        });
        updatedMapping.SourceLocationOptions = SourceLocationOptions;
        updatedMapping.selectedLocationsOption = selectedLocationsOption;
        modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;
      }
      resolve(updatedMapping);
    });
  }

  async getSourceDataForSvm(clientId = 0,isProgram = false) {
    return new Promise((resolve, reject) => {    
      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceProgram?ClientId=${clientId}&IsProgram=${isProgram}`)

        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }


  async getSourceClients(eachMapping) {
    return new Promise((resolve, reject) => {
      let SourceClientOptions = [];
      let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
      let updatedMapping = { ...eachMapping };
      let sourceId = eachMapping.OneTimeSetupModel.sourceConnectorID;

      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceClient?sourceId=${sourceId}`)

        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }
  async getSourceOfferings(eachMapping) {
    return new Promise((resolve, reject) => {
      let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
      let updatedMapping = { ...eachMapping };
      let sourceId = eachMapping.OneTimeSetupModel.sourceConnectorID;
      let clientName = eachMapping.OneTimeSetupModel.sourceClientName.toString();
      clientName = encodeURIComponent(clientName);
      axiosInstance
        .get(`${LocalApiBaseUrl}ConnectorMapping/GetSourceOffering?sourceId=${sourceId}&clientName=${clientName}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }
  async getSourceSubOfferings(eachMapping) {
    return new Promise((resolve, reject) => {
      let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
      let updatedMapping = { ...eachMapping };
      let sourceId = eachMapping.OneTimeSetupModel.sourceConnectorID;
      let clientName = eachMapping.OneTimeSetupModel.sourceClientName.toString();
      clientName = encodeURIComponent(clientName);
      let offeringName = eachMapping.OneTimeSetupModel.sourceOfferingName.join("|");
      offeringName = encodeURIComponent(offeringName);

      axiosInstance
        .get(
          `${LocalApiBaseUrl}ConnectorMapping/GetSourceSubOffering?sourceId=${sourceId}&clientName=${clientName}&offeringName=${offeringName}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }
  async getSourceLocations(eachMapping) {
    return new Promise((resolve, reject) => {
      let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
      let updatedMapping = { ...eachMapping };
      let sourceId = eachMapping.OneTimeSetupModel.sourceConnectorID;
      let clientName = eachMapping.OneTimeSetupModel.sourceClientName.toString();
      let offeringName = eachMapping.OneTimeSetupModel.sourceOfferingName.join("|");
      let subOfferingname = eachMapping.OneTimeSetupModel.sourceSubOfferingName.join("|");

      // new method
      let data = {};
      data.sourceId = sourceId;
      data.clientName = clientName;
      data.offeringName = offeringName;
      data.subOfferingname = subOfferingname;
      axiosInstance
        .post(`${LocalApiBaseUrl}ConnectorMapping/GetSourceLocation`, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
        });
    });
  }
  programMultipleselectbar = (eachMapping, isOnClickEnabled) => {
    const {programDetailsForSVM} = this.state;
    
      return (
        <React.Fragment>
          <MultiSelect
            options={programDetailsForSVM.programList}
            value={programDetailsForSVM.selectedProgramOption}
            onChange={isOnClickEnabled?(e)=> this.onSourceProgramSelect(e,eachMapping):null}
            disabled={!isOnClickEnabled}
            labelledBy="Program Name"
            shouldToggleOnHover={false}
            overrideStrings={overrideStrings}
            hasSelectAll={false}
          />
        </React.Fragment>
      );
    
     
  
  };

  clientMultipleselectbar = (eachMapping, isOnClickEnabled) => {
    if (eachMapping !== undefined) {
      return (
        <React.Fragment>
          <MultiSelect
            options={eachMapping.SourceClientOptions}
            value={eachMapping.selectedClientOption}
            onChange={isOnClickEnabled ? this.onSourceClientSelect.bind(this, eachMapping) : null}
            labelledBy="OrgEntityName"
            shouldToggleOnHover={false}
            disabled={!isOnClickEnabled}
            overrideStrings={overrideStrings}
            hasSelectAll={eachMapping.multiselect.cl}
          />
        </React.Fragment>
      );
    }
  };
  offeringMultipleselectbar = (eachMapping, isOnClickEnabled) => {
    if (eachMapping !== undefined) {
      return (
        <React.Fragment>
          <MultiSelect
            options={eachMapping.SourceOfferingOptions}
            value={eachMapping.selectedOfferingOption}
            onChange={isOnClickEnabled ? this.onSourceOfferingSelect.bind(this, eachMapping) : null}
            labelledBy="OrgEntityName"
            shouldToggleOnHover={false}
            overrideStrings={overrideStrings}
            hasSelectAll={eachMapping.multiselect.off}
          />
        </React.Fragment>
      );
    }
  };
  subOfferingMultipleselectbar = (eachMapping, isOnClickEnabled) => {
    if (eachMapping !== undefined) {
      return (
        <React.Fragment>
          <MultiSelect
            options={eachMapping.SourceSubOfferingOptions}
            value={eachMapping.selectedSubOfferingOption}
            onChange={isOnClickEnabled ? this.onSourceProcessSelect.bind(this, eachMapping) : null}
            labelledBy="OrgEntityName"
            shouldToggleOnHover={false}
            overrideStrings={overrideStrings}
            hasSelectAll={eachMapping.multiselect.sub}
          />
        </React.Fragment>
      );
    }
  };
  locationMultipleselectbar = (eachMapping, isOnClickEnabled) => {
    if (eachMapping !== undefined) {
      return (
        <React.Fragment>
          <MultiSelect
            options={eachMapping.SourceLocationOptions}
            value={eachMapping.selectedLocationsOption}
            onChange={isOnClickEnabled ? this.onSourceLocationSelect.bind(this, eachMapping) : null}
            labelledBy="OrgEntityName"
            shouldToggleOnHover={false}
            overrideStrings={overrideStrings}
            hasSelectAll={eachMapping.multiselect.loc}
          />
        </React.Fragment>
      );
    }
  };

  ///------------ ON Source side Change Methods

  onSourceProgramSelect = (e, eachMapping) =>{
      const {programDetailsForSVM,modifiedMappingDetails} = this.state;
      
      if(e.length>0){
        programDetailsForSVM.selectedProgramOption = [e[e.length -1]];
        eachMapping.OneTimeSetupModel.ProgramID = e[e.length -1].value;
      }
      else{
        programDetailsForSVM.selectedProgramOption = [];
      }

      
      const status = this.statusHandler(eachMapping,programDetailsForSVM.selectedProgramOption.length);
      eachMapping.OneTimeSetupModel.Status = status;
      const submitInput = { ...modifiedMappingDetails };
      submitInput[eachMapping.mappingIndex] = eachMapping;
      this.submitHandler(submitInput);
  
      modifiedMappingDetails[eachMapping.mappingIndex] = eachMapping;
      
      
       
      this.setState({
        programDetailsForSVM,
        modifiedMappingDetails: modifiedMappingDetails,
      })
  
  }

  setProgramList = async (clientID,isProgram) =>{
    const {programDetailsForSVM} = this.state;
    const programData = await this.getSourceDataForSvm(clientID,isProgram)
    
    const programList = programData.data.ProgramList.map(e => {
      return {label:e.ProgramName,value:e.ProgramID}
    })

    programDetailsForSVM.programList = programList
    programDetailsForSVM.selectedProgramOption=[]
    this.setState({
      programDetailsForSVM 
    })
  }


  onSourceClientSelect = async (eachMapping, selectedList) => {
    let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
    let updatedMapping = { ...eachMapping };
    let OneTimeSetupModel = { ...updatedMapping.OneTimeSetupModel };
    let isApiCalled = { ...updatedMapping.isApiCalled };

    isApiCalled.loc = false;
    isApiCalled.sub = false;
    isApiCalled.off = false;
    OneTimeSetupModel.sourceLocationName = [];
    OneTimeSetupModel.sourceSubOfferingName = [];
    OneTimeSetupModel.sourceOfferingName = [];
    updatedMapping.SourceLocationOptions = [];
    updatedMapping.selectedLocationsOption = [];
    updatedMapping.SourceSubOfferingOptions = [];
    updatedMapping.selectedSubOfferingOption = [];
    updatedMapping.SourceOfferingOptions = [];
    updatedMapping.selectedOfferingOption = [];
    let selectedClientOption = updatedMapping.selectedClientOption;

    if (updatedMapping.multiselect.cl) {
      let tempClient = [];
      let tempClientValue = [];

      let len = selectedList.length;
      for (let i = 0; i < len; i++) {
        tempClient.push(selectedList[i]);
        tempClientValue.push(selectedList[i].value);
      }
      selectedClientOption = tempClient;
      OneTimeSetupModel.sourceClientName = tempClientValue;
    } else {
      let tempClient = [];
      let tempClientValue = [];
      let len = selectedList.length;
      tempClient.push(selectedList[len - 1]);
      tempClientValue.push(selectedList[len - 1].value);
      selectedClientOption = tempClient;
      OneTimeSetupModel.sourceClientName = tempClientValue;

      if(updatedMapping.ConnectorName === "SVM"){
        OneTimeSetupModel.sourceClientName = tempClient[0].label;
        OneTimeSetupModel.sourceClientId = tempClientValue[0];
      }
    }
    
    updatedMapping.selectedClientOption = selectedClientOption;
    updatedMapping.OneTimeSetupModel = OneTimeSetupModel;
    updatedMapping.isApiCalled = isApiCalled;
    let status = this.statusHandler(updatedMapping);
    updatedMapping.OneTimeSetupModel.Status = status;

    
    let submitInput = { ...modifiedMappingDetails };
    submitInput[updatedMapping.mappingIndex] = updatedMapping;
    this.submitHandler(submitInput);

    let allPromises = [];
    const eachPromise = this.SingleFilterSelectionControl(updatedMapping);
    allPromises.push(eachPromise);
    
    if(updatedMapping.ConnectorName === "SVM"){
      const selectedClientID = updatedMapping.selectedClientOption[0].value
      this.setProgramList(selectedClientID,true)    
    }

    Promise.all(allPromises)
      .then((allValues) => {
        let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
        _.forEach(allValues, (eachMapping, index) => {
          modifiedMappingDetails[eachMapping.mappingIndex] = eachMapping;
        });
        
        this.setState({
          modifiedMappingDetails: modifiedMappingDetails,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  onSourceOfferingSelect = (eachMapping, selectedList) => {
    let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
    let updatedMapping = { ...eachMapping };
    let OneTimeSetupModel = { ...updatedMapping.OneTimeSetupModel };
    let isApiCalled = { ...updatedMapping.isApiCalled };
    isApiCalled.loc = false;
    isApiCalled.sub = false;
    OneTimeSetupModel.sourceLocationName = [];
    OneTimeSetupModel.sourceSubOfferingName = [];
    updatedMapping.SourceLocationOptions = [];
    updatedMapping.selectedLocationsOption = [];
    updatedMapping.SourceSubOfferingOptions = [];
    updatedMapping.selectedSubOfferingOption = [];
    let selectedOfferingOption = updatedMapping.selectedClientOption;

    if (updatedMapping.multiselect.off) {
      let tempOffering = [];
      let tempOfferingValue = [];

      let len = selectedList.length;
      for (let i = 0; i < len; i++) {
        tempOffering.push(selectedList[i]);
        tempOfferingValue.push(selectedList[i].value);
      }
      selectedOfferingOption = tempOffering;
      OneTimeSetupModel.sourceOfferingName = tempOfferingValue;
    } else {
      let tempOffering = [];
      let tempOfferingValue = [];
      let len = selectedList.length;
      tempOffering.push(selectedList[len - 1]);
      tempOfferingValue.push(selectedList[len - 1].value);

      selectedOfferingOption = tempOffering;
      OneTimeSetupModel.sourceOfferingName = tempOfferingValue;
    }

    updatedMapping.selectedOfferingOption = selectedOfferingOption;
    updatedMapping.OneTimeSetupModel = OneTimeSetupModel;
    updatedMapping.isApiCalled = isApiCalled;
    let status = this.statusHandler(updatedMapping);
    updatedMapping.OneTimeSetupModel.Status = status;

    let submitInput = { ...modifiedMappingDetails };
    submitInput[updatedMapping.mappingIndex] = updatedMapping;
    this.submitHandler(submitInput);

    let allPromises = [];
    const eachPromise = this.SingleFilterSelectionControl(updatedMapping);
    allPromises.push(eachPromise);
    Promise.all(allPromises)
      .then((allValues) => {
        let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
        _.forEach(allValues, (eachMapping, index) => {
          modifiedMappingDetails[eachMapping.mappingIndex] = eachMapping;
        });
        this.setState({
          modifiedMappingDetails: modifiedMappingDetails,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  onSourceProcessSelect = (eachMapping, selectedList) => {
    let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
    let updatedMapping = { ...eachMapping };
    let OneTimeSetupModel = { ...updatedMapping.OneTimeSetupModel };
    let isApiCalled = { ...updatedMapping.isApiCalled };
    isApiCalled.loc = false;
    OneTimeSetupModel.sourceLocationName = [];
    updatedMapping.SourceLocationOptions = [];
    updatedMapping.selectedLocationsOption = [];
    let selectedSubOfferingOption = updatedMapping.selectedSubOfferingOption;

    if (updatedMapping.multiselect.sub) {
      let tempProcess = [];
      let tempProcessValue = [];
      let len = selectedList.length;
      for (let i = 0; i < len; i++) {
        tempProcess.push(selectedList[i]);
        tempProcessValue.push(selectedList[i].value);
      }
      selectedSubOfferingOption = tempProcess;
      OneTimeSetupModel.sourceSubOfferingName = tempProcessValue;
    } else {
      let tempProcess = [];
      let tempProcessValue = [];

      let len = selectedList.length;
      tempProcess.push(selectedList[len - 1]);
      tempProcessValue.push(selectedList[len - 1].value);

      selectedSubOfferingOption = tempProcess;
      OneTimeSetupModel.sourceSubOfferingName = tempProcessValue;
    }

    updatedMapping.selectedSubOfferingOption = selectedSubOfferingOption;
    updatedMapping.OneTimeSetupModel = OneTimeSetupModel;
    updatedMapping.isApiCalled = isApiCalled;
    let status = this.statusHandler(updatedMapping);
    updatedMapping.OneTimeSetupModel.Status = status;

    let submitInput = { ...modifiedMappingDetails };
    submitInput[updatedMapping.mappingIndex] = updatedMapping;
    this.submitHandler(submitInput);

    let allPromises = [];
    const eachPromise = this.SingleFilterSelectionControl(updatedMapping);
    allPromises.push(eachPromise);
    Promise.all(allPromises)
      .then((allValues) => {
        let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
        _.forEach(allValues, (eachMapping, index) => {
          modifiedMappingDetails[eachMapping.mappingIndex] = eachMapping;
        });
        this.setState({
          modifiedMappingDetails: modifiedMappingDetails,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  };
  onSourceLocationSelect = (eachMapping, selectedList) => {
    let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
    let updatedMapping = { ...eachMapping };
    let OneTimeSetupModel = { ...updatedMapping.OneTimeSetupModel };
    let selectedLocationsOption = updatedMapping.selectedLocationsOption;

    if (updatedMapping.multiselect.loc) {
      let tempLocation = [];
      let tempLocationValue = [];
      let len = selectedList.length;
      for (let i = 0; i < len; i++) {
        tempLocation.push(selectedList[i]);
        tempLocationValue.push(selectedList[i].value);
      }
      selectedLocationsOption = tempLocation;
      OneTimeSetupModel.sourceLocationName = tempLocationValue;
    } else {
      let tempLocation = [];
      let tempLocationValue = [];
      let len = selectedList.length;
      tempLocation.push(selectedList[len - 1]);
      tempLocationValue.push(selectedList[len - 1].value);
      selectedLocationsOption = tempLocation;
      OneTimeSetupModel.sourceLocationName = tempLocationValue;
    }
    updatedMapping.selectedLocationsOption = selectedLocationsOption;
    updatedMapping.OneTimeSetupModel = OneTimeSetupModel;
    let status = this.statusHandler(updatedMapping);
    updatedMapping.OneTimeSetupModel.Status = status;
    let submitInput = { ...modifiedMappingDetails };
    submitInput[updatedMapping.mappingIndex] = updatedMapping;
    this.submitHandler(submitInput);

    modifiedMappingDetails[updatedMapping.mappingIndex] = updatedMapping;
    this.setState({
      modifiedMappingDetails: modifiedMappingDetails,
    });
  };
  async SingleFilterSelectionControl(eachMapping) {
    return new Promise(async (resolve) => {
      let updatedMapping = { ...eachMapping };

      if (eachMapping.filterDisplay.cl && !eachMapping.isApiCalled.cl) {
        let clientResponse;
        let sortedClients=[];
        if(eachMapping.ConnectorName === "SVM"){
          clientResponse = await this.getSourceDataForSvm();
          sortedClients = clientResponse.data.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));          
        }else{
          clientResponse = await this.getSourceClients(eachMapping);
          sortedClients = clientResponse.data.clientList.sort((a, b) => (a.name > b.name ? 1 : -1));
        }
        updatedMapping.isApiCalled.cl = true;
        let SourceClientOptions = [];
        let selectedClientOption = [];
       
        sortedClients.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            if(eachMapping.ConnectorName === "SVM"){
              dummy.value = row.ClientID;
            }else{
              dummy.value = row.name;
            }                        
            SourceClientOptions.push(dummy);

            if (eachMapping.OneTimeSetupModel.sourceClientName.includes(row.name)) {
              selectedClientOption.push(dummy);
            }
          }
        });

        updatedMapping.SourceClientOptions = SourceClientOptions;
        updatedMapping.selectedClientOption = selectedClientOption;
      } else if (eachMapping.filterDisplay.off && !eachMapping.isApiCalled.off) {
        const offeringResponse = await this.getSourceOfferings(eachMapping);
        updatedMapping.isApiCalled.off = true;
        let SourceOfferingOptions = [];
        let selectedOfferingOption = [];
        let sortedOffering = offeringResponse.data.offeringList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedOffering.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            dummy.id = row.OrgEntityID;
            SourceOfferingOptions.push(dummy);

            if (eachMapping.OneTimeSetupModel.sourceOfferingName.includes(row.name)) {
              selectedOfferingOption.push(dummy);
            }
          }
        });

        updatedMapping.SourceOfferingOptions = SourceOfferingOptions;
        updatedMapping.selectedOfferingOption = selectedOfferingOption;
      } else if (eachMapping.filterDisplay.sub && !eachMapping.isApiCalled.sub) {
        const subOfferingResponse = await this.getSourceSubOfferings(eachMapping);
        updatedMapping.isApiCalled.sub = true;
        let SourceSubOfferingOptions = [];
        let selectedSubOfferingOption = [];
        let sortedSubOffering = subOfferingResponse.data.subOfferingList.sort((a, b) => (a.name > b.name ? 1 : -1));

        sortedSubOffering.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceSubOfferingOptions.push(dummy);

            if (eachMapping.OneTimeSetupModel.sourceSubOfferingName.includes(row.name)) {
              selectedSubOfferingOption.push(dummy);
            }
          }
        });

        updatedMapping.SourceSubOfferingOptions = SourceSubOfferingOptions;
        updatedMapping.selectedSubOfferingOption = selectedSubOfferingOption;
      } else if (eachMapping.filterDisplay.loc && !eachMapping.isApiCalled.loc) {
        const locationResponse = await this.getSourceLocations(eachMapping);
        updatedMapping.isApiCalled.loc = true;
        let SourceLocationOptions = [];
        let selectedLocationsOption = [];
        let sortedlocation = locationResponse.data.sort((a, b) => (a.name > b.name ? 1 : -1));
        sortedlocation.map((row, i) => {
          if (row.name != "") {
            let dummy = [];
            dummy.label = row.name;
            dummy.value = row.name;
            SourceLocationOptions.push(dummy);
            if (eachMapping.OneTimeSetupModel.sourceLocationName.includes(row.name)) {
              selectedLocationsOption.push(dummy);
            }
          }
        });
        updatedMapping.SourceLocationOptions = SourceLocationOptions;
        updatedMapping.selectedLocationsOption = selectedLocationsOption;
      }
      resolve(updatedMapping);
    });
  }

  toggleHandler = (id, eachMapping) => {
    // isActive true means disable toggle action has to be performed
    if (eachMapping.OneTimeSetupModel.isActive) {
      let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
      let updatedMapping = { ...eachMapping, ...defaultSourceFilterOptions };
      updatedMapping.isApiCalled = { ...defaultFilterDetails.isApiCalled };
      updatedMapping.OneTimeSetupModel = {
        ...eachMapping.OneTimeSetupModel,
        sourceClientName: [],
        sourceLocationName: [],
        sourceOfferingName: [],
        sourceSubOfferingName: [],
      };
      updatedMapping.OneTimeSetupModel.isActive = false;
      let status = "Pending";
      updatedMapping.OneTimeSetupModel.Status = status;
      modifiedMappingDetails[eachMapping.mappingIndex] = updatedMapping;

      this.setState(
        {
          modifiedMappingDetails: modifiedMappingDetails,
        },
        () => {
          this.submitHandler(modifiedMappingDetails);
        }
      );
      this.showAcco(id, false);
    } else {
      let updatedMapping = { ...eachMapping };
      updatedMapping.OneTimeSetupModel.isActive = true;
      let status = "Pending";
      updatedMapping.OneTimeSetupModel.Status = status;

      let submitInput = { ...this.state.modifiedMappingDetails };
      submitInput[updatedMapping.mappingIndex] = updatedMapping;
      this.submitHandler(submitInput);
      let allPromises = [];
      const eachPromise = this.SingleFilterSelectionControl(updatedMapping);
      allPromises.push(eachPromise);
      Promise.all(allPromises)
        .then((allValues) => {
          let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
          _.forEach(allValues, (eachMapping, index) => {
            modifiedMappingDetails[eachMapping.mappingIndex] = eachMapping;
          });
          this.setState({
            modifiedMappingDetails: modifiedMappingDetails,
          });
          this.showAcco(id, true);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }

  };

  statusHandler = (eachMapping,programLength = 0) => {
    let updatedMapping = { ...eachMapping };
    const {programDetailsForSVM} = this.state;
    let OneTimeSetupModel = { ...eachMapping.OneTimeSetupModel };
    let filter;
    if (!OneTimeSetupModel.isActive) {
      return "Pending";
    } else {
      let completeStatus = true;

      if (eachMapping.filterDisplay.cl) {
        if(eachMapping.ConnectorName === "SVM" && programLength == 0 ){
          completeStatus = false;
        }
        if (OneTimeSetupModel.sourceClientName.toString() === "") {
          completeStatus = false;
        }
      }
      if (eachMapping.filterDisplay.off) {
        if (OneTimeSetupModel.sourceOfferingName.toString() === "") {
          completeStatus = false;
        }
      }
      if (eachMapping.filterDisplay.sub) {
        if (OneTimeSetupModel.sourceSubOfferingName.toString() === "") {
          completeStatus = false;
        }
      }
      if (eachMapping.filterDisplay.loc) {
        if (OneTimeSetupModel.sourceLocationName.toString() === "") {
          completeStatus = false;
        }
      }

      if (completeStatus) {
        return "Completed";
      } else {
        return "In Progress";
      }
    }
  };

  submitHandler = (modifiedMappingDetails) => {
    let isSubmitValid = false;
    let isActiveTrueCount = 0;
    let completedStatusCount = 0;

    _.forEach(modifiedMappingDetails, (eachMapping, index) => {

      if (eachMapping.setupCompleted === false) {
        if (eachMapping.OneTimeSetupModel.isActive === true) {
          ++isActiveTrueCount;
        }
        if (eachMapping.OneTimeSetupModel.Status === "Completed") {
          ++completedStatusCount;
        }
      }
    });

    if (isActiveTrueCount === completedStatusCount) {
      isSubmitValid = true;
    } else {
      isSubmitValid = false;
    }
    if (isActiveTrueCount === 0) {
      isSubmitValid = false;
    }

    this.setState({
      isSubmitValid: isSubmitValid,
      completedStatusCount: completedStatusCount,
    });
  };

  showAcco = (id, status) => {
    $("#" + id).toggleClass("card-link", status);
    let val = "#collapse" + id;

    if ($("#" + id).hasClass("card-link")) {
      $("#" + id).attr("href", val);
    } else {
      $("#" + id).attr("href", "");
    }
  };
  toggleAddTool = (id) => {
    $("#" + id).click(function () {
      let x = document.getElementById(`collapseCard1Child${id}`);
      let y = document.getElementById("collapseCard1");
      let cardHeaders = $("#collapseCard1Child" + id)
        .siblings()
        .children(".card-header");

      if ($("#" + id).hasClass("collapsed")) {
        $("#" + id)
          .parent(".card-header")
          .find(".toggle-btn-group")
          .show();
        $("#" + id)
          .parent(".card-header")
          .find(".toggle-group")
          .hide();
        $.each(cardHeaders, function (key, value) {
          let x = value.children[0].id;
          $("#" + x)
            .parent(".card-header")
            .find(".toggle-group")
            .show();
        });
      } else {
        $("#" + id)
          .parent(".card-header")
          .find(".toggle-group")
          .show();
        $("#" + id)
          .parent(".card-header")
          .find(".toggle-btn-group")
          .hide();

        $.each(cardHeaders, function (key, value) {
          let x = value.children[0].id;
          $("#" + x)
            .parent(".card-header")
            .find(".toggle-group")
            .show();
        });
      }
    });
  };
  toggleEditTool = (id) => {
    $("#" + id).click(function () {
      if ($("#" + id).hasClass("collapsed")) {
        $("#" + id)
          .parent(".card-header")
          .find(".displayUpdateCancel")
          .show();
      } else {
        $("#" + id)
          .parent(".card-header")
          .find(".displayUpdateCancel")
          .hide();
      }
    });
  };

  generatePostApiData = () => {
    let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
    const OneTimeSetupConnectorsData = _.map(modifiedMappingDetails, (eachItem) => {
      let OneTimeSetupData = {
        ...eachItem.OneTimeSetupModel,
        sourceClientName: eachItem.OneTimeSetupModel.sourceClientName.toString(),
        sourceOfferingName: eachItem.OneTimeSetupModel.sourceOfferingName.join("|"),
        sourceSubOfferingName: eachItem.OneTimeSetupModel.sourceSubOfferingName.join("|"),
        sourceLocationName: eachItem.OneTimeSetupModel.sourceLocationName.join("|"),
      };
      let ConnectorID = eachItem.ConnectorId;
      let ConnectorName = eachItem.ConnectorName;

      return {
        OneTimeSetupData,
        ConnectorID,
        ConnectorName,
      };
    });
    return OneTimeSetupConnectorsData;
  };

  postMappingInfoApiCall = (action) => {
    const { isClientOnboarding } = this.props;
    let modifiedMappingDetails = { ...this.state.modifiedMappingDetails };
    let OneTimeSetupConnectorsData = this.generatePostApiData();
    let Action = action;
    let data = {
      OneTimeSetupConnectorsData,
      Action,
    };

    let ToolsSentForApproval = 0;
    _.forEach(modifiedMappingDetails, (eachMapping, index) => {
      if (eachMapping.setupCompleted === false) {
        if (eachMapping.OneTimeSetupModel.isActive === true) {
          ++ToolsSentForApproval;
        }
      }
    });

    if (this.postMappingInfoApiCallHandler(action)) {
      this.setState({
        isApiLoading: true,
      });

      let apiUrl = isClientOnboarding ? "DealOnboarding/PostDealOnboardOneTimeSetupConnectorsData" : "ConnectorMapping/PostOneTimeSetupConnectorsData";

      axiosInstance
        .post(`${LocalApiBaseUrl}${apiUrl}`, data)
        .then(
          (response) => {
            this.setState(
              {
                isApiLoading: false,
                showNotification: true,
                Action: Action,
                showMapping: false,
                ToolsSentForApproval: ToolsSentForApproval,
              },
              () => {
                if (Action === "Save") {
                  window.alert(response.data.StatusMessage);
                  this.props.childToParent("AddMapping");
                }
              }
            );
          },
          (error) => {
            this.setState({
              isApiLoading: false,
            });

            trycatchAlertPopup(error);
            window.alert(error);
          }
        )
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };

  postMappingInfoApiCallHandler(action) {
    if (action === "Submit") {
      return true;
    } else {
      let pendingToolsCount = 0;
      _.forEach(this.state.modifiedMappingDetails, (eachMapping, index) => {
        if (eachMapping.setupCompleted === false) {
          if (eachMapping.OneTimeSetupModel.isActive === true && eachMapping.OneTimeSetupModel.Status === "Pending") {
            ++pendingToolsCount;
          }
        }
      });

      if (pendingToolsCount) {
        return window.confirm("Tools in Pending state will be reset");
      } else return true;
    }
  }

  handleMappingHide(e) {
    this.props.childToParent("AddMapping");
    this.setState({ showMapping: false });
  }
  handleNotification(e) {
    this.setState({ showMapping: false, showNotification: false });
    this.props.childToParent("AddMapping");
  }

  render() {
    const {
      showNotification,
      isSubmitValid,
      completedStatusCount,
      showMapping,
      showDrafts,
      ToolsSentForApproval,
      TotalsetupCompleted,
      TotalsetupNotCompleted,
      modifiedMappingDetails,
    } = this.state;
    return (
      <>
        <LoadingOverlay
          active={this.state.isApiLoading}
          spinner={<SynopsLoader />}
        >
          <Modal
            show={this.state.showMapping}
            onHide={this.handleMappingHide.bind(this)}
            className="modal fade addMappingModal"
            size="medium"
            backdrop="static"
          >
            <Modal.Body className="px-3 pt-4">
              <div className="toggleForm tabs-small">
                <Tabs
                  defaultActiveKey={this.state.selectedTabIndex}
                  className={this.state.selectedTabIndex === 1 ? "" : "previous-active"}
                  activeKey={this.state.selectedTabIndex}
                >
                  {/* *******SYNOPS DETAILS TAB STARTS HERE********* */}
                  <Tab eventKey={1} title="Synops Details">
                    <div className="synopsDetails-tabcontent">
                      <div className="synopsfields-section">
                        <form>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="tab-desc">
                                <h5>Synops Details</h5>
                                <p>
                                  Mapping Source detail with corresponding fields in SynOPS for fetching Metric Data{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-section">
                                <div class="form-group detail-group disabled">
                                  <label for="clientname" class="form-label">
                                    Client Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.destinationModel.OrgEntityName}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-section">
                                <div class="form-group detail-group">
                                  <label for="offering" class="form-label">
                                    Offering
                                  </label>
                                  {_.isEmpty(this.props.dealInfo) ? (
                                    <select
                                      id="offering"
                                      class="form-control"
                                      onChange={this.onDestinationOfferingsChange.bind(this)}
                                      value={this.state.DestinationOfferingValueID}
                                      disabled={this.state.isMappingApiCalled}
                                    >
                                      <option value="0">Select Offering</option>
                                      {this.state.OfferingOptions.map((row) => {
                                        return <option value={row.OfferingID}>{row.OfferingName}</option>;
                                      })}
                                    </select>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.destinationModel.Offering}
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-section">
                                <div class="form-group detail-group">
                                  {_.isEmpty(this.props.dealInfo) ? (
                                    <SubOfferingDropdowns
                                      selectedSubOffering={this.state.destinationModel.SubOffering}
                                      subOfferings={this.state.processOptions}
                                      onSubOfferingChange={this.onDestinationProcessChange.bind(this)}
                                      defaultToggleName={i18n.t("Select_Sub_Offerings")}
                                      selectedSubOfferingId={this.state.destinationModel.OfferingId}
                                      required={false}
                                      labelClassName="form-label"
                                      labelfor="Process"
                                      disabledValue={this.state.isMappingApiCalled}
                                      dropdownItem={true}
                                      labelpresent={true}
                                      SubOfferingDropdownsClass={true}
                                    />) :
                                    (
                                      <>
                                        <label for="Process" class="form-label">
                                          Sub-Offering
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={this.state.destinationModel.SubOffering}
                                          disabled
                                        />
                                      </>)
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-section">
                                <div class="form-group detail-group last-dropdown">
                                  <label for="location" class="form-label">
                                    Location
                                  </label>
                                  {_.isEmpty(this.props.dealInfo) ? (
                                    <select
                                      id="Location"
                                      class="form-control"
                                      onChange={this.onDestinationLocationChange.bind(this)}
                                      value={this.state.destinationModel.LocationId}
                                      disabled={this.state.isMappingApiCalled}
                                    >
                                      <option value="0">Select Location</option>
                                      {this.state.LocationOptions.map((row) => {
                                        if (row.IsActive) {
                                          return <option value={row.LocationID}>{row.City}</option>;
                                        }
                                      })}
                                    </select>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={this.state.destinationModel.DeliveryCenter}
                                      disabled
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Tab>
                  {/* *******SYNOPS DETAILS TAB ENDS HERE*********** */}

                  {/* *******SOURCE SYSTEM DETAILS TAB STARTS HERE********* */}
                  <Tab eventKey={2} title="Source System Details">
                    <div className="synopsDetails-tabcontent source-system-details">
                      <div className="tab-desc">
                        <h5>Source System Details</h5>
                        <p>Select Source Tool applicable to the Deal</p>
                      </div>
                      <div className="sourceSystemDetails-accordion">
                        <div id="accordion" className="mainAccordion">
                          {/* Tool Setup Not Completed Card 1 Starts Here*/}
                          <div class="card">
                            <div class="card-header">
                              <div class="collapsed card-link" data-toggle="collapse" href="#collapseCard1" id="15">
                                <div className="header-content">
                                  <div className="header-link" id="header9">
                                    <h6>Tool setup not completed</h6>
                                    <p className="toolDesc">Setup the tools here</p>
                                  </div>
                                </div>
                              </div>
                              <div className="header-info-right">
                                <h6 className="toolCount">
                                  Completed Tools: {completedStatusCount}/{TotalsetupNotCompleted}
                                </h6>
                              </div>
                            </div>
                            <div id="collapseCard1" class="collapse collapseType1" data-parent="#accordion">
                              <div class="card-body">
                                <div id="accordionB" className="subAccordion">
                                  {_.map(this.state.modifiedMappingDetails, (eachMapping, index) => {
                                    if (!eachMapping.setupCompleted) {
                                      let id = eachMapping.mappingIndex;
                                      let OneTimeSetupModel = eachMapping.OneTimeSetupModel;
                                      return (
                                        <div class="card" id={`collapseCard1Child${id}`}>
                                          <div class="card-header">
                                            <div
                                              class="collapsed"
                                              data-toggle="collapse"
                                              id={id}
                                              onClick={this.toggleAddTool(id)}
                                            >
                                              <div className="header-content">
                                                <div className="header-link">
                                                  <h6>{eachMapping.ConnectorName}</h6>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="header-info-right">
                                              {OneTimeSetupModel.isActive && (
                                                <span
                                                  className={statusColors[`${OneTimeSetupModel.Status}`]}
                                                >
                                                  {OneTimeSetupModel.Status}
                                                </span>
                                              )}
                                              <span className="toggle-group-container">
                                                <div className="toggle-group selecttooltip">
                                                  <input
                                                    type="checkbox"
                                                    checked={OneTimeSetupModel.isActive}
                                                    name={`check${id}`}
                                                    id={`check${id}`}
                                                  />
                                                  <label
                                                    htmlFor={`check${id}`}
                                                    onClick={(e) => this.toggleHandler(id, eachMapping)}
                                                  >
                                                    Connection Status
                                                  </label>
                                                  <div className="onoffswitch " aria-hidden="true">
                                                    <div className="onoffswitch-label">
                                                      <div className="onoffswitch-inner"></div>
                                                      <div className="onoffswitch-switch">
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {OneTimeSetupModel.isActive ? (
                                                    <span class="selecttooltiptext">
                                                      Unselect tool and erase mapping
                                                    </span>
                                                  ) : (
                                                    <span class="selecttooltiptext">Select Tool</span>
                                                  )}
                                                </div>
                                              </span>
                                            </div>
                                          </div>

                                          <div id={`collapse${id}`} class="collapse" data-parent="#accordionB">
                                            <div class="card-body">
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group disabled">
                                                      <label for="offering" class="form-label">
                                                        Destination System
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id="offering"
                                                        value="SynOps"
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Source System
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id="offering"
                                                        value={OneTimeSetupModel.sourceConnectorName}
                                                        disabled={true}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Client Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationClientName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.cl && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.cl}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.clientMultipleselectbar(eachMapping, true)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Offering
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationOfferingName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                
                                                  {eachMapping.filterDisplay.cl && eachMapping.ConnectorName === "SVM" && (
                                                    <div className="col-sm-4 sourceSystemGroup">
                                                      <div className="form-section">
                                                        <div class="form-group detail-group">
                                                          <label for="offering" class="form-label">
                                                            Program Name
                                                          </label>
                                                          <div className="custom-multi-select">
                                                            {this.programMultipleselectbar(eachMapping, true)}
                                                          </div>
                                                        </div>
                                                      </div>
                                                   </div>
                                                  )}
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.off && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.off}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.offeringMultipleselectbar(eachMapping, true)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Sub-Offering
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationSubOfferingName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.sub && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.sub}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.subOfferingMultipleselectbar(eachMapping, true)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Delivery Location
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationLocationName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.loc && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.loc}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.locationMultipleselectbar(eachMapping, true)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Tool Setup Not Completed Card 1 Ends Here*/}

                          {/* Tool Setup Completed Card 2 Starts Here*/}
                          <div class="card">
                            <div class="card-header">
                              <div class="collapsed card-link" data-toggle="collapse" href="#collapseCard2" id="15">
                                <div className="header-content">
                                  <div className="header-link" id="header9">
                                    <h6>Tool setup completed</h6>
                                    <p className="toolDesc">
                                      Tools with approved, awaiting approval and rejected status are available here
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="header-info-right">
                                <h6 className="toolCount">Tools: {TotalsetupCompleted}</h6>
                              </div>
                            </div>

                            <div id="collapseCard2" class="collapse collapseType2" data-parent="#accordion">
                              <div class="card-body">
                                {/* If no tools here enable below commented 'noTool-data' */}
                                {TotalsetupCompleted === 0 ? (
                                  <div className="noTool-data">No tool available yet</div>
                                ) : null}
                                <div id="accordionB" className="subAccordion">
                                  {_.map(this.state.modifiedMappingDetails, (eachMapping, index) => {
                                    if (eachMapping.setupCompleted) {
                                      let id = eachMapping.mappingIndex;
                                      let OneTimeSetupModel = eachMapping.OneTimeSetupModel;
                                      return (
                                        <div class="card">
                                          <div class="card-header">
                                            <div
                                              class="collapsed card-link"
                                              data-toggle="collapse"
                                              id={`${id}B`}
                                              href={`#collapse${id}B`}
                                              onClick={this.toggleEditTool(`${id}B`)}
                                            >
                                              <div className="header-content">
                                                <div className="header-link">
                                                  <h6>{OneTimeSetupModel.sourceConnectorName}</h6>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="header-info-right">
                                              <span
                                                // className="mapping-status status-rejected"
                                                className={statusColors[`${OneTimeSetupModel.Status}`]}
                                              >
                                                {OneTimeSetupModel.Status}
                                              </span>
                                              <span style={{ display: "none" }} className="displayUpdateCancel">
                                              </span>
                                            </div>
                                          </div>
                                          <div id={`collapse${id}B`} class="collapse" data-parent="#accordionB">
                                            <div class="card-body">
                                              <div className="row">
                                                <div className="col-sm-12 approverRemark">
                                                  <h5>Approver’s Remark</h5>
                                                  <p>{OneTimeSetupModel.Remarks}</p>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group disabled">
                                                      <label for="offering" class="form-label">
                                                        Destination System
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id="offering"
                                                        value="SynOps"
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Source System
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id="offering"
                                                        value={OneTimeSetupModel.sourceConnectorName}
                                                        disabled={true}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Client Name
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationClientName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.cl && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.cl}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.clientMultipleselectbar(eachMapping, false)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Offering
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationOfferingName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                {eachMapping.filterDisplay.cl && eachMapping.ConnectorName === "SVM" && (
                                                    <div className="col-sm-4 sourceSystemGroup">
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          Program Name
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.programMultipleselectbar(eachMapping, false)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                </div>
                                                  )}
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.off && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.off}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.offeringMultipleselectbar(eachMapping, false)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Sub-Offering
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationSubOfferingName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.sub && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.sub}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.subOfferingMultipleselectbar(eachMapping, false)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  <div className="form-section">
                                                    <div class="form-group detail-group">
                                                      <label for="offering" class="form-label">
                                                        Delivery Location
                                                      </label>
                                                      <input
                                                        type="text"
                                                        maxlength="200"
                                                        className="form-control"
                                                        id=""
                                                        value={OneTimeSetupModel.destinationLocationName}
                                                        disabled
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-sm-4 sourceSystemGroup">
                                                  {eachMapping.filterDisplay.loc && (
                                                    <div className="form-section">
                                                      <div class="form-group detail-group">
                                                        <label for="offering" class="form-label">
                                                          {eachMapping.friendlyNames.loc}
                                                        </label>
                                                        <div className="custom-multi-select">
                                                          {this.locationMultipleselectbar(eachMapping, false)}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* Tool Setup Completed Card 2 Ends Here*/}
                        </div>
                      </div>
                    </div>
                  </Tab>
                  {/* *******SOURCE SYSTEM DETAILS TAB ENDS HERE*********** */}
                </Tabs>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="modalFooter-btn">
                <Button variant="default" className="btn-sm mr-2" onClick={this.handleMappingHide.bind(this)}>
                  Cancel
                </Button>
                <Button
                  disabled={this.state.selectedTabIndex === 1}
                  variant="outline-secondary"
                  className="btn-sm mr-auto"
                  onClick={(e) => {
                    this.postMappingInfoApiCall("Save");
                  }}
                >
                  Save & Exit
                </Button>
                <Button
                  disabled={this.state.selectedTabIndex === 1}
                  variant="outline-secondary mr-2"
                  onClick={(e) => {
                    this.nextTabChangeEvent(1);
                  }}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    this.state.selectedTabIndex === 1
                      ? this.nextTabChangeEvent(2)
                      : this.postMappingInfoApiCall("Submit");
                  }}
                  disabled={this.state.selectedTabIndex === 1 ? false : !isSubmitValid}
                >
                  {this.state.selectedTabIndex === 1 ? "Next" : "Submit"}
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
          {/* Mapping Model Ends Here */}

          {/* Notification Starts Here */}
          <Modal
            show={showNotification && this.state.Action === "Submit"}
            onHide={this.handleNotification.bind(this)}
            className="modal fade submitNotifyModal"
            size="medium"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>One Time Setup Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-3 pt-4">
              <div className="notifySection">
                <div className="notifyIcon">
                  <span>
                    <i class="far fa-check" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="notifyDesc">
                  <h5>Source Systems sent for approval!</h5>
                  <p>
                    Source systems are sent for approval. You will get notified about acceptance/rejection in the
                    ‘Approval’ tab.
                  </p>
                  <div className="toolsection">
                    <p>{ToolsSentForApproval} New tool</p>
                    {/* <p>1 Rejected tool with updates</p> */}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={this.handleNotification.bind(this, false)}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Notification Ends Here */}
        </LoadingOverlay>
      </>
    );
  }
}
