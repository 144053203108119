import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axiosInstance from "../Shared/interceptor";
import { trycatchAlertPopup, idTokenstring } from "../Shared/Constant";
import MultiSelect from "react-multi-select-component";
import JourneyInitiativeTabs from "./JourneyInitiativeTabs";
import noFilter from "../Images/funnel-icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faFilePowerpoint } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { Tabs, Tab, Dropdown, DropdownButton, ToastHeader } from "react-bootstrap";
import { Modal, Carousel, Button } from "react-bootstrap";
import { store } from "../reducers/configureStore";
import LoadingOverlay from "react-loading-overlay";
import Toast from 'react-bootstrap/Toast'
import { Bubble } from "react-chartjs-2";
import $ from "jquery";
import Chart from "chart.js";
import "chartjs-plugin-datalabels";
import SynopsLoader from "../Shared/SynopsLoader";
import ReactTooltip from 'react-tooltip';
import i18n from "i18next";
import TabQCScore from "../QCScore/TabQCScore";
import iojlinktoreportImg from "../Images/iojlinktorepotImg.png";
import omLinktoreportImg from "../Images/omlinktoreportImg.png";
import { LocalApiBaseUrl, formattertwo } from "../Shared/Constant";
import pptxgen from "pptxgenjs";
import * as htmlToImage from 'html-to-image';
import AccentureLogo from "../Images/AccentureLogo.png";
import SynopsLogo from "../Images/Synops_Logo.png";
import Confidential from "../Images/confidential.png";
import Copyright from "../Images/copyright.png";
import BookGrey from "../Images/book-grey.svg";
import BookBlue from "../Images/book-blue.svg";
import Success from "../Images/Success.svg";
import MORcard from "../Images/morcard.jpg";
import DeleteIcon from "../Images/exclamation-triangle-grad.svg";
import summaryDashboardIcon from "../Images/summary-dashboard.svg";
import summaryDashboardIconHover from "../Images/summary-dashboard-hover.svg"
import ganttChartIcon from "../Images/gantt-chart.svg";
import ganttChartIconHover from "../Images/gantt-chart-hover.svg"
import exportRoadmapIcon from "../Images/export-road-map.svg";
import exportRoadmapIconHover from "../Images/export-road-map-hover.svg"
import IOJCardLogo from "../Images/IOJ-synops-logo.svg";
import { connect } from "react-redux";
import { compose } from "redux";
import _ from "lodash";
import References from "../Shared/References";
import "../Dashboard/reference.css";
import {
  InitializeInitiative,
  InitializeBOIList,
  InitializeIIMetric,
  InitializeIOCategory,
  InitializeIOJData,
  saveJourneyData,
  ClearDatas_ViewDataStageData,
  InitializeIOJOM_RatingScore,
  SetOMIDStatus,
  InitializeInitiativedatas,
  actionStatus
} from "../actions/JourneyActions";
import "./JourneyInitiative.css";
import { FormatDateWithMonthAndYear, ToChunk, openURLInNewWindow } from "../Shared/commonFunctions";
import CustompieChartLabel from "./CustompieChartLabel";
import PerformancePopup from "./PerformancePopup";
import IOJClientStory from "./IOJClientStory";
import ClientStoryNotification from "./ClientStoryNotification";
import { NavLink } from "react-router-dom";
import JourneyGanttChart from './JourneyGanttChart';

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${sessionStorage.getItem("msal.idtoken")}`;

// Doughnut Chart
////////////
var randomScalingFactor = function () {
  return Math.round(Math.random() * 100);
};
let doughnutDatasets = {
  labels: ["OK", "WARNING", "CRITICAL", "UNKNOWN"],
  datasets: [
    {
      data: [11, 8, 8, 11, 70],
      backgroundColor: [
        "rgb(156, 106, 222)",
        "rgb(0, 186, 255)",
        "rgb(38, 193, 201)",
        "rgb(145, 158, 171)",
        "rgb(255, 255, 255)",
      ],
      borderWidth: 0,
      datalabels: {
        display: false,
      },
    },
    {
      data: [50, 60, 30, 60],
      backgroundColor: [
        "rgb(167, 65, 231)",
        "rgb(181, 103, 232)",
        "rgb(103, 46, 140)",
        "rgb(142, 46, 203)",
      ],
      borderWidth: 1,
    },
  ],
};
let doughnutOptions = {
  layout: {
    padding: {
      top: 50,
      left: 30,
      right: 30,
      bottom: 50,
    },
  },
  legend: {
    display: false,
  },

  plugins: {
    datalabels: {
      color: "rgb(33, 43, 54)",
      anchor: "end",
      align: "end",

      formatter: (value, ctx) => {
        let initiativecount = "Total Initiatives: " + value;
        return initiativecount;
      },
      font: {
        weight: 500,
        size: 12,
        family: "Graphik-Medium",
      },
      offset: 10,
    },
  },
  maintainAspectRatio: false,
  cutoutPercentage: 70,
  tooltips: {
    enabled: false,
  },
};

const overrideStrings = {
  "selectSomeItems": "Select",
  "allItemsAreSelected": "All items selected.",
  "selectAll": "Select All ",
  "search": "Search"
}
const formProperties = {
  StoryName: "",
  Challenges: "",
  Outcomes: "",
  Solution: "",
  DiverseData: "",
  WorkOrchestration: "",
  HumanMachine: "",
  InsightsIntelligence: "",
  WhatNext: "",
  Version: "",
  hmInitiatives: [],
  woInitiatives: [],
  ddInitiatives: [],
  iiInitiatives: [],
  InitiativesandOutComes: []
}
class JourneyInitiativeContainer extends Component {
  constructor() {
    super();
    this.AssessmentFoundation = [];
    this.AssessmentAutomated = [];
    this.AssessmentInsights = [];
    this.AssessmentIntelligent = [];
    this.InitiativeFoundation = [];
    this.InitiativeAutomated = [];
    this.InitiativeIntelligent = [];
    this.InitiativeInsights = [];
    this.OutcomeFoundation = [];
    this.OutcomeAutomated = [];
    this.OutcomeIntelligent = [];
    this.OutcomeInsights = [];
    this.tempCountFoundation = 0;
    this.tempCountAutomated = 0;
    this.tempCountInsights = 0;
    this.tempCountIntelligent = 0;
    this.state = {
      isClientStory:false,
      isShowClientStorySucess: false,
      showForm: false,
      isChanged: false,
      clonedclientStoryFormData: {},
      tempVersion: "",
      clientStoryFormData: _.cloneDeep(formProperties),
      deleteStoryId: 0,
      isDeleteStory: false,
      ClientStoryData: [],
      isInitativeManagement: false,
      initiativesDropDownData: [],
      selectedOutcomeDropdownData: [],
      outcomeDropDownData: [],
      selectedOutcomeOption: "",
      selectedDDOption: "",
      selectedWOOption: "",
      selectedHMOption: "",
      selectedIIOption: "",
      getStoryID: 0,
      version: 0.0,
      getIOJFormData: {},
      isCheckedOutcomes: [],
      isConfig: window.location.pathname === "/admin/Configurator",
      isShowSummaryDashboard: false,
      isparentdonutshow: true,
      isapicalled: false,
      isApiLoading: false,
      isAutoMigrateAPILoading: false,
      isGetNewGlidePathLoading: false,
      show: false,
      autohideshow: false,
      msg: "",
      userData: "",
      showConfiguratorUser: false,
      storyName: "",
      challenges: "",
      outcomes: "",
      solution: "",
      diverseData: "",
      woData: "",
      h_mData: "",
      iiData: "",
      next: "",
      isEdit: false,
      updateData: {},
      showIOJForm: false,
      initiativeDropdownValue: "",
      statusDropdown: "",
      iskpiLoading: false,
      startRoadMap: false,
      startDate: new Date(),
      selectedPhase: "",
      selectedTab: "",
      showQcGraph: false,
      journeyInitiativeContainerData: {},
      InitiativeCategory: [],
      iojLinktoReportStatus: false,
      omLinktoReportStatus: false,
      dsKpiData: [],
      DonutparentData: [],
      DonutchildData: [],
      IOJStageIDs: [],
      KPIData: [],
      TotalCount: 0,
      activeIndex: null,
      multiselect: { cl: false, off: false, sub: false, loc: false },
      FTEBenefitValueandunit: {
        FTEBenefitValue: 0,
        FTEUnit: "",
      },
      CostBenefitValueandunit: {
        CostBenefitValue: 0,
        CostBenefitUnit: "",
      },
      CarouselItemSize: 0,
      bubbleOptions: {
        responsive: true,
        title: {
          display: false,
        },
        animation: {

          animation: true,
          animationSteps: 100,
          animationEasing: "easeOutBounce",
          onAnimationComplete: null,
          duration: 8000
        },
        layout: {
          padding: {
            top: 24,
            left: 40,
            right: 40,
            bottom: 30,
          },
        },
        legend: {
          display: false,
        },
        tooltips: {
          mode: "index",
          intersect: true,
          position: "nearest",
          enabled: false,
          custom: function (tooltip) {
            //debugger
            const tooltipDataPoints = tooltip.dataPoints;
            if (!_.isNil(tooltipDataPoints)) {
              const currentDataPoint = _.first(tooltipDataPoints);
              if (!_.isNil(currentDataPoint)) {
                let timeValue = currentDataPoint.xLabel;
                let y = currentDataPoint.yLabel;
                var tooltipEl = document.getElementById(
                  "chartjs-bubbleIOJTooltip"
                );
                if (!tooltipEl) {
                  tooltipEl = document.createElement("div");
                  tooltipEl.id = "chartjs-bubbleIOJTooltip";
                  tooltipEl.innerHTML =
                    "<div class='bubbleIOJTooltip-body'></div>";
                  this._chart.canvas.parentNode.appendChild(tooltipEl);

                }
                // Hide if no tooltip
                if (tooltip.opacity === 0) {
                  tooltipEl.style.opacity = 0;
                  return;
                }
                // Set caret Position
                tooltipEl.classList.remove("above", "below", "no-transform");
                if (tooltip.yAlign) {
                  tooltipEl.classList.add(tooltip.yAlign);
                } else {
                  tooltipEl.classList.add("no-transform");
                }
                if (tooltip.body) {
                  let tooltipContent = `
                <div class='bubbleIOJTooltip-content'>
                    <span>${y}</span>
                </div>
              `;
                  var tableRoot = tooltipEl.querySelector(
                    ".bubbleIOJTooltip-body"
                  );
                  tableRoot.innerHTML = tooltipContent;
                }
                var positionY = (this._chart.canvas.offsetTop - 15);
                var positionX = this._chart.canvas.offsetLeft;
                // Display, position, and set styles for font
                tooltipEl.style.opacity = 1;
                tooltipEl.style.left = positionX + tooltip.caretX + "px";
                tooltipEl.style.top = positionY + tooltip.caretY + "px";
                tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
                tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
                tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
                tooltipEl.style.padding =
                  tooltip.yPadding + "px " + tooltip.xPadding + "px";
              } else {
                const tooltipEl = document.getElementById(
                  "chartjs-bubbleIOJTooltip"
                );
                if (tooltipEl) {
                  $(tooltipEl).remove();
                }
              }
            } else {
              const tooltipEl = document.getElementById(
                "chartjs-bubbleIOJTooltip"
              );
              if (tooltipEl) {
                $(tooltipEl).remove();
              }
            }
          },
        },
        plugins: {
          datalabels: {
            display: false,
          },
        },
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1,

                max: 20,

              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
              },

              gridLines: {
                drawOnChartArea: true,
                drawTicks: false,
                drawBorder: false,
                borderColor: "rgb(223, 227, 232)",
                lineWidth: 0.5,
                zeroLineColor: "rgb(223, 227, 232)",
              },
              ticks: {
                beginAtZero: true,
                stepSize: 1,
                max: 20,
                padding: 5,
                fontFamily: "Graphik",
                fontSize: 12,
                fontStyle: "normal",
                fontColor: "rgb(145, 158, 171)",
              },
            },
          ],
        },
      },
      bubbleDatasets: {
        datasets: [
          {
            backgroundColor: [
              "rgb(156, 106, 222)",
              "rgb(0, 186, 255)",
              "rgb(38, 193, 201)",
              "rgb(145, 158, 171)",
            ],
            borderWidth: 0,
            hoverRadius: 0,
            data: [
              { x: 0, y: 0, r: 20 },
              { x: 0, y: 0, r: 20 },
              { x: 0, y: 0, r: 20 },
              { x: 0, y: 0, r: 20 },
            ],
          },
        ],
      },
      DoughnutOptions: {
        type: "doughnut",
        data: {

          datasets: [],
        },
        options: {
          responsive: true,
          showAllTooltips: true,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          layout: {
            padding: {
              top: 50,
              left: 30,
              right: 30,
              bottom: 50,
            },
          },
          datasetRadiusBuffer: 25,
          plugins: {
            datalabels: {
              color: "rgb(33, 43, 54)",
              anchor: "end",
              align: "end",

              formatter: (value, ctx) => {
                let initiativecount = "Total Initiatives: " + value;
                return initiativecount;
              },
              font: {
                weight: 500,
                size: 12,
                family: "Graphik-Medium",
              },
              offset: 10,
            },
          },
          maintainAspectRatio: false,
          cutoutPercentage: 70,
        },
      },
      showPerformanceModal: false,
      performanceData: [],
      performanceStageID: 1,
      isInitiativeOutcomesDropdownStatus: false,
      isInitiativeOutcomesDropdownStatusDiverseData: false,
      isInitiativeOutcomesDropdownStatusWO: false,
      isInitiativeOutcomesDropdownStatusHM: false,
      isInitiativeOutcomesDropdownStatusII: false,
      viewClientStoryModalStatus: false,
      minimize: false,
      savedStoryStatus: false,
      updateStoryStatus: false,
      deleteDemoStatus: false,
      options: [{ name: 'Option 1??', id: 1 }, { name: 'Option 2??', id: 2 }],
      isVisibleGanttChart: false,
      hasALPAccess: false,
    };
    this.startRoadMap = this.startRoadMap.bind(this);
    this.handleDiverse = this.handleDiverse.bind(this);

    this.viewClientStoryModalClose = this.viewClientStoryModalClose.bind(this);
    this.DonutChart = React.createRef();
    this.DonutChart2 = React.createRef();
  }
  deleteDemoModalShow = (id) => {

    this.setState({ deleteDemoStatus: true, deleteStoryId: id, });
  };
  deleteDemoModalClose = () => {
    this.setState({ deleteDemoStatus: false });
  };
  minimize = () => {
    document.getElementsByTagName("iframe")[0].className = "";
    this.setState({ minshow: false });
  };
  Maximize = () => {
    document.getElementsByTagName("iframe")[0].className = "fullScreen";
    this.setState({ minshow: true });
  };
  viewClientStoryModalShow = (id) => {

    this.setState({ viewClientStoryModalStatus: true });

    axiosInstance
      .get(`${LocalApiBaseUrl}IOJJourneyInitiative/ClientStoryData`, {
        params: {
          ClientStoryID: id,
          LanguageID: store.getState().CurrentFilter.languageData.id
        },
      })
      .then((response) => {

        this.setState({
          getIOJFormData: response.data
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  }
  viewClientStoryModalClose() {
    this.setState({ viewClientStoryModalStatus: false });
  }
  //InitiativeOtcomes
  isInitiativeOutcomesDropdown = () => {
    this.setState({ isInitiativeOutcomesDropdownStatus: true });
  };
  isInitiativeOutcomesDropdownOverlay = () => {
    this.setState({ isInitiativeOutcomesDropdownStatus: false });
  };
  //DiverseData
  isInitiativeOutcomesDropdownDiverseData = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusDiverseData: true });
  };
  isInitiativeOutcomesDropdownOverlayDiverseData = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusDiverseData: false });
  };
  //WorkOrchestation
  isInitiativeOutcomesDropdownWO = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusWO: true });
  };
  isInitiativeOutcomesDropdownOverlayWO = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusWO: false });
  };
  //Human&Machine
  isInitiativeOutcomesDropdownHM = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusHM: true });
  };
  isInitiativeOutcomesDropdownOverlayHM = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusHM: false });
  };
  //Insights&Intelligence
  isInitiativeOutcomesDropdownII = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusII: true });
  };
  isInitiativeOutcomesDropdownOverlayII = () => {
    this.setState({ isInitiativeOutcomesDropdownStatusII: false });
  };

  handleInitiativeOutcomeChange = (event) => {

    this.setState({
      initiativeDropdownValue: event.target.value
    });
  }
  onClickFetchCategoryby = selectedData => (evt) => {
    this.setState({ selectedClientName: selectedData });
    setTimeout(() => {
      this.isInitiativeOutcomesDropdownOverlay();
    }, 100);
    setTimeout(() => {
      this.isInitiativeOutcomesDropdownOverlayDiverseData();
    }, 100);
    setTimeout(() => {
      this.isInitiativeOutcomesDropdownOverlayWO();
    }, 100);
    setTimeout(() => {
      this.isInitiativeOutcomesDropdownOverlayHM();
    }, 100);
    setTimeout(() => {
      this.isInitiativeOutcomesDropdownOverlayII();
    }, 100);
  }

  handleUpdate = (data) => {

    this.state.updateData = data;
    this.setState({
      showIOJForm: true,
      isEdit: true,
    });

  }
  performanceModalShow = (stageID) => {
    let data = this.state.journeyInitiativeContainerData.IOJGlidePathAllModels.filter(d => d.IOJStageID === stageID)[0].IOJInitiativeModels;
    let performanceData = data.length > 0 ? data.filter(d => d.InitiativeStatus === "In flight").length > 0 ? data.filter(d => d.InitiativeStatus === "In flight") : [] : []

    this.setState({ showPerformanceModal: true, performanceData, performanceStageID: stageID });
  };
  performanceModalClose = () => {
    this.setState({ showPerformanceModal: false, performanceData: [] });
  };
  onSelectInitaitve = (data, stageId) => {
    this.getInitiativeData(data, stageId);
  }

  getInitiativeData = (data, stageId) => {

    let configuratorStatus = this.props.history.location.pathname === "/admin/Configurator" ? true : false;
    const { languageData, currentFilter, clientData } = this.props;
    this.setState({
      isApiLoading: true,
    })
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetExpandedViewData?IOJStageID=${stageId}&LanguageId=${languageData.id}`, {
          OrgEntityID: clientData.id,
          OfferingID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
          LocationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
          OMID: currentFilter.LocationOMID[0] === "All" ? 0 : currentFilter.LocationOMID[0],
          OfferingName: currentFilter.OfferingName ? currentFilter.OfferingName : "All",
          IsConfigurator: configuratorStatus,
        })
        .then((response) => {
          let selectedInitiative = response.data.filter(e => e.IntelOpsInitiativesID === data.IOJInitiativeID)[0]
          store.dispatch(InitializeInitiative(response.data));
          let Datas = {
            viewData: selectedInitiative,
            StageData: response.data,
          };
          store.dispatch(InitializeInitiativedatas(Datas));
          this.setState({
            isApiLoading: false,
          }, () => {
            this.performanceModalClose()
            this.props.ShowInitativeDetails()
          })
          resolve();
        })
        .catch((error) => {
          this.setState({
            isApiLoading: false,
          })
          reject(error);
        });
    });
  };

  toggleShow = () => {
    this.setState({
      show: false
    })
  }


  handleScreenshot = async () => {
    const { currentFilter, languageData, clientData, filter } = this.props;

    this.setState({
      msg: "We are creating your presentation. Please wait...",
      show: true,
      autohideshow: false,
      isApiLoading: true,
    })

    this.AssessmentFoundation.length = 0;
    this.AssessmentAutomated.length = 0;;
    this.AssessmentInsights.length = 0;
    this.AssessmentIntelligent.length = 0;
    this.InitiativeFoundation.length = 0;
    this.InitiativeAutomated.length = 0;
    this.InitiativeIntelligent.length = 0;
    this.InitiativeInsights.length = 0;
    this.OutcomeFoundation.length = 0;
    this.OutcomeAutomated.length = 0;
    this.OutcomeIntelligent.length = 0;
    this.OutcomeInsights.length = 0;
    this.tempCountFoundation = 0;
    this.tempCountAutomated = 0;
    this.tempCountInsights = 0;
    this.tempCountIntelligent = 0;

    let a = [];
    let i = [];
    let o = [];

    let requestParameters = {
      orgEntityID: clientData.id,
      offeringID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
      offeringName: filter.offeringName ? filter.offeringName : "All",
      locationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
      omid: currentFilter.LocationOMID[0] === "All" ? 0 : parseInt(currentFilter.LocationOMID),
      IsConfigurator: false
    };
    for (let i = 1; i <= 4; i++) {
      o[i] = axiosInstance.post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetFrontDoorOutcomes?IOJStageID=${i}&languageId=${languageData.id}`,
        requestParameters)
        .then((response) => {
          if (response.data) {
            let outcomeCount = 0
            response.data.map((item) => {
              if (item.Initiative === null) {
                let tempArr = [];
                tempArr.push({
                  "text": outcomeCount += 1
                })
                tempArr.push({
                  "text": item.OutcomeDesc
                })
                if (i == 1) {
                  this.OutcomeFoundation.push(tempArr)
                }
                else if (i == 2) {
                  this.OutcomeAutomated.push(tempArr)
                }
                else if (i == 3) {
                  this.OutcomeInsights.push(tempArr)
                }
                else {
                  this.OutcomeIntelligent.push(tempArr)
                }
              }
            })
          }
        })
    }

    for (let j = 1; j <= 4; j++) {
      a[j] = axiosInstance.post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetAllAssessmentCriteriaR2FrontDoor?LanguageId=${languageData.id}&IOJStageID=${j}`,
        requestParameters
      )
        .then((response) => {
          if (response.data) {
            response.data.map((item, assessmentCount) => {
              let tempArr = []
              let status = item.RAGStatus === 'Amber' ? 'Not Met' : 'Met'
              tempArr.push({
                "text": assessmentCount + 1
              })
              tempArr.push({
                "text": item.CriteriaName,
                "options": {
                  "align": "left"
                }
              })
              tempArr.push({
                "text": status,
                "options": {
                  "align": "left"
                }
              })
              if (j == 1) {
                this.AssessmentFoundation.push(tempArr)
              }
              else if (j == 2) {
                this.AssessmentAutomated.push(tempArr)
              }
              else if (j == 3) {
                this.AssessmentInsights.push(tempArr)
              }
              else {
                this.AssessmentIntelligent.push(tempArr)
              }
            })
          }
        })
    }
    for (let k = 1; k <= 4; k++) {
      i[k] = axiosInstance.post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetExpandedViewData?IOJStageID=${k}&LanguageId=${languageData.id}&IsDraft=false`,
        requestParameters)
        .then((response) => {
          if (response.data) {
            response.data.map((item, initiativeCount) => {
              let tempArr = [];
              let extraSlide = [];
              let tempStr = [];
              let temp1 = '';
              tempArr.push({
                "text": initiativeCount + 1
              })
              tempArr.push({
                "text": item.ProjectInitiativeName
              })
              tempArr.push({
                "text": item.InitiativeStatus
              })
              if (item.InitiativeImpacts) {
                item.InitiativeImpacts.map((a) => {
                  tempStr.push(a.ImpactName)
                })
                extraSlide = [...new Set(tempStr)]
                temp1 = [...new Set(tempStr)].toString();
              }

              tempArr.push({
                "text": temp1
              })
              tempArr.push({
                "text": item.InitiativeOutcomeOption
              })
              if (k === 1) {
                if (extraSlide.length > 1) {
                  this.tempCountFoundation += extraSlide.length
                }
                else {
                  this.tempCountFoundation += 1
                }
                this.InitiativeFoundation.push(tempArr)
              }
              else if (k === 2) {
                if (extraSlide.length > 1) {
                  this.tempCountAutomated += extraSlide.length
                }
                else {
                  this.tempCountAutomated += 1
                }
                this.InitiativeAutomated.push(tempArr)
              }
              else if (k === 3) {
                if (extraSlide.length > 1) {
                  this.tempCountInsights += extraSlide.length
                }
                else {
                  this.tempCountInsights += 1
                }
                this.InitiativeInsights.push(tempArr)
              }
              else {
                if (extraSlide.length > 1) {
                  this.tempCountIntelligent += extraSlide.length
                }
                else {
                  this.tempCountIntelligent += 1
                }
                this.InitiativeIntelligent.push(tempArr)
              }
            })
          }

        })
    }
    await a[1];
    await a[1];
    await a[2];
    await a[3];
    await a[4];
    await o[1];
    await o[2];
    await o[3];
    await o[4];
    await i[1];
    await i[2];
    await i[3];
    await i[4];

    this.handleSlides();
  }

  handleSlides = async () => {
    console.time("test")
    let pres = new pptxgen();

    // 2. Add a Slide
    pres.defineSlideMaster({
      title: "Master",
      background: { color: "FFFFFF" },
      objects: [
        { image: { x: "88%", y: "2%", w: 0.4, h: 0.5, data: AccentureLogo } },
        { image: { x: "92%", y: "2%", w: 0.82, h: 0.55, path: SynopsLogo } },
        { image: { x: "5%", y: "96%", w: 2.5, h: 0.2, data: Confidential } },
        { image: { x: "55%", y: "96%", w: 4, h: 0.2, data: Copyright } },
      ],
      slideNumber: { x: "97%", y: "96%", fontSize: 8 },
    });
    let slide = pres.addSlide({ masterName: "Master" });
    let slide1 = pres.addSlide({ masterName: "Master" });

    // Array that holds the allotted slides
    let AssessmentFoundationSlide = []
    let AssessmentAutomatedSlide = []
    let AssessmentInsightsSlide = []
    let AssessmentIntelligentSlide = []
    let InitiativeFoundationSlide = []
    let InitiativeAutomatedSlide = []
    let InitiativeInsightsSlide = []
    let InitiativeIntelligentSlide = []
    let OutcomeFoundationSlide = []
    let OutcomeAutomatedSlide = []
    let OutcomeInsightsSlide = []
    let OutcomeintelligentSlide = []

    //Array that holds all the data from API call
    let AssessmentFoundation = this.AssessmentFoundation;
    let AssessmentAutomated = this.AssessmentAutomated;
    let AssessmentInsights = this.AssessmentInsights;
    let AssessmentIntelligent = this.AssessmentIntelligent;
    let InitiativeFoundation = this.InitiativeFoundation;
    let InitiativeAutomated = this.InitiativeAutomated;
    let InitiativeIntelligent = this.InitiativeIntelligent;
    let InitiativeInsights = this.InitiativeInsights;
    let OutcomeFoundation = this.OutcomeFoundation;
    let OutcomeAutomated = this.OutcomeAutomated;
    let OutcomeIntelligent = this.OutcomeIntelligent;
    let OutcomeInsights = this.OutcomeInsights;
    let tempCountFoundation = this.tempCountFoundation;
    let tempCountAutomated = this.tempCountAutomated;
    let tempCountInsights = this.tempCountInsights;
    let tempCountIntelligent = this.tempCountIntelligent;

    // Rows to be appended on all respective slides
    let row1 = [["Sr. No", "Assessment Criteria", "Status"]]
    let row2 = [["Sr. No", "Initiative Name", "Status", "Impact", "Outcome"]]
    let row3 = [["Sr. No", "Outcome"]]

    const LocationName =
      this.props.currentFilter != undefined
        ? this.props.currentFilter.LocationId != "All" && this.props.currentFilter.LocationId != "" ?
          this.props.OMIDDataList.filter((each) => each.locID == this.props.currentFilter.LocationId)
            .length > 0
            ? this.props.OMIDDataList.filter(
              (each) => each.locID == this.props.currentFilter.LocationId
            )[0].locname
            : ""
          : this.props.currentFilter.LocationId
        : "";
    let offering = this.props.CurrentFilter.SyncFilter.OfferingName === '' ? "All" : this.props.CurrentFilter.SyncFilter.OfferingName

    let location = offering === 'All' || suboffering === "All" ? "All" : LocationName
    let suboffering = offering === 'All' ? "All" : this.props.subOfferingData.subOfferingName
    let tempOMID = this.props.currentFilter.LocationOMID[0] === "All" ? '' : `- ${this.props.currentFilter.LocationOMID[0]}`
    slide.addText('Intelligent Operations Journey Internal Review', {
      align: pres.AlignH.center,
      color: "511281",
      fontSize: 40,
      fontFace: 'Calibri Light',
      x: '10%',
      y: '35%',
      bold: true,
    })

    slide.addShape(pres.shapes.LINE, { x: 0.2, y: '50%', w: '96%', h: 0.0, line: { color: "39A2DB", width: 1 } });

    slide1.addText("Client Name :", {
      x: '3%',
      y: '3%',
      color: "363636",
      fill: { color: "ffffff" },
      fontSize: 13,
      bold: true
    });

    slide1.addText(this.props.clientData.name, {
      x: '14%',
      y: '1.2%',
      color: "363636",
      fill: { color: "ffffff" },
      fontSize: 12,
      h: '3.5%',
      w: '30%',
      isTextBox: true
    });

    slide1.addText("Offering :", {
      x: '49%',
      y: '3%',
      color: "363636",
      fill: { color: "ffffff" },
      fontSize: 13,
      bold: true
    });

    slide1.addText(offering, {
      x: '57%',
      y: '1.2%',
      color: "363636",
      fill: { color: "ffffff" },
      fontSize: 12,
      h: '3.5%',
      w: '30%',
      isTextBox: true
    });


    slide1.addText("Sub Offering:",
      {
        x: '3%',
        y: '8%',
        color: "363636",
        fill: { color: "ffffff" },
        fontSize: 13,
        bold: true,
        isTextBox: true
      })

    slide1.addText(suboffering,
      {
        x: '14%',
        y: '6.2%',
        color: "363636",
        fill: { color: "ffffff" },
        fontSize: 12,
        h: '4%',
        w: '30%'
      })


    slide1.addText("Location:", {
      x: '49%',
      y: '8%',
      color: "363636",
      fill: { color: "ffffff" },
      fontSize: 13,
      bold: true
    })

    slide1.addText(`${location} ${tempOMID}`, {
      x: '57%',
      y: '6.2%',
      color: "363636",
      fill: { color: "ffffff" },
      fontSize: 12,
      h: '4%',
      w: '30%',
      isTextBox: true
    })
    //Capturing the screenshot and displaying it in the slide


    await htmlToImage.toPng(document.querySelector(".ioj-roadmap-container"))
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        slide1.addImage({ data: img.src, w: '77%', h: '85%', x: 1, y: 0.7 })
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });



    await htmlToImage.toPng(document.querySelector(".ioj-roadmap-container"))
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        slide1.addImage({ data: img.src, w: '77%', h: '85%', x: 1, y: 0.7 })
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });



    // Number of slides to be created
    let NumberAssessmentFoundation = Number.isInteger(AssessmentFoundation.length / 10) && AssessmentFoundation.length != 0 ? (AssessmentFoundation.length / 10) : (Math.floor(AssessmentFoundation.length / 10) + 1)
    let NumberAssessmentAutomated = Number.isInteger(AssessmentAutomated.length / 10) && AssessmentAutomated.length != 0 ? (AssessmentAutomated.length / 10) : (Math.floor(AssessmentAutomated.length / 10) + 1)
    let NumberAssessmentInsights = Number.isInteger(AssessmentInsights.length / 10) && AssessmentInsights.length != 0 ? (AssessmentInsights.length / 10) : (Math.floor(AssessmentInsights.length / 10) + 1)
    let NumberAssessmentIntelligent = Number.isInteger(AssessmentIntelligent.length / 10) && AssessmentIntelligent.length != 0 ? (AssessmentIntelligent.length / 10) : (Math.floor(AssessmentIntelligent.length / 10) + 1)
    let NumberInitiativeFoundation = Number.isInteger(InitiativeFoundation.length / 7) && InitiativeFoundation.length != 0 ? (InitiativeFoundation.length / 7) : (Math.floor(InitiativeFoundation.length / 7) + 1)
    let NumberInitiativeAutomated = Number.isInteger(InitiativeAutomated.length / 7) && InitiativeAutomated.length != 0 ? (InitiativeAutomated.length / 7) : (Math.floor(InitiativeAutomated.length / 7) + 1)
    let NumberInitiativeIntelligent = Number.isInteger(InitiativeIntelligent.length / 7) && InitiativeIntelligent.length != 0 ? (InitiativeIntelligent.length / 7) : (Math.floor(InitiativeIntelligent.length / 7) + 1)
    let NumberInitiativeInsights = Number.isInteger(InitiativeInsights.length / 7) && InitiativeInsights.length != 0 ? (InitiativeInsights.length / 7) : (Math.floor(InitiativeInsights.length / 7) + 1)
    let NumberOutcomeFoundation = Number.isInteger(OutcomeFoundation.length / 11) ? (OutcomeFoundation.length / 11) : (Math.ceil(OutcomeFoundation.length / 11))
    let NumberOutcomeAutomated = Number.isInteger(OutcomeAutomated.length / 11) ? (OutcomeAutomated.length / 11) : (Math.ceil(OutcomeAutomated.length / 11))
    let NumberOutcomeIntelligent = Number.isInteger(OutcomeIntelligent.length / 11) ? (OutcomeIntelligent.length / 11) : (Math.ceil(OutcomeIntelligent.length / 11))
    let NumberOutcomeInsights = Number.isInteger(OutcomeInsights.length / 11) ? (OutcomeInsights.length / 11) : (Math.ceil(OutcomeInsights.length / 11))

    if (InitiativeFoundation.length > 0) {
      if (NumberInitiativeFoundation * 16 <= tempCountFoundation) {
        NumberInitiativeFoundation += 1
      }
    }
    if (InitiativeAutomated.length > 0) {
      if (NumberInitiativeAutomated * 16 <= tempCountAutomated) {
        NumberInitiativeAutomated += 1
      }
    }
    if (InitiativeIntelligent.length > 0) {
      if (NumberInitiativeIntelligent * 16 <= tempCountIntelligent) {
        NumberInitiativeIntelligent += 1
      }
    }
    if (InitiativeInsights.length > 0) {
      if (NumberInitiativeInsights * 16 <= tempCountInsights) {
        NumberInitiativeInsights += 1
      }
    }


    for (let i = 0; i < NumberAssessmentFoundation; i++) {
      AssessmentFoundationSlide[i] = pres.addSlide({ masterName: "Master" });
      AssessmentFoundationSlide[i].addText("Foundation", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      AssessmentFoundationSlide[i].addTable(row1, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 6, 2.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }
    for (let i = 0; i < NumberInitiativeFoundation; i++) {
      InitiativeFoundationSlide[i] = pres.addSlide({ masterName: "Master" });
      InitiativeFoundationSlide[i].addText("Foundation", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      InitiativeFoundationSlide[i].addTable(row2, {
        y: 0.7,
        align: "center",
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        w: '90%',
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }

    for (let i = 0; i < NumberOutcomeFoundation; i++) {
      OutcomeFoundationSlide[i] = pres.addSlide({ masterName: "Master" });
      OutcomeFoundationSlide[i].addText("Foundation", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      OutcomeFoundationSlide[i].addTable(row3, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 8.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }


    for (let i = 0; i < NumberAssessmentAutomated; i++) {
      AssessmentAutomatedSlide[i] = pres.addSlide({ masterName: "Master" });
      AssessmentAutomatedSlide[i].addText("Automated", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      AssessmentAutomatedSlide[i].addTable(row1, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 6, 2.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }
    for (let i = 0; i < NumberInitiativeAutomated; i++) {
      InitiativeAutomatedSlide[i] = pres.addSlide({ masterName: "Master" });
      InitiativeAutomatedSlide[i].addText("Automated", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      InitiativeAutomatedSlide[i].addTable(row2, {
        y: 0.7,
        align: "center",
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        w: '90%',
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })

    }

    for (let i = 0; i < NumberOutcomeAutomated; i++) {
      OutcomeAutomatedSlide[i] = pres.addSlide({ masterName: "Master" });
      OutcomeAutomatedSlide[i].addText("Automated", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      OutcomeAutomatedSlide[i].addTable(row3, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 8.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }

    for (let i = 0; i < NumberAssessmentInsights; i++) {
      AssessmentInsightsSlide[i] = pres.addSlide({ masterName: "Master" });
      AssessmentInsightsSlide[i].addText("Insights Driven", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      AssessmentInsightsSlide[i].addTable(row1, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 6, 2.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }
    for (let i = 0; i < NumberInitiativeInsights; i++) {
      InitiativeInsightsSlide[i] = pres.addSlide({ masterName: "Master" });
      InitiativeInsightsSlide[i].addText("Insights Driven", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      InitiativeInsightsSlide[i].addTable(row2, {
        y: 0.7,
        align: "center",
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        w: '90%',
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }

    for (let i = 0; i < NumberOutcomeInsights; i++) {
      OutcomeInsightsSlide[i] = pres.addSlide({ masterName: "Master" });
      OutcomeInsightsSlide[i].addText("Insights Driven", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      OutcomeInsightsSlide[i].addTable(row3, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 8.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }

    for (let i = 0; i < NumberAssessmentIntelligent; i++) {
      AssessmentIntelligentSlide[i] = pres.addSlide({ masterName: "Master" });
      AssessmentIntelligentSlide[i].addText("Intelligent", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      AssessmentIntelligentSlide[i].addTable(row1, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 6, 2.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }
    for (let i = 0; i < NumberInitiativeIntelligent; i++) {
      InitiativeIntelligentSlide[i] = pres.addSlide({ masterName: "Master" });
      InitiativeIntelligentSlide[i].addText("Intelligent", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      InitiativeIntelligentSlide[i].addTable(row2, {
        y: 0.7,
        align: "center",
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        w: '90%',
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }

    for (let i = 0; i < NumberOutcomeIntelligent; i++) {
      OutcomeintelligentSlide[i] = pres.addSlide({ masterName: "Master" });
      OutcomeintelligentSlide[i].addText("Intelligent", {
        y: 0.3,
        x: 0.3,
        w: '85%',
        align: "left",
        color: "000000",
        fontSize: 18,
        bold: true
      })
      OutcomeintelligentSlide[i].addTable(row3, {
        y: 0.7,
        align: "center",
        w: '90%',
        colW: [0.7, 8.3],
        fill: 'e3d1ff',
        color: '000000',
        fontFace: 'Graphik-Medium',
        fontSize: 12,
        margin: 0.2,
        h: 0.3,
        align: pres.AlignH.center,
      })
    }

    if (AssessmentFoundation.length > 0) {
      AssessmentFoundationSlide[0].addTable(AssessmentFoundation, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 6, 2.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },
      })
    }
    else {
      AssessmentFoundationSlide[0].addText("No Assessment Criteria Added", {
        x: 1.2,
        y: 2.2,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (InitiativeFoundation.length > 0) {
      InitiativeFoundationSlide[0].addTable(InitiativeFoundation, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        addHeaderToEach: true,
        color: '000000',
        margin: 4.0,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },
        autoPageLineWeight: -0.25,
        autoPageCharWeight: -0.10
      })
    }
    else {
      InitiativeFoundationSlide[0].addText("No Initiatives Added", {
        x: 1.5,
        y: 2.5,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (OutcomeFoundation.length > 0) {
      OutcomeFoundationSlide[0].addTable(OutcomeFoundation, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 8.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },
      })
    }


    if (AssessmentAutomated.length > 0) {
      AssessmentAutomatedSlide[0].addTable(AssessmentAutomated, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 6, 2.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        rowH: 0.25,
        h: '65%',
        border: { pt: "1", color: "c7c7c7" },

      })
    }
    else {
      AssessmentAutomatedSlide[0].addText("No Assessment Criteria Added", {
        x: 1.5,
        y: 2.5,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (InitiativeAutomated.length > 0) {
      InitiativeAutomatedSlide[0].addTable(InitiativeAutomated, {
        y: 1.0,
        align: "left",
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        addHeaderToEach: true,
        w: '90%',
        color: '000000',
        margin: 4.5,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        rowH: 0.25,
        h: '65%',
        border: { pt: "1", color: "c7c7c7" },
        autoPageLineWeight: -0.25,
        autoPageCharWeight: -0.10
      })
    }
    else {
      InitiativeAutomatedSlide[0].addText("No Initiatives Added", {
        x: 1.5,
        y: 2.5,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (OutcomeAutomated.length > 0) {
      OutcomeAutomatedSlide[0].addTable(OutcomeAutomated, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 8.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },
      })
    }


    if (AssessmentInsights.length > 0) {
      AssessmentInsightsSlide[0].addTable(AssessmentInsights, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 6, 2.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },
      })
    }
    else {
      AssessmentInsightsSlide[0].addText("No Assessment Criteria Added", {
        x: 1.5,
        y: 2.5,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (InitiativeInsights.length > 0) {
      InitiativeInsightsSlide[0].addTable(InitiativeInsights, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        addHeaderToEach: true,
        color: '000000',
        margin: 4.5,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        rowH: 0.25,
        h: '65%',
        border: { pt: "1", color: "c7c7c7" },
        autoPageLineWeight: -0.25,
        autoPageCharWeight: -0.10
      })
    }
    else {
      InitiativeInsightsSlide[0].addText("No Initiatives Added", {
        x: 1.5,
        y: 2.5,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (OutcomeInsights.length > 0) {
      OutcomeInsightsSlide[0].addTable(OutcomeInsights, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 8.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },

      })
    }

    if (AssessmentIntelligent.length > 0) {
      AssessmentIntelligentSlide[0].addTable(AssessmentIntelligent, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 6, 2.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },

      })
    }
    else {
      AssessmentIntelligentSlide[0].addText("No Assessment Criteria Added", {
        x: 1.5,
        y: 2.5,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (InitiativeIntelligent.length > 0) {
      InitiativeIntelligentSlide[0].addTable(InitiativeIntelligent, {
        y: 1.0,
        colW: [0.7, 2.1, 1.2, 2.5, 2.5],
        addHeaderToEach: true,
        align: "left",
        w: '90%',
        color: '000000',
        margin: 4.0,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        h: '65%',
        rowH: 0.25,
        border: { pt: "1", color: "c7c7c7" },
        autoPageLineWeight: -0.25,
        autoPageCharWeight: -0.10
      })
    }
    else {
      InitiativeIntelligentSlide[0].addText("No Initiatives Added", {
        x: 1.5,
        y: 2.5,
        color: "363636",
        fill: { color: "F1F1F1" },
        align: pres.AlignH.center,
      })
    }

    if (OutcomeIntelligent.length > 0) {
      OutcomeintelligentSlide[0].addTable(OutcomeIntelligent, {
        y: 1.0,
        align: "left",
        w: '90%',
        colW: [0.7, 8.3],
        color: '000000',
        margin: 3,
        fontSize: 12,
        autoPage: true,
        newSlideStartY: 1.0,
        rowH: 0.25,
        h: '65%',
        border: { pt: "1", color: "c7c7c7" },
      })
    }

    let tempTime = ""
    let temp = new Date()
    let currentTime = tempTime.concat(temp.getFullYear(), temp.getMonth() + 1, temp.getDate(), '_', temp.getHours(), temp.getMinutes(), temp.getSeconds())

    pres.writeFile({ fileName: `Snapshot_${currentTime}.pptx` })
  
    this.setState({
      msg: "File downloaded successfully",
      show: true,
      autohideshow: true,
      isApiLoading: false
    })
  }

  hasRoadmapViewAccess = () => {
    const { roleData } = this.props;
    const foundPermissionObj = _.find(roleData, { FeatureName: "Export Roadmap" });

    let hasAccessPermission = false;
    if (!_.isEmpty(foundPermissionObj)) {
      hasAccessPermission = true;
    }
    return hasAccessPermission;
  };


  startRoadMap(phase, tab) {
    this.setState({
      startRoadMap: true,
      selectedPhase: phase,
      selectedTab: tab,
    });
  }
  setStartDate(data, IOJStageID) {
    let { journeyInitiativeContainerData } = this.state;
    let UpdatedJourneyData = _.cloneDeep(journeyInitiativeContainerData);
    UpdatedJourneyData.IOJGlidePathAllModels = UpdatedJourneyData.IOJGlidePathAllModels.map(
      (each, i) => {
        if (!each.GlidePathMonth) {
          if (i === 0) {
            each.GlidePathMonth = UpdatedJourneyData.GlideToFoundation;
          } else if (i === 1) {
            each.GlidePathMonth = UpdatedJourneyData.GlideToAutomated;
          } else if (i === 2) {
            each.GlidePathMonth = UpdatedJourneyData.GlideToInsights;
          } else {
            each.GlidePathMonth = UpdatedJourneyData.GlideToIntelligent;
          }
        }
        return each;
      }
    );
    UpdatedJourneyData.IOJGlidePathAllModels = UpdatedJourneyData.IOJGlidePathAllModels.map(
      (each) => {
        if (each.IOJStageID === IOJStageID) {
          let errorExist = false;
          let validPrevStageFlow = false;
          let validNextStageFlow = false;

          let currentStage = IOJStageID - 1;
          for (
            let i = 0;
            i < UpdatedJourneyData.IOJGlidePathAllModels.length;
            i++
          ) {
            if (currentStage !== i && i < currentStage) {
              if (
                UpdatedJourneyData.IOJGlidePathAllModels[currentStage - (i + 1)]
                  .GlidePathMonth
              ) {
                if (
                  new Date(
                    FormatDateWithMonthAndYear(
                      UpdatedJourneyData.IOJGlidePathAllModels[
                        currentStage - (i + 1)
                      ].GlidePathMonth
                    )
                  ) < new Date(FormatDateWithMonthAndYear(data))
                ) {
                  validNextStageFlow = true;
                } else {
                  alert(
                    `${i18n.t(
                      "Glide_Path_Date_should_be_greater_than"
                    )} ${i18n.t(
                      UpdatedJourneyData.IOJGlidePathAllModels[
                        currentStage - (i + 1)
                      ].IOJStageName
                    )} ${i18n.t("Glide_Path_Date")}`
                  );

                  errorExist = true;
                  break;
                }
              }
            } else if (currentStage !== i && i > currentStage) {
              if (UpdatedJourneyData.IOJGlidePathAllModels[i].GlidePathMonth) {
                if (
                  new Date(
                    FormatDateWithMonthAndYear(
                      UpdatedJourneyData.IOJGlidePathAllModels[i].GlidePathMonth
                    )
                  ) > new Date(FormatDateWithMonthAndYear(data))
                ) {
                  validPrevStageFlow = true;
                } else {
                  alert(
                    `${i18n.t("Glide_Path_Date_should_be_less_than")} ${i18n.t(
                      UpdatedJourneyData.IOJGlidePathAllModels[i].IOJStageName
                    )} ${i18n.t("Glide_Path_Date")}`
                  );
                  errorExist = true;
                  break;
                }
              }
            }
          }
          if (!errorExist || (validPrevStageFlow && validNextStageFlow)) {
            this.saveGlidePathMonth(new Date(data), IOJStageID);
            each.GlidePathMonth = new Date(data);
          }
        }
        return each;
      }
    );
    this.setState({ startDate: data });
  }
  saveGlidePathMonth = (date, IOJStageID) => {
    const { journeyInitiativeContainerData } = this.state;
    // if (date) {
    this.setState({ isApiLoading: true });
    let data = {
      ConfigurationIntelOpsRoadmapID:
        journeyInitiativeContainerData.ConfigurationIntelOpsRoadmapID,
      IOJStageID: IOJStageID,
      GlidePathMonthYear: date
        ? FormatDateWithMonthAndYear(date) + "/01"
        : null,
    };
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/NewAddEditGlidePathMonth`,
        data
      )
      .then((response) => {
        if (response.data) {
          // alert(i18n.t("Glide_path_date_s"));
          //Glide path date saved Successfully
        }
        // this.getJourneyInitiativeContainerData();
        this.getGlidePathData();
        // this.setState({ isApiLoading: false });
      })
      .catch((error) => {
        this.setState({ isApiLoading: false });
        trycatchAlertPopup(error);
      });
    // }
  };
  disableRoadMapView = () => {
    store.dispatch(ClearDatas_ViewDataStageData(true));
    this.setState({ startRoadMap: false });
    // this.getJourneyInitiativeContainerData();
    this.getGlidePathData();
  };

  getIIMetricDatafromStore = () => {
    let iiDetails = this.props.IIData;
    let SocatAnalytics = [];
    let SocatProcess = [];
    iiDetails.map((el) => {
      if (el.SOCategoryName === "Analytics Apps & AI Advisors") {
        SocatAnalytics.push(el.SOCategoryID);
      }
    });
    iiDetails.map((el) => {
      if (el.SOCategoryName === "Other Process Insights") {
        SocatProcess.push(el.SOCategoryID);
      }
    });

    let IImetrics = [];
    if (iiDetails.length !== 0) {
      iiDetails.map((row) => {
        IImetrics.push(...row.kpiData);
      });
    }
    store.dispatch(InitializeIIMetric(IImetrics));
  };


  async getKPIMappings(so, i) {

    const { ProcessId, LocationId } = this.props.currentFilter;
    const { clientData } = this.props;


    this.setState({ iskpiLoading: true });
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetKpiMapping`, {
          params: {
            OrgEntityID: clientData.id,
            LocationID: LocationId,
            OfferingID: ProcessId,
            SOCategoryID: so.SOCategoryID,
          },
        })
        .then((response) => {
          resolve(response.data);

        })
        .catch((error) => {
          trycatchAlertPopup(error);
          reject(error);
          this.setState({ iskpiLoading: false });
        });
    });
  }
  async getBOdataFromStore() {
    this.setState({ isapicalled: true });
    let BoiList = [];
    let that = this;
    let time = (this.props.boData.length + 4) * 1000;
    Promise.all(
      this.props.boData.map(async function (so, i) {
        const data = await that.getKPIMappings(so, i);

        BoiList.push(...data);

        store.dispatch(InitializeBOIList(BoiList), () => {
          that.setState({ isapicalled: false });
        });
      })
    )
      .then(() => {
        this.setState({ iskpiLoading: false });
      })
      .catch((error) => {
        this.setState({ iskpiLoading: false });
      });

  }
  getIIMetricDatafromStore() {
    let iiDetails = store.getState().intelligentInsightReducers[
      "insightandintelligence"
    ];
    let SocatAnalytics = [];
    let SocatProcess = [];
    iiDetails.map((el) => {
      if (el.SOCategoryName === "Analytics Apps & AI Advisors") {
        SocatAnalytics.push(el.SOCategoryID);
      }
    });
    iiDetails.map((el) => {
      if (el.SOCategoryName === "Other Process Insights") {
        SocatProcess.push(el.SOCategoryID);
      }
    });

    let IImetrics = [];
    if (iiDetails.length !== 0) {
      iiDetails.map((row) => {
        IImetrics.push(...row.kpiData);
      });
    }
    store.dispatch(InitializeIIMetric(IImetrics));
  }
  getIOJCategory = () => {
    const { process } = this.props.filterDetails;
    axiosInstance
      .get(`${LocalApiBaseUrl}IntelOpsCategory/GetIntelOpsCategory`, {
        params: {
          OfferingID: process,
        },
      })
      .then((response) => {
        store.dispatch(InitializeIOCategory(response.data));
        this.setState({
          InitiativeCategory: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });


  };

  setChildDonutData = (childdata) => {
    this.setState(
      {
        isparentdonutshow: false,
      },
      () => {
        this.ctx3 = this.DonutChart2.current.getContext("2d");
        var myChart2 = new Chart(this.ctx3, {
          type: "doughnut",
          data: {

            datasets: [
              {
                data: [
                  childdata[0].InConceptCount,
                  childdata[0].InFlightCount,
                  childdata[0].DeliveredCount,
                  childdata[0].OnHoldCount,
                  this.state.TotalCount,
                ],
                backgroundColor: [
                  "rgb(156, 106, 222)",
                  "rgb(0, 186, 255)",
                  "rgb(38, 193, 201)",
                  "rgb(145, 158, 171)",
                  "#FFFFFF",
                ],
                labels: ["In Concept", "In Flight", "Delivered", "On Hold"],
                datalabels: {
                  color: [
                    "rgb(33, 43, 54)",
                    "rgb(33, 43, 54)",
                    "rgb(33, 43, 54)",
                    "rgb(33, 43, 54)",
                    "#FFFFFF",
                  ],
                  formatter: (value, context, index, ctx) => {
                    debugger;
                    if (
                      context.dataset.labels[context.dataIndex] !== undefined
                    ) {


                      let initiativecount = `${("0" + value).slice(-2) +
                        "\n" +
                        context.dataset.labels[context.dataIndex]}`;

                      return initiativecount;
                    }
                  },
                },
              },
              {
                data: [20, 20, 20, 20],
                backgroundColor: [
                  "rgb(156, 106, 222)",
                  "rgb(156, 106, 222)",
                  "rgb(156, 106, 222)",
                  "rgb(156, 106, 222)",
                ],

                labels: [],
                datalabels: {
                  display: false,
                },
              },
            ],
          },
          options: {
            responsive: true,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: false,
            },
            layout: {
              padding: {
                top: 50,
                left: 30,
                right: 30,
                bottom: 50,
              },
            },
            datasetRadiusBuffer: 25,
            plugins: {
              datalabels: {
                color: "rgb(33, 43, 54)",
                anchor: "end",
                align: "end",

                font: {
                  weight: "normal",
                  size: 12,
                  family: "graphik",
                },
                offset: 10,
              },
            },
            maintainAspectRatio: false,
            cutoutPercentage: 50,
          },
        });
      }
    );
  };
  setparentdonut = () => {
    var that = this;
    this.setState(
      {
        isparentdonutshow: true,
      },
      () => {
        //Donut graph logics
        if (
          this.DonutChart.current &&
          this.DonutChart.current !== undefined &&
          this.DonutChart.current !== null
        ) {
          this.ctx2 = this.DonutChart.current.getContext("2d");
          var myChart = new Chart(this.ctx2, {
            type: "doughnut",
            data: {
              labels: that.state.IOJStageIDs,
              datasets: [
                {
                  data: this.state.DonutparentData.map((a) => a.Count),
                  backgroundColor: [
                    "rgb(167, 65, 231)",
                    "rgb(181, 103, 232)",
                    "rgb(103, 46, 140)",
                    "rgb(142, 46, 203)",
                  ],
                  labels: this.state.DonutparentData,
                },
              ],
            },
            options: {
              hover: {
                mode: "nearest",
                intersect: false,
                onHover: function (e, item) {
                  // debugger
                  var activePoint = myChart.getElementAtEvent(e);
                  if (activePoint.length > 0) {
                    var clickedDatasetIndex = activePoint[0]._datasetIndex;
                    var clickedElementindex = activePoint[0]._index;
                    var label = myChart.data.labels[clickedElementindex];
                    var value =
                      myChart.data.datasets[clickedDatasetIndex].data[
                      clickedElementindex
                      ];
                    var childdata = that.state.DonutchildData.filter(
                      (a) => a.IOJStageID === parseInt(label)
                    );
                    that.setChildDonutData(childdata);

                  }
                },
              },
              responsive: true,
              showAllTooltips: false,
              legend: {
                display: false,
              },
              tooltips: {
                enabled: false,
              },
              layout: {
                padding: {
                  top: 50,
                  left: 30,
                  right: 30,
                  bottom: 50,
                },
              },
              datasetRadiusBuffer: 25,
              plugins: {
                datalabels: {
                  color: "rgb(33, 43, 54)",
                  anchor: "end",
                  align: "end",

                  formatter: (value, context, index, ctx, e) => {
                    debugger;

                    let initiativecount = `${context.dataset.labels[
                      context.dataIndex
                    ].StageName +
                      "\n" +
                      "Total Initiatives:" +
                      "\t" +
                      value}`;
                    return initiativecount;
                  },
                  font: {
                    weight: "normal",
                    size: 12,
                    family: "graphik",
                  },
                  offset: 10,
                },
              },
              maintainAspectRatio: false,
              cutoutPercentage: 70,
            },
          });
        }
      }
    );
  };
  setGraphData = (SummaryDashboardData) => {
    let that = this;
    // debugger
    let TotalCount = 0,
      InConceptCount = 0,
      InFlightCount = 0,
      DeliveredCount = 0,
      OnHoldCount = 0,
      BaseInConceptCount = 0,
      BaseInFlightCount = 0,
      BaseDeliveredCount = 0,
      BaseOnHoldCount = 0,
      DonutparentData = [],
      DonutchildData = [],
      IOJStageIDs = [],
      KPIData = [],
      DonutData = {},
      BubblexaxisSize = 0,
      BubblepixelSize = 0,
      CarouselItemSize = 0;

    let {
      bubbleDatasets,
      bubbleOptions,
      DoughnutOptions,
      FTEBenefitValueandunit,
      CostBenefitValueandunit,
    } = this.state;
    if (Object.entries(SummaryDashboardData).length !== 0) {
      const IOJGlidePathAllModels = SummaryDashboardData.IOJGlidePathAllModels;
      const BenefitValuesAndUnits = SummaryDashboardData.BenefitValuesAndUnits;

      if (SummaryDashboardData.AllJourneyDashboardBOIMetric && SummaryDashboardData.AllJourneyDashboardBOIMetric.length > 0) {
        KPIData = SummaryDashboardData.AllJourneyDashboardBOIMetric.map(
          (a) => a.ImpactedBOKPIName
        );
      }

      FTEBenefitValueandunit.FTEBenefitValue =
        BenefitValuesAndUnits.FTEBenefitValue;
      FTEBenefitValueandunit.FTEUnit = BenefitValuesAndUnits.FTEUnit;
      CostBenefitValueandunit.CostBenefitValue =
        BenefitValuesAndUnits.CostBenefitValue;
      CostBenefitValueandunit.CostBenefitUnit =
        BenefitValuesAndUnits.CostBenefitUnit;
      BubblexaxisSize = BenefitValuesAndUnits.BubblexaxisSize;
      CarouselItemSize = BenefitValuesAndUnits.CarouselItemSize;
      BubblepixelSize = BenefitValuesAndUnits.BubblepixelSize;

      if (
        IOJGlidePathAllModels &&
        IOJGlidePathAllModels != null &&
        IOJGlidePathAllModels != undefined &&
        IOJGlidePathAllModels.length > 0
      ) {
        IOJGlidePathAllModels.map((a, index) => {
          IOJStageIDs = [...IOJStageIDs, a.IOJStageID];
          if (a.IOJInitiativeModels && a.IOJInitiativeModels.length > 0) {
            let color = "";
            if (a.IOJStageID === 1) {
              color = "#b567e8";
            } else if (a.IOJStageID === 2) {
              color = "#b567e8";
            } else if (a.IOJStageID === 3) {
              color = "#8e2ecb";
            } else {
              color = "#672e8c";
            }
            DonutparentData = [
              ...DonutparentData,
              {
                ID: a.IOJStageID,
                StageName: a.IOJStageName,
                Count: a.IOJInitiativeModels.length,
                fill: color,
              },
            ];
            TotalCount = TotalCount + a.IOJInitiativeModels.length;

            BaseInConceptCount = a.IOJInitiativeModels.filter(
              (a) => a.InitiativeStatus === "In concept"
            ).length;
            BaseInFlightCount = a.IOJInitiativeModels.filter(
              (a) => a.InitiativeStatus === "In flight"
            ).length;
            BaseDeliveredCount = a.IOJInitiativeModels.filter(
              (a) => a.InitiativeStatus === "Delivered"
            ).length;
            BaseOnHoldCount = a.IOJInitiativeModels.filter(
              (a) => a.InitiativeStatus === "On Hold"
            ).length;

            InConceptCount = InConceptCount + BaseInConceptCount;
            InFlightCount = InFlightCount + BaseInFlightCount;
            DeliveredCount = DeliveredCount + BaseDeliveredCount;
            OnHoldCount = OnHoldCount + BaseOnHoldCount;


            DonutchildData = [
              ...DonutchildData,
              {
                IOJStageID: a.IOJStageID,
                InConceptCount: BaseInConceptCount,
                InFlightCount: BaseInFlightCount,
                DeliveredCount: BaseDeliveredCount,
                OnHoldCount: BaseOnHoldCount,
              },
            ];
          } else {
            DonutchildData = [
              ...DonutchildData,
              {
                IOJStageID: a.IOJStageID,
                InConceptCount: BaseInConceptCount,
                InFlightCount: BaseInFlightCount,
                DeliveredCount: BaseDeliveredCount,
                OnHoldCount: BaseOnHoldCount,
              },
            ];
          }
        });
      }
    }
    KPIData = [...new Set(KPIData)];

    bubbleOptions.scales.xAxes[0].ticks.max = BubblexaxisSize;

    bubbleOptions.scales.yAxes[0].ticks.max = TotalCount + 1;

    bubbleDatasets.datasets[0].data = [
      { x: 5, y: InConceptCount, r: BubblepixelSize },
      { x: 15, y: InFlightCount, r: BubblepixelSize },
      { x: 25, y: DeliveredCount, r: BubblepixelSize },
      { x: 35, y: OnHoldCount, r: BubblepixelSize },
    ];

    this.setState(
      {
        KPIData,
        DonutparentData,
        DonutchildData,
        TotalCount,
        IOJStageIDs,
        FTEBenefitValueandunit,
        CostBenefitValueandunit,
        bubbleDatasets,
        CarouselItemSize,
      },
      () => {
        if (TotalCount > 0) {
          this.setparentdonut();
        }
      }
    );
    //
  };
  getGlidePathData = () => {
    this.setState({
      DonutparentData: [],
      DonutchildData: [],
    });
    const {
      currentFilter,
      languageData,
      clientData,
      history,
      roleReducers,
    } = this.props;
    const { isConfig } = this.state;
    if (
      clientData.id &&
      currentFilter.ProcessId &&
      currentFilter.LocationId &&
      currentFilter.LocationOMID &&
      currentFilter.LocationOMID[0]
    ) {
      this.setState({ isApiLoading: true, isAutoMigrateAPILoading: true });

      store.dispatch(saveJourneyData({}));
      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AutoMigrateData`, {
          OrgEntityID: clientData.id,
          OfferingID:
            currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
          LocationID:
            currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
          OMID:
            currentFilter.LocationOMID[0] === "All"
              ? 0
              : currentFilter.LocationOMID[0],
          LanguageID: languageData.id,
          OfferingName: !currentFilter.OfferingName
            ? "All"
            : currentFilter.OfferingName,
          IsConfigurator:
            history.location.pathname === "/admin/Configurator" ? true : false,
        })
        .then((response) => {
          this.setState({
            isAutoMigrateAPILoading: false
          })
          if (response.data) {
            this.setState({ isApiLoading: true, isGetNewGlidePathLoading: true });
            axiosInstance
              .post(
                `${LocalApiBaseUrl}IOJJourneyInitiative/GetNewAllGlidePathData`,
                {
                  OrgEntityID: clientData.id,
                  OfferingID:
                    currentFilter.ProcessId === "All"
                      ? 0
                      : currentFilter.ProcessId,
                  LocationID:
                    currentFilter.LocationId === "All"
                      ? 0
                      : currentFilter.LocationId,
                  OMID:
                    currentFilter.LocationOMID[0] === "All"
                      ? 0
                      : currentFilter.LocationOMID[0],
                  LanguageID: languageData.id,
                  OfferingName: !currentFilter.OfferingName
                    ? "All"
                    : currentFilter.OfferingName,
                  IsConfigurator:
                    history.location.pathname === "/admin/Configurator"
                      ? true
                      : false,
                }
              )
              .then((response) => {
                if (response.data) {
                  if (!isConfig) {
                    this.setGraphData(response.data);
                  }
                  this.setState(
                    {
                      journeyInitiativeContainerData: response.data,
                      isApiLoading: false,
                      isGetNewGlidePathLoading: false
                    },
                    () => {
                      if (
                        this.state.journeyInitiativeContainerData.IsIOJCategory
                      ) {
                        this.getIOJCategory();
                      }
                    }
                  );
                  store.dispatch(saveJourneyData(response.data));
                  store.dispatch(
                    InitializeIOJData(response.data.IOJGlidePathAllModels)
                  );
                }
              })
              .catch((error) => {
                this.setState({ isApiLoading: false, isAutoMigrateAPILoading: false, isGetNewGlidePathLoading: false });
                trycatchAlertPopup(error);
              });
          }
          if (response.data) {
            axiosInstance
              .get(`${LocalApiBaseUrl}Configurator/GetIntelligentOperationsJourneyQualityScore`, {
                params: {
                  OrgEntityID: clientData.id,
                  OfferingID: currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
                  LocationID: currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
                  OMID: currentFilter.LocationOMID.length === 0 || currentFilter.LocationOMID[0] === "All" ? 0 : currentFilter.LocationOMID[0],
                  LanguageID: store.getState().CurrentFilter.languageData.id,
                },
              })
              .then((response) => {
                if (response.data.length) {
                }
                store.dispatch({
                  type: "SAVE_QC_SCORE_OF_EACH_TAB",
                  payload: response.data,
                });
              })
              .catch((err) => {
                this.setState({ isApiLoading: false });
              });
          }
          if (currentFilter.LocationOMID[0] != "All") {
            if (
              roleReducers.filter((each) => {
                if (
                  each.FeatureName === "Configurator" &&
                  each.Operations.filter((op) => op === "Submit").length
                ) {
                  return each;
                }
              }).length !== 0
            ) {
              axiosInstance
                .get(
                  `${LocalApiBaseUrl}IOJJourneyInitiative/GetOMIDToggleButtonStatus`,
                  {
                    params: {
                      OfferingId: currentFilter.ProcessId,
                      OrgEntityID: clientData.id,
                      LocationID: currentFilter.LocationId,
                      OMID: currentFilter.LocationOMID[0],
                    },
                  }
                )
                .then((response) => {
                  store.dispatch(SetOMIDStatus(response.data));
                })
                .catch((error) => {
                  trycatchAlertPopup(error);
                });


            }
          }
        })
        .catch((err) => {
          this.setState({ isApiLoading: false });
        });
    }
  };
  getBOIMetricData = () => {
    const { ProcessId, LocationId } = this.props.currentFilter;
    const { clientData } = this.props;
    if (ProcessId && LocationId != "All" && clientData.id) {
      axiosInstance
        .get(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetBOIandKpiSlaMetricList`,
          {
            params: {
              OrgEntityID: clientData.id,
              LocationID: LocationId,
              OfferingID: ProcessId,
              IsBOI: true,
            },
          }
        )
        .then((response) => {
          store.dispatch(InitializeBOIList(response.data));
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };
  getKPIiiMetricData = () => {
    const { ProcessId, LocationId } = this.props.currentFilter;
    const { clientData } = this.props;
    if (ProcessId && LocationId != "All" && clientData.id) {
      axiosInstance
        .get(
          `${LocalApiBaseUrl}IOJJourneyInitiative/GetBOIandKpiSlaMetricList`,
          {
            params: {
              OrgEntityID: clientData.id,
              LocationID: LocationId,
              OfferingID: ProcessId,
              IsBOI: false,
            },
          }
        )
        .then((response) => {
          let iimetrics = response.data.map((each) => ({
            ...each,
            KPIMappingID: each.KpiMappingID,
          }));
          store.dispatch(InitializeIIMetric(iimetrics));
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });
    }
  };
  componentDidUpdate(prevProps) {

    const {
      currentFilter,
      clientData,
      Datas,
      IIData,
      boData,
      JourneyData,
    } = this.props;
    let prevpropsCurrentFilter = { ...prevProps.currentFilter, OfferingId: "" }  //Making OfferingID empty string to avoid duplicate render of api calls

    let currentPropsCurrentFilter = { ...currentFilter, OfferingId: "" }
    if (
      JSON.stringify(prevpropsCurrentFilter) !== JSON.stringify(currentPropsCurrentFilter) &&
      currentFilter &&
      currentFilter.OfferingId &&
      currentFilter.ProcessId &&
      currentFilter.LocationId &&
      currentFilter.LocationOMID &&
      currentFilter.LocationOMID.length
    ) {
      this.props.storeActionStatus({ isAction: true });
      this.getGlidePathData();
      this.getBOIMetricData();
      this.getKPIiiMetricData();

    }
    if (boData.length > 0 && !this.state.isapicalled) {

    }


    if (Datas.IImetrics.length === 0) {
      if (IIData.length > 0) {

      }
    } else {
      // if data is there , then compare with two reducers in II and IOJ
      let IImetrics = [];
      if (IIData.length !== 0) {
        IIData.map((row) => {
          IImetrics.push(...row.kpiData);
        });
      }
      if (
        JSON.stringify(
          IImetrics.map((a) => a.KPIMappingID).sort((a, b) => (a > b ? 1 : -1))
        ) !==
        JSON.stringify(
          Datas.IImetrics.map((a) => a.KPIMappingID).sort((a, b) =>
            a > b ? 1 : -1
          )
        )
      ) {

      }
    }

    if (
      prevProps.currentFilter !== currentFilter &&
      prevProps.clientData !== clientData
    ) {
      this.getGlidePathData();
      this.getBOIMetricData();
      this.getKPIiiMetricData();

    }
    if (prevProps.JourneyData !== JourneyData) {
      this.setState({ journeyInitiativeContainerData: JourneyData });
    }
  }

  selectOutcome = (outcomes) => {
    if (outcomes.length > 6) {
      window.alert("You can select only 6 items")
    }
    else {
      let cloneItems = []
      outcomes.map((item) => {
        cloneItems.push(item)
      })
      this.setState({
        selectedOutcomeDropdownData: cloneItems
      }, () => {

      })

    }
  }
  getInitiativesOutcome = (body) => {
    let outcomeClientStory = [];
    let selectedOutcomeClientStory = []

    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetOutcomeDropdownForClientStory`,
        body
      )
      .then((response) => {

        const responseData = response.data;
        let mappedDataForDropdown = [];
        if (!_.isEmpty(responseData)) {
          mappedDataForDropdown = _.sortBy(
            _.map(responseData, (eachItem) => ({
              label: eachItem.OutcomeDesc,
              label_en: eachItem.OutcomeDesc_En,
              value: String(eachItem.ID),
              IsCustom: eachItem.IsCustom
            })),
            ["value"]
          );
        }
        this.setState({
          outcomeDropDownData: mappedDataForDropdown
        })


      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  GetIOJClientStories = (body) => {
    this.setState({
      isApiLoading: true
    })
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetIOJClientStories`,
        body, {
        params: {
          LanguageID: store.getState().CurrentFilter.languageData.id,
        }
      }
      )
      .then((response) => {
        this.setState({
          isApiLoading: false
        })

        this.setState({
          ClientStoryData: response.data
        });


      })
      .catch((error) => {
        this.setState({
          isApiLoading: false
        })
        trycatchAlertPopup(error);
      });
  }

  GetSOCatDropdownDataForClientStory = (body) => {
    //get lever dropdowns
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetSOCatDropdownDataForClientStory`,
        body
      )
      .then((response) => {
        const responseData = response.data;
        let mappedDataForDropdown = [];
        if (!_.isEmpty(responseData)) {
          mappedDataForDropdown = _.sortBy(
            _.map(responseData, (eachItem) => ({
              label: eachItem.InitiativeName,
              label_en: eachItem.InitiativeName_En,
              SOCategoryID: eachItem.SOCategoryID,
              value: String(eachItem.InitiativeID)
            })),
            ["value"]
          );
        }
        this.setState({
          initiativesDropDownData: mappedDataForDropdown
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    //End IOJ_Client_StoryTelling
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    let { clientStoryFormData, clonedclientStoryFormData, tempVersion, isChanged } = this.state
    clientStoryFormData[name] = value;
    this.setState({
      clientStoryFormData
    }, () => {
      if (Object.entries(clonedclientStoryFormData).length !== 0) {
        /*         let isArray=_.isArray(clientStoryFormData[name]);
                if(isArray){ */
        let ChangedCount = 0;
        Object.keys(clientStoryFormData).map((name) => {
            if(name!=="Version"){
          if(!_.isEqual(clonedclientStoryFormData[name], clientStoryFormData[name]))
          {


              ChangedCount = ChangedCount + 1;
            }
          else{

            }
          }
        });
        if (ChangedCount > 0) {
          clientStoryFormData.Version = tempVersion;
          isChanged = true;
        }
        else {
          clientStoryFormData.Version = clonedclientStoryFormData.Version;
          isChanged = false;
        }
        this.setState({
          clientStoryFormData,
          isChanged
        })


      }
    });
  }
  generateVersion = () => {
    const { currentFilter, clientData } = this.props;
    const { clientStoryFormData, clonedclientStoryFormData } = this.state
    let params = {
      "OrgEntityID": clientData.id,
      "Offering": !currentFilter.OfferingName
        ? "All"
        : currentFilter.OfferingName,
      "StoryName": clientStoryFormData.StoryName
    }
    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GenerateVersion`,
        params
      )
      .then((response) => {
        let version = response.data;
        let tempVersion = "";
        if (Object.keys(clonedclientStoryFormData).length !== 0) {
          tempVersion = version;
        }
        else {
          clientStoryFormData.Version = version;
        }
        this.setState({
          clientStoryFormData,
          tempVersion
        })
      }
      ).catch((error) => {

      })
  }

  submitStoryForm = () => {
    const { clientStoryFormData, outcomeDropDownData } = this.state
    const { currentFilter, clientData } = this.props;
    this.setState({
      isApiLoading: true
    })
    let InitiativeIDs = [], InitiativeoutcomesIDs = [], CustomOutcomeIDs = [];
    InitiativeIDs = [...clientStoryFormData.ddInitiatives,
    ...clientStoryFormData.hmInitiatives, ...clientStoryFormData.iiInitiatives,
    ...clientStoryFormData.woInitiatives]

    if (!_.isEmpty(clientStoryFormData.InitiativesandOutComes) && !_.isEmpty(outcomeDropDownData)) {
      let selectedOutcomeIds = clientStoryFormData.InitiativesandOutComes;
      InitiativeoutcomesIDs = outcomeDropDownData.filter(a => selectedOutcomeIds.includes(String(a.value))
        && a.IsCustom === false).map(a => parseInt(a.value))///STANDARD OUTCOMEIDS
      CustomOutcomeIDs = outcomeDropDownData.filter(a => selectedOutcomeIds.includes(String(a.value))
        && a.IsCustom).map(a => parseInt(a.value))///CUSTOM OUTCOMEIDS
    }

    let data = {
      "OrgEntityId": clientData.id,
      "Offering": !currentFilter.OfferingName
        ? "All"
        : currentFilter.OfferingName,
      "StoryName": clientStoryFormData.StoryName,
      "Version": clientStoryFormData.Version.substring(1),
      "Challenges": clientStoryFormData.Challenges,
      "Outcomes": clientStoryFormData.Outcomes,
      "Solution": clientStoryFormData.Solution,
      "DiverseData": clientStoryFormData.DiverseData,
      "WorkOrchestration": clientStoryFormData.WorkOrchestration,
      "HumanMachine": clientStoryFormData.HumanMachine,
      "InsightsIntelligence": clientStoryFormData.InsightsIntelligence,
      "WhatNext": clientStoryFormData.WhatNext,
      "InitiativesIDs": InitiativeIDs,
      "InitiativesandOutComeIDs": {
        "InitiativeoutcomesIDs": InitiativeoutcomesIDs,
        "CustomOutcomeIDs": CustomOutcomeIDs
      },
    }
    // let Data=updateData;
    axiosInstance.post(`${LocalApiBaseUrl}IOJJourneyInitiative/SaveIOJClientStory`, data, {
      params: {},
    }).then((response) => {
      if (response.data.IsSuccess) {
        this.setState({
          isApiLoading: false
        })
        const param = {
          OrgEntityID: clientData.id,
          Offering: !currentFilter.OfferingName
            ? "All"
            : currentFilter.OfferingName
        }

        this.GetIOJClientStories(param);

        this.closeform();
        this.setState({
          isShowClientStorySucess: true
        })
      }
    }).catch((err) => {
      this.setState({
        isApiLoading: false
      })
      trycatchAlertPopup(err);
    })
    setTimeout(() => {
      this.setState({
        isShowClientStorySucess: false
      });
    }, 10000);
  }
  getSingleStoryData = (data) => {
    this.setState({
      showForm: true,
      isApiLoading: true
    })
    axiosInstance
      .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetClientStoryData`, {
        params: {
          ClientStoryID: data.ID,
          LanguageID: store.getState().CurrentFilter.languageData.id
        },
      })
      .then((response) => {
        this.setState({
          isApiLoading: false
        })
        let singleStoryData = response.data
        let { clientStoryFormData } = this.state
        clientStoryFormData.StoryName = singleStoryData.StoryName;
        clientStoryFormData.Challenges = singleStoryData.Challenges;
        clientStoryFormData.DiverseData = singleStoryData.DiverseData;
        clientStoryFormData.HumanMachine = singleStoryData.HumanMachine;
        clientStoryFormData.Outcomes = singleStoryData.Outcomes;
        clientStoryFormData.Solution = singleStoryData.Solution;
        clientStoryFormData.Version = "V" + parseFloat(singleStoryData.Version).toString();
        clientStoryFormData.WhatNext = singleStoryData.WhatNext;
        clientStoryFormData.WorkOrchestration = singleStoryData.WorkOrchestration;

        if (!_.isEmpty(singleStoryData.initiatives)) {
          let initiatives = singleStoryData.initiatives;
          clientStoryFormData.ddInitiatives = initiatives.filter(a => a.SOCategoryID === 5).map(a => String(a.InitiativeID));
          clientStoryFormData.iiInitiatives = initiatives.filter(a => a.SOCategoryID === 4).map(a => String(a.InitiativeID));
          clientStoryFormData.woInitiatives = initiatives.filter(a => a.SOCategoryID === 3).map(a => String(a.InitiativeID));
          clientStoryFormData.hmInitiatives = initiatives.filter(a => a.SOCategoryID === 2).map(a => String(a.InitiativeID));
        }
        else {
          clientStoryFormData.ddInitiatives = [];
          clientStoryFormData.iiInitiatives = [];
          clientStoryFormData.woInitiatives = [];
          clientStoryFormData.hmInitiatives = [];
        }
        if (!_.isEmpty(singleStoryData.initiativeoutcomes)) {
          let initiativeoutcomes = singleStoryData.initiativeoutcomes;
          clientStoryFormData.InitiativesandOutComes = initiativeoutcomes.map(a => String(a.OutcomeID))
        }
        else {
          clientStoryFormData.InitiativesandOutComes = [];
        }
        this.setState({
          clientStoryFormData,
          clonedclientStoryFormData: _.cloneDeep(clientStoryFormData)
        }, () => {
          this.generateVersion();
        })
        /*  setupdateData(data);
         setshowIOJForm(true); */
      })
      .catch((error) => {
        this.setState({
          isApiLoading: false
        })
        trycatchAlertPopup(error);
      });
  }
  showformmethod = () => {


    this.setState({

      showForm: true
    })
  }
  closeform = () => {
    this.setState({
      showForm: false,
      clonedclientStoryFormData: {},
      isChanged: false,
      //  clientStoryFormData:_.cloneDeep(formProperties)
    })
  }
    callClientStoryAPI = () => {
    const { currentFilter, languageData, clientData, history } = this.props;
    const body = {
      OrgEntityID: clientData.id,
      Offering: !currentFilter.OfferingName
        ? "All"
        : currentFilter.OfferingName
    }
    this.GetIOJClientStories(body);
    this.GetSOCatDropdownDataForClientStory(body);
    this.getInitiativesOutcome(body);
  }

  componentDidMount() {
    const {
      isStage,
      currentFilter,
      clientData,
      Datas,
      IIData,
      boData,
      isInitativeEditMode,
      Tab,
      roleData
    } = this.props;


    if (Tab === "IOJMaturityRoadmap") {
      this.props.storeActionStatus({ isAction: true })
    }
    if (isStage !== null && isStage !== undefined) {
      if (isStage) {
        const {
          viewData,
          StageData,
          BoiList,
        } = store.getState().JourneyReducer["Datas"];
        let temp = this.props.allSelected ? "All" : viewData.StageName_En
        this.setState({
          startRoadMap: true,
          selectedTab: "Initiatives",
          selectedPhase: temp,
        });
      }
    }
    if (currentFilter) {
      this.getGlidePathData();
      this.getBOIMetricData();
      this.getKPIiiMetricData();

    }



    if (boData.length > 0 && !this.state.isapicalled) {

    }

    if (Datas.IImetrics.length === 0) {
      if (IIData.length > 0) {

      }
    } else {
      let IImetrics = [];
      if (IIData.length !== 0) {
        IIData.map((row) => {
          IImetrics.push(...row.kpiData);
        });
      }
      if (IImetrics.length !== 0 && IImetrics !== Datas.IImetrics) {

      }
    }
    store.dispatch(ClearDatas_ViewDataStageData(true));
    this.handleDisplayReferencesWindow();
    let showConfiguratorUser = roleData.filter(a => a.FeatureName === "Configurator").length > 0 ? true : false
    this.setState({
      showConfiguratorUser
    });
    this.getHasALPAccess();
  }
  getHasALPAccess = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}User/ALPUsersDetails`, {
        params: {
          userID: sessionStorage.getItem('EnterpriseID'),
         Token: sessionStorage.getItem(idTokenstring),
        }
      }).then((response) => {
        this.setState({
          hasALPAccess: response.data
        })
      }).catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getNewJourneyGetALLGlidePathAPI = () => {
    const { currentFilter, languageData, clientData, history } = this.props;
    let data = store.getState().CurrentFilter;
    this.setState({ isApiLoading: true, isGetNewGlidePathLoading: false });
    axiosInstance
      .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetNewAllGlidePathData`, {
        params: {
          OrgEntityID: clientData.id,
          OfferingID:
            currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
          LocationID:
            currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
          OMID:
            currentFilter.LocationOMID[0] === "All"
              ? 0
              : currentFilter.LocationOMID[0],
          LanguageID: languageData.id,
          OfferingName: !currentFilter.OfferingName
            ? "All"
            : currentFilter.OfferingName,
          IsConfigurator:
            history.location.pathname === "/admin/Configurator" ? true : false,
        },
      })
      .then((response) => {
        let data = response.data;
        store.dispatch(InitializeIOJOM_RatingScore(data));
        this.setState({ isApiLoading: false, isGetNewGlidePathLoading: false });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });

  };

  getJourneyInitiativeContainerData = () => {
    const { currentFilter, languageData, clientData, history } = this.props;
    this.setState({ isApiLoading: true });
    let data = {
      OrgEntityID: clientData.id,
      OfferingID:
        currentFilter.ProcessId === "All" ? 0 : currentFilter.ProcessId,
      LocationID:
        currentFilter.LocationId === "All" ? 0 : currentFilter.LocationId,
      OMID:
        currentFilter.LocationOMID[0] === "All"
          ? 0
          : currentFilter.LocationOMID[0],
      LanguageID: languageData.id,
      OfferingName: !currentFilter.OfferingName
        ? "All"
        : currentFilter.OfferingName,
      IsConfigurator:
        history.location.pathname === "/admin/Configurator" ? true : false,
    };
    store.dispatch(
      saveJourneyData({
        ConfiguratorID: "",
        IOJGlidePathAllModels: [],
        IsIOJCategory: false,
      })
    );
    this.setState({
      isGetNewGlidePathLoading: true
    })
    axiosInstance
      .post(
        `${LocalApiBaseUrl}IOJJourneyInitiative/GetNewAllGlidePathData`,
        data
      )
      .then((response) => {
        if (response.data) {
          this.setState(
            {
              journeyInitiativeContainerData: response.data,
              isApiLoading: false,
              isGetNewGlidePathLoading: false
            },
            () => {
              if (this.state.journeyInitiativeContainerData.IsIOJCategory) {
                this.getIOJCategory();
              }
            }
          );
          store.dispatch(saveJourneyData(response.data));
          store.dispatch(
            InitializeIOJData(response.data.IOJGlidePathAllModels)
          );
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false, isGetNewGlidePathLoading: false });
        trycatchAlertPopup(error);
      });

  };
  clearDateValue = (IOJStageID) => {
    let { journeyInitiativeContainerData } = this.state;
    journeyInitiativeContainerData.IOJGlidePathAllModels = journeyInitiativeContainerData.IOJGlidePathAllModels.map(
      (each) => {
        if (each.IOJStageID === IOJStageID) {
          each.GlidePathMonth = "";
          this.saveGlidePathMonth("", IOJStageID);
        }
        return each;
      }
    );
  };
  iojLinktoReport = () => {
    this.setState({ iojLinktoReportStatus: true, omLinktoReportStatus: false });
  };
  iojLinktoReportDisable = () => {
    this.setState({
      iojLinktoReportStatus: false,
      omLinktoReportStatus: false,
    });
  };
  omLinktoReport = () => {
    this.setState({ omLinktoReportStatus: true, iojLinktoReportStatus: false });
  };
  GlideMonthAlert = () => {
    let confirm = window.confirm(i18n.t("glid_path_month_change_message"));
    if (confirm) {
      return true;
    } else {
      return false;
    }
  };
  handleStatusDropdown = (id) => {


    if (window.confirm(i18n.t("glid_path_status_change_message"))) {
      this.setState({
        statusDropdown: "stage" + id,
      });
    } else {
      this.setState(
        {
          statusDropdown: "",
        },
        () => { }
      );
    }
  }
  onStatusChange = (value, IOJStageID) => {
    if (
      window.confirm(
        "There may be a difference between Glide Path Status on SynOps and Intelligent Operation Journey tool post editing the Glide Path Status. Do you want to proceed?"
      )
    ) {
      const { journeyInitiativeContainerData } = this.state;

      this.setState({ isApiLoading: true });
      let data = {
        ConfigurationIntelOpsRoadmapID: journeyInitiativeContainerData.ConfigurationIntelOpsRoadmapID,
        IOJStageID: IOJStageID,
        Status: value,
      };

      axiosInstance
        .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddEditStageStatus`, data)
        .then((response) => {
          this.setState({ isApiLoading: false });
          if (response.data.IsSuccess) {
            this.getGlidePathData();
          }
        })
        .catch((error) => {
          this.setState({ isApiLoading: false });
          trycatchAlertPopup(error);
        });
    }
  };
  onStatusChange = (value, IOJStageID, e) => {
    e.stopPropagation();
    e.preventDefault();
    const { journeyInitiativeContainerData } = this.state;

    this.setState({ isApiLoading: true });
    let data = {
      ConfigurationIntelOpsRoadmapID:
        journeyInitiativeContainerData.ConfigurationIntelOpsRoadmapID,
      IOJStageID: IOJStageID,
      Status: value,
    };

    axiosInstance
      .post(`${LocalApiBaseUrl}IOJJourneyInitiative/AddEditStageStatus`, data)
      .then((response) => {
        this.setState({ isApiLoading: false, statusDropdown: "" });
        if (response.data.IsSuccess) {
          this.getGlidePathData();
        }
      })
      .catch((error) => {
        this.setState({ isApiLoading: false, statusDropdown: "" });
        trycatchAlertPopup(error);
      });
  };
  checkifOMIDisAvailable = (locationId) => {
    let status = false;
    if (locationId != "All") {
      if (
        this.props.OMIDDataList.filter((each) => each.locID == locationId).length > 0 &&
        this.props.OMIDDataList.filter((each) => each.locID == locationId)[0]
          .IOJOMIDDatas.length > 0
      ) {
        status =
          this.props.currentFilter.LocationOMID[0] != "All" ? true : false;
      } else {
        status = true;
      }
    }
    return status;
  };
  statusDropdownOverlay = () => {
    this.setState({
      statusDropdown: "",
    });
  };
  // REFERENCE HANDLE CHANGE //
  showQcGraph = (val1, val2) => {
    this.setState({ showQcGraph: val1, selectedTab: val2 });
  }

  handleDisplayReferencesWindow = () => {
    let roleData = store.getState().roleReducers.roleReducers;
    let referencesExist =
      roleData.filter((data) => data.FeatureName === "References" && data.Operations.includes("View")).length > 0
        ? true
        : false;
    let admin = false;
    let reference = roleData.filter((data) => data.FeatureName === "References");
    if (reference.length > 0) {
      admin = roleData.filter((data) => data.FeatureName === "References")[0].Operations.includes("Post")
        ? true
        : false;
    }
    this.setState({
      referencesExist: referencesExist,
      adminReferences: admin,
    });
  };
  showQcGraph = (val1, val2) => {
    this.setState({ showQcGraph: val1, selectedTab: val2 });
  }

  openALP = () => {
    const alpUrl = process.env.REACT_APP_ALP_URL;
    openURLInNewWindow(alpUrl);
  }

  showSummaryDashboard = () => {
    let { isShowSummaryDashboard } = this.state;
    this.setState({ isShowSummaryDashboard: !isShowSummaryDashboard });
  }
  setKey = (k) => {
    this.props.storeActionStatus({ isAction: true })
    if (k === "InitiativeManagement") {
      this.setState({
        isInitativeManagement: true
      }, () => {
        this.props.setTabstatus(true);
      })
    }
    else if(k === "ClientStorytelling"){
      this.setState({
        isClientStory: true
      }, () => {
        this.props.setTabstatus(false);
      })
    }
    else {
      this.setState({
        isInitativeManagement: false,
        isClientStory:false
      }, () => {
        this.props.setTabstatus(false);
      })
    }
    this.closeform();
  }
  handleForm = () => {
    this.setState({
      isEdit: false,
      showIOJForm: true
    });
  }

  onSelect = (selectedList, selectedItem) => {


  }

  onRemove = (selectedList, selectedItem) => {


  }

  handleDiverse = (event) => {

    this.setState({
      selectedDDOption: event.target.value
    });

  }
  handleWO = (event) => {
    this.setState({
      selectedWOOption: event.target.value
    });
  }
  handleHM = (event) => {
    this.setState({
      selectedHMOption: event.target.value
    });
  }
  handleII = (event) => {
    this.setState({
      selectedIIOption: event.target.value
    });
  }
  handleDelete = () => {


    // axiosInstance
    //   .delete(`${LocalApiBaseUrl}IOJJourneyInitiative/DeleteIOJClientStory`, {
    //     params:{
    //       ID:id
    //     }
    //   })
    //   .then((response) => {
    //     alert(response.data.StatusMessage);
    //     
    //   })
    //   .catch((error) => {

    //     trycatchAlertPopup(error);
    //   });
    this.setState({
      deleteDemoStatus: false
    })
  }

  handleClickGanttChartButton = () => {
    this.setState({
      isVisibleGanttChart: true,
      isShowSummaryDashboard: false,
    });
  };

  handleHideGanttChart = (backToScreenName) => {
    let isVisibleSummaryDashboard = true;
    if (backToScreenName === 'roadMapJourney') {
      isVisibleSummaryDashboard = false;
    }
    this.setState({
      isVisibleGanttChart: false,
      isShowSummaryDashboard: isVisibleSummaryDashboard,
    },()=>{
      store.dispatch(ClearDatas_ViewDataStageData(true));
    });
  }

  render() {

    const Iframe = `<iframe width="100%" height="680" src="https://synopsuat.accenture.com/Reports_PBI/powerbi/Reports/IOJ" frameborder="0" allowFullScreen="true"></iframe>`
    var dateObj = new Date();
    var month = dateObj.getUTCMonth() + 1; //months from 1-12
    var day = dateObj.getUTCDate();
    var year = dateObj.getUTCFullYear();
    var newdate = day + "-" + month + "-" + year;
    const {
      history,
      currentFilter,
      roleReducers,
      RecordStatus,
      isManintencneWindowOpen,
      isAccess,
      JourneyData,
      RatingScore,
      publishJourneyInitiatives,
      OMIDEnabled,
      OMIDDataList,
      clientData,
      Tab
    } = this.props;
    const {
      DonutparentData,
      DonutchildData,
      activeIndex,
      isConfig,
      selectedPhase,
      selectedTab,
      KPIData,
      journeyInitiativeContainerData,
      isApiLoading,
      iskpiLoading,
      bubbleDatasets,
      bubbleOptions,
      TotalCount,
      FTEBenefitValueandunit,
      CostBenefitValueandunit,
      isparentdonutshow,
      CarouselItemSize,
      referencesExist,
      adminReferences,
      isShowSummaryDashboard,
      isInitativeManagement,
      ClientStoryData,
      initiativesDropDownData,
      outcomeDropDownData,
      clientStoryFormData,
      isShowClientStorySucess,
      showForm,
      clonedclientStoryFormData,
      isChanged,
      showConfiguratorUser,
      isGetNewGlidePathLoading,
      isVisibleGanttChart,
      isClientStory
    } = this.state;
    const body = {
      OrgEntityID: clientData.id,
      Offering: !currentFilter.OfferingName
        ? "All"
        : currentFilter.OfferingName
    }

    const { compDetails } = this.props;
    let soCategoryName = "Intelligent Operations Journey";
    let tabQcScore = 0;
    let tabUnit = "";
    let hasQcSocreData = false;

    if (
      this.props.QcOverallScore &&
      this.props.QcOverallScore.QualityScoreDatas &&
      this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.length
    ) {
      this.props.QcOverallScore.QualityScoreDatas.QualityScoreTabDatas.filter((each) => {
        if (each.LeverName_En === soCategoryName) {
          tabQcScore = each.AchievedScore;
          tabUnit = each.Unit;
          hasQcSocreData = true;
        }
      });
    }
    if (KPIData.length <= CarouselItemSize) {
      if (document.getElementsByClassName("carousel-control-prev").length > 0) {
        document.getElementsByClassName(
          "carousel-control-prev"
        )[0].style.visibility = "hidden";
      }
      if (document.getElementsByClassName("carousel-control-next").length > 0) {
        document.getElementsByClassName(
          "carousel-control-next"
        )[0].style.visibility = "hidden";
      }
      if (document.getElementsByClassName("carousel-control-next").length > 0) {
        document.getElementsByClassName(
          "carousel-control-next"
        )[0].style.visibility = "hidden";
      }
      if (document.getElementsByClassName("carousel-indicators").length > 0) {
        document.getElementsByClassName(
          "carousel-indicators"
        )[0].style.visibility = "hidden";
      }
    } else {
      if (document.getElementsByClassName("carousel-control-prev").length > 0) {
        document.getElementsByClassName(
          "carousel-control-prev"
        )[0].style.visibility = "visible";
      }
      if (document.getElementsByClassName("carousel-control-next").length > 0) {
        document.getElementsByClassName(
          "carousel-control-next"
        )[0].style.visibility = "visible";
      }
      if (document.getElementsByClassName("carousel-indicators").length > 0) {
        document.getElementsByClassName(
          "carousel-indicators"
        )[0].style.visibility = "visible";
      }
    }
    ////////////
    let Customstyle = { marginTop: "140px", marginBottom: "73px" };
    /////////////////
    const ExampleCustomInput = ({ value, onClick, editedValue }) =>
      currentFilter.ProcessId === "All" ||
        currentFilter.LocationId === "All" ||
        (currentFilter.LocationOMID.length &&
          currentFilter.LocationOMID[0] === "All" &&
          currentFilter.isOMIDAvailable) ? (
        ""
      ) : (
        <span
          className="example-custom-input"
          onClick={() => {
            if (
              history.location.pathname === "/admin/Configurator" &&
              OMIDEnabled &&
              isAccess
            ) {
              if (this.GlideMonthAlert()) {
                onClick();
              }
            }
          }}
        >
          {value}{" "}
          {history.location.pathname === "/admin/Configurator" &&
            OMIDEnabled ? (
            <FontAwesomeIcon icon={faCalendarAlt} />
          ) : (
            ""
          )}
          {editedValue &&
            history.location.pathname === "/admin/Configurator" &&
            OMIDEnabled ? (
            <span className="dateEdited">({i18n.t("Edited")})</span>
          ) : (
            ""
          )}
        </span>
      );
    const LocationName =
      currentFilter != undefined
        ? OMIDDataList.filter((each) => each.locID == currentFilter.LocationId)
          .length > 0
          ? OMIDDataList.filter(
            (each) => each.locID == currentFilter.LocationId
          )[0].locname
          : ""
        : "";

    if (isVisibleGanttChart) {
      return (
        <JourneyGanttChart onHide={(backToScreenName) => this.handleHideGanttChart(backToScreenName)} />
      );
    }

    return (
      <>
        <div className="container-fluid">
          {(isApiLoading || isGetNewGlidePathLoading) && (
            <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
          )}

          {currentFilter &&
            currentFilter.OfferingId &&
            currentFilter.ProcessId &&
            currentFilter.LocationId &&
            currentFilter.LocationOMID &&
            currentFilter.LocationOMID.length ? (
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="ioj-header-actions">
                  <div className="button-wrappers">
                    {currentFilter.OfferingId !== 'All' &&
                      currentFilter.ProcessId !== 'All' && isConfig &&
                      hasQcSocreData && compDetails.component === "Journey Initiatives" ?
                      (
                        <span className="qcscore-status" style={{ right: OMIDEnabled && isAccess ? '5rem' : '1rem' }}>
                          <span
                            onClick={this.showQcGraph.bind(this, true, soCategoryName)}
                            className={
                              tabQcScore < 50
                                ? "qcscore-percent red-status"
                                : tabQcScore >= 50 && tabQcScore <= 75
                                  ? "qcscore-percent orange-status"
                                  : "qcscore-percent green-status"
                            }
                          >
                            QC Score {tabQcScore}
                            {tabUnit}
                          </span>
                          {compDetails.component === "Journey Initiatives" &&
                            OMIDEnabled &&
                            isAccess && (<span className="border-line-end qc-mr"></span>)}
                        </span>
                      ) : null}
                    {this.state.showQcGraph ? (
                      <TabQCScore showQcGraph={this.showQcGraph} selectedTab={this.state.selectedTab} />
                    ) : (
                      ""
                    )}
                    {history.location.pathname === "/admin/Configurator" &&
                      this.props.compDetails.component === "Journey Initiatives" &&
                      OMIDEnabled &&
                      isAccess && (
                        <>
                          <button
                            className="btn btn-primary btn-sm mr-2 button-get-data"
                            id="btnsubmit"
                            //Metric Migration Condn

                            onClick={publishJourneyInitiatives}
                          >
                            {i18n.t("Submit")}
                          </button>
                        </>
                      )}
                    {history.location.pathname === "/admin/Configurator" &&
                      <NavLink
                        to={{
                          pathname: "/helpfeatures",
                          state: {
                            name: compDetails.component
                          }
                        }}
                      >
                        <span className="help-notification">
                          {this.props.isHelpCountLoaded &&
                            <a data-tip data-for="help-notification-tooltip" href="javascript:void(0)">
                              {this.props.newHelpFeaturesCount > 0 &&
                                <span>
                                  {this.props.newHelpFeaturesCount > 9 ? i18n.t('9+') : this.props.newHelpFeaturesCount}
                                </span>
                              }
                            </a>
                          }
                        </span>
                        {this.props.isHelpCountLoaded &&
                          <ReactTooltip id="help-notification-tooltip" effect="solid" place="left">
                            <span>
                              {this.props.newHelpFeaturesCount > 0
                                ? (this.props.newHelpFeaturesCount + " " + i18n.t('New') + " " +
                                  (this.props.newHelpFeaturesCount == 1 ? i18n.t('Feature') : i18n.t('Features')) + " " +
                                  i18n.t('updated.') + " " + i18n.t('Click here for details'))
                                : i18n.t('Click here for updates')
                              }
                            </span>
                          </ReactTooltip>
                        }
                      </NavLink>
                    }
                  </div>
                </div>

                {this.state.startRoadMap ? (
                  <JourneyInitiativeTabs
                    isInitativeManagement={false}
                    iskpiLoading={this.state.iskpiLoading}
                    disableRoadMapView={this.disableRoadMapView}

                    ConfiguratorID={
                      journeyInitiativeContainerData.ConfiguratorID
                    }
                    RecordStatus={this.props.getRecordStatus}
                    isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                    roleReducers={this.props.roleReducers}
                    isStage={this.props.isStage}
                    isInitativeEditMode={this.props.isInitativeEditMode}
                    isAccess={this.props.isAccess}

                    selectedPhase={selectedPhase}
                    selectedTab={selectedTab}
                    ShowInitativeDetails={this.props.ShowInitativeDetails}
                    history={history}
                    {...this.props}

                    isIOJCategory={journeyInitiativeContainerData.IsIOJCategory}
                    getGlidePathData={this.getGlidePathData}
                    checkifOMIDisAvailable={this.checkifOMIDisAvailable}
                    isApiLoading={this.state.isApiLoading}
                    isAutoMigrateAPILoading={this.state.isAutoMigrateAPILoading}
                  />
                ) : (
                  isShowSummaryDashboard ? (
                    <>
                      <div className="breadcrumb initiative-breadcrumb">
                        <ul>
                          <li className="initiative-heading back-navigation"
                            onClick={() => this.showSummaryDashboard()}
                          >
                            <i class="fal fa-arrow-circle-left"></i>
                          </li>
                          <li onClick={() => this.showSummaryDashboard()}>
                            {i18n.t("Roadmap_Journey")}
                          </li>
                          <li className="active">{i18n.t("Summary_Dashboard")}</li>
                        </ul>
                      </div>

                      <div className="summrydashboard-tab">
                        <div className="summarydashboard-container">
                          <div className="summarydashboard-card summarydashboard-tilebg">
                            <h4>
                              {i18n.t("Total_Initiatives")}:{TotalCount}
                            </h4>
                            {TotalCount !== 0 ? (
                              <div className="summary-chart-container">
                                <Bubble
                                  data={bubbleDatasets}
                                  options={bubbleOptions}
                                />
                                <div
                                  id="bubblelegend"
                                  className="custom-bubblelegend"
                                >
                                  <ul>
                                    <li>
                                      <span className="concept-dot"></span>
                                      {i18n.t("In_Concept")}
                                    </li>
                                    <li>
                                      <span className="flight-dot"></span>
                                      {i18n.t("In_Flight")}
                                    </li>
                                    <li>
                                      <span className="delivered-dot"></span>
                                      {i18n.t("Delivered")}
                                    </li>
                                    <li>
                                      <span className="hold-dot"></span>
                                      {i18n.t("On_Hold")}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            ) : (
                              <div style={Customstyle}>
                                {i18n.t(
                                  "Enough_data_is_not_available_for_generating_the_graph"
                                )}
                              </div>
                            )}
                          </div>
                          <div className="summarydashboard-card summarydashboard-tilebg">
                            <h4>{i18n.t("Initiative_Stage")}</h4>
                            <div className="summary-chart-container doughnutchart-height">
                              {DonutparentData.length > 0 ? (
                                <CustompieChartLabel
                                  DonutparentData={DonutparentData}
                                  DonutchildData={DonutchildData}
                                />
                              ) : (
                                <div style={Customstyle}>
                                  {i18n.t(
                                    "Enough_data_is_not_available_for_generating_the_graph"
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="summarydashboard-container">
                          <div className="summarydashboard-card">
                            <h4 className="pl-4">
                              {i18n.t("FTE_Benefit_Value")}
                            </h4>
                            <div className="summarydashboard-tilebg">
                              <div className="summarytile-values">
                                {/* { FTEBenefitValueandunit.FTEBenefitValue >0 || FTEBenefitValueandunit.FTEBenefitValue <0?
                              <> */}
                                <h3>
                                  {formattertwo.format(
                                    parseFloat(
                                      FTEBenefitValueandunit.FTEBenefitValue
                                    )
                                  )}
                                </h3>
                                <h3 className="summarytile-metricunit">
                                  {FTEBenefitValueandunit.FTEUnit}
                                </h3>
                                {/* </>
                                :
                                <>
                                <h3 className="summarytile-metricunit">{i18n.t("Not_Available")}</h3>
                                <h3 className="summarytile-metricunit">{}</h3>
                                </>
                                }   */}
                              </div>
                            </div>
                          </div>
                          <div className="summarydashboard-card">
                            <h4 className="pl-4">
                              {i18n.t("Cost_Benefit_Value")}
                            </h4>
                            <div className="summarydashboard-tilebg">
                              <div className="summarytile-values">
                                {/* { CostBenefitValueandunit.CostBenefitValue >0 || CostBenefitValueandunit.CostBenefitValue <0?
                              <> */}
                                <h3>
                                  {formattertwo.format(
                                    parseFloat(
                                      CostBenefitValueandunit.CostBenefitValue
                                    )
                                  )}
                                </h3>
                                <h3 className="summarytile-metricunit">
                                  {CostBenefitValueandunit.CostBenefitUnit}
                                </h3>
                                {/* </>
                                :
                                <>
                                <h3 className="summarytile-metricunit">{i18n.t("Not_Available")}</h3>
                                <h3 className="summarytile-metricunit">{}</h3>
                                </>
                                } */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="summarykpiimpacted-slider">
                          <h4 className="mb-4 pl-4">
                            {i18n.t("BOI/SLA/KPI Impacted")}
                          </h4>
                          {/* {KPIData && KPIData.length>0 && (
                                KPIData.map((a,index)=>{
                                  return(
                                <li key={index}>{a}</li>
                                  )
                                }
                                )
                              )} */}
                          {KPIData.length > 0 && (
                            <Carousel>
                              {ToChunk(KPIData, CarouselItemSize).map(
                                (chunk, index) => (
                                  <Carousel.Item key={index}>
                                    <ul>
                                      {chunk.map((item, idx2) => (
                                        <li key={idx2}>{item}</li>
                                      ))}
                                    </ul>
                                  </Carousel.Item>
                                )
                              )}
                            </Carousel>
                          )}
                        </div>
                      </div>
                    </>
                  ) :
                    (
                      <div className="ioj-roadmap-container journeyinitiative-tabs">
                        {
                          history.location.pathname !== "/admin/Configurator"
                            ?
                            (
                              <div className="category-references dashboard-category-references bo-references mt-2">
                                {
                                  this.props.currentFilter.ProcessId &&
                                  String(this.props.currentFilter.ProcessId) !== "0" &&
                                  this.props.currentFilter.LocationId &&
                                  String(this.props.currentFilter.LocationId) !== "0" &&
                                  this.props.currentFilter.OfferingId !== 'All' &&
                                  this.props.currentFilter.ProcessId !== 'All' &&
                                  this.props.currentFilter.LocationId !== "All" &&
                                  <References
                                    {...this.props}
                                    IOJSectionHierarchyID={1}
                                    SOCatID={0}
                                    leverName="Journey Initiatives"
                                    referenceScreen="IOJ" />
                                }
                              </div>
                            )
                            :
                            (
                              <div className="category-references dashboard-category-references mt-2">
                                {
                                  this.props.currentFilter.ProcessId &&
                                  String(this.props.currentFilter.ProcessId) !== "0" &&
                                  this.props.currentFilter.LocationId &&
                                  String(this.props.currentFilter.LocationId) !== "0" &&
                                  this.props.currentFilter.OfferingId !== 'All' &&
                                  this.props.currentFilter.ProcessId !== 'All' &&
                                  this.props.currentFilter.LocationId !== "All" &&
                                  <References
                                    {...this.props}
                                    IOJSectionHierarchyID={1}
                                    SOCatID={0}
                                    isConfig={true}
                                    leverName="Journey Initiatives"
                                    referenceScreen="IOJ" />
                                }
                              </div>
                            )
                        }
                        <Tabs defaultActiveKey={Tab} onSelect={(k) => this.setKey(k)}>
                          <Tab
                            eventKey="IOJMaturityRoadmap"
                            title={i18n.t("Maturity_Roadmap")}
                          >
                            <div className="ioj-roadmap">
                              <div className="ioj-right-btn-controls">
                                {!isConfig &&
                                  <>
                                 { this.state.hasALPAccess && <div className="alp-btn"
                                      data-tip data-for="alp-btn" 
                                      onClick={() =>
                                        this.openALP()
                                      }
                                    >
                                      Synops for Account Leaders
                                      
                                    </div>}
                                  <div className="summary-dashboard-btn"
                                      data-tip data-for="summary-dashboard-btn" 
                                      onClick={() =>
                                        this.showSummaryDashboard()
                                      }
                                      onMouseOver={e => e.currentTarget.firstChild.src = summaryDashboardIconHover}
                                      onMouseOut={e => e.currentTarget.firstChild.src = summaryDashboardIcon} 
                                    >
                                      <img src={summaryDashboardIcon} width="40px" height="18px" alt="" />
                                      <ReactTooltip id="summary-dashboard-btn" place="top" effect="solid"> {i18n.t("Summary_Dashboard")} </ReactTooltip>
                                    </div>
                                    <div className="gantt-chart-btn"
                                    data-tip data-for="gantt-chart-btn" 
                                    onClick={this.handleClickGanttChartButton}
                                    onMouseOver={e => e.currentTarget.firstChild.src = ganttChartIconHover}
                                    onMouseOut={e => e.currentTarget.firstChild.src = ganttChartIcon} 
                                    >
                                      <img src={ganttChartIcon} width="31px" height="18px" alt="" />
                                      <ReactTooltip id="gantt-chart-btn" place="top" effect="solid"> {i18n.t("Initiative_timelines_Gantt_Chart")}  </ReactTooltip>
                                    </div>
                                    {
                                      this.hasRoadmapViewAccess() &&
                                      <div className="export-roadmap-btn" onClick={this.handleScreenshot} data-tip data-for="export-roadmap-btn" 
                                      onMouseOver={e => e.currentTarget.firstChild.src = exportRoadmapIconHover}
                                      onMouseOut={e => e.currentTarget.firstChild.src = exportRoadmapIcon} 
                                      >             
                                      <img src={exportRoadmapIcon} width="33px" height="18px" alt="" />                         
                                      <ReactTooltip className="text-center" id="export-roadmap-btn" place="top" effect="solid">                                          
                                        <FontAwesomeIcon icon={faFilePowerpoint} className="mr-1" />   
                                        {i18n.t("Export Roadmap")}
                                      </ReactTooltip>
                                    </div>
                                    }
                                  </>
                                }
                              </div>
                              <div className="ioj-steps">
                                {/* <p className="yettostart-msg mb-0">Yet to take the Assessment</p> */}
                                {history.location.pathname ===
                                  "/admin/Configurator" &&
                                  !currentFilter.LocationOMID.includes("All") &&
                                  !OMIDEnabled &&
                                  isAccess && (
                                    <p className="disabled-omid">
                                      OM ID disabled for {LocationName} location
                                    </p>
                                  )}
                                <p className="configurator-iojrating mb-0">
                                  <span>
                                    {i18n.t("Rating")}: {JourneyData.CurrentRating}
                                  </span></p>
                                <div className="row m-0">
                                  {journeyInitiativeContainerData.IOJGlidePathAllModels &&
                                    journeyInitiativeContainerData.IOJGlidePathAllModels.filter(a => a.IOJStageName_En !== "All").map(
                                      (each, i) => {
                                        return (
                                          <div className="col-lg-3 col-md-3 col-sm-6 col-xs-6">
                                            <div
                                              className={`insightsSteps ${"insights-" +
                                                each.IOJStageID} ${each.Status === "Completed"
                                                  ? "completed"
                                                  : ""
                                                }${each.Status === "Yet to start"
                                                  ? each.IOJAssessmentModels
                                                    .length ||
                                                    each.IOJInitiativeModels
                                                      .length ||
                                                    each.IOJOutcomeModels.length
                                                    ? "yetto-start partially-start"
                                                    : "yetto-start"
                                                  : ""
                                                }${each.Status === "In Progress"
                                                  ? "in-progress"
                                                  : ""
                                                }${!each.Status
                                                  ? "yetto-start partially-start"
                                                  : ""
                                                } ${each.IOJInitiativeModels.filter(
                                                  (initiative) =>
                                                    initiative.InitiativeStatus ===
                                                    "In flight"
                                                ).length > 0 ? "in-flight-activeparent" : ""}
                                          `}
                                            >
                                              <div className="statusSec">
                                                <div className="topHeader">
                                                  <h2>{each.IOJStageName}</h2>
                                                  <div className="ioj-step-cards">
                                                    {!each.GlidePathMonth &&
                                                      history.location.pathname !==
                                                      "/admin/Configurator" &&
                                                      ((i === 0 &&
                                                        !JourneyData.GlideToFoundation) ||
                                                        (i === 1 &&
                                                          !JourneyData.GlideToAutomated) ||
                                                        (i === 2 &&
                                                          !JourneyData.GlideToInsights) ||
                                                        (i === 3 &&
                                                          !JourneyData.GlideToIntelligent)) ? null : !each.GlidePathMonth &&
                                                            !isAccess &&
                                                            ((i === 0 &&
                                                              !JourneyData.GlideToFoundation) ||
                                                              (i === 1 &&
                                                                !JourneyData.GlideToAutomated) ||
                                                              (i === 2 &&
                                                                !JourneyData.GlideToInsights) ||
                                                              (i === 3 &&
                                                                !JourneyData.GlideToIntelligent)) ? null : (
                                                      <DatePicker
                                                        className="form-control IOJDatepicker"
                                                        selected={
                                                          each.GlidePathMonth
                                                            ? new Date(
                                                              each.GlidePathMonth
                                                            )
                                                            : i === 0 &&
                                                              JourneyData.GlideToFoundation
                                                              ? new Date(
                                                                JourneyData.GlideToFoundation
                                                              )
                                                              : i === 1 &&
                                                                JourneyData.GlideToAutomated
                                                                ? new Date(
                                                                  JourneyData.GlideToAutomated
                                                                )
                                                                : i === 2 &&
                                                                  JourneyData.GlideToInsights
                                                                  ? new Date(
                                                                    JourneyData.GlideToInsights
                                                                  )
                                                                  : i === 3 &&
                                                                    JourneyData.GlideToIntelligent
                                                                    ? new Date(
                                                                      JourneyData.GlideToIntelligent
                                                                    )
                                                                    : ""
                                                        }
                                                        onChange={(date) =>
                                                          this.setStartDate(
                                                            date,
                                                            each.IOJStageID
                                                          )
                                                        }
                                                        disabled={
                                                          history.location
                                                            .pathname !==
                                                          "/admin/Configurator" ||
                                                          !isAccess
                                                        }
                                                        customInput={
                                                          <ExampleCustomInput
                                                            editedValue={
                                                              each.GlidePathMonth
                                                            }
                                                          />
                                                        }
                                                        dateFormat="MMM yyyy"
                                                        showMonthYearPicker
                                                        onBlur={
                                                          this.saveGlidePathMonth
                                                        }
                                                      >
                                                        <div className="date-controls">
                                                          <span
                                                            className={
                                                              each.GlidePathMonth
                                                                ? ""
                                                                : "disabled"
                                                            }
                                                            onClick={this.clearDateValue.bind(
                                                              this,
                                                              each.IOJStageID
                                                            )}
                                                          >
                                                            Clear
                                                          </span>
                                                        </div>
                                                      </DatePicker>
                                                    )}
                                                    <span className="stateColor">
                                                      {OMIDEnabled &&
                                                        history.location.pathname ===
                                                        "/admin/Configurator" &&
                                                        isAccess &&
                                                        this.checkifOMIDisAvailable(
                                                          currentFilter.LocationId
                                                        ) ? (
                                                        <>
                                                          <DropdownButton
                                                            menuAlign="right"
                                                            title={each.Status}
                                                            className="custombg-headerdropdown"
                                                            onClick={() => {
                                                              this.handleStatusDropdown(
                                                                i
                                                              );
                                                            }}
                                                            id={`customdropdown${i}`}
                                                            show={
                                                              this.state
                                                                .statusDropdown ==
                                                                "stage" + i
                                                                ? true
                                                                : false
                                                            }
                                                            rootCloseEvent="mousedown"
                                                          >
                                                            <Dropdown.Item
                                                              href="javascript:void(0);"
                                                              className={
                                                                each.Status ===
                                                                  "Yet to start"
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={(e) =>
                                                                this.onStatusChange(
                                                                  "Yet to start",
                                                                  each.IOJStageID,
                                                                  e
                                                                )
                                                              }
                                                            >
                                                              Yet to start
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              href="javascript:void(0);"
                                                              className={
                                                                each.Status ===
                                                                  "In Progress"
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={(e) =>
                                                                this.onStatusChange(
                                                                  "In Progress",
                                                                  each.IOJStageID,
                                                                  e
                                                                )
                                                              }
                                                            >
                                                              In Progress
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                              href="javascript:void(0);"
                                                              className={
                                                                each.Status ===
                                                                  "Completed"
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                              onClick={(e) =>
                                                                this.onStatusChange(
                                                                  "Completed",
                                                                  each.IOJStageID,
                                                                  e
                                                                )
                                                              }
                                                            >
                                                              Completed
                                                            </Dropdown.Item>
                                                          </DropdownButton>
                                                          {this.state
                                                            .statusDropdown ==
                                                            "stage" + i ? (
                                                            <div
                                                              className="initiative-dropdown-overlay"
                                                              onClick={
                                                                this
                                                                  .statusDropdownOverlay
                                                              }
                                                            ></div>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </>
                                                      ) : (
                                                        i18n.t(each.Status)
                                                      )}
                                                      {each.isStatusEdited &&
                                                        this.checkifOMIDisAvailable(
                                                          currentFilter.LocationId
                                                        ) ? (
                                                        <span className="dateEdited">
                                                          ({i18n.t("Edited")})
                                                        </span>
                                                      ) : null}
                                                    </span>
                                                  </div>
                                                </div>
                                                {each.IOJAssessmentModels.length ||
                                                  each.IOJInitiativeModels.length ||
                                                  each.IOJOutcomeModels.length ? (
                                                  <div className="insights-sections">
                                                    <div className="information">
                                                      <h2
                                                        onClick={this.startRoadMap.bind(
                                                          this,
                                                          each.IOJStageName_En,
                                                          "Assesment Criteria"
                                                        )}
                                                      >
                                                        <span>
                                                          {i18n.t(
                                                            "Assesment_Criteria"
                                                          )}
                                                        </span>

                                                        <span>
                                                          {" "}
                                                          {
                                                            each.IOJAssessmentModels
                                                              .length
                                                          }
                                                        </span>
                                                      </h2>
                                                      {each.IOJAssessmentModels.map(
                                                        (assessment, index) => {
                                                          if (index < 2) {
                                                            return (
                                                              <p
                                                                className={
                                                                  assessment.RAGStatus !=
                                                                  null &&
                                                                  (assessment.RAGStatus.trim() ===
                                                                    "Green"
                                                                    ? "greenColor"
                                                                    : "amberColor")
                                                                }
                                                              >
                                                                <span>
                                                                  {
                                                                    assessment.CriteriaName
                                                                  }
                                                                </span>
                                                              </p>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                      {each.IOJAssessmentModels
                                                        .length === 0 ? (
                                                        <p>
                                                          {i18n.t(
                                                            "No_Assesment_Added"
                                                          )}
                                                        </p>
                                                      ) : null}
                                                    </div>
                                                    <div className="information">
                                                      <h2
                                                        onClick={this.startRoadMap.bind(
                                                          this,
                                                          each.IOJStageName_En,
                                                          "Initiatives"
                                                        )}
                                                      >
                                                        <span>
                                                          {i18n.t("Initiatives")}
                                                        </span>
                                                        <span>
                                                          {" "}
                                                          {
                                                            each.IOJInitiativeModels
                                                              .length
                                                          }
                                                        </span>
                                                      </h2>
                                                      {each.IOJInitiativeModels
                                                        .length ? (
                                                        <div className="information-status">
                                                          <h4>
                                                            {i18n.t(
                                                              "Overal_Status"
                                                            )}
                                                          </h4>
                                                          <ul>
                                                            <li className={each.IOJInitiativeModels.filter(
                                                              (initiative) =>
                                                                initiative.InitiativeStatus ===
                                                                "In flight"
                                                            ).length > 0 ? "in-flight-active" : ""}
                                                              onClick={() => {
                                                                each.IOJInitiativeModels.filter(
                                                                  (initiative) =>
                                                                    initiative.InitiativeStatus ===
                                                                    "In flight"
                                                                ).length > 0 &&
                                                                  this.performanceModalShow(each.IOJStageID)
                                                              }
                                                              }

                                                            >
                                                              <h6>
                                                                {
                                                                  each.IOJInitiativeModels.filter(
                                                                    (initiative) =>
                                                                      initiative.InitiativeStatus ===
                                                                      "In flight"
                                                                  ).length
                                                                }
                                                              </h6>
                                                              <span
                                                                className={each.IOJInitiativeModels.filter(
                                                                  (initiative) =>
                                                                    initiative.InitiativeStatus ===
                                                                    "In flight"
                                                                ).length > 0 ? "performance-pointer" : ""}
                                                              >
                                                                {i18n.t(
                                                                  "In_Flight"
                                                                )}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <h6>
                                                                {
                                                                  each.IOJInitiativeModels.filter(
                                                                    (initiative) =>
                                                                      initiative.InitiativeStatus ===
                                                                      "In concept"
                                                                  ).length
                                                                }
                                                              </h6>
                                                              <span>
                                                                {i18n.t(
                                                                  "In_Concept"
                                                                )}
                                                              </span>
                                                            </li>
                                                            <li>
                                                              <h6>
                                                                {
                                                                  each.IOJInitiativeModels.filter(
                                                                    (initiative) =>
                                                                      initiative.InitiativeStatus ===
                                                                      "Delivered"
                                                                  ).length
                                                                }
                                                              </h6>
                                                              <span>
                                                                {i18n.t(
                                                                  "Delivered"
                                                                )}
                                                              </span>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {each.IOJInitiativeModels.map(
                                                        (initiative, index) => {
                                                          if (index < 2) {
                                                            return (
                                                              <p
                                                                className={`${initiative.RAGStatus.toLowerCase()}Color`}
                                                              >
                                                                <span>
                                                                  {
                                                                    initiative.ProjectInitiativeName
                                                                  }
                                                                </span>
                                                              </p>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                      {each.IOJInitiativeModels
                                                        .length === 0 ? (
                                                        <p>
                                                          {i18n.t(
                                                            "No_Initiatives_Added"
                                                          )}
                                                        </p>
                                                      ) : null}
                                                    </div>
                                                    <div className="information outcome-sec">
                                                      <h2
                                                        onClick={this.startRoadMap.bind(
                                                          this,
                                                          each.IOJStageName_En,
                                                          "Outcome"
                                                        )}
                                                      >
                                                        <span>
                                                          {i18n.t("Outcome")}
                                                        </span>

                                                        <span>
                                                          {" "}
                                                          {
                                                            each.IOJOutcomeModels
                                                              .length
                                                          }
                                                        </span>
                                                      </h2>
                                                      {each.IOJOutcomeModels.map(
                                                        (outcome, index) => {
                                                          if (index < 2) {
                                                            return (
                                                              <p>
                                                                <span>
                                                                  {
                                                                    outcome.OutcomeDesc
                                                                  }
                                                                </span>
                                                              </p>
                                                            );
                                                          }
                                                        }
                                                      )}
                                                      {each.IOJOutcomeModels
                                                        .length === 0 ? (
                                                        <p>
                                                          {i18n.t(
                                                            "No_Outcome_Added"
                                                          )}
                                                        </p>
                                                      ) : null}
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </div>
                                              {each.IOJAssessmentModels.length ===
                                                0 &&
                                                each.IOJInitiativeModels.length ===
                                                0 &&
                                                each.IOJOutcomeModels.length === 0 &&
                                                history.location.pathname ===
                                                "/admin/Configurator" &&
                                                OMIDEnabled ? (
                                                !isAccess ? (
                                                  <div className="start-roadmap nodata-alllocations"><img width="115px" height="115px" src={IOJCardLogo} alt="ioj-card-image" /></div>
                                                ) : (
                                                  <div className="start-roadmap">
                                                    <div
                                                      className="link-to-roadmap"
                                                      onClick={this.startRoadMap.bind(
                                                        this,
                                                        each.IOJStageName_En,
                                                        "Assesment Criteria"
                                                      )}
                                                    >
                                                      {i18n.t("Start")}{" "}
                                                      {each.IOJStageName}{" "}
                                                      &nbsp;&#62;
                                                    </div>
                                                  </div>
                                                )
                                              ) : each.IOJAssessmentModels
                                                .length === 0 &&
                                                each.IOJInitiativeModels.length ===
                                                0 &&
                                                each.IOJOutcomeModels.length ===
                                                0 &&
                                                history.location.pathname !==
                                                "/admin/Configurator" ? (
                                                <div className="start-roadmap nodata-alllocations"><img width="115px" height="115px" src={IOJCardLogo} alt="ioj-card-image" /></div>
                                              ) : null}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            </div>
                          </Tab>
                          <Tab
                            eventKey="InitiativeManagement"
                            title={i18n.t("Initiative_Management")}
                          >
                            {(isInitativeManagement || Tab === "InitiativeManagement") && (
                              <JourneyInitiativeTabs
                                isInitativeManagement={(isInitativeManagement || Tab === "InitiativeManagement")}
                                iskpiLoading={this.state.iskpiLoading}
                                disableRoadMapView={this.disableRoadMapView}
                                // fetchJourneyInitiativeData={this.fetchJourneyInitiativeData}
                                ConfiguratorID={
                                  journeyInitiativeContainerData.ConfiguratorID
                                }
                                RecordStatus={this.props.getRecordStatus}
                                isManintencneWindowOpen={this.state.isManintencneWindowOpen}
                                roleReducers={this.props.roleReducers}
                                isStage={this.props.isStage}
                                isInitativeEditMode={this.props.isInitativeEditMode}
                                isAccess={this.props.isAccess}
                                //initiativeDatas={this.state.initiativeData}
                                selectedPhase={selectedPhase}
                                selectedTab={Tab === "InitiativeManagement" ? "initiative" : selectedTab}
                                ShowInitativeDetails={this.props.ShowInitativeDetails}
                                history={history}
                                {...this.props}
                                // IOJDataApi = {journeyInitiativeContainerData.IOJGlidePathAllModels}
                                isIOJCategory={journeyInitiativeContainerData.IsIOJCategory}
                                getGlidePathData={this.getGlidePathData}
                                checkifOMIDisAvailable={this.checkifOMIDisAvailable}
                                isApiLoading={this.state.isApiLoading}
                                isAutoMigrateAPILoading={this.state.isAutoMigrateAPILoading}
                              />
                            )}
                          </Tab>
                          <Tab eventKey="profile" title={i18n.t("Assessment")}>
                            <div className="assesmentTab-container">
                              <div className="list-cards">
                                <h2>{i18n.t("Intelligent_Operations_Journey")}</h2>
                                <div className="rating-section">
                                  <div className="rating-left">
                                    {JourneyData.CurrentRating !== null ? (
                                      currentFilter.isOMIDAvailable == false ? (
                                        <p className="asses-name">
                                          {i18n.t("N.A")}
                                        </p>
                                      ) : (
                                        <p className="asses-name">
                                          {JourneyData.CurrentRating}
                                        </p>
                                      )
                                    ) : (
                                      <p className="asses-name">{i18n.t("N.A")}</p>
                                    )}
                                    <p className="asses-caption">
                                      {i18n.t("Current_Rating")}
                                    </p>
                                  </div>
                                  <div className="rating-right">
                                    <p className="asses-name">
                                      {JourneyData.CurrentScore > 0 ? JourneyData.CurrentScore : i18n.t("N.A")}
                                    </p>
                                    <p className="asses-caption">
                                      {i18n.t("Current_Score")}
                                    </p>
                                  </div>
                                </div>

                                {window.location.pathname ===
                                  "/admin/Configurator" ? (
                                  <div className="start-assesment">
                                    <div className="link-to-assesment-main">
                                      <a
                                        href="https://be-automation-portal.accenture.com/IntelligentOperation/Assessment/OMIDList"
                                        target="_blank"
                                        className="link-to-assesment"
                                      >
                                        {i18n.t("Take_Assesment")} &nbsp;&#62;
                                      </a>
                                    </div>
                                    <div className="link-to-report-main">
                                      <a
                                        href="javascript:void(0);"
                                        onClick={this.iojLinktoReport}
                                        className="link-to-assesment link-to-report"
                                      >
                                        {i18n.t("Link_to_Report")} &nbsp;&#62;
                                      </a>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              <div className="list-cards">
                                <h2> {i18n.t("Operations_Maturity")}</h2>
                                <div className="rating-section">
                                  <div className="rating-left">
                                    {JourneyData.OMCurrentRating !== null ? (
                                      <p className="asses-name">
                                        {JourneyData.OMCurrentRating}
                                      </p>
                                    ) : (
                                      <p className="asses-name">{i18n.t("N.A")}</p>
                                    )}
                                    <p className="asses-caption">
                                      {i18n.t("Current_Rating")}
                                    </p>
                                  </div>

                                </div>
                                {window.location.pathname ===
                                  "/admin/Configurator" ? (
                                  <div className="start-assesment">
                                    <div className="link-to-assesment-main">
                                      <a
                                        href="https://core-beat.accenture.com/Assessments.aspx"
                                        target="_blank"
                                        className="link-to-assesment"
                                      >
                                        {i18n.t("Take_Assesment")} &nbsp;&#62;
                                      </a>
                                    </div>
                                    <div className="link-to-report-main">
                                      <a
                                        href="javascript:void(0);"
                                        onClick={this.omLinktoReport}
                                        className="link-to-assesment link-to-report"
                                      >
                                        {i18n.t("Link_to_Report")} &nbsp;&#62;
                                      </a>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </Tab>
                          {this.props.history.location.pathname !== "/admin/Configurator" &&

                            <Tab
                              eventKey="ClientStorytelling"
                              title={i18n.t("Client_Storytelling")}
                            >
                              {this.props.clientData.name && this.props.SelectedFilter.OfferingName && isClientStory ? (
                                <IOJClientStory
                                isApiLoading={isApiLoading}
                                  ClientStoryData={ClientStoryData}
                                  callClientStoryAPI={this.callClientStoryAPI}
                                  clonedclientStoryFormData={clonedclientStoryFormData}
                                  initiativesDropDownData={initiativesDropDownData}
                                  outcomeDropDownData={outcomeDropDownData}
                                  showConfiguratorUser={showConfiguratorUser}
                                  isAccess={isAccess}
                                  isChanged={isChanged}
                                  formData={clientStoryFormData}
                                  handleChange={this.handleChange}
                                  generateVersion={this.generateVersion}
                                  submitStoryForm={this.submitStoryForm}
                                  getSingleStoryData={this.getSingleStoryData}
                                  showformmethod={this.showformmethod}
                                  showForm={showForm}
                                  body={body}
                                  GetIOJClientStories={this.GetIOJClientStories}
                                  closeform={this.closeform}
                                  {...this.props}
                                />) : (
                                <div className="pulse-dashboard">
                                  <div className="select-filter-msg">
                                    <img
                                      src={noFilter}
                                      alt={i18n.t(
                                        "Select Client Name and Offering to View Client Story"
                                      )}
                                    />
                                    <p className="mb-0">
                                      {i18n.t(
                                        "Select Client Name and Offering to View Client Story"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </Tab>
                          }
                        </Tabs>
                      </div>)
                )}
              </div>
            </div>
          ) : (
            <div className="pulse-dashboard">
              <div className="select-filter-msg">
                <img
                  src={noFilter}
                  alt={i18n.t(
                    "Joureny_Roadmap_No_Offering_Suboffering_Selected"
                  )}
                />
                <p className="mb-0">
                  {i18n.t("Joureny_Roadmap_No_Offering_Suboffering_Selected")}
                </p>
              </div>
            </div>
          )}
        </div>
        {isShowClientStorySucess && (
          <ClientStoryNotification
            isshow={isShowClientStorySucess}
            isdeleteshow={false}
            formdata={clientStoryFormData}
          />
        )
        }

        {this.state.iojLinktoReportStatus || this.state.omLinktoReportStatus ? (
          <Modal
            className="ioj-assessment-modal"
            show={
              this.state.iojLinktoReportStatus ||
              this.state.omLinktoReportStatus
            }
            onHide={this.iojLinktoReportDisable}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {this.state.iojLinktoReportStatus
                  ? "Intelligent Operations Journey"
                  : null}
                {this.state.omLinktoReportStatus ? "Operation Maturity" : null}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactTooltip id={"image-IntelligentOperationJourney"} className="tooltip-ioj" effect="solid"> {i18n.t(`Click here to View Report`)} </ReactTooltip>
              <ReactTooltip id={"image-OperationMaturityAssessment"} place="top" effect="solid"> {i18n.t(`Click here to View Report`)} </ReactTooltip>
              {this.state.iojLinktoReportStatus ? (
                <a
                  onClick={this.iojLinktoReportDisable}
                  href="https://ts.accenture.com/:x:/r/sites/BPO%20Deliver/_layouts/15/Doc.aspx?sourcedoc=%7B4B0874FB-3E1B-4B3A-B0B7-029072753340%7D&file=Intelligent%20Operations%20Dashboard%20-%20Global%20-%2015.03.2021%20v1.1.xlsb&action=default&mobileredirect=true"
                  target="_blank"
                >
                  <img data-tip data-for="image-IntelligentOperationJourney" src={iojlinktoreportImg} />
                </a>
              ) : null}
              {this.state.omLinktoReportStatus ? (
                <a
                  href="https://core-beat.accenture.com/"
                  target="_blank"
                  onClick={this.iojLinktoReportDisable}
                >
                  <img data-tip data-for="image-OperationMaturityAssessment" src={omLinktoreportImg} />
                </a>
              ) : null}
            </Modal.Body>
          </Modal>
        ) : null}
        {
          this.state.showPerformanceModal && <PerformancePopup modalClose={this.performanceModalClose}
            showPerformanceModal={this.state.showPerformanceModal}
            performanceData={this.state.performanceData}
            onSelectInitaitve={this.onSelectInitaitve}
            performanceStageID={this.state.performanceStageID}
          />
        }
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    storeActionStatus: (payload) => dispatch(actionStatus(payload))
  };
};
const mapStateToProps = (state) => {
  return {
    languageData: state.CurrentFilter.languageData,
    currentFilter: state.CurrentFilter.SyncFilter,
    clientData: state.CurrentFilter.ClientData,
    boData: state.BOReducers.BO,
    Datas: state.JourneyReducer.Datas,
    IIData: state.intelligentInsightReducers.insightandintelligence,
    QcOverallScore: { ...state.CurrentFilter.QcOverallScore },
    QcIOJScore: state.CurrentFilter.QcIOJScore,
    JourneyData: state.JourneyReducer.JourneyData,
    RatingScore: state.JourneyReducer.RatingScore,
    OMIDEnabled: state.JourneyReducer.OMIDEnabled,
    ActiveToggleCount: state.JourneyReducer.ActiveToggleCount,
    OMIDDataList: state.JourneyReducer.OMIDDataList,
    allSelected: state.JourneyReducer.allSelected,
    SelectedFilter: { ...state.CurrentFilter.SyncFilter },
    CurrentFilter: { ...state.CurrentFilter },
    filter: state.CurrentFilter.Filter,
    subOfferingData: state.JourneyReducer.subOfferingData,
    roleData: state.roleReducers.roleReducers,
    FilterData: state.FilterReducer.DasboardOfferingData,
  };
};
let conntedFunc = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withRouter,
  conntedFunc
)(JourneyInitiativeContainer);