/* eslint-disable import/no-cycle */

import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal, Overlay, Popover } from 'react-bootstrap';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import i18n from 'i18next';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
// import { CancelPresentationOutlined } from '@material-ui/icons';
import axiosInstance from '../Shared/interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl } from '../Shared/Constant';

import { store } from '../reducers/configureStore';
import SynopsLoader from '../Shared/SynopsLoader';
import {
  InitializeDatas, saveJourneyData, IncrementGetDataCounter,
} from '../actions/JourneyActions';

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      CurrentYearData: [],
      responseData: [],
      selectedYear: new Date().getFullYear(),
      isVisibleDatepickerDropdownPopover: true,
      selectedMonthData: {},
      show: false,
      dataType: 0,
      loading: false,
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      LeversOptions: [
        {
          label: 'Business Outcomes',
          value: 'Business Outcomes',
          SOCategoryID: 1,
          checked: true,
        },
        {
          label: 'Human + Machine Talent',
          value: 'Human + Machine Talent',
          SOCategoryID: 2,
          checked: true,
        },
        {
          label: 'Work Orchestration',
          value: 'Work Orchestration',
          SOCategoryID: 3,
          checked: true,
        },
        {
          label: 'Insights & Intelligence',
          value: 'Insights & Intelligence',
          SOCategoryID: 4,
          checked: true,
        },
        {
          label: 'Diverse Data',
          value: 'Diverse Data',
          SOCategoryID: 5,
          checked: true,
        },
      ],
      selectedAllLevers: true,
      selectedLeverOptionsIDs: [],
      toggleName: i18n.t('All_Selected'),
    };
  }

  componentDidMount() {
    const { LeversOptions, months, selectedYear } = this.state;
    const { ClientData, FilterDetails } = this.props;
    let CurrentYearData = [];
    months.forEach((month, i) => {
      CurrentYearData.push({
        Month: month,
        IsPublish: false,
        IsReview: false,
        PublishDate: null,
        ReviewDate: null,
        ShowMessage: false,
        index: i,
      });
    });
    const selectedLeverOptionsIDs = [];
    LeversOptions.forEach((each) => {
      if (each.checked) {
        selectedLeverOptionsIDs.push(each.SOCategoryID);
      }
    });

    this.setState({ CurrentYearData, loading: true, selectedLeverOptionsIDs });
    axiosInstance
      .get(`${LocalApiBaseUrl}Configurator/GetMonthData`, {
        params: {
          OrgEntityID: ClientData.id,
          OfferingID: FilterDetails.ProcessId,
          LocationID: FilterDetails.LocationId,
        },
      })
      .then((response) => {
        response.data.forEach((each) => {
          const date = new Date(each.Month);
          if (date.getFullYear() === selectedYear) {
            CurrentYearData = CurrentYearData.map((val) => {
              if (val.index === date.getMonth()) {
                /* eslint-disable no-param-reassign */
                val.IsPublish = each.IsPublish;
                val.IsReview = each.IsReview;
                val.PublishDate = each.PublishDate;
                val.ReviewDate = each.ReviewDate;
                val.ShowMessage = each.ShowMessage;
              }
              return val;
            });
          }
        });
        this.setState({ CurrentYearData, responseData: response.data, loading: false });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ loading: false });
      });
  }

  onSelectData = () => {
    this.getSelectedMonthData();
  };

  ShowMessage = () => {};

  showModal = (data) => {
    const { selectedLeverOptionsIDs } = this.state;
    if (data.IsPublish && data.IsReview) {
      this.setState({ show: true, selectedMonthData: data });
    } else {
      this.setState({ dataType: data.IsPublish ? '1' : '2', selectedMonthData: data }, () => {
        this.getSelectedMonthData();
      });
    }
    // CONDITION ADDED FOR SHOWING THE POPUP FOR GETTING THE DATA FOR PUBLISHED MONTH AS BAT //
    if (data.ShowMessage && selectedLeverOptionsIDs.filter((a) => a === 5).length > 0) {
      const StatusMessage = 'For the selected month, no data will be fetched in Diverse data section as the configuration for the selected month was set to fetch data from Benchmark Analytics';
      window.alert(StatusMessage);
    }
  };

  onDataTypeSelection = (e) => {
    this.setState({ dataType: e.target.value });
  };

  getSelectedMonthData = () => {
    const {
      history, tabTitle, ClientData, FilterDetails, onTabChange, onGetData,
    } = this.props;

    const {
      selectedLeverOptionsIDs, monthNames, selectedMonthData, selectedYear, dataType,
    } = this.state;
    const date = `${monthNames[selectedMonthData.index]}-${selectedYear}`;
  
    const soCategories = ['Business Outcomes','Human and Machine','Work Orchestration','Insights & Intelligence','Diverse Data']
    let selectCatStr = "";
    selectedLeverOptionsIDs.forEach((each,i) => selectCatStr += `\n${i+1}. ${soCategories[each-1]}` )


    if(!window.confirm(`Data added in the below levers will be copied/over written with selected month data ${selectCatStr} \n\n Do you want to proceed?`)) return;

    this.setState({ loading: true });
    const data = {
      OrgEntityID: ClientData.id,
      MonthDT: date,
      OfferingID: FilterDetails.ProcessId,
      LocationID: FilterDetails.LocationId,
      Status: dataType === '1' ? 'publish' : 'submit',
      CurrentMonth: FilterDetails.Month,
      SOCategoryIDs: selectedLeverOptionsIDs,
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}Configurator/GetDataForSelectedMonth`, data, {
        params: {
          OrgEntityID: ClientData.id,
          MonthDT: date,
          OfferingID: FilterDetails.ProcessId,
          LocationID: FilterDetails.LocationId,
          Status: dataType === '1' ? 'publish' : 'submit',
          CurrentMonth: FilterDetails.Month,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          if (selectedLeverOptionsIDs.filter((each) => each === 1).length === 1) {
            store.dispatch({
              type: 'INITIALISE_BO',
              payload: response.data.filter((a) => a.TabID === 1)[0].TabData,
            });
          }
          if (selectedLeverOptionsIDs.filter((each) => each === 2).length === 1) {
            store.dispatch({ // Human and Machine
              type: 'INITIALISE',
              payload: response.data.filter((a) => a.TabID === 2)[0].TabData,
            });
            if (tabTitle === 'Human + Machine Talent') {
              onTabChange('Human + Machine Talent');
            }
          }
          if (selectedLeverOptionsIDs.filter((each) => each === 4).length === 1) {
            store.dispatch({
              type: 'INITIALISEINSIGHT',
              payload: response.data.filter((a) => a.TabID === 4)[0].TabData,
            });
          }
          if (selectedLeverOptionsIDs.filter((each) => each === 5).length === 1) {
            store.dispatch({
              type: 'INITIALISEDIVERSE',
              payload: response.data.filter((a) => a.TabID === 5)[0].TabData,
            });
          }
          if (selectedLeverOptionsIDs.filter((each) => each === 3).length === 1) {
            store.dispatch({
              type: 'INITIALISEWO',
              payload: response.data.filter((a) => a.TabID === 3)[0].TabData,
            });
          }
          store.dispatch(InitializeDatas({ isGetData: true, GetDataMonthDT: date }));
        }
        store.dispatch(IncrementGetDataCounter());
        axiosInstance
          .get(`${LocalApiBaseUrl}Configurator/GetQualityScoreAutomationSummaryData`, {
            params: {
              OrgEntityID: ClientData.id,
              LocationID: FilterDetails.LocationId,
              MonthDT: FilterDetails.Month,
              OfferingID: FilterDetails.ProcessId,
              LanguageID: store.getState().CurrentFilter.languageData.id,
            },
          })
          .then(() => {
            if (response.data.length) { // empty block
            }
            store.dispatch({
              type: 'SAVE_QUALITY_SCORE_AUTOMATION_SUMMARY_DATA',
              payload: response.data,
            });
          })
          .catch((error) => {
            trycatchAlertPopup(error);
          });

        store.dispatch(saveJourneyData({ ConfiguratorID: '', IOJGlidePathAllModels: [], IsIOJCategory: false }));
        /* eslint-disable no-return-assign */
        // axiosInstance
        //   .post(`${LocalApiBaseUrl}IOJJourneyInitiative/GetAllGlidePathData`, data)
        //   .then((response) => {
        //     if (response.data) {
        //       this.setState({ journeyInitiativeContainerData: response.data });
        //       store.dispatch(InitializeIOJData(response.data.IOJGlidePathAllModels));
        //       store.dispatch(saveJourneyData(response.data));
        //     }
        //   })
        //   .catch((err) => {
        //   //   this.setState({ isApiLoading: false });
        //   });
        this.setState({ show: false, loading: false }, () => this.executeAPIGetDiverseDataFetchStatus());
        onGetData();
        // GetConfiguratedInitativeJourneyData API
        // Not required
      // axiosInstance
      // .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetConfiguratedInitativeJourneyData`, {
      //   params: {
      //     OrgEntityID: this.props.ClientData.id,
      //     LocationID: this.props.FilterDetails.LocationId,
      //         MonthDT: this.props.FilterDetails.Month,
      //     OfferingID: this.props.FilterDetails.ProcessId,
      //   },
      // })
      // .then((response) => {
      //   store.dispatch(InitializeInitiative(response.data));
      // })
      // .catch((error) => {
      //   window.alert(error.response.data.Message);
      //
      // });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        this.setState({ show: false, loading: false });
        onGetData();
      });
    // GetConfiguratedInitativeJourneyData API
    // axiosInstance
    // .get(`${LocalApiBaseUrl}IOJJourneyInitiative/GetConfiguratedInitativeJourneyData`, {
    //   params: {
    //     OrgEntityID: this.props.ClientData.id,
    //     LocationID: this.props.FilterDetails.LocationId,
    //     MonthDT: date,
    //     OfferingID: this.props.FilterDetails.ProcessId,
    //   },
    // })
    // .then((response) => {
    //   store.dispatch(InitializeInitiative(response.data));

    // })
    // .catch((error) => {

    // });
  };

  executeAPIGetDiverseDataFetchStatus = () => {
    const { FilterDetails, ClientData, languageData } = this.props;

    if (ClientData.id && FilterDetails.ProcessId && FilterDetails.LocationId && FilterDetails.Month) {
      this.setState({ loading: true });
      axiosInstance
        .get(`${LocalApiBaseUrl}BAT/GetDiverseDataFetchSatus`, {
          params: {
            OrgEntityID: ClientData.id,
            OfferingID: FilterDetails.ProcessId,
            LocationID: FilterDetails.LocationId,
            MonthDT: FilterDetails.Month,
            LanguageID: languageData.id,
          },
        })
        .then((response) => {
          const {
            FetchFromBAT,
            IsToggleGrayedOut,
            ConfiguratorID,
            ID,
            ShowBannerMessage,
            MonthToShowOnBannerMessage,
          } = response.data;
          if (response) {
            store.dispatch({
              type: 'FETCHFROMBAT',
              payload: FetchFromBAT,
            });
            store.dispatch({
              type: 'ISTOGGLEGRAYEDOUT',
              payload: IsToggleGrayedOut,
            });
            store.dispatch({
              type: 'CONFIGURATORID',
              payload: ConfiguratorID,
            });
            store.dispatch({
              type: 'ID',
              payload: ID,
            });
            store.dispatch({
              type: 'SHOWBANNERMESSAGE',
              payload: ShowBannerMessage,
            });
            store.dispatch({
              type: 'MONTHSHOWBANNERMESSAGE',
              payload: MonthToShowOnBannerMessage,
            });

            this.setState({ loading: false });
          }
        })
        .catch((error) => {
          window.alert(error.response.data.Message);
          this.setState({ loading: false });
        });
    }
  }

  handleHide = () => {
    this.setState({ show: false });
  };

  onYearChange = (val) => {
    const { months, responseData } = this.state;
    const selectedYear = this.state.selectedYear + val;
    let CurrentYearData = [];
    months.forEach((month, i) => {
      CurrentYearData.push({
        Month: month,
        IsPublish: false,
        IsReview: false,
        PublishDate: null,
        ReviewDate: null,
        ShowMessage: false,
        index: i,
      });
    });
    responseData.forEach((each) => {
      const date = new Date(each.Month);
      if (date.getFullYear() === selectedYear) {
        /* eslint-disable no-shadow */
        CurrentYearData = CurrentYearData.map((val) => {
          if (val.index === date.getMonth()) {
            val.IsPublish = each.IsPublish;
            val.IsReview = each.IsReview;
            val.PublishDate = each.PublishDate;
            val.ReviewDate = each.ReviewDate;
            val.ShowMessage = each.ShowMessage;
          }
          return val;
        });
      }
    });
    this.setState({ CurrentYearData, selectedYear: this.state.selectedYear + val });
  };

  hideDatepickerDropdownPopover = () => {
    const { show } = this.state;
    const { onGetData } = this.props;

    if (!show) {
      onGetData();
    }
    // this.setState({
    //     isVisibleDatepickerDropdownPopover: false,
    // });
  };

  onAllChange = (event, option) => {
    let { selectedAllLevers } = this.state;
    const { LeversOptions } = this.state;
    const selectedIDs = [];
    if (option === 'All') {
      selectedAllLevers = !selectedAllLevers;
      if (selectedAllLevers) {
        LeversOptions.map((each) => each.checked = true);
      } else {
        LeversOptions.map((each) => each.checked = false);
      }
    }
    LeversOptions.forEach((each) => {
      if (each.checked) { selectedIDs.push(each.SOCategoryID); }
    });
    this.setState(
      {
        selectedAllLevers,
        LeversOptions,
        selectedLeverOptionsIDs: selectedIDs,
      }, () => {
        this.toggleNameRenderer();
      },
    );
  }

  onLeverNameChange = (event, value) => {
    let { selectedAllLevers } = this.state;
    const { LeversOptions } = this.state;
    let count = 0;
    const selectedIDs = [];
    LeversOptions.forEach((each) => {
      if (each.value === value) {
        each.checked = event.target.checked;
      }
    });
    LeversOptions.forEach((each) => {
      if (each.checked) {
        count += 1;
        selectedIDs.push(each.SOCategoryID);
      }
    });
    if (count === LeversOptions.length) {
      selectedAllLevers = true;
    } else {
      selectedAllLevers = false;
    }
    this.setState(
      {
        selectedAllLevers,
        LeversOptions,
        selectedLeverOptionsIDs: selectedIDs,
      }, () => {
        this.toggleNameRenderer();
      },
    );
  };

  toggleNameRenderer = () => {
    const { selectedLeverOptionsIDs, LeversOptions } = this.state;
    let toggleName = '';

    if (selectedLeverOptionsIDs.length === 0) {
      toggleName = i18n.t('Select Lever');
    } if (selectedLeverOptionsIDs.length > 0 && selectedLeverOptionsIDs.length === LeversOptions.length) {
      toggleName = i18n.t('All_Selected');
    } else {
      LeversOptions.forEach((each) => {
        if (each.checked) {
          toggleName = toggleName + (_.isEmpty(toggleName) ? '' : ', ') + each.label;
        }
      });
    }

    this.setState({
      toggleName,
    });
  };

  render() {
    const {
      LeversOptions, selectedAllLevers, selectedLeverOptionsIDs, toggleName, loading, isVisibleDatepickerDropdownPopover, selectedYear, CurrentYearData, show, selectedMonthData, monthNames, months, dataType,
    } = this.state;
    const { datepickerDropdownPopoverTarget } = this.props;
    return (
      <div>
        <LoadingOverlay
          fadeSpeed={0}
          spinner={<SynopsLoader />}
          active={loading}
        >
          <Overlay
            target={datepickerDropdownPopoverTarget.current}
            show={isVisibleDatepickerDropdownPopover}
            placement="bottom"
            rootClose
            rootCloseEvent="click"
            onHide={!loading && this.hideDatepickerDropdownPopover}
          >
            <Popover id="popover-basic4" className="calenderPopover customCalender">
              <Popover.Content>
                <div className="custom-picker-wrapper">
                  {/* Dropdown added  */}
                  <div className="form-group border-group">
                    <div className="customform-dropdown">
                      <Dropdown id="role">
                        <Dropdown.Toggle>
                          <span>
                            {selectedLeverOptionsIDs.length === 0
                              ? i18n.t('Select Lever')
                              : toggleName}
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div className="offering-droplistwrapper">

                            <Dropdown.ItemText>
                              <label className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  onChange={(e) => this.onAllChange(e, 'All')}
                                  checked={selectedAllLevers}
                                />
                                <span className="checkmark" />
                                <span className="form-check-label">{i18n.t('All')}</span>
                              </label>
                            </Dropdown.ItemText>

                            {LeversOptions.map((each) => (
                              <Dropdown.ItemText>
                                <label className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) => this.onLeverNameChange(e, each.label)}
                                    checked={each.checked}
                                  />
                                  <span className="checkmark" />
                                  <span className="form-check-label">{each.label}</span>
                                </label>
                              </Dropdown.ItemText>
                            ))}

                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* Dropdown added */}
                  <div className="custom-datepicker">
                    <div className="custom-datepicker-header">
                      <p>
                        <span onClick={this.onYearChange.bind(this, -1)} role="button" tabIndex={0}>
                          <i className="fal fa-chevron-circle-left" />
                        </span>
                        <span className="p-l-35p f-s-12 m-t-2-n year">{selectedYear}</span>
                        <span className="p-l-40p" onClick={this.onYearChange.bind(this, 1)} role="button" tabIndex={0}>
                          <i className="fal fa-chevron-circle-right" />
                        </span>
                      </p>
                    </div>
                    <div className="custom-datepicker-body">
                      {CurrentYearData.map((each) => (
                        <p
                        /* eslint-disable no-useless-concat */
                          // eslint-disable-next-line no-nested-ternary
                          className={selectedLeverOptionsIDs.length
                            ? (!each.IsPublish && !each.IsReview ? 'disabled' : '')
                            : 'disabled'}
                          onClick={!each.IsPublish && !each.IsReview ? () => {} : this.showModal.bind(this, each)}
                          role="presentation"
                        >
                          {each.Month}
                          <span>
                            {each.IsPublish ? (
                              <p className="green ">
                                <i className="fas fa-badge-check" />
                              </p>
                            ) : (
                              ''
                            )}
                            {each.IsReview ? (
                              <p className="orange">
                                <i className="fas fa-eye" />
                              </p>
                            ) : (
                              ''
                            )}
                          </span>
                        </p>
                      ))}
                    </div>
                  </div>
                  <div className="custom-datepicker-footer m-t-5">
                    <p>
                      <span className="badge-icon ">
                        <i className="fas fa-badge-check" />
                        <span>Published</span>
                      </span>
                      <span className="eye-icon ">
                        <i className="fas fa-eye" />
                        <span>In Review</span>
                      </span>
                    </p>
                  </div>
                </div>
              </Popover.Content>
            </Popover>
          </Overlay>
          {show ? (
            <Modal
              show={show}
              onHide={this.handleHide}
              size="lg"
              className="modal fade v-center"
              id="custom-date-picker-popup"
              backdrop="static"
            >
              <Modal.Header closeButton className="no-border p-t-15" onClick={this.ShowMessage}>
                <h4 className="modal-title" id="dealprofile">
                  {' '}
                  {'Get Last Month'}
                </h4>
              </Modal.Header>
              {/* {this.state.showGraph ? */}
              <Modal.Body>
                <div className="row">
                  <div className="col-sm-2">
                    <span className="succes-tick">
                      <i className="fal fa-check" />
                    </span>
                  </div>
                  <div className="col-sm-10">
                    <p className="font-color-black">
                      Currently there is multiple data for
                      {' '}
                      {`${monthNames[selectedMonthData.index]}-${selectedYear}`}
                      {' '}
                    </p>
                    <p>Select from below options</p>
                    <div>
                      <label className="form-check form-check-inline">
                        <input
                          id="chckProject"
                          className="form-check-input"
                          value="1"
                          type="radio"
                          name="MetricGroup"
                          checked={dataType === '1'}
                          onChange={(e) => {
                            this.onDataTypeSelection(e);
                          }}
                        />
                        <span className="checkmark" />
                        <span className="form-check-label">
                          {'Published ('}
                          {new Date(selectedMonthData.PublishDate).getDate() <= 9
                            ? `0${new Date(selectedMonthData.PublishDate).getDate()}`
                            : new Date(selectedMonthData.PublishDate).getDate()}
                          {`/${
                            months[new Date(selectedMonthData.PublishDate).getMonth()]
                          }/${
                            new Date(selectedMonthData.PublishDate).getFullYear()
                          })`}
                        </span>
                      </label>
                    </div>
                    <div>
                      <label className="form-check form-check-inline">
                        <input
                          id="chckProject"
                          className="form-check-input"
                          value="2"
                          type="radio"
                          name="MetricGroup"
                          checked={dataType === '2'}
                          onChange={(e) => {
                            this.onDataTypeSelection(e);
                          }}
                        />
                        <span className="checkmark" />
                        <span className="form-check-label">

                          {'In Review' + ' ('}
                          {new Date(selectedMonthData.ReviewDate).getDate() <= 9
                            ? `0${new Date(selectedMonthData.ReviewDate).getDate()}`
                            : new Date(selectedMonthData.ReviewDate).getDate()}
                          {`/${
                            months[new Date(selectedMonthData.ReviewDate).getMonth()]
                          }/${
                            new Date(selectedMonthData.ReviewDate).getFullYear()
                          })`}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  className="btn btn-primary btn-sm mr-2 float-right"
                  name="selectDatatype"
                  onClick={this.onSelectData}
                >
                  Select Data
                </button>
              </Modal.Footer>
            </Modal>
          ) : (
            ''
          )}
        </LoadingOverlay>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  FilterDetails: state.CurrentFilter.SyncFilter,
  ClientData: state.CurrentFilter.ClientData,
  tabTitle: state.CurrentFilter.Tab.title,
  languageData: state.CurrentFilter.languageData,
});

CustomDatePicker.propTypes = {
  ClientData: PropTypes.object.isRequired,
  languageData: PropTypes.object.isRequired,
  FilterDetails: PropTypes.object.isRequired,
  datepickerDropdownPopoverTarget: PropTypes.object.isRequired,
  onGetData: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onTabChange: PropTypes.bool.isRequired,
};

export default compose(withRouter, connect(
  mapStateToProps,
  '',
))(CustomDatePicker);
