import _ from "lodash";
import moment from "moment";
import { store } from "../reducers/configureStore";
import Found from "../Images/intel-4.png";
import Auto from "../Images/intel-3.png";
import Smrt from "../Images/intel-2.png";
import intlgnt from "../Images/intel-1.png";

// IMPORT BAT OFFERINGS ICONS
import BillPNG from "../Images/bat_icons/Bill.png";
import HeartPNG from "../Images/bat_icons/Heart.png";
import Heart2PNG from "../Images/bat_icons/Heart 2.png";
import ChartPNG from "../Images/bat_icons/Chart.png";
import LinkPNG from "../Images/bat_icons/Link.png";
import TeamPNG from "../Images/bat_icons/Team.png";
import CoverPNG from "../Images/bat_icons/Cover.png";
import TrendUpPNG from "../Images/bat_icons/Trend-up.png";
import InteractivePNG from "../Images/bat_icons/Interactive.png";
import ImprovisePNG from "../Images/bat_icons/Improvise.png";
import ProcurementPNG from "../Images/bat_icons/Procurement.png";
import CustomerOperationsPNG from "../Images/bat_icons/CustomerOperations.png";
import MarketingPNG from "../Images/bat_icons/Marketing.png";

import BankingBlackSVG from "../Images/offering_icons/Banking.svg";
import BankingWhiteSVG from "../Images/offering_icons_white/Banking.svg";
import Capital_MarketBlackSVG from "../Images/offering_icons/Capital Market.svg";
import Capital_MarketWhiteSVG from "../Images/offering_icons_white/Capital Market.svg";
import ComplianceBlackSVG from "../Images/offering_icons/Compliance.svg";
import ComplianceWhiteSVG from "../Images/offering_icons_white/Compliance.svg";
import Content_ModerationBlackSVG from "../Images/offering_icons/Content Moderation.svg";
import Content_ModerationWhiteSVG from "../Images/offering_icons_white/Content Moderation.svg";
import F_ABlackSVG from "../Images/offering_icons/F&A.svg";
import F_AWhiteSVG from "../Images/offering_icons_white/F&A.svg";
import HealthBlackSVG from "../Images/offering_icons/Health.svg";
import HealthWhiteSVG from "../Images/offering_icons_white/Health.svg";
import InsuranceBlackSVG from "../Images/offering_icons/Insurance.svg";
import InsuranceWhiteSVG from "../Images/offering_icons_white/Insurance.svg";
import Life_SciencesBlackSVG from "../Images/offering_icons/Life Sciences.svg";
import Life_SciencesWhiteSVG from "../Images/offering_icons_white/Life Sciences.svg";
import MarketingBlackSVG from "../Images/offering_icons/Marketing.svg";
import MarketingWhiteSVG from "../Images/offering_icons_white/Marketing.svg";
import NetworkBlackSVG from "../Images/offering_icons/Network.svg";
import NetworkWhiteSVG from "../Images/offering_icons_white/Network.svg";
import Sales_CustomerBlackSVG from "../Images/offering_icons/Sales & Customer.svg";
import Sales_CustomerWhiteSVG from "../Images/offering_icons_white/Sales & Customer.svg";
import Customer_OperationsBlackSVG from "../Images/offering_icons/Customer Operations.svg";
import Customer_OperationsWhiteSVG from "../Images/offering_icons_white/Customer Operations.svg";
import Source_ProcurementBlackSVG from "../Images/offering_icons/Source & Procurement.svg";
import Source_ProcurementWhiteSVG from "../Images/offering_icons_white/Source & Procurement.svg";
import Supply_ChainBlackSVG from "../Images/offering_icons/Supply Chain.svg";
import Supply_ChainWhiteSVG from "../Images/offering_icons_white/Supply Chain.svg";
import Talent_ManagenentBlackSVG from "../Images/offering_icons/Talent & Managenent.svg";
import Talent_ManagenentWhiteSVG from "../Images/offering_icons_white/Talent & Managenent.svg";
import UtilitiesBlackSVG from "../Images/offering_icons/Utilities.svg";
import UtilitiesWhiteSVG from "../Images/offering_icons_white/Utilities.svg";
   /* MOR ICONS  */
import MorListexcel from "../Images/MORListIcons/MorListexcel.svg";
import MorListgeneric from "../Images/MORListIcons/MorListgeneric.svg";
import MorListimage from "../Images/MORListIcons/MorListimage.svg";
import MorListpdf from "../Images/MORListIcons/MorListpdf.svg";
import MorListpowerbi from "../Images/MORListIcons/MorListpower-bi.svg";
import MorListppt from "../Images/MORListIcons/MorListppt.svg";
import MorListvideo from "../Images/MORListIcons/MorListvideo.svg";
import MorListword from "../Images/MORListIcons/MorListword.svg";

import MorSmallexcel from "../Images/MORSmallIcons/MorSmallexcel.svg";
import MorSmallgeneric from "../Images/MORSmallIcons/MorSmallgeneric.svg";
import MorSmallimage from "../Images/MORSmallIcons/MorSmallimage.svg";
import MorSmallpdf from "../Images/MORSmallIcons/MorSmallpdf.svg";
import MorSmallpowerbi from "../Images/MORSmallIcons/MorSmallpower-bi.svg";
import MorSmallppt from "../Images/MORSmallIcons/MorSmallppt.svg";
import MorSmallvideo from "../Images/MORSmallIcons/MorSmallvideo.svg";
import MorSmallword from "../Images/MORSmallIcons/MorSmallword.svg";

import MorTileexcel from "../Images/MORTileIcons/MorTileexcel.svg";
import MorTilegeneric from "../Images/MORTileIcons/MorTilegeneric.svg";
import MorTileimage from "../Images/MORTileIcons/MorTileimage.svg";
import MorTilepdf from "../Images/MORTileIcons/MorTilepdf.svg";
import MorTilepowerbi from "../Images/MORTileIcons/MorTilepowerbi.svg";
import MorTileppt from "../Images/MORTileIcons/MorTileppt.svg";
import MorTilevideo from "../Images/MORTileIcons/MorTilevideo.svg";
import MorTileword from "../Images/MORTileIcons/MorTileword.svg";

/* MOR ICONS  */



// RETRIEVE THE VALUES OF THE VARIABLES CONFIGURED IN THE ENVIRONMENT FILE OR THE CURRENT APP ENVIRONMENT
export const LocalApiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const LocalApiBaseUrlImage = process.env.REACT_APP_API_BASE_URL_IMAGE;
export const DomainBaseUrl = process.env.REACT_APP_DOMAIN_BASE_URL;
export const ProfilePic = process.env.REACT_APP_PROFILE_PIC_URL;
export const PeoplePage = process.env.REACT_APP_PEOPLE_PAGE_URL;

// const literals = {
//   1: EnglishLiterals,
//   2: JapaneseLiterals
// }
let currentLanguageData = store.getState().CurrentFilter.languageData;
let currentlangjson = currentLanguageData.messages;
store.subscribe(() => {
  if (
    currentLanguageData.id !== store.getState().CurrentFilter.languageData.id ||
    currentLanguageData.messages !== store.getState().CurrentFilter.languageData.messages
  ) {
    currentLanguageData = store.getState().CurrentFilter.languageData;
    currentlangjson = currentLanguageData.messages;
    Navigation = [
       { itemName: "Business Outcomes",itemValue: currentlangjson.Business_Outcomes
       ? currentlangjson.Business_Outcomes
       : "Business Outcomes", image: "bo-icon", imageName: "business" },
      {
        itemName: "Human + Machine Talent",
        image: "hm-icon",
        imageName: "human",
        itemValue: currentlangjson.Human_plus_Machine_Talent
          ? currentlangjson.Human_plus_Machine_Talent
          : "Human + Machine Talent",
      },
      {
        itemName: "Work Orchestration",
        image: "wo-icon",
        imageName: "work",
        itemValue: currentlangjson.Work_Orchestration ? currentlangjson.Work_Orchestration : "Work Orchestration",
      },
      {
        itemName: "Insights & Intelligence",
        image: "ii-icon",
        imageName: "ii",
        itemValue: currentlangjson.Insights_Driven_and_Intelligence
          ? currentlangjson.Insights_Driven_and_Intelligence
          : "Insights & Intelligence",
      },
      {
        itemName: "Diverse Data",
        image: "dd-icon",
        imageName: "diverse",
        itemValue: currentlangjson.Diverse_Data ? currentlangjson.Diverse_Data : "Diverse Data",
      },
      {
        itemName: "Tools",
        image: "icon-connect",
        imageName: "work",
        itemValue: currentlangjson.Tools ? currentlangjson.Tools : "Tools",
      }
      // { itemName: "IntelOps", image: barchart1, imageName: "bar-chart-2" }
    ];
    IOJNavigation = [
      {
        itemName: "IOJ Scorecard",
        image: "bullseye",
        imageName: "scorecard",
        itemValue: currentlangjson.IOJ_Scorecard ? currentlangjson.IOJ_Scorecard : "IOJ Scorecard",
      },
      {
        itemName: "Journey Initiatives",
        image: "ji-icon",
        imageName: "bar-chart-2",
        itemValue: currentlangjson.Journey_Initiatives ? currentlangjson.Journey_Initiatives : "Journey Initiatives",
      },
      {
        itemName: "Value Realization",
        image: "vr-icon",
        imageName: "profits",
        itemValue: currentlangjson.Value_Realization ? currentlangjson.Value_Realization : "Value Realization",
      },
      {
        itemName: "Maturity Plan",
        image: "mp-icon",
        imageName: "growth",
        itemValue: currentlangjson.Maturity_Plan ? currentlangjson.Maturity_Plan : "Maturity Plan",
      },
      // { itemName: "Initiative Comparison", image: "icon-hand-plant", imageName: "Initiative", itemValue: currentlangjson.Initiative_Comparison ? currentlangjson.Initiative_Comparison : "Initiative Comparison" },
      // {
      //   itemName: "Deal Report",
      //   image: "icon-hand-plant",
      //   imageName: "Initiative",
      //   itemValue: currentlangjson.Deal_Report ? currentlangjson.Deal_Report : "Deal Report",
      // },
    ];
    FoundationTools = [
      currentlangjson.GPH,
      currentlangjson.SAM,
      currentlangjson.OPSNAV,
      currentlangjson.AIDT,
      currentlangjson.ACF,
      currentlangjson.CSS,
    ];
    IOJDataForFilterBar = [
      {
        IOJName: "Foundation",
        IOJNameValue: currentlangjson.Foundation ? currentlangjson.Foundation : "Foundation",
        IOJDesc: currentlangjson.Stable ? currentlangjson.Stable : "Stable",
        image: Found,
      },
      {
        IOJName: "Automated",
        IOJNameValue: currentlangjson.Automated ? currentlangjson.Automated : "Automated",
        IOJDesc: currentlangjson.Stable ? currentlangjson.Efficient : "Efficient",
        image: Auto,
      },
      {
        IOJName: "Insights Driven",
        IOJNameValue: currentlangjson.Insights_Driven ? currentlangjson.Insights_Driven : "Insights Driven",
        IOJDesc: currentlangjson.Stable ? currentlangjson.Predictive : "Predictive",
        image: Smrt,
      },
      {
        IOJName: "Intelligent",
        IOJNameValue: currentlangjson.Intelligent ? currentlangjson.Intelligent : "Intelligent",
        IOJDesc: currentlangjson.Stable ? currentlangjson.Future_Ready : "Future Ready",
        image: intlgnt,
      },
    ];
    IOJData = [
      {
        IOJName: "Foundation",
        IOJNameValue: currentlangjson.Foundation ? currentlangjson.Foundation : "Foundation",
        IOJDesc: currentlangjson.Stable_Operations ? currentlangjson.Stable_Operations : "Stable Operations",
        image: Found,
      },
      {
        IOJName: "Automated",
        IOJNameValue: currentlangjson.Automated ? currentlangjson.Automated : "Automated",
        IOJDesc: currentlangjson.Efficient_Operations ? currentlangjson.Efficient_Operations : "Efficient Operations",
        image: Auto,
      },
      {
        IOJName: "Insights Driven",
        IOJNameValue: currentlangjson.Insights_Driven ? currentlangjson.Insights_Driven : "Insights Driven",
        IOJDesc: currentlangjson.Predictive_Operations
          ? currentlangjson.Predictive_Operations
          : "Predictive Operations",
        image: Smrt,
      },
      {
        IOJName: "Intelligent",
        IOJNameValue: currentlangjson.Intelligent ? currentlangjson.Intelligent : "Intelligent",
        IOJDesc: currentlangjson.Future_Ready_Operations
          ? currentlangjson.Future_Ready_Operations
          : "Future Ready Operations",
        image: intlgnt,
      },
    ];
  }
});
export const procurementOfferingList = ['Procurement','Sourcing & Procurement'];
export const procurementOffering = 'Sourcing & Procurement';
export const header = `Authorization: Bearer ${sessionStorage.getItem("msal.idtoken")}`;

// export const FoundationTools = ["GPH", "SAM", "OPSNAV", "AIDT", "ACF", "CSS"];
export const ToolsCategoryMaster = ["AI", "AT", "AA", "WF"];
export let IOJData = [
  {
    IOJName: "Foundation",
    IOJNameValue: currentlangjson.Foundation ? currentlangjson.Foundation : "Foundation",
    IOJDesc: currentlangjson.Stable_Operations ? currentlangjson.Stable_Operations : "Stable Operations",
    image: Found,
  },
  {
    IOJName: "Automated",
    IOJNameValue: currentlangjson.Automated ? currentlangjson.Automated : "Foundation",
    IOJDesc: currentlangjson.Efficient_Operations ? currentlangjson.Efficient_Operations : "Efficient Operations",
    image: Auto,
  },
  {
    IOJName: "Insights Driven",
    IOJNameValue: currentlangjson.Insights_Driven ? currentlangjson.Insights_Driven : "Insights Driven",
    IOJDesc: currentlangjson.Predictive_Operations ? currentlangjson.Predictive_Operations : "Predictive Operations",
    image: Smrt,
  },
  {
    IOJName: "Intelligent",
    IOJNameValue: currentlangjson.Intelligent ? currentlangjson.Intelligent : "Foundation",
    IOJDesc: currentlangjson.Future_Ready_Operations
      ? currentlangjson.Future_Ready_Operations
      : "Future Ready Operations",
    image: intlgnt,
  },
];
export let IOJDataForFilterBar = [
  {
    IOJName: "Foundation",
    IOJNameValue: currentlangjson.Foundation ? currentlangjson.Foundation : "Foundation",
    IOJDesc: currentlangjson.Stable ? currentlangjson.Stable : "Stable",
    image: Found,
  },
  {
    IOJName: "Automated",
    IOJNameValue: currentlangjson.Automated ? currentlangjson.Automated : "Automated",
    IOJDesc: currentlangjson.Stable ? currentlangjson.Efficient : "Efficient",
    image: Auto,
  },
  {
    IOJName: "Insights Driven",
    IOJNameValue: currentlangjson.Insights_Driven ? currentlangjson.Insights_Driven : "Insights Driven",
    IOJDesc: currentlangjson.Stable ? currentlangjson.Predictive : "Predictive",
    image: Smrt,
  },
  {
    IOJName: "Intelligent",
    IOJNameValue: currentlangjson.Intelligent ? currentlangjson.Intelligent : "Intelligent",
    IOJDesc: currentlangjson.Stable ? currentlangjson.Future_Ready : "Future Ready",
    image: intlgnt,
  },
];
export let Navigation = [
  { itemName: "Business Outcomes",itemValue: currentlangjson.Business_Outcomes
       ? currentlangjson.Business_Outcomes
       : "Business Outcomes", image: "bo-icon", imageName: "business" },
  {
    itemName: "Human + Machine Talent",
    image: "hm-icon",
    imageName: "human",
    itemValue: currentlangjson.Human_plus_Machine_Talent
      ? currentlangjson.Human_plus_Machine_Talent
      : "Human + Machine Talent",
  },
  {
    itemName: "Work Orchestration",
    image: "wo-icon",
    imageName: "work",
    itemValue: currentlangjson.Work_Orchestration ? currentlangjson.Work_Orchestration : "Work Orchestration",
  },
  {
    itemName: "Insights & Intelligence",
    image: "ii-icon",
    imageName: "ii",
    itemValue: currentlangjson.Insights_Driven_and_Intelligence
      ? currentlangjson.Insights_Driven_and_Intelligence
      : "Insights & Intelligence",
  },
  {
    itemName: "Diverse Data",
    image: "dd-icon",
    imageName: "diverse",
    itemValue: currentlangjson.Diverse_Data ? currentlangjson.Diverse_Data : "Diverse Data",
  },
  {
    itemName: "Tools",
    image: "icon-connect",
    imageName: "work",
    itemValue: currentlangjson.Tools ? currentlangjson.Tools : "Tools",
  }
  // { itemName: "IntelOps", image: barchart1, imageName: "bar-chart-2" }
];
export let FoundationTools = [
  currentlangjson.GPH,
  currentlangjson.SAM,
  currentlangjson.OPSNAV,
  currentlangjson.AIDT,
  currentlangjson.ACF,
  currentlangjson.CSS,
];
export const compDetails = {
  "Business Outcomes": { title: "Business Outcomes", description: "", component: "BO" },
  "Human + Machine Talent": {
    title: "Human + Machine Talent",
    description: "Complementing Human Expertise with Machine Workforce and AI Agents",
    component: "HumanMachineTalent",
  },
  "Work Orchestration": {
    title: "Work Orchestration",
    description:
      "Orchestrate work through Robots, AI and Analytics and provide insights to people performing complex work",
    component: "WorkOrchestration",
  },
  "Insights & Intelligence": {
    title: "Insights & Intelligence",
    description: "Decision accelerating insights",
    component: "InsightsIntelligence",
  },
  "Diverse Data": {
    title: "Diverse Data",
    description: "Data driven predictive decisions from diverse sources",
    component: "DiverseData",
  },
  Tools: {
    title: "Tools",
    description: "Tools",
    component: "Tools",
  },
  // "IntelOps": { title: "Intelligent Operations Journey", description: "", component: "IntelOps" },
  // "Intelligent Operations Journey": {
  //   title: "Intelligent Operations Journey",
  //   description: "",
  //   component: "Intelligent Operations Journey",
  // },
  "IOJ Scorecard": {
    title: "IOJ Scorecard",
    description: "",
    component: "IOJ Scorecard",
  },
  "IOJ Scorecard": {
    title: "IOJ Scorecard",
    description: "",
    component: "IOJ Scorecard",
  },
  "Value Realization": {
    title: "Value Realization",
    description: "",
    component: "Value Realization",
  },
  "Maturity Plan": {
    title: "Maturity Plan",
    description: "",
    component: "Maturity Plan",
  },
  // "Initiative Comparison": {
  //   title: "Initiative Comparison",
  //   description: "",
  //   component: "Initiative Comparison"
  // },
  "Journey Initiatives": {
    title: "Journey Initiatives",
    description: "",
    component: "Journey Initiatives",
  },
  // "Deal Report": {
  //   title: "Deal Report",
  //   description: "",
  //   component: "Deal Report",
  // },
};

export let IOJNavigation = [
  {
    itemName: "IOJ Scorecard",
    image: "bullseye",
    imageName: "scorecard",
    itemValue: currentlangjson.IOJ_Scorecard ? currentlangjson.IOJ_Scorecard : "IOJ Scorecard",
  },
  {
    itemName: "Journey Initiatives",
    image: "ji-icon",
    imageName: "bar-chart-2",
    itemValue: currentlangjson.Journey_Initiatives ? currentlangjson.Journey_Initiatives : "Journey Initiatives",
  },
  {
    itemName: "Value Realization",
    image: "vr-icon",
    imageName: "profits",
    itemValue: currentlangjson.Value_Realization ? currentlangjson.Value_Realization : "Value Realization",
  },
  {
    itemName: "Maturity Plan",
    image: "mp-icon",
    imageName: "growth",
    itemValue: currentlangjson.Maturity_Plan ? currentlangjson.Maturity_Plan : "Maturity Plan",
  },
  // { itemName: "Initiative Comparison", image: "icon-hand-plant", imageName: "Initiative", itemValue: currentlangjson.Initiative_Comparison ? currentlangjson.Initiative_Comparison : "Initiative Comparison" },
  // {
  //   itemName: "Deal Report",
  //   image: "icon-hand-plant",
  //   imageName: "Initiative",
  //   itemValue: currentlangjson.Deal_Report ? currentlangjson.Deal_Report : "Deal Report",
  // },
];
export const getPrevMonth = () => {
  let today = new Date();
  let prevMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1).toISOString().slice(0, 10);
  return "1900-01-01";
  // return prevMonth;
};
export const getMonthNumber = (monthvalue) => {
  let monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let monthNumber = monthNames.indexOf(monthvalue);
  // let monthNumber = indexof(monthNames[monthvalue])
  return monthNumber;
};
export const regexForFields = /^[A-Za-z0-9 @ $ % & () \n \- _ , . < > # ? ‘ ” \' \"/ ]+$/;
//export const regexForConnectorField=/^[A-Za-z0-9 @ $ % & () \n \- _ , . ~ ` # $ ! ? ^ + = [ \] \\ { } * : ; ‘ ” < > | \' \"/ ]+$/;
export const regexForConnectorField = /^[^¡¢£¤¥¦§¨©ª«¬®ˉ°±²³´µ¶¸¹º»¼½¾¿ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõö÷øùúûüýþÿ]+$/;
export const regexForConfMasterFields = /^[A-Za-z0-9-&_@ , . ]+$/;
export const regexForCCPUserLoginField = /^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const regexForCCPUsername= /^[a-zA-Z ]+$/;
export const regexForGOCFields = /^[A-Za-z0-9 \- & _ @ # % $ , . ]+$/;
export const regexForSimilarLeading = /^(\d*\.)?\d+$/;
export const regexForDescription = /^[A-Za-z0-9?;:"',.-\s]+$/;
//export const regexForDescription = /^[A-Za-z0-9~`!@#$%^&*(){}[\]_+=?/|\\;:"',.-\s]+$/;
export const regexForTextAreas = /^[A-Za-z0-9.,\n \-  _ \' \"]+$/;
export const regexForTextAreasStoryDemo = /^[A-Za-z0-9-_. ]+$/;
export const regexForAccentureDomainEmail = /^[A-za-z0-9.]+@accenture.com$/;
export const reduxUserProfile = ["View", "", "Add", ""];
export const regexForNumbersOnly = /^[0-9]+$/;
export const regexForPositiveInteger = /^[1-9]\d*$/;
export const regexForNumbersOnlyMTC = /^[0-9]\d*\.?\d*$/;
export const regexForNegativeNumbers = /^-?\d*(\.?\d*)?$/;
export const regexForURL = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const regexForToolURL = /^http[s]?\:\/\/[^\/\s]+(\/.*)?$/;
export const regexForUnit = /^[A-Za-z @ $ % & () \n \- _ , . < > # ? ‘ ” \' \"/ ]+$/;
//export const regexForClickableToolURL=/^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/;
export const regexForVideoDocURL = /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}?[a-zA-Z0-9-.]{2,256}\.(com|in|co|govt|edu|net|org)\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const regexForDomainURL = /^((?!-))(xn--)?[a-z0-9*][a-z0-9-_]{0,61}[a-z0-9]{0,1}\.(xn--)?([a-z0-9\-]{1,61}|[a-z0-9-]{1,30}\.[a-z]{2,})$/;
export const ImageFormats = ["png", "PNG"];
export const commonToolRegex = /[\s\\<>{}]/;
export const regexForStartWithSpecialCharcterinText = /^(?![-|+|@|=].*$).*/;
export const BATOfferingIcons = [
  {
    key: "Marketing",
    iconBlue: MarketingPNG,
    iconBlack: MarketingBlackSVG,
    iconWhite: MarketingWhiteSVG,
  },
  {
    //"offering": "F&A",
    key: "Bill",
    iconBlue: BillPNG,
    iconBlack: F_ABlackSVG,
    iconWhite: F_AWhiteSVG,
  },
  {
    //"offering": "Health",
    key: "Heart",
    iconBlue: HeartPNG,
    iconBlack: HealthBlackSVG,
    iconWhite: HealthWhiteSVG,
  },
  {
    //"offering": "Network",
    key: "Chart",
    iconBlue: ChartPNG,
    iconBlack: NetworkBlackSVG,
    iconWhite: NetworkWhiteSVG,
  },
  {
    //"offering": "Supply Chain",
    key: "Link",
    iconBlue: LinkPNG,
    iconBlack: Supply_ChainBlackSVG,
    iconWhite: Supply_ChainWhiteSVG,
  },
  {
    //"offering": "IS&CO - Intelligent Sales",
    key: "Trend-up",
    iconBlue: TrendUpPNG,
    iconBlack: Sales_CustomerBlackSVG,
    iconWhite: Sales_CustomerWhiteSVG,
  },
  {
    //"offering": "Talent & HR",
    key: "Team",
    iconBlue: TeamPNG,
    iconBlack: Talent_ManagenentBlackSVG,
    iconWhite: Talent_ManagenentWhiteSVG,
  },
  {
    //"offering": "AIO",
    key: "Interactive",
    iconBlue: InteractivePNG,
    iconBlack: Capital_MarketBlackSVG,
    iconWhite: Capital_MarketWhiteSVG,
  },
  {
    //"offering": "Utilities",
    key: "Improvise",
    iconBlue: ImprovisePNG,
    iconBlack: UtilitiesBlackSVG,
    iconWhite: UtilitiesWhiteSVG,
  },
  {
    //"offering": "Insurance",
    key: "Cover",
    iconBlue: CoverPNG,
    iconBlack: InsuranceBlackSVG,
    iconWhite: InsuranceWhiteSVG,
  },
  {
    //"offering": "Procurement",
    key: "Procurement",
    iconBlue: ProcurementPNG,
    iconBlack: Source_ProcurementBlackSVG,
    iconWhite: Source_ProcurementWhiteSVG,
  },
  {
    //"offering": "IS&CO - Customer Operations",
    key: "CustomerOperations",
    iconBlue: CustomerOperationsPNG,
    iconBlack: Customer_OperationsBlackSVG,
    iconWhite: Customer_OperationsWhiteSVG,
  },
  {
    key: "Banking",
    iconBlue: "",
    iconBlack: BankingBlackSVG,
    iconWhite: BankingWhiteSVG,
  },
  {
    key: "Compliance",
    iconBlue: "",
    iconBlack: ComplianceBlackSVG,
    iconWhite: ComplianceWhiteSVG,
  },
  {
    key: "Content_Moderation",
    iconBlue: "",
    iconBlack: Content_ModerationBlackSVG,
    iconWhite: Content_ModerationWhiteSVG,
  },
  {
    key: "Life Sciences",
    iconBlue: "",
    iconBlack: Life_SciencesBlackSVG,
    iconWhite: Life_SciencesWhiteSVG,
  },
  {
    key: "Marketing",
    iconBlue: "",
    iconBlack: MarketingBlackSVG,
    iconWhite: MarketingWhiteSVG,
  },
  {
    key: "Heart 2",
    iconBlue: Heart2PNG,
    iconBlack: "",
    iconWhite: "",
  },
];

// FOR BAT - BELOW ARRAY MAINTAINS THE LIST OF OFFERINGS THAT ARE CURRENTLY ENABLED FOR TAKING THE ASSESSMENT, THE REST WOULD BE DISABLED
export const enabledOfferingsBATForAssessment = ["F&A", "Next Generation Customer Operations", "Sales Services and Operations", "Marketing Ops Value Solutioning"];

// FOR BAT - BELOW ARRAY MAINTAINS THE LIST OF OFFERINGS AND SUB-OFFERINGS FOR WHICH THE MAPPING OF SynOps PARAMETERS TO BAT PARAMETERS IS AVAILABLE.
const parameterMappingAvailableForBATOfferingSubOffering = [
  { offeringName: "F&A", subOfferingName: "Order To Cash" },
  { offeringName: "F&A", subOfferingName: "Procure To Pay" },
  { offeringName: "F&A", subOfferingName: "Record To Report" },
  { offeringName: "F&A", subOfferingName: "Financial Planning & Analysis" },
];

// FOR THE GIVEN OFFERING AND SUB-OFFERING, CHECKS IF THE THOSE ARE AVAILABLE IN THE ABOVE ARRAY. RETURNS `true` IF IT EXISTS ELSE RETURNS `false`
export function isParamMappingAvailableForBATOfferingSubOffering(offeringName, subOfferingName) {
  let isMappingAvailable;

  // IF ONLY OFFERING NAME IS TO BE CHECKED
  if (_.isEmpty(subOfferingName)) {
    isMappingAvailable = !_.isEmpty(
      _.find(parameterMappingAvailableForBATOfferingSubOffering, {
        offeringName: offeringName,
      })
    );
  } else {
    isMappingAvailable = !_.isEmpty(
      _.find(parameterMappingAvailableForBATOfferingSubOffering, {
        offeringName: offeringName,
        subOfferingName: subOfferingName,
      })
    );
  }

  return isMappingAvailable;
}
export const BATDateFormat = "MMM YYYY"; // date format as ex: Apr 2019

export function formatDate(momentObj) {
  return momentObj.format(BATDateFormat);
}

export function validateBATFieldValue(value) {
  return regexForNegativeNumbers.test(value) && !isNaN(value) && !_.startsWith(value, ".") && !_.endsWith(value, ".");
}

export function getFormattedUnitNameBAT(unit) {
  const unitInLowerCase = _.toLower(unit);

  let formattedUnitName = "";
  if (unitInLowerCase === "count" || unitInLowerCase === "quantity") {
    formattedUnitName = "Number";
  } else if (unitInLowerCase === "percentage") {
    formattedUnitName = "%";
  } else if (unitInLowerCase === "k usd") {
    formattedUnitName = unit;
  } else if (unitInLowerCase === "value" || unitInLowerCase === "number") {
    formattedUnitName = "";
  } else if (unitInLowerCase === "value-no.ofdays") {
    formattedUnitName = "Days";
  } else if (unitInLowerCase === "usd") {
    formattedUnitName = "USD";
  } else {
    formattedUnitName = _.upperFirst(unit);
  }
  return formattedUnitName;
}

// FORMATS THE NUMBER AS THOUSANDS (K), MILLIONS (Mn)
export function numberFormatter(num, digitsAfterDecimal) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" }, // symbol for thousand
    { value: 1e6, symbol: "Mn" }, // symbol for million
    { value: 1e9, symbol: "Bn" }, // symbol for billion
    { value: 1e12, symbol: "Tn" }, // symbol for trillion
    // { value: 1E15, symbol: "P" },
    // { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(num) >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digitsAfterDecimal).replace(rx, "$1") + si[i].symbol;
}

// THE DATEPICKERS WOULD ONLY BE ALLOWED SELECT DATE LATER THAN THIS
export const BATAllowedMinDateMoment = moment("2019-09-01 00:00Z");



  export const trycatchAlertPopup = (error) => {
    console.log(error);
    // return window.alert(error.response.data.Message);
  };

// SET DEFAULT TIME FRAME OF 12 MONTHS
const BATDateBeforeOneYear = moment().subtract(12, "months");
export const BATDefaultStartDateMoment = BATDateBeforeOneYear.isAfter(BATAllowedMinDateMoment)
  ? BATDateBeforeOneYear
  : BATAllowedMinDateMoment;
export const BATDefaultEndDateMoment = moment();
export const BATFormattedDefaultStartDate = formatDate(BATDefaultStartDateMoment);
export const BATFormattedDefaultEndDate = formatDate(BATDefaultEndDateMoment);

// CONSTANTS FOR ROUTES SLUGS
const batLandingScreenURLSlug = "bat";
export const ROUTE_CONSTANTS = {
  BAT_LANDING_PAGE: batLandingScreenURLSlug,
  BAT_OFFERING_BENCHMARK_DETAILS: "batView",
  // CLIENT ASSESSMENT SCREEN ROUTES
  BAT_TAKE_ASSESSMENT: `${batLandingScreenURLSlug}/take-assessment`,
  BAT_VIEW_ASSESSMENT: `${batLandingScreenURLSlug}/view-assessment`,
  BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT: `${batLandingScreenURLSlug}/assessment-metric-details`,
  BAT_CLIENT_PERFORMANCE_VIEW: `${batLandingScreenURLSlug}/client-performance-view`,
  BAT_ACCESS_MANAGEMENT: `${batLandingScreenURLSlug}/bat-access-management`,
};
export const formattertwo = new Intl.NumberFormat('en', {
  useGrouping: true
});
export const MorDocInfo=[
  {key:".jpeg",Extension:".jpeg",Style:"img-color",SmallIcon:MorSmallimage,ListIcon:MorListimage,TileIcon:MorTileimage},
  {key:".jpg",Extension:".jpg",Style:"img-color",SmallIcon:MorSmallimage,ListIcon:MorListimage,TileIcon:MorTileimage},
  {key:".giff",Extension:".giff",Style:"img-color",SmallIcon:MorSmallimage,ListIcon:MorListimage,TileIcon:MorTileimage},
  {key:".png",Extension:".png",Style:"img-color",SmallIcon:MorSmallimage,ListIcon:MorListimage,TileIcon:MorTileimage},
  {key:".mp4",Extension:".mp4",Style:"video-color",SmallIcon:MorSmallvideo,ListIcon:MorListvideo,TileIcon:MorTilevideo},
  {key:".mov",Extension:".mov",Style:"video-color",SmallIcon:MorSmallvideo,ListIcon:MorListvideo,TileIcon:MorTilevideo},
  {key:".webm",Extension:".webm",Style:"video-color",SmallIcon:MorSmallvideo,ListIcon:MorListvideo,TileIcon:MorTilevideo},
  {key:".mkv",Extension:".mkv",Style:"video-color",SmallIcon:MorSmallvideo,ListIcon:MorListvideo,TileIcon:MorTilevideo},
  {key:".ogv",Extension:".ogv",Style:"video-color",SmallIcon:MorSmallvideo,ListIcon:MorListvideo,TileIcon:MorTilevideo},
  {key:".3g2",Extension:".3g2",Style:"video-color",SmallIcon:MorSmallvideo,ListIcon:MorListvideo,TileIcon:MorTilevideo},
  {key:".pdf",Extension:".pdf",Style:"pdf-color",SmallIcon:MorSmallpdf,ListIcon:MorListpdf,TileIcon:MorTilepdf},
  {key:"wdAr",Extension:".ppt",Style:"ppt-color",SmallIcon:MorSmallppt,ListIcon:MorListppt,TileIcon:MorTileppt},
  {key:"wdInConfigurator",Extension:".xlsx",Style:"excel-color",SmallIcon:MorSmallexcel,ListIcon:MorListexcel,TileIcon:MorTileexcel},
  {key:"powerbi",Extension:".pbix",Style:"pblx-color",SmallIcon:MorSmallpowerbi,ListIcon:MorListpowerbi,TileIcon:MorTilepowerbi},
  {key:"Word",Extension:".docx",Style:"docx-color",SmallIcon:MorSmallword,ListIcon:MorListword,TileIcon:MorTileword},
  {key:"Invalid",Extension:"",Style:"docs-color",SmallIcon:MorSmallgeneric,ListIcon:MorListgeneric,TileIcon:MorTilegeneric}
];

export const dataAutomationSubMenuOrder = [
  { name: "One-Time Setup", displayName: "", orderSeq: 1 },
  { name: "Configure Source Parameter", displayName: "Metric Automation", orderSeq: 2 },
  { name: "Tool Configurator", displayName: "H&M Tool Configurator", orderSeq: 3 },
  { name: "Build Query", displayName: "", orderSeq: 4 },
  { name: "Connector Field Master Data", displayName: "", orderSeq: 5 },
];

export const displayApprovalFeatureData = [
  { name: "Category Approval", displayName: "Multilevel Category" },
  { name: "CustomTool Approval", displayName: "Custom Tools" },
  { name: "Industry Approval", displayName: "Industry" },
  { name: "Metric", displayName: "Metric Details" },
  { name: "Offering Approval", displayName: "Offering" },
  { name: "OneTimeSetup Approval", displayName: "One Time Setup" },
  { name: "Work Orchestration Step", displayName: "Work Orchestration" },
];

export const RetainleftMenuOptions = [
  {name:"Reports" },
  {name:"Approval" },
  {name:"Connector" },
  {name:"User Trends" },
  {name:"Master" },
  {name:"Master Template Configurator" },
  {name:"Configure Source Application"},
  {name:"Help"},
  {name:"My Deal Profile"},
  {name:"Support"},
  {name:"User Trends"},

]

export const FDAnalyticsUrl = process.env.REACT_APP_FRONTDOOR_ANALYTICS_POWERBI_URL;

export const idleTimedout= parseInt(process.env.REACT_APP_IDLE_TIMED_OUT_NO_OF_MINUTES)* parseInt(process.env.REACT_APP_MINUTES);//30 MINUTES in case of prod
export const popUpidleTimedout=parseInt(process.env.REACT_APP_POPUP_IDLE_TIMED_OUT_NO_OF_MINUTES)* parseInt(process.env.REACT_APP_SECONDS);//5 SECONDS 

export const idTokenstring = 'msal.idtoken';
export const accessTokenstring = 'msal.accessToken';

export const pbiScopes = ['https://analysis.windows.net/powerbi/api/Report.Read.All'];


