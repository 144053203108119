import React, { Component } from 'react';
import {
  BrowserRouter as Router, Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import _ from 'lodash';
import LoadingOverlay from 'react-loading-overlay';
import {
  Widget, addResponseMessage, addLinkSnippet, addUserMessage,
} from 'react-chat-widget';
import Moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from './Shared/Header';
import LandingHeader from './Shared/landingHeader';
import SynOpsHomePage from './LandingPage/synopsHome';
import SideBar from './Shared/SideBar';
import Filters from './Shared/Filter';
import PulseFilter from './Shared/PulseFilter';
import Footer from './Shared/Footer';
import StoryView from './Story/StoryView';
import ViewProject from './ClientSelect/ViewProject';
// import HelpTopics from "./HelpTopic/HelpTopicList";
import Empty from './Shared/empty';
import { PageNotFound } from './Shared/PageNotFound';
import HelpTopics from './HelpTopic/HelpTopicList';
import HelpFeatures from './Configurator/HelpFeatures/HelpFeaturesList';
import AddHelpFeatures from './Configurator/HelpFeatures/AddFeature';
import OverallQCScore from './QCScore/OverallQCScore';
import { PermissionDenied } from './Shared/PermissionDenied';
import {
  Navigation as Navigate,
  compDetails,
  LocalApiBaseUrl,
  IOJNavigation,
  trycatchAlertPopup,
  getMonthNumber,
  getPrevMonth,
  ROUTE_CONSTANTS,
  idTokenstring
} from './Shared/Constant';
import Navigation from './Dashboard/DashboardNavigation';
import ViewInsights from './IOJ/IOJ_ViewInsights';
import ConfViewInsights from './IOJConf/IOJ_ViewInsights';
import RoutesList from './Routes';
import GOC from './SOHome/GOC';
import LandingPage from './LandingPage/landingPage';
import LandingPageAdmin from './LandingPage/landingPageAdmin';
import axiosInstance from './Shared/interceptor';
import logo from './Images/SynOps architecture-01.png';
import InitiativeDeatils from './JourneyInitiative/InitiativeDetails';
import QuickLinks from './Dashboard/QuickLinks';
import ExcelUpload from './Dashboard/ExcelUpload';
import Report from './Report/MasterList';
import Bat from './Bat/Bat';
import BatView from './Bat/BatView';
import ClientPerformanceView from './Bat/ClientPerformanceView';
import ViewAssessment from './Bat/ViewAssessment';
import TakeAssessment from './Bat/TakeAssessment';
import ProvideMetricDetailsForAssessment from './Bat/ProvideMetricDetailsForAssessment';
import BatAccessManagement from './Bat/BatAccessManagement';
import { getDayStatusFromTimestamp } from './Shared/commonFunctions';
import {TRACKING_MODULES, PARAM_NAMES, userTracker, createParamObject} from "./Shared/Common";
// import ReportAdmin from './Reports/ReportAdmin'

import ChartReport from './Report/ChartReport';
import UserList from './User/userList';
import DealList from './Deal/dealList';
import ClientStory from './ClientStory/ClientStory';
import FrontDoorAnalytics from './Analytics/FrontDoorAnalytics';
import OfferingList from './Offering/offeringList';
import LocationList from './Location/locationList';
import MasterList from './MasterScreen/MasterList';
import ConnectorList from './ConnectorScreen/ConnectorList';
import UserTrendsContainer from './UserTrends/UserTrendsContainer';
import MORContainer from './MonthlyOperationalReview/MORAliasContainer';
import Approval from './Approval/MasterList';
// import QueryMasterContainer from './QueryBuilder/QueryMasterContainer'
import GOCForm from './GOCAdmin/GOCForm';
import ConnectorForm from './ConnectorMaster/ConnectorForm';

import { store } from './reducers/configureStore';
import InsightAndIntellegence from './Approval/InsightAndIntellegenceList';
import WorkOrchestation from './Approval/WorkOrchestationList';

import ConfiguratorFilters from './Shared/configuratorFilter';
import ConfiguratorMasterFilters from './Shared/configuratorMasterFilter';
import ClientSelect from './ClientSelect';
import HomePage from './LandingHome/HomePage';
import { SingleChatComponent } from './Shared/SingleChatComponent';
import StaticMessage from './Shared/StaticMessage';
import { InitializeIOJData, InitializeDatas, saveJourneyData } from './actions/JourneyActions';
import { saveClientData } from './actions/ClientAndFilterActions';
import { updateBATFilter } from './actions/BATActions';
import { setSisenseScript } from './actions/appActions'
import RequestAccess from '../src/RequestAccess'
import SynopsLoader from './Shared/SynopsLoader';
// import 'react-chat-widget/lib/styles.css';

let configDetails = {};
const filterValues = {};
let masterFilterValues = {};
// const key = foo.getUniqueId()
class BaseContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      getConfiguratorapicall: false,
      isBoLoaded: false,
      isHMLoaded: false,
      isWOLoaded: false,
      isIILoaded: false, 
      isDDLoaded: false,
      isMPLoaded: false, 
      configDataFull :[],
      loaded:false,
    };
    this.filteComp = React.createRef();
    this.HeaderComp = React.createRef();
    this.clientDetails = {};
    this.state = {
      isorgapiloading: false,
      isBatApiLoading: false,
      isClientStoriesApiloading: false,
      isTabChanged: false,
      BatCount: [],
      userclientCount: '',
      UserOnboardedClients: [],
      FunctionalIndustryOffering: [],
      isClientApiLoading: true,
      ViewAdmin: false,
      headerText: '',
      userRole: {},
      selectedClient: '',
      pulsePageData: [],
      selectedClientId: '',
      isRelatorBlock: '',
      isIojBlock: '',
      isMasterConfigurator: false,
      viewmore: false,
      SODetails: [],
      clientId: '',
      opsgrp: [],
      MaturityPhase: '',
      Display: 'off',
      GOCPermission: null,
      PulsePagePermission: null,
      OperatingGropus: [],
      viewUpload: false,
      clientFilterProp: '',
      isReset: false,
      clientHide: false,
      roledata: [],
      attributes: [],
      fromChild: [],
      isNewDeal: false,
      renderChildren: false,
      showStaticMessage: false,
      pathLocation: '',
      tabDetails: { title: 'Business Outcomes', description: '', component: 'BO' },
      SODisplay: true,
      IOJDisplay: false,
      pathName: '',
      isProfileExist: false,
      headerHoverStatus: false,
    };
    this.sendClientDetails = this.sendClientDetails.bind(this);
    this.SetIOJDetails = this.SetIOJDetails.bind(this);
    this.GetOrgStructure = this.GetOrgStructure.bind(this);
    this.viewMore = this.viewMore.bind(this);
    this.GetQuickLinks = this.GetQuickLinks.bind(this);
    this.GetAdmin = this.GetAdmin.bind(this);
    this.isMasterConfiguratorFilter = this.isMasterConfiguratorFilter.bind(this);
    this.changeHeaderText = this.changeHeaderText.bind(this);
    this.getSelectedRelatorBlock = this.getSelectedRelatorBlock.bind(this);
    this.getSelectedIoj = this.getSelectedIoj.bind(this);
    this.handleData = this.handleData.bind(this);
    this.showStaticMessage = this.showStaticMessage.bind(this);
    this.hideStaticMessage = this.hideStaticMessage.bind(this);
    this.isIOJLoaded = false;
  }

  UNSAFE_componentWillMount() {
    this.GOCPermission();
    this.GetAdmin();
    this.showStaticMessage();
  }

  componentDidMount() {
    $('.widget-floating--state-5').css({'visibility': 'visible'});
    addResponseMessage('Welcome to Synops chat!');
    // function added later
    this.getBatCount();

    this.GetAdmin();
    this.getUserFeature();
    this.getNotifications();
    this.getAllUnits();

    axiosInstance
      .get(
        `${LocalApiBaseUrl}so/GetSOCategories`,
        { params: { LanguageID: store.getState().CurrentFilter.languageData.id } },
        // params: { OrgEntityID: filterClient, OfferingID: filterProcess, LocationID: filterLocations, MonthDT: todayDate }
      )
      .then((response) => {
        if (response.data.length) {
          store.dispatch({
            type: 'GET_SOCATEGORIES',
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
    const { history } = this.props;
    this.setState({ pathName: history.location.pathname });

    const this_ = this;
    $(window).on('scroll', function () {
      if (($('#subnav-header-id').length > 0) && ($('.newBanner').length > 0)) {
        if ($(this).scrollTop() > 128) {
          $('.wrapper').eq(0).addClass('onPageScroll');
          $('.subnav-header, .newBanner').fadeOut('slow');
          $('#back-to-top').fadeIn('slow');
        }
        else {
          $('.wrapper').eq(0).removeClass('onPageScroll');
          $('.subnav-header, .newBanner').fadeIn('slow');
          $('#back-to-top').fadeOut('slow');
        }
      } else {
        if ($('#subnav-header-id').length === 0 && ($('.newBanner').length > 0)) {
          if ($(this).scrollTop() > 60) {
            $('.wrapper').eq(0).addClass('onPageScroll');
            $('.newBanner').fadeOut('slow');
            $('#back-to-top').fadeIn('slow');
          }
          else {
            $('.wrapper').eq(0).removeClass('onPageScroll');
            $('.newBanner').fadeIn('slow');
            $('#back-to-top').fadeOut('slow');
          }
        }
      }
    });
  }

  backToTop = () => {
    $('html, body').animate({
      scrollTop: 0,
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { history, roleReducerData } = this.props;
    const { pathName, filterValues } = this.state;
    const adminSectionFrom = !!(history.location.state && history.location.state.showConfiguratorFilterForAdminView);
    const Details = compDetails['Business Outcomes'];
    if (history.location.pathname === '/HomePage' && filterValues && filterValues.Client) {
      this.setState({
        filterValues: {
          Client: 0, Locations: 0, offering: 0, process: 0, Month: 0,
        },
        selectedClientId: 0,
      });
    }
    if (history.location.pathname !== pathName && !adminSectionFrom) {
      const currentSelectedTab = Navigate.filter((each) => each.itemName === 'Business Outcomes')[0];
      Details.tabName = currentSelectedTab.itemValue;
      store.dispatch({
        type: 'ADD_SELECTED_TAB_DETAILS',
        payload: {
          title: 'Business Outcomes',
          description: '',
          component: 'Business Outcomes',
          tabName: currentSelectedTab.itemValue,
          ioj: false,
        },
      });

      this.setState({
        tabDetails: Details,
        SODisplay: true,
        IOJDisplay: false,
        pathName: history.location.pathname,
      });
    }
    if (
      this.state.ClientId != 0
      && this.state.ClientId
      && (this.state.filterValues.Locations != 0 && this.state.filterValues.Locations)
      && (this.state.filterValues.Month != 0 && this.state.filterValues.Month)
      && (this.state.filterValues.offering != 0 && this.state.filterValues.offering)
      && (this.state.filterValues.process != 0 && this.state.filterValues.process)
      && this.state.Display === 'off'
    ) {
      this.setState({ Display: 'on' });
    }
    if (prevProps.history.location.pathname !== history.location.pathname) {
      const currentSelectedTab = Navigate.filter((each) => each.itemName === 'Business Outcomes')[0];
      Details.tabName = currentSelectedTab.itemValue;
      store.dispatch({
        type: 'ADD_SELECTED_TAB_DETAILS',
        payload: {
          title: 'Business Outcomes',
          description: '',
          component: 'Business Outcomes',
          tabName: currentSelectedTab.itemValue,
          ioj: false,
        },
      });
      this.setState({
        tabDetails: {
          title: 'Business Outcomes',
          description: '',
          tabName: currentSelectedTab.itemValue,
          component: 'BO',
        },
      });
    }
    if (history.location.pathname === '/Dashboard' || history.location.pathname === '/admin/Configurator') {
      if (pathName !== history.location.pathname) {
        this.showStaticMessage();
      }
    }
    if (prevProps.roleReducerData !== roleReducerData && roleReducerData.length) {
      this.setState({ roledata: roleReducerData });
      this.getRoleData();
    }
    if (prevProps.history.location.pathname !== pathName) {
      this.setState({
        pathName: history.location.pathname,
      });
      this.getNotifications();
    }
  }

  getAllUnits = () => {
    axiosInstance
      .get(`${LocalApiBaseUrl}SOFilter/GetAllUnits`, {
        params: { languageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((response) => {
        store.dispatch({
          type: 'SAVE_STANDARD_UNITS',
          payload: response.data,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }

  handleData(data) {
    this.setState({
      attributes: data,
    });
  }

  clearFilter = () => {
    this.setState({
      filterValues: {
        offering: '', process: '', Locations: '', Month: '',
      },
      Display: 'off',
    });
  };

  handleNewUserMessage = (newMessage) => {
    // Now send the message throught the backend API
    // addResponseMessage("Your Message:" + newMessage);
    axiosInstance
      .get(`${LocalApiBaseUrl}Bot/GetAnswer`, {
        params: { question: newMessage },
      })
      .then((response) => {
        addResponseMessage(response.data.Answer);
        addLinkSnippet({
          link: 'https://github.com/Wolox/react-chat-widget',
          target: '_blank',
        });
      });

    // Ask for Feedback
  };

  sendClientDetails(value) {
    this.clientDetails = value;
    // this.props.propsCallback(value);
  }

  GetAdmin() {
    const locationName = this.props.history.location.pathname;
    if (locationName.indexOf('/admin') == 0) {
      this.setState({ headerText: '', ViewAdmin: true });
    } else this.setState({ ViewAdmin: false });
  }

  viewMore() {
    this.setState({ viewmore: true });
  }

  getRoleData = () => {
    // let roleReducerData = _.cloneDeep(this.props.roleReducerData);
    const { roleReducerData } = this.props;

    this.setState({ roleData: roleReducerData, roledata: roleReducerData });
    const GOCPermission = roleReducerData.filter((data) => data.FeatureName == 'GOC Dashboard' && data.Operations.includes('View')).length
      > 0;
    this.setState({ GOCPermission });
    const PulsePagePermission = roleReducerData.filter((data) => data.FeatureName === 'PulsePage')[0];
    this.setState({ PulsePagePermission, GOCPermission });
  };

  async GOCPermission() {
    this.getRoleData();
    this.setState({
      isClientStoriesApiloading: true,
    });
    axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetMyclientStory`, {
        params: { LanguageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((Response) => {
        this.setState({
          userclientCount: Response.data.TotalPublishedClients,
          UserOnboardedClients: Response.data.UserClientMap,
          isProfileExist: Response.data.isProfileExist,
          FunctionalIndustryOffering: Response.data.FunctionalIndustryOffering,
          isClientStoriesApiloading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isClientStoriesApiloading: false,
        });
      });
    this.setState({
      isorgapiloading: true,
    });
    await axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetOrgStructure`, {
        params: { LanguageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((OperatingGropus) => {
        const { saveClientData } = this.props;
        saveClientData(OperatingGropus.data);
        this.setState({
          isorgapiloading: false,
        });
        let GOCPermission = false;
        let viewUpload = false;
        let UserOnboardedClients = [];
        let tempublishClients = [];
        const data = store.getState();
        this.setState({ roleData: data.roleReducers.roleReducers, roledata: data.roleReducers.roleReducers });
        GOCPermission = this.state.roleData.filter((data) => data.FeatureName == 'GOC Dashboard' && data.Operations.includes('View'))
          .length > 0;
        this.setState({ GOCPermission });


        const clientList = OperatingGropus.data.map((client) => {

          if (client.RoleName == 'SuperAdmin' || client.RoleName == 'ClientAdmin') {
            viewUpload = true;
          } else {
            viewUpload = false;
          }
        });
        tempublishClients = OperatingGropus.data.filter((tpc) => tpc.IsEverPublished === true); // ONBOARDED CLIENT
        UserOnboardedClients = tempublishClients.filter((uoc) => uoc.IsFavourite == true);
        tempublishClients = this.getRandomClients(tempublishClients, tempublishClients.length); // FETCHING THE RANDOM 7 CLIENTS
        tempublishClients.forEach((a) => {
          if (!UserOnboardedClients.includes(a) && UserOnboardedClients.length < 4) {
            UserOnboardedClients.push(a);
          } else return;
        });
        if (UserOnboardedClients.length > 4) {
          UserOnboardedClients = this.getRandomClients(UserOnboardedClients, 4);
        }
        this.setState({
          OperatingGropus: OperatingGropus.data,
          viewUpload,
        });

        sessionStorage.setItem('lastLoginTime', OperatingGropus.data[0].lastLoginTime);
      })
      .catch((error) => {
        alert(error);
        this.setState({
          isorgapiloading: false,
        });
      });
  }

  getRandomClients(ClientData, RandomNumber) {
    const RandomClients = new Array(RandomNumber);
    let len = ClientData.length;
    const taken = new Array(len);
    if (RandomNumber > len) throw new RangeError('getRandom: more elements taken than available');
    while (RandomNumber--) {
      const x = Math.floor(Math.random() * len);
      RandomClients[RandomNumber] = ClientData[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }
    return RandomClients;
  }

  getBatCount() {
    this.setState({
      isBatApiLoading: true,
    });
    const requestData = {
      clientCount: 5,
      offeringNames: [],
    };
    axiosInstance
      .post(`${LocalApiBaseUrl}BAT/GetClientSummary`, requestData)
      .then((response) => {
        this.setState({
          ...this.state,
          BatCount: response.data,
          isBatApiLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isBatApiLoading: false,
        });
        alert(error);
      });
  }

  getUserFeature() {
    const { roleReducerData } = this.props;
    this.setState({ roledata: roleReducerData });
  }

  getUserRole() {
    axiosInstance.get(`${LocalApiBaseUrl}User/GetUsers`).then((users) => {
      const allLink = [];
      let viewUpload = false;
      const user = users.data;
      const currentUser = user.filter((x) => x.UserName == sessionStorage.getItem('EnterpriseID'));
      const userRoles = [...new Set(currentUser.map((eachValue) => eachValue.RoleNames))];
      if (userRoles == 'SuperAdmin' || userRoles == 'ClientAdmin') {
        viewUpload = true;
      } else {
        viewUpload = false;
      }
      this.setState({ viewUpload });
    });
  }

  passClientFilter(selectedClient, props) {
    this.setState({ clientFilterProp: props });
    if (this.props.location.pathname !== '/admin/MasterTemplateConfigurator') {
      if (this.filteComp.current != null) {
        for (const ctrl in this.filteComp.current.refs) {
          filterValues[ctrl] = this.filteComp.current.refs[ctrl].options[
            this.filteComp.current.refs[ctrl].selectedIndex
          ].value;
        }
      }

      let clientId;
      if (selectedClient.id != undefined) {
        clientId = selectedClient.id;
      } else if (selectedClient.currentTarget.options != undefined) {
        clientId = selectedClient.currentTarget.options[selectedClient.currentTarget.selectedIndex].value;
      } else {
        clientId = 0;
      }


      let { MaturityPhase } = this.state;
      if (filterValues.Client != clientId && filterValues.Client != undefined) {
        this.setState({ Display: 'off' });
        filterValues.Client = clientId;
        filterValues.Locations = 0;
        filterValues.Month = 0;
        filterValues.offering = 0;
        MaturityPhase = '';
        filterValues.process = 0;
        this.state.headerText = '';
      }
      filterValues.Client = clientId;
      props.setState({ filterValues });
      this.setState({ selectedClientId: clientId, MaturityPhase, selectedClient });
    } else {
      let clientId;
      // clientId = selectedClient.currentTarget.options[selectedClient.currentTarget.selectedIndex].value;
      if (selectedClient.id != undefined) {
        clientId = selectedClient.id;
      } else if (selectedClient.currentTarget.options != undefined) {
        clientId = selectedClient.currentTarget.options[selectedClient.currentTarget.selectedIndex].value;
      } else {
        clientId = 0;
      }
      filterValues.Client = clientId;
      masterFilterValues.Client = clientId;
      this.setState({ selectedClientId: clientId });
    }

    this.setState({ isReset: false });
  }

  showStaticMessage() {
    const locationName = this.props.history.location.pathname;
    if (locationName.indexOf('/Dashboard') == 0) {
      this.setState({
        showStaticMessage: true,
        pathLocation: locationName,
      });
    } else if (locationName.indexOf('/admin/Configurator') == 0) {
      this.setState({
        showStaticMessage: true,
        pathLocation: locationName,
      });
    }
  }

  hideStaticMessage() {
    this.setState({ showStaticMessage: false });
  }

  callConfiguratorAPI = async (filterValues, soCategoryID, isbaseCall) =>{    

    isbaseCall = isbaseCall == undefined ? false : isbaseCall;
    soCategoryID = soCategoryID == undefined ? 0 : soCategoryID;
   
    if (filterValues.Client && filterValues.Locations && filterValues.Month && filterValues.process) {            
      await axiosInstance
        .get(`${LocalApiBaseUrl}Configurator/GetConfiguratorData`, {
          params: {
            OrgEntityID: filterValues.Client,
            LocationID: filterValues.Locations,
            MonthDT: filterValues.Month,
            OfferingID: filterValues.process,
            SOCategoryID: soCategoryID
          },
        })
        .then((response) => {
          var cnt = 0;
          if(soCategoryID == 0 || soCategoryID == 1){
            store.dispatch({
              type: "INITIALISE_BO",
              payload: response.data[cnt].TabData,              
            });

            store.dispatch({
              type: "LastPublishedDate",
              payload: response.data[0].LastPublishedDate,
            });

            if(soCategoryID == 0)
                cnt++;

            this.setState({ isBoLoaded: true });
          }
          if(soCategoryID == 0 || soCategoryID == 2){
            store.dispatch({
              type: "INITIALISE",
              payload: response.data[cnt].TabData,
            });

            if(soCategoryID == 0)
                cnt++;

            this.setState({ isHMLoaded: true });
          }
          if(soCategoryID == 0 || soCategoryID == 4){
            store.dispatch({
              type: "INITIALISEINSIGHT",
              payload: response.data[cnt].TabData,
            });
            
            if(soCategoryID == 0)
                cnt++;

            this.setState({ isIILoaded: true });
          }
          if(soCategoryID == 0 || soCategoryID == 5){
            store.dispatch({
              type: "INITIALISEDIVERSE",
              payload: response.data[cnt].TabData,
            });
            
            if(soCategoryID == 0)
                cnt++;

            const Client = this.state.selectedClientId;

            const { SelectedFilter } = this.props;
            if (response.data && Client && SelectedFilter.ProcessId && SelectedFilter.LocationId && SelectedFilter.Month) {
              // BAT-DiveseDta API CALL FOR GETTING THE STATUS FOR THE BAT DATA //               
              axiosInstance
                .get(`${LocalApiBaseUrl}BAT/GetDiverseDataFetchSatus`, {
                  params: {
                    OrgEntityID: Client,
                    OfferingID: SelectedFilter.ProcessId,
                    LocationID: SelectedFilter.LocationId,
                    MonthDT: SelectedFilter.Month,
                    LanguageID: store.getState().CurrentFilter.languageData.id,
                  },
                })
                .then((response) => {
                  const { FetchFromBAT } = response.data;
                  const { IsToggleGrayedOut } = response.data;
                  const { ConfiguratorID } = response.data;
                  const { ID } = response.data;
                  const { ShowBannerMessage } = response.data;
                  const { MonthToShowOnBannerMessage } = response.data;
                  this.setState({
                    showLoaderOnPage: false,
                    FetchFromBAT,
                    IsToggleGrayedOut,
                    ConfiguratorID,
                    ID,
                    ShowBannerMessage,
                    MonthToShowOnBannerMessage,
                  });
                  if (response) {
                    store.dispatch({
                      type: 'FETCHFROMBAT',
                      payload: this.state.FetchFromBAT,
                    });
                    store.dispatch({
                      type: 'ISTOGGLEGRAYEDOUT',
                      payload: this.state.IsToggleGrayedOut,
                    });
                    store.dispatch({
                      type: 'CONFIGURATORID',
                      payload: this.state.ConfiguratorID,
                    });
                    store.dispatch({
                      type: 'ID',
                      payload: this.state.ID,
                    });
                    store.dispatch({
                      type: 'SHOWBANNERMESSAGE',
                      payload: this.state.ShowBannerMessage,
                    });
                    store.dispatch({
                      type: 'MONTHSHOWBANNERMESSAGE',
                      payload: this.state.MonthToShowOnBannerMessage,
                    });
                  }
                })
                .catch((error) => {
                  window.alert(error.response.data.Message);
                  trycatchAlertPopup(error);
                  this.setState({ showLoaderOnPage: false });
                });
            }
            else
            {
              this.setState({ showLoaderOnPage: false });
            }
            this.setState({ isDDLoaded: true });
          }
          
          if(soCategoryID == 0 || soCategoryID == 3){
            store.dispatch({
              type: "INITIALISEWO",
              payload: response.data[cnt].TabData,
            });
            
            if(soCategoryID == 0)
                cnt++;

            this.setState({ isWOLoaded: true });
          }
          if(soCategoryID == 0 || soCategoryID == 7){
            store.dispatch({
              type: "INITIALISEMaturity",
              payload: response.data[cnt].MaturityPlanTabData,
            });

            this.setState({ isMPLoaded: true });
          }

          if(isbaseCall){
            this.setState({ isApiSuccess: true, getConfiguratorapicall: false  }, async () => {
              let goupTab = soCategoryID;
              while(goupTab < 7){
                goupTab++;     

                if(goupTab != 6)
                  await this.callConfiguratorAPI(filterValues, goupTab);     
              }

              goupTab = soCategoryID;
              while(goupTab > 1){
                goupTab--;    
                if(goupTab != 6)
                  await this.callConfiguratorAPI(filterValues, goupTab);
              }
            });
          }
          
        })
        .catch((error) => {
          trycatchAlertPopup(error);
          this.setState({ isApiSuccess: false, getConfiguratorapicall: false  });
        });
    }
  }


  // bipin added
  GetOrgStructure(filterValues) {
    const { history } = this.props;
    this.setState({ isIOJLoaded: false });
    const adminSectionFrom = !!(history.location.state && history.location.state.showConfiguratorFilterForAdminView);
    if (filterValues.Client && filterValues.Locations && filterValues.process) {
      axiosInstance
        .get(`${LocalApiBaseUrl}SOFilter/GetDealInfo`, {
          params: {
            OrgEntityID: filterValues.Client,
            LocationId: filterValues.Locations,
            OfferingId: filterValues.process,
            LanguageID: store.getState().CurrentFilter.languageData.id,
          },
        })
        .then((response) => {
          if(response.data[0] && response.data[0].DealID){
            store.dispatch({
              type: 'SAVE_DEALID',
              payload: response.data[0].DealID,
            });
          }
          const opsgrp = response.data;
          const { BATOfferingName } = response.data[0];
          const { BATSubOfferingName } = response.data[0];
          const { BATClientName } = response.data[0];
          const { BATOfferingType } = response.data[0];
          
          this.setState({ opsgrp, MaturityPhase: opsgrp[0].MaturityPhase_En, isNewDeal: opsgrp[0].IsNew });

          this.setState({
            renderChildren: true, BATClientName, BATSubOfferingName, BATOfferingName, BATOfferingType,
          });
          // ADDING DATA FOR BAT-DiveseData functionality TO THE REDUX FOR UPDATING THE OFFERING,SUBOFFERING AND OFFRING TYPE//
          const updateBATFilterData = {
            BATClientName: this.state.BATClientName,
            BATSubOfferingName: this.state.BATSubOfferingName,
            BATOfferingName: this.state.BATOfferingName,
            BATOfferingType: this.state.BATOfferingType,
          };

          this.props.updateBATFilter(updateBATFilterData);
        })
        .catch((error) => {
          trycatchAlertPopup(error);
        });

      if (
        filterValues.Client
        && filterValues.Locations
        && filterValues.process
        && filterValues.Month !== '0'
        && filterValues.Month
      ) {
        this.setState({ getConfiguratorapicall: true, 
          isBoLoaded: false,
          isHMLoaded: false,
          isWOLoaded: false,
          isIILoaded: false,
          isDDLoaded: false,
          isMPLoaded: false,  });
        if (this.props.history.location.pathname === '/admin/Configurator' || adminSectionFrom) {          
          store.dispatch(InitializeDatas({ isGetData: false }));

          var tab = this.props.tabData != undefined ? this.props.tabData.title : "";
          var tabID = 0;
          switch(tab){
                case "Business Outcomes":
                  tabID = 1;
                  break;
                case "Human + Machine Talent":
                  tabID = 2;
                  break;
                case "Work Orchestration":
                  tabID = 3;
                  break;
                case "Insights & Intelligence":
                  tabID = 4;
                  break;
                case "Diverse Data":
                  tabID = 5;
                  break;
                case "Maturity Plan":
                  tabID = 7;
                  break;
          }

          this.callConfiguratorAPI(filterValues, tabID, true);          
                

              

              axiosInstance
                .get(`${LocalApiBaseUrl}Configurator/GetRecordStatus`, {
                  params: {
                    OrgEntityID: filterValues.Client,
                    LocationID: filterValues.Locations,
                    MonthDT: filterValues.Month,
                    OfferingID: filterValues.process,
                  },
                })
                .then((response) => {
                  if (response.data.length) {
                    store.dispatch({
                      type: 'SAVE_GET_RECORD_STATUS',
                      payload: response.data,
                    });
                  }
                });
              axiosInstance
                .get(`${LocalApiBaseUrl}Configurator/GetQualityScoreAutomationSummaryData`, {
                  params: {
                    OrgEntityID: filterValues.Client,
                    LocationID: filterValues.Locations,
                    MonthDT: filterValues.Month,
                    OfferingID: filterValues.process,
                    LanguageID: store.getState().CurrentFilter.languageData.id,
                  },
                })
                .then((response) => {
                  if (response.data.length) {
                  }
                  store.dispatch({
                    type: 'SAVE_QUALITY_SCORE_AUTOMATION_SUMMARY_DATA',
                    payload: response.data,
                  });
                })
                .catch((error) => {
                  trycatchAlertPopup(error);
                });
              const data = {
                OrgEntityID: filterValues.Client,
                LocationID: filterValues.Locations,
                MonthDT: filterValues.Month,
                OfferingID: filterValues.process,
                LanguageID: store.getState().CurrentFilter.languageData.id,
                IsConfigurator: history.location.pathname === '/admin/Configurator',
              };
              store.dispatch(saveJourneyData({ ConfiguratorID: '', IOJGlidePathAllModels: [], IsIOJCategory: false }));
           
          this.setState({ Display: 'on' });
        } else {
          this.setState({ Display: 'on' });
        }
      }
    }
  }

  GetQuickLinks() {
    axiosInstance.get(`${LocalApiBaseUrl}SO/GetQuickLinksInfo`).then((quickLinks) => {
      const quicklink = quickLinks.data;

      const link = quicklink.map((x) => (
        <li>
          <a href={x.LinkUrl}>
            <span className="popimg">
              <img src={x.LinkImage} alt="" />
            </span>
            {' '}
            <span>{x.LinkName}</span>
          </a>
        </li>
      ));

      this.setState({ link });
    });
  }

  // bipin added
  SetIOJDetails(e) {
    configDetails = e;
    this.props.propsCallback(e);
  }

  changeHeaderText(text, text2) {
    this.setState({ headerText: text, isRelatorBlock: text2 });
  }

  getSelectedRelatorBlock(text) {
    this.setState({ isRelatorBlock: text });
  }

  getPulsePageData = (val) => {
    this.setState({
      pulsePageData: val.pulseData,
      PulseSubOffering: val.SubOffering,
      PulseofferingId: val.offeringId,
      PulseOffering: val.Offering,
    });
  };

  getSelectedIoj(text) {
    this.setState({ isIojBlock: text });
  }

  isMasterConfiguratorFilter(text) {
    this.setState({ isMasterConfigurator: true });
  }

  displayOFf = () => {
    const { filterValues } = this.state;
    filterValues.Locations = '';
    filterValues.Month = '';
    filterValues.offering = '';
    filterValues.process = '';
    this.setState({ Display: 'teest', filterValues });
  };

  applyFilters = (filterValues) => {
    const { ClientData } = this.props;
    if (store.getState().CurrentFilter.Tab.title === 'Journey Initiatives') {
      this.setState({ Display: 'on' });
      if (filterValues != undefined) {
        filterValues.Client = ClientData.id;
      } else {
        filterValues = {};
        for (const ctrl in this.filteComp.current.refs) {
          filterValues[ctrl] = this.filteComp.current.refs[ctrl].options[
            this.filteComp.current.refs[ctrl].selectedIndex
          ].value;
        }
        filterValues.Client = ClientData.id;
      }
      this.setState({ filterValues, ClientId: filterValues.Client, isMasterConfigurator: false });
      // this.GetOrgStructure(filterValues);
    } else if (
      filterValues.offering !== '0'
        && filterValues.offering
        && filterValues.process !== '0'
        && filterValues.process
        && filterValues.Locations !== '0'
        && filterValues.Locations
        && filterValues.Month !== '0'
        && filterValues.Month
    ) {
      this.setState({ Display: 'off' });

      if (filterValues != undefined) {
        filterValues.Client = ClientData.id;
      } else {
        filterValues = {};
        for (const ctrl in this.filteComp.current.refs) {
          filterValues[ctrl] = this.filteComp.current.refs[ctrl].options[
            this.filteComp.current.refs[ctrl].selectedIndex
          ].value;
        }
        filterValues.Client = ClientData.id;
      }

      this.setState({ filterValues });
      this.setState({ ClientId: filterValues.Client });
      this.setState({ isMasterConfigurator: false });

      if (
        filterValues.Client > 0
          && filterValues.Locations > 0
          && filterValues.offering > 0
          && filterValues.process > 0

      ) {
        this.GetOrgStructure(filterValues);
      }
    } else {
      this.setState({ Display: 'no' });
    }
  };

  disableDisplay = () => {
    this.setState({ Display: 'off' });
  };

  displayOFf = () => {
    const { filterValues } = this.state;
    filterValues.Locations = '';
    filterValues.Month = '';
    filterValues.offering = '';
    filterValues.process = '';
    this.setState({ Display: 'teest', filterValues });
  };

  applyMTCFilters = (filter) => {
    this.setState({ masterFilterValues: filter });
  };

  applyOfferingFilters = (masterFilterValues) => {
    //  let headerClientCtrl = this.HeaderComp.current.refs.ClientDropdown;
    const { ClientData } = this.props;
    if (masterFilterValues != undefined) {
      // masterFilterValues.Client = headerClientCtrl.options[headerClientCtrl.selectedIndex].value;
    } else {
      masterFilterValues = {};
      for (const ctrl in this.filteComp.current.refs) {
        masterFilterValues[ctrl] = this.filteComp.current.refs[ctrl].options[
          this.filteComp.current.refs[ctrl].selectedIndex
        ].value;
      }
      masterFilterValues.Client = ClientData.id;
    }

    this.setState({ masterFilterValues });

    if (masterFilterValues.offering != 0 && masterFilterValues.process != 0) {
      this.setState({ Display: 'on' });
    } else {
      this.setState({ Display: 'no' });
    }
  };

  applyReportFilters = () => {
    const { ClientData } = this.props;
    // let headerClientCtrl;
    if (masterFilterValues != undefined) {
      masterFilterValues.Client = ClientData.id;
    } else {
      masterFilterValues = {};
      for (const ctrl in this.filteComp.current.refs) {
        masterFilterValues[ctrl] = this.filteComp.current.refs[ctrl].options[
          this.filteComp.current.refs[ctrl].selectedIndex
        ].value;
      }
      masterFilterValues.Client = ClientData.id;
    }
  };

  onTabChange = (value) => {
    let pageName = '';

    if(this.props.history.location.pathname === "/Dashboard") {
      switch (value) {
        case "Business Outcomes":
          pageName = TRACKING_MODULES.APPLIED_NOW_BUSINESS_OUTCOMES;
          break;
        case "Human + Machine Talent":
          pageName=TRACKING_MODULES.APPLIED_NOW_HUMAN_MACHINE_TALENT;
          break;
        case "Work Orchestration":
          pageName=TRACKING_MODULES.APPLIED_NOW_WORK_ORCHESTRATION;
          break;
        case "Insights & Intelligence":
          pageName=TRACKING_MODULES.APPLIED_NOW_INSIGHT_INTELLIGENCE;
          break;
        case "Diverse Data":
          pageName=TRACKING_MODULES.APPLIED_NOW_DIVERSE_DATA;
          break;
        case "Journey Initiatives":
          pageName=TRACKING_MODULES.APPLIED_NOW_JOURNEY_INITIATIVES;
          break;
        case "Value Realization":
          pageName=TRACKING_MODULES.APPLIED_NOW_VALUE_REALIZATION;
          break;
        case "Maturity Plan":
          pageName=TRACKING_MODULES.APPLIED_NOW_MATURITY_PLAN;
      }

           
    userTracker(
      pageName,
      false,
      null,
      null,
      createParamObject(
        PARAM_NAMES.OFFERING,
        this.props.SelectedFilter.OfferingName
      ),
      createParamObject(
        PARAM_NAMES.SUB_OFFERING,
        store.getState().CurrentFilter.Common.SubOfferingName
      ),
      createParamObject(
        PARAM_NAMES.LOCATION,
        store.getState().CurrentFilter.Location.locationName
      ),
      createParamObject(
        PARAM_NAMES.MONTH,
        this.state.filterValues.Month
      ),
    );
    }
    else if(this.props.history.location.pathname === "/admin/Configurator") {
      switch (value) {
        case "Business Outcomes":
          pageName = TRACKING_MODULES.CONFIGARATOR_BUSINESS_OUTCOMES;
          break;
        case "Human + Machine Talent":
          pageName=TRACKING_MODULES.CONFIGARATOR_HUMAN_MACHINE_TALENT;
          break;
        case "Work Orchestration":
          pageName=TRACKING_MODULES.CONFIGARATOR_WORK_ORCHESTRATION;
          break;
        case "Insights & Intelligence":
          pageName=TRACKING_MODULES.CONFIGARATOR_INSIGHT_INTELLIGENCE;
          break;
        case "Diverse Data":
          pageName=TRACKING_MODULES.CONFIGARATOR_DIVERSE_DATA;
          break;
        case "Journey Initiatives":
          pageName=TRACKING_MODULES.CONFIGARATOR_JOURNEY_INITIATIVES;
          break;
        case "Maturity Plan":
          pageName=TRACKING_MODULES.CONFIGARATOR_MATURITY_PLAN;
      }

           
    userTracker(
      pageName,
      false,
      null,
      null,
      createParamObject(
        PARAM_NAMES.OFFERING,
        this.props.SelectedFilter.OfferingName
      ),
      createParamObject(
        PARAM_NAMES.SUB_OFFERING,
        store.getState().CurrentFilter.Common.SubOfferingName
      ),
      createParamObject(
        PARAM_NAMES.LOCATION,
        store.getState().CurrentFilter.Location.locationName
      ),
      createParamObject(
        PARAM_NAMES.MONTH,
        this.state.filterValues.Month
      ),
    );
    }
   

    const parsedResponse = store.getState().CurrentFilter.SOCategories;
    this.setState({ isTabChanged: true });
    const { history } = this.props;
    const Details = compDetails[value];
    if (
      value === 'Journey Initiatives'
      || value === 'IOJ Scorecard'
      || value === 'Value Realization'
      || value === 'Maturity Plan'
      || value === 'Initiative Comparison'
      || value === 'Deal Report'
    ) {
      const currentSelectedTab = IOJNavigation.filter((each) => each.itemName === value)[0];
      Details.tabName = currentSelectedTab.itemValue;
      store.dispatch({
        type: 'ADD_SELECTED_TAB_DETAILS',
        payload: {
          title: value,
          description: '',
          component: value,
          tabName: currentSelectedTab.itemValue,
          ioj: true,
        },
      });
      this.setState({ tabDetails: Details, SODisplay: false, IOJDisplay: true });
    } else {
      // if (history.location.pathname !== "/admin/MasterTemplateConfigurator") {
      const Segregation = parsedResponse.filter((boDetails) => boDetails.SOCategoryName_En === value);
      const currentSelectedTab = Navigate.filter((each) => each.itemName === value)[0];
      Details.tabName = currentSelectedTab.itemValue;
      store.dispatch({
        type: 'ADD_SELECTED_TAB_DETAILS',
        payload: {
          title: value,
          description: '',
          component: '',
          ioj: false,
          tabName: currentSelectedTab.itemValue,
        },
      });

      this.setState({
        tabDetails: Details,
        SODisplay: true,
        IOJDisplay: false,
        SODetails: Segregation,
      });
    }
    setTimeout(
      () => {
        this.setState({ isTabChanged: false });
      },
      1000,
    );
  };

  handleSubHeaderOpen = (data) => {
  };

  handleSubHeaderClose = (data) => {
  };

  getNotifications() {
    const { languageData } = this.props;
    const isAnyNewNotification = false;

    axiosInstance
      .get(`${LocalApiBaseUrl}Notification/GetNotifications?IsRead=false&IsMore=false`, {
        params: {
          LanguageID: languageData.id,
        },
      })
      .then((response) => {
        const responseData = response.data;
        const newNotifiactionList = _.filter(responseData, ['IsRead', false]);

        const notifications = _.map(responseData, (eachNotification) => ({
          ...eachNotification,
          receivedDayText: getDayStatusFromTimestamp(eachNotification.UpdatedTS),
        }));
        const payloadData = { notifications, newNotificationsData: { hasNewNotification: !!newNotifiactionList.length, notificationCount: newNotifiactionList.length } };
        store.dispatch({
          type: 'NOTIFICATION_DATA',
          payload: payloadData,
        });
      })
      .catch((error) => {
        trycatchAlertPopup(error);
      });
  }
  getMyClientStories = () =>{
    axiosInstance
      .get(`${LocalApiBaseUrl}SO/GetMyclientStory`, {
        params: { LanguageId: store.getState().CurrentFilter.languageData.id },
      })
      .then((Response) => {
        this.setState({
          userclientCount: Response.data.TotalPublishedClients,
          UserOnboardedClients: Response.data.UserClientMap,
          isProfileExist: Response.data.isProfileExist,
          FunctionalIndustryOffering: Response.data.FunctionalIndustryOffering,
          isClientStoriesApiloading: false,
        });
      })
      .catch((error) => {
        this.setState({
          isClientStoriesApiloading: false,
        });
      });
  }

  render() {
    const currentURLPath = this.props.history.location.pathname;
    const {
      tabDetails, SODisplay, IOJDisplay, isTabChanged, isorgapiloading
    } = this.state;
    const {
      location, CurrentFilter, tabData, history,
    } = this.props;
    let isActiveBATScreen = false;
    if (
      currentURLPath.startsWith(`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`)
      || currentURLPath.startsWith(`/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`)
      || currentURLPath.startsWith(`/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`)
      || currentURLPath.startsWith(`/${ROUTE_CONSTANTS.BAT_TAKE_ASSESSMENT}`)
      || currentURLPath.startsWith(`/${ROUTE_CONSTANTS.BAT_VIEW_ASSESSMENT}`)
      || currentURLPath.startsWith(`/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`)
      || currentURLPath.startsWith(`/${ROUTE_CONSTANTS.BAT_ACCESS_MANAGEMENT}`)
    ) {
      isActiveBATScreen = true;
    }
    const adminSectionFrom = !!(history.location.state && history.location.state.showConfiguratorFilterForAdminView);
    const homeAdmin = location.pathname === '/admin/Home'
      || location.pathname.startsWith('/admin/Master/')
      || location.pathname.startsWith('/admin/Connector')
      || location.pathname.startsWith('/admin/Approval')
      || location.pathname.startsWith('/admin/Report')
      || location.pathname.startsWith('/admin/UserTrends')
    return (
      <>
        {isTabChanged || (isorgapiloading && location.pathname !== '/HomePage') ? (          
          <LoadingOverlay fadeSpeed={0} spinner={<SynopsLoader />} active />
        ) : (
          ''
        )}
        
        
        <Route exact path="/chat" render={() => <SingleChatComponent />}/>
        <div id="sisenseApp"/>
        <div  
        className={this.props.location.pathname.startsWith('/HomePage') ? 
        'landingHome' : this.props.location.pathname.startsWith('/FrontdoorAnalytics') 
        ? 'nolandingHome frontdoorAnalytics' : this.props.location.pathname.startsWith('/chat') ? null : 'nolandingHome'}>
          <Route exact path="/" render={() => <Redirect to="/HomePage" />} />
          <Route exact path="/admin" render={() => <Redirect to="/admin/Home" />} />
          {!this.props.location.pathname.startsWith('/HomePage')
          && !this.props.location.pathname.startsWith('/GOC')
          && !this.props.location.pathname.startsWith('/chat')
          && !this.props.location.pathname.startsWith(
            '/Home',
          )  ? (
            <Header
              pulseData={this.state.pulsePageData}
              displayOFf={this.displayOFf}
              OperatingGropus={this.state.OperatingGropus}
              prevSelectedClient={this.state.selectedClient}
              history={this.props.history}
              browserHistory={history}
              ref={this.HeaderComp}
              isMasterConfigurator={this.state.isMasterConfigurator}
              isReset={this.state.isReset}
              headerText={this.state.headerText}
              // OperatingGropus={this.state.OperatingGropus}
              selectedFilter={(value) => {
                this.passClientFilter(value, this);
              }}
              userRole={this.props.userRole}
              opsgrp={this.state.opsgrp}
              filterDetails={this.state.filterValues}
              subHeaderOpenCallback={this.handleSubHeaderOpen}
              subHeaderCloseCallback={this.handleSubHeaderClose}
              onTabChange={this.onTabChange}
            />
            ) : null}

          {/* this.props.location.pathname != "/HomePage" || */
          this.props.location.pathname == '/Home' /* ||
          this.props.location.pathname == "/clientselect" */ ? (
            <LandingHeader
              clientHide={this.state.clientHide}
              ref={this.HeaderComp}
              isMasterConfigurator={this.state.isMasterConfigurator}
              isReset={this.state.isReset}
              headerText={this.state.headerText}
              isorgapiloading={this.state.isorgapiloading}
              OperatingGropus={this.state.OperatingGropus}
              selectedFilter={(value) => {
                this.passClientFilter(value, this);
              }}
            />
            ) : null
}
          {this.props.location.pathname === '/admin/GOCForm' ? (
            <SideBar
              userRole={this.props.userRole}
              history={this.props.history}
              ViewAdmin={this.state.ViewAdmin}
              masterFilterValues={this.state.masterFilterValues}
              opsgrp={this.state.opsgrp}
              view={this.viewMore}
              ExcelView={this.state.viewUpload}
              linkValues={this.state.link}
              onQCLinkClick={this.GetQuickLinks}
              filterDetails={this.state.filterValues}
              location={this.props.location}
              onTabChange={this.onTabChange}
            />
          ) : null}
          {this.props.location.pathname === '/admin/ConnectorForm' ? (
            <SideBar
              history={this.props.history}
              userRole={this.props.userRole}
              masterFilterValues={this.state.masterFilterValues}
              ViewAdmin={this.state.ViewAdmin}
              opsgrp={this.state.opsgrp}
              view={this.viewMore}
              ExcelView={this.state.viewUpload}
              linkValues={this.state.link}
              onQCLinkClick={this.GetQuickLinks}
              filterDetails={this.state.filterValues}
              location={this.props.location}
              onTabChange={this.onTabChange}
            />
          ) : null}

          <div
            className={`${
              this.props.location.pathname != '/HomePage'
                ? !adminSectionFrom && homeAdmin
                  ? 'wrapper home-admin'
                  : adminSectionFrom
                    ? 'wrapper configurator-admin'
                    : 'wrapper'
                : 'wrapper landingHome'
            }`}
          >
            {!this.props.location.pathname.startsWith('/HomePage')
            && !this.props.location.pathname.startsWith('/OverallQCScore')
            && !this.props.location.pathname.startsWith('/GOC')
            /* this.props.location.pathname != "/clientselect" && */
            && !this.props.location.pathname.startsWith('/landing')
            && !this.props.location.pathname.startsWith('/SynOpsDemo')
            && !this.props.location.pathname.startsWith('/ClientStory')
            && !this.props.location.pathname.startsWith('/FrontdoorAnalytics')
            /* this.props.location.pathname != "/helptopics" && */

            && !this.props.location.pathname.startsWith('/helpfeatures')
            && !this.props.location.pathname.startsWith('/addfeature')
            && !this.props.location.pathname.startsWith('/viewclientproject')
            && !this.props.location.pathname.startsWith('/OperationalReviews')
            && !this.props.location.pathname.startsWith('/chat')
            && !this.props.location.pathname.startsWith('/RequestAccess')
            && !isActiveBATScreen ? (
              <SideBar
                userRole={this.props.userRole}
                history={this.props.history}
                clearFilter={this.clearFilter}
                ViewAdmin={this.state.ViewAdmin}
                location={this.props.location}
                opsgrp={this.state.opsgrp}
                view={this.viewMore}
                GOCPermission={this.state.GOCPermission}
                ExcelView={this.state.viewUpload}
                linkValues={this.state.link}
                onQCLinkClick={this.GetQuickLinks}
                filterDetails={this.state.filterValues}
                onTabChange={this.onTabChange}
                masterFilterValues={this.state.masterFilterValues}
              />
              ) : null }
            {this.state.GOCPermission && this.props.location.pathname == '/GOC' ? (
              <Route
                exact
                path="/GOC"
                render={() => <GOC />}
              />
            ) : null}
            {!this.state.GOCPermission && this.state.GOCPermission != null && this.props.location.pathname == '/GOC' ? (
              <PermissionDenied roleData={this.state.roleData} />
            ) : null}
            <div
              className={
                this.props.location.pathname.startsWith('/HomePage')
                /* this.props.location.pathname == "/clientselect" || */
                || this.props.location.pathname.startsWith('/landing')
                || this.props.location.pathname.startsWith('/SynOpsDemo')
                || this.props.location.pathname.startsWith('/ClientStory')
                || this.props.location.pathname.startsWith('/FrontdoorAnalytics')
                /* this.props.location.pathname == "/helptopics" || */
                || this.props.location.pathname.startsWith('/helpfeatures')
                || this.props.location.pathname.startsWith('/addfeature')
                || this.props.location.pathname.startsWith('/OverallQCScore')
                || this.props.location.pathname.startsWith('/viewclientproject')
                || this.props.location.pathname.startsWith('/OperationalReviews')
                || this.props.location.pathname.startsWith('/RequestAccess')
                || isActiveBATScreen
                  ? 'noSide main-container'
                  : 'main-container'
              }
            >
              {/* <Route path="/QL" render={() => { return <QuickLinks /> }} />

                        {this.props.location.pathname != "/GOC" && (this.props.location.pathname != "/QL") ? <Filters ref={this.filteComp} filterClient={filterValues.Client} applyFilters={this.applyFilters} MaturityPhase={this.state.MaturityPhase} /> : null} */}
              {this.props.location.pathname == '/QL'
              || this.props.location.pathname == '/Viewinsights'
              || this.props.location.pathname == '/ClientStory'
              || this.props.location.pathname == '/Dashboard' ? (
                <div>
                  <Filters
                    path={this.props.location.pathname}
                    ref={this.filteComp}
                    filterClient={filterValues.Client}
                    selectedClientId={this.state.selectedClientId}
                    changeHeaderText={this.changeHeaderText}
                    getSelectedRelatorBlock={this.getSelectedRelatorBlock}
                    getSelectedIoj={this.getSelectedIoj}
                    applyFilters={this.applyFilters}
                    MaturityPhase={this.state.MaturityPhase}
                    isIOJLoaded={this.state.isIOJLoaded}
                    OperatingGropus={this.state.OperatingGropus}
                    subHeaderOpenCallback={this.handleSubHeaderOpen}
                    subHeaderCloseCallback={this.handleSubHeaderClose}
                    onTabChange={this.onTabChange}
                    isTabChanged={this.state.isTabChanged}
                    {...this.props}
                  />
                  {this.state.showStaticMessage && this.state.OperatingGropus.length && (
                    <StaticMessage
                      show={this.state.showStaticMessage}
                      pathLocation={this.props.location.pathname}
                      hideStaticMessage={this.hideStaticMessage}
                      messages={this.state.messages}
                    />
                  )}
                </div>
                ) : null}

              {this.props.location.pathname == '/admin/Configurator'
              || this.props.location.pathname == '/admin/Viewinsights'
              || adminSectionFrom ? (
                <div>
                  <ConfiguratorFilters
                    path={this.props.location.pathname}
                    history={this.props.history}
                    ref={this.filteComp}
                    filterClient={filterValues.Client}
                    selectedClientId={this.state.selectedClientId}
                    applyFilters={this.applyFilters}
                    changeHeaderText={this.changeHeaderText}
                    getSelectedRelatorBlock={this.getSelectedRelatorBlock}
                    handlerFromParant={this.handleData}
                    getSelectedIoj={this.getSelectedIoj}
                    MaturityPhase={this.state.MaturityPhase}
                    subHeaderOpenCallback={this.handleSubHeaderOpen}
                    subHeaderCloseCallback={this.handleSubHeaderClose}
                    OperatingGropus={this.state.OperatingGropus}
                    onTabChange={this.onTabChange}
                    isTabChanged={this.state.isTabChanged}
                    {...this.props}
                  />
                  {this.state.showStaticMessage && this.state.OperatingGropus.length && (
                    <StaticMessage
                      show={this.state.showStaticMessage}
                      pathLocation={this.props.location.pathname}
                      hideStaticMessage={this.hideStaticMessage}
                      messages={this.state.messages}
                    />
                  )}
                </div>
                ) : null}
              {this.props.location.pathname == '/landing' ? (
                <PulseFilter
                  history={this.props.history}
                  getPulseData={this.getPulsePageData}
                  subHeaderOpenCallback={this.handleSubHeaderOpen}
                  subHeaderCloseCallback={this.handleSubHeaderClose}
                  onTabChange={this.onTabChange}
                />
              ) : null}
              {this.props.location.pathname == '/admin/MasterTemplateConfigurator' ? (
                <ConfiguratorMasterFilters
                  path={this.props.location.pathname}
                  ref={this.filteComp}
                  isMasterConfiguratorFilter={this.isMasterConfiguratorFilter}
                  isReset={this.state.isReset}
                  selectedClientId={this.state.selectedClientId}
                  applyMTCFilters={this.applyMTCFilters}
                  MaturityPhase={this.state.MaturityPhase}
                  roledata={this.state.roledata}
                  subHeaderOpenCallback={this.handleSubHeaderOpen}
                  subHeaderCloseCallback={this.handleSubHeaderClose}
                />
              ) : null}

              <Route
                path="/QL"
                render={() => <QuickLinks />}
              />
              {this.state.viewUpload ? (
                <Route
                  path="/excel"
                  render={() => <ExcelUpload />}
                />
              ) : null}

              {/* <Route path="/ReportAdmin" render={() => { return <ReportAdmin filterDetails={this.state.filterValues} applyReportFilters={this.applyReportFilters} /> }} /> */}

              <Route
                path="/admin/Report"
                render={() => (
                  <Report
                    filterDetails={this.state.filterValues}
                    applyReportFilters={this.applyReportFilters}
                    location={this.props.location}
                  />
                )}
              />
              <Route
                path="/admin/User"
                render={() => <UserList filterDetails={this.state.filterValues} />}
              />
              <Route
                path="/admin/Offering"
                render={() => <OfferingList />}
              />
              <Route
                path="/admin/Location"
                render={() => <LocationList />}
              />
              <Route
                path="/admin/Deal"
                render={() => <DealList />}
              />
              {/* {store.getState().roleReducers["roleReducers"].filter(data=>data.FeatureName==="PulsePage").length && store.getState().roleReducers["roleReducers"].filter(data=>data.FeatureName==="PulsePage")[0].Operations.includes("Edit") ?
                        <Route path="/landing" render={() => { return <LandingPageAdmin filterDetails={this.state.filterValues} roledata={this.state.roledata.filter(data=>data.FeatureName==="PulsePage")[0]} pulseData={this.state.pulsePageData} historyval={this.props.history} selectedClientId={this.state.selectedClientId} /> }} />:
                    <Route path="/landing" render={() => { return <LandingPage filterDetails={this.state.filterValues} roledata={this.state.roledata.filter(data=>data.FeatureName==="PulsePage")[0]} pulseData={this.state.pulsePageData} historyval={this.props.history} selectedClientId={this.state.selectedClientId} /> }} /> } */}
              <Route
                path="/landing"
                render={() => (
                  <LandingPage
                    filterDetails={this.state.filterValues}
                    roledata={this.state.roledata.filter((data) => data.FeatureName === 'PulsePage')[0]}
                    pulseData={this.state.pulsePageData}
                    userRole={this.props.userRole}
                    historyval={this.props.history}
                    selectedClientId={this.state.selectedClientId}
                    getPulseData={this.getPulsePageData}
                    PulseSubOffering={this.state.PulseSubOffering}
                    PulseofferingId={this.state.PulseofferingId}
                    PulseOffering={this.state.PulseOffering}
                    isorgapiloading={this.state.isorgapiloading}
                  />
                )}
              />
              <Route
                path={`/RequestAccess`}
                render={() => <RequestAccess />}
              />
              <Route
                path="/ChartReport"
                render={() => <ChartReport />}
              />
              <Route
                path="/admin/Master"
                render={() => <MasterList filterDetails={this.state.filterValues} location={this.props.location} />}
              />
              {/* <Route path="/admin/Connector" render={() => { return <ConnectorList filterDetails={this.state.filterValues} /> }} /> */}
              <Route
                path="/admin/UserTrends"
                render={() => <UserTrendsContainer filterDetails={this.state.filterValues} location={this.props.location} />}
              />
              <Route
                path="/OperationalReviews"
                render={() => (
                  <MORContainer
                    isorgapiloading={this.state.isorgapiloading}
                    isTabChanged={this.state.isTabChanged}
                    userRole={this.props.userRole}
                    history={this.props.history}
                    filterDetails={this.state.filterValues}
                  />
                )}
              />
              <Route
                path="/admin/Approval"
                render={() => <Approval filterDetails={this.state.filterValues} location={this.props.location} />}
              />
              {/* <Route path="/admin/QueryBuilder" render={() => { return <QueryMasterContainer filterDetails={this.state.filterValues} /> }} /> */}
              <Route
                exact
                path="/admin/GOCForm"
                render={() => <GOCForm filterDetails={this.state.filterValues} />}
              />
              <Route
                path="/admin/Connector"
                render={() => (
                  <ConnectorList
                    applyOfferingFilters={this.applyOfferingFilters}
                    filterDetails={this.state.filterValues}
                    selectedClientId={this.state.selectedClientId}
                    location={this.props.location}
                  />
                )}
              />

              <Route
                exact
                path="/SynopsHomePage"
                render={(props) => <SynOpsHomePage {...props} />}
              />
              <div className="on">
                {/* {this.state.Display === "on" ? ( */}
                <>
                  <Route
                    exact
                    path="/Dashboard"
                    render={(props) => (
                      <RoutesList
                        disableDisplay={this.disableDisplay}
                        menuDisplay={this.state.Display}
                        menu="Dashboard"
                        headerText={this.state.headerText}
                        Nav={Navigate}
                        SubNav={IOJNavigation}
                        compDetails={this.state.compDetails}
                        SODetails={this.state.SODetails}
                        isRelatorBlock={this.state.isRelatorBlock}
                        isIojBlock={this.state.isIojBlock}
                        filterDetails={this.state.filterValues}
                        isReset={this.state.isReset}
                        getSelectedRelatorBlock={this.getSelectedRelatorBlock}
                        getSelectedIoj={this.getSelectedIoj}
                        roledata={this.state.roledata}
                        isIOJLoaded={this.state.isIOJLoaded}
                        {...props}
                        tabDetails={tabDetails}
                        SODisplay={SODisplay}
                        IOJDisplay={IOJDisplay}
                        getConfiguratorapicall={this.state.getConfiguratorapicall}
                        IOJcompDetails={this.state.IOJcompDetails}
                        attributes={this.state.attributes}
                        isNewDeal={this.state.isNewDeal}
                        redirectClick={this.getRedirect}
                        isorgapiloading={this.state.isorgapiloading}
                        isTabChanged={this.state.isTabChanged}
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/admin/Configurator"
                    render={(props) => (
                      <RoutesList
                        menuDisplay={this.state.Display}
                        disableDisplay={this.disableDisplay}
                        getConfiguratorapicall={this.state.getConfiguratorapicall}
                        menu="Configurator"
                        Nav={Navigate}
                        SubNav={IOJNavigation}
                        compDetails={this.state.compDetails}
                        SODetails={this.state.SODetails}
                        isRelatorBlock={this.state.isRelatorBlock}
                        isIojBlock={this.state.isIojBlock}
                        filterDetails={this.state.filterValues}
                        isReset={this.state.isReset}
                        roledata={this.state.roledata}
                        attributes={this.state.attributes}
                        isNewDeal={this.state.isNewDeal}
                        {...props}
                        tabDetails={tabDetails}
                        SODisplay={SODisplay}
                        IOJDisplay={IOJDisplay}
                        isIOJLoaded={this.state.isIOJLoaded}
                        isorgapiloading={this.state.isorgapiloading}
                        isTabChanged={this.state.isTabChanged}
                        onTabChange={this.onTabChange}
                        isBoLoaded={this.state.isBoLoaded}
                        isHMLoaded={this.state.isHMLoaded}
                        isWOLoaded={this.state.isWOLoaded}
                        isIILoaded={this.state.isIILoaded}
                        isDDLoaded={this.state.isDDLoaded}
                        isMPLoaded={this.state.isMPLoaded}
                      />
                    )}
                  />
                </>
                {/* ) : (
                  ""
                )} */}
                <Route
                  exact
                  path="/admin/MasterTemplateConfigurator"
                  render={(props) => (
                    <RoutesList
                      menuDisplay={this.state.Display}
                      disableDisplay={this.disableDisplay}
                      {...props}
                      menu="SuperAdmin"
                      Nav={Navigate}
                      isMasterConfigurator={this.state.isMasterConfigurator}
                      roledata={this.state.roledata}
                      compDetails={this.state.compDetails}
                      SODetails={this.state.SODetails}
                      filterDetails={this.state.masterFilterValues}
                      isReset={this.state.isReset}
                      tabDetails={tabDetails}
                      isorgapiloading={this.state.isorgapiloading}
                      isTabChanged={this.state.isTabChanged}
                    />
                  )}
                />
                {/* <Route exact path="/admin/Report" render={() => { return <RouteContainer menuDisplay={this.state.Display} clientId={this.state.clientId} menu="Report" Nav={Navigate} filterDetails={this.state.masterFilterValues} SODetails={this.state.SODetails}/> }} /> */}
                {/* <Route exact path="/ReportAdmin" render={() => { return <RouteContainer menuDisplay={this.state.Display} clientId={this.state.clientId} menu="ReportAdmin" Nav={Navigate} filterDetails={this.state.masterFilterValues} SODetails={this.state.SODetails}/> }} /> */}

                {/* <Route path="/intelligent"
                                render={() => { return <RouteContainer menuDisplay={this.state.Display} menu="IOJ" Nav={IOJNavigation} compDetails={this.state.compDetails} SODetails={this.state.SODetails} isIojBlock={this.state.isIojBlock} filterDetails={this.state.filterValues} getSelectedRelatorBlock={this.getSelectedRelatorBlock} getSelectedIoj={this.getSelectedIoj} roledata={this.state.roledata} /> }}
                            />
                            <Route path="/admin/Intelligent"
                                render={() => {
                                    return <RouteContainer menuDisplay={this.state.Display} menu="IOJ" Nav={IOJNavigation} compDetails={this.state.compDetails} SODetails={this.state.SODetails} isIojBlock={this.state.isIojBlock}
                                        isNewDeal={this.state.isNewDeal}
                                        filterDetails={this.state.filterValues} roledata={this.state.roledata} />
                                }}
                            /> */}

                {/* <Route
                  path="/viewinsights"
                  render={(props) => {
                    return (
                      <RouteContainer
                        menuDisplay={this.state.Display}
                        disableDisplay={this.disableDisplay}
                        menu="Dashboard"
                        Nav={Navigate}
                        SubNav={IOJNavigation}
                        compDetails={this.props.compDetails}
                        SODeatails={this.props.SODetails}
                        filterDetails={this.state.filterValues}
                        roledata={this.state.roledata}
                        {...props}
                      />
                    );
                  }}
                /> */}

                {/* <Route
                  path="/admin/Viewinsights"
                  render={(props) => {
                    return (
                      <RouteContainer
                        menuDisplay={this.state.Display}
                        disableDisplay={this.disableDisplay}
                        menu="Configurator"
                        Nav={Navigate}
                        SubNav={IOJNavigation}
                        compDetails={this.props.compDetails}
                        SODeatails={this.props.SODetails}
                        filterDetails={this.state.filterValues}
                        roledata={this.state.roledata}
                        {...props}
                      />
                    );
                  }}
                /> */}

                <Route
                  path="/initiativedetails"
                  render={() => <InitiativeDeatils />}
                />
                {/* <Route path="/viewinsights"
                                render={() => { return <ViewInsights compDetails={this.props.compDetails} SODeatails={this.props.SODetails} filterDetails={this.state.filterValues} roledata={this.state.roledata} /> }} />

                            <Route path="/admin/Viewinsights"
                                render={() => { return <ConfViewInsights compDetails={this.props.compDetails} SODeatails={this.props.SODetails} filterDetails={this.state.filterValues} roledata={this.state.roledata} /> }} />
                            {this.props.location.pathname != "/GOC" && (this.props.location.pathname != "/excel")}
                         */}
              </div>


              <Route
                path="/SynOpsDemo"
                render={() => <StoryView />}
              />
              <Route
                path="/ClientStory"
                render={() => <ClientStory />}
              />
              <Route
                path="/FrontdoorAnalytics"
                render={() => <FrontDoorAnalytics />}
              />
              <Route
                path="/HomePage"
                render={() => (
                  <HomePage
                    OperatingGropus={this.state.OperatingGropus}
                    userclientCount={this.state.userclientCount}
                    UserOnboardedClients={this.state.UserOnboardedClients}
                    FunctionalIndustryOffering={this.state.FunctionalIndustryOffering}
                    BatCount={this.state.BatCount}
                    isBatApiLoading={this.state.isBatApiLoading}
                    isClientApiLoading={this.state.isClientApiLoading}
                    isorgapiloading={this.state.isorgapiloading}
                    selectedFilter={(value) => {
                      this.passClientFilter(value, this);
                    }}
                    historyval={this.props.history}
                    isProfileExist={this.state.isProfileExist}
                    userRole={this.props.userRole}
                    onTabChange={this.onTabChange}
                    isClientStoriesApiloading={this.state.isClientStoriesApiloading}
                    getMyClientStories={this.getMyClientStories}
                    {...this.props}
                  />
                )}
              />
              <Route
                path="/viewclientproject"
                render={() => <ViewProject isorgapiloading={this.state.isorgapiloading} />}
              />
              {/* <Route
                path="/helptopics"
                render={() => {
                  return <HelpTopics />;
                }}
                            /> */}
              <Route
                path="/helpfeatures"
                render={() => <HelpFeatures history={this.props.history} isorgapiloading={this.state.isorgapiloading} location={this.props.location} />}
              />
              <Route
                path="/addfeature"
                render={() => <AddHelpFeatures history={this.props.history} />}
              />

              <Route
                path="/OverallQCScore"
                render={() => <OverallQCScore />}
              />
              <Route
                exact
                path={`/${ROUTE_CONSTANTS.BAT_LANDING_PAGE}`}
                render={() => <Bat />}
              />
              <Route
                exact
                path={`/${ROUTE_CONSTANTS.BAT_OFFERING_BENCHMARK_DETAILS}`}
                render={() => <BatView />}
              />
              <Route
                exact
                path={`/${ROUTE_CONSTANTS.BAT_CLIENT_PERFORMANCE_VIEW}`}
                render={() => <ClientPerformanceView />}
              />
              <Route
                exact
                path={`/${ROUTE_CONSTANTS.BAT_VIEW_ASSESSMENT}`}
                render={() => <ViewAssessment />}
              />
              <Route
                exact
                path={`/${ROUTE_CONSTANTS.BAT_TAKE_ASSESSMENT}`}
                render={() => <TakeAssessment />}
              />
              <Route
                exact
                path={`/${ROUTE_CONSTANTS.BAT_PROVIDE_METRIC_DETAILS_FOR_ASSESSMENT}`}
                render={() => <ProvideMetricDetailsForAssessment />}
              />
              <Route
                exact
                path={`/${ROUTE_CONSTANTS.BAT_ACCESS_MANAGEMENT}`}
                render={() => <BatAccessManagement />}
              />
            </div>
          </div>
         
          {!this.props.location.pathname.startsWith('/HomePage') && !this.props.location.pathname.startsWith('/chat')? <Footer /> : null}
        </div>
        {/* <button type="button" class="btn btn-primary" id='back-to-top' onClick={() => this.backToTop()}>Back to Top</button> */}
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  tabData: { ...state.CurrentFilter.Tab },
  languageData: { ...state.CurrentFilter.languageData },
  roleReducerData: state.roleReducers.roleReducers,
  CurrentFilter: state.CurrentFilter.SyncFilter,
  ClientData: state.CurrentFilter.ClientData,
  SelectedFilter: { ...state.CurrentFilter.SyncFilter },
});
const mapDispatchToProps = (dispatch) => ({
  saveClientData: (data) => dispatch(saveClientData(data)),
  updateBATFilter: (BATData) => dispatch(updateBATFilter(BATData)),
  setSisenseScript : (sisenseData) => dispatch(setSisenseScript(sisenseData))
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(BaseContainer);
