// THIS FILE CONTAINS ALL THE COMMON FUNCTIONS THAT USED APPLICATION WIDE
import React from 'react';
import _ from 'lodash';
import Moment from 'moment';
import i18n from 'i18next';
import psl from 'psl';
import extractUrls from 'extract-urls';
import axiosInstance from './interceptor';
import { trycatchAlertPopup, LocalApiBaseUrl, MorDocInfo,regexForStartWithSpecialCharcterinText } from './Constant';
import {jwtDecode} from 'jwt-decode';

if(axiosInstance){
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('msal.idtoken')}`;
}

/**
 * For the given input string, this function would replace all the plain text links starting with 'http://' or 'https://' with anchor tag `<a>` wrapped around them so the links can be made navigatable.
 * @param {String} text The input string
 * @param {String} linkTarget The `target` for the `<a>` tag. Default value '_blank'
 * @return {String} The string with plain text links replaced with anchor tags
 */

//  MM/DD/YY HH:MM UTC. (Example 19:30 UTC)
export const FormatDate = (date) => {
  if (!date) return '';
  let newDate = new Date(date);
  newDate = new Date(newDate);
  return (
    `${[
      (`0${newDate.getMonth() + 1}`).slice(-2),
      (newDate.getDate() < 10 ? '0' : '') + newDate.getDate(),
      (`${newDate.getFullYear()}`).substr(2),
    ].join('/')
    } ${[
      (newDate.getHours() < 10 ? '0' : '') + newDate.getHours(),
      (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes(),
    ].join(':')
    } UTC`
  );
};
Date.shortMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

// DD/MMM/YYYY
export const FormatDateNoTime = (date) => {
  if (!date) return '';
  let newDate = new Date(date);
  newDate = new Date(newDate);
  return [
    (newDate.getDate() < 10 ? '0' : '') + newDate.getDate(),
    short_months(newDate),
    `${newDate.getFullYear()}`,
  ].join('/');
};

function short_months(dt) {
  return Date.shortMonths[dt.getMonth()];
}

export const FormatDateWithoutUTC = (date) => {
  if (!date) return '';
  let newDate = new Date(date);
  newDate = new Date(newDate);
  return (
    `${[
      (`0${newDate.getMonth() + 1}`).slice(-2),
      (newDate.getDate() < 10 ? '0' : '') + newDate.getDate(),
      (`${newDate.getFullYear()}`).substr(2),
    ].join('/')
    } ${[
      (newDate.getHours() < 10 ? '0' : '') + newDate.getHours(),
      (newDate.getMinutes() < 10 ? '0' : '') + newDate.getMinutes(),
    ].join(':')}`
  );
};

// Return false if value is 0, empty, undefined, null otherwise true
export const HasValue = (val) => (val ? (!(val === 0 || val === '')) : false);
export const DisplayMessage = () => {
  window.alert(
    'Internal server error occurred. Please contact your system administrator',
  );
};

// SCREEN NAMES WITH CORRESPONDING KEY. THE VALUE FOR EACH SCREEN CORRESPONDS TO THE VALUE THAT WOULD BE PASSED FOR `PageName` ATTRIBUTE IN THE FUNCTION `logUserTrackingData` THAT IS USED FOR LOGGING USER TRACKING DATA
export const screeNamesForUserTracking = {
  BAT_LANDING_SCREEN: 'BAT',
  SynOpsDemo: 'SynOpsDemo',
  ClientSelect: 'ClientSelect',
  LandingPage: 'LandingPage',
  HomePage: 'HomePage',
};
// LOGS USER TRACKING DATA TO DB
export function logUserTrackingData(data) {
  const requestParameters = {
    PageName: _.get(data, 'PageName'), // Required
    Client: _.get(data, 'Client', null),
    Language: _.get(data, 'Language', null),
    Navigation1: _.get(data, 'Navigation1', null),
    Navigation2: _.get(data, 'Navigation2', null),
    Navigation3: _.get(data, 'Navigation3', null),
    Navigation4: _.get(data, 'Navigation4', null),
    Navigation5: _.get(data, 'Navigation5', null),
  };
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(
        `${LocalApiBaseUrl}NavigationAnalytics/AddNavigationEntry`,
        requestParameters,
      )
      .then((response) => {
        const responseData = response.data;

        resolve(responseData);
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        reject(error);
      });
  });
}

// EXECUTES THE API TO GET THE CLIENT DETAILS AND THEN SAVE THE DATA TO REDUX STORE
export function executeAPIGetClientDetailsAndSaveToRedux(
  data,
  reduxSetClientDetails,
) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(
        `${LocalApiBaseUrl}Client/GetClientDetails?OrgEntityID=${data.clientId}`,
      )
      .then((response) => {
        const responseData = response.data;
        reduxSetClientDetails(responseData);
        resolve(responseData);
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        reject(error);
      });
  });
}

// EXECUTES THE API TO GET THE CLIENT OPRIA SISENSE DETAILS AND THEN SAVE THE DATA TO REDUX STORE
export function executeAPIGetClientOpriaSisenseDetailsAndSaveToRedux(
  data,
  reduxSetClientOpriaSisenseDetails,
) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(
        `${LocalApiBaseUrl}Client/GetClientOpriaDetails?OrgEntityID=${data.clientId
        }&Offering=${encodeURIComponent(data.offeringName)}`,
      )
      .then((response) => {
        const responseData = response.data;
        reduxSetClientOpriaSisenseDetails(responseData);
        resolve(responseData);
      })
      .catch((error) => {
        // const errMsg = 'THERE WAS SOME ERROR WHILE GETTING CLIENT DETAILS';
        reject(error);
      });
  });
}

// RETURNS USER INITIALS FROM USER ENTERPRISE ID. For ex: for EID: "abc.xyz.qwe", Return initials: "AQ"
export function getInitialsFromUserEID(userEID) {
  let userInitials = '';
  const splitArr = _.split(userEID, '.');
  userInitials = _.toUpper(
    _.head(splitArr).charAt(0) + _.last(splitArr).charAt(0),
  );
  return userInitials;
}
export const FormatDateWithMonthAndYear = (date) => {
  if (!date) return '';
  const newDate = new Date(date);

  return (
    `${newDate.getFullYear()}/${(`0${newDate.getMonth() + 1}`).slice(-2)}`
  );
};

// RETURNS A RESOLVED PROMISE AFTER THE SPECIFIED NUMBER OF MILLISECONDS
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const ToChunk = (Originalarray, size) => {
  const chunkedarray = [];
  let index = 0;
  while (index < Originalarray.length) {
    chunkedarray.push(Originalarray.slice(index, size + index));
    index += size;
  }
  return chunkedarray;
};
export const ExtractSrcLink = (Link) => {
  const DocsearchTerm = 'src="';
  const VideoImagesearchTerm = '?';
  const Extensions = [
    '.mp4',
    '.mov',
    '.webm',
    '.mkv',
    '.ogv',
    '.3g2',
    '.jpeg',
    '.jpg',
    '.giff',
    '.png',
  ];
  if (Extensions.some((v) => Link.toUpperCase().includes(v.toUpperCase()))) {
    return `${Link.split(VideoImagesearchTerm)[0]}`;
  } if (Link.includes('<iframe')) {
    return `${Link.split(DocsearchTerm)[1].split('"')[0]}`;
  }
  return Link;
};
export const GenerateExtensionIcon = (link) => {
  const matches = MorDocInfo.filter((a) => {
    if (
      link
        .trim()
        .toUpperCase()
        .includes(a.key.trim().toUpperCase())
    ) {
      return a;
    }
    return;

    return '';
  });

  if (matches.length > 0) {
    return matches[0];
  }
  if (
    link
      .trim()
      .toUpperCase()
      .includes('Doc.aspx'.trim().toUpperCase())
    && !MorDocInfo.map((a) => a.key.toUpperCase()).includes(
      link.trim().toUpperCase(),
    )
  ) {
    return MorDocInfo.filter((a) => a.key === 'Word')[0];
  }
  return MorDocInfo.filter((a) => a.key === 'Invalid')[0];
};

// Converting UTC time to current date format(Used in refrences)
export const dateFormatMethod = (dateTime) => {
  const conv = dateTime.replace('T', ' ');

  const tempData = Moment.utc(conv)
    .local()
    .format('D MMM YYYY');
  const tempTime = Moment.utc(conv)
    .local()
    .format('hh:mm A');

  const result = `${tempData}, ${tempTime.toLowerCase()}`;

  return result;
};

export const dateFormatMethod2 = (dateTime) => {
  const conv = dateTime.replace('T', ' ');

  const tempData = Moment.utc(conv)
    .local()
    .format('D MMM YY');
  const tempTime = Moment.utc(conv)
    .local()
    .format('hh:mm A');

  const result = `${tempData}, ${tempTime.toLowerCase()}`;

  return result;
};

// FOR SORTING ALPHANUMERIC STRING IN ASCENDING ORDER
export const sortDataAsc = (data, property) => [...data].sort((a, b) => a[property]
  .toLowerCase()
  .localeCompare(b[property].toLowerCase(), undefined, {
    numeric: true,
    sensitivity: 'base',
  }));
// TO AVOID STATE MUTATION
export const deepCopyData = (data) => {
  if (data.length > 0) {
    return JSON.parse(JSON.stringify(data));
  }
  return {};
};
export const isEmptyObject = (objectData) => Object.entries(objectData).length === 0;

export const truncateTwoDecimal = (figure, decimals) => {
  if (figure.toString().split('.').length > 1) {
    if (!decimals) decimals = 2;
    const d = Math.pow(10, decimals);
    return (parseInt(figure * d) / d).toFixed(decimals);
  }
  return (parseInt(figure));
};

export const filterSelectedSubOffering = (list, selectedId) => {
  let selectedSubOfferingTree = {};
  const firstSuboffering = [];
  const subofferingData = [];
  const allLeafNodes = [];
  _.cloneDeep(list).forEach((each) => {
    if (each.IsSubOffering) {
      allLeafNodes.push(each);
      if (!firstSuboffering.length) {
        firstSuboffering.push(each);
      }
      if (parseInt(each.OfferingID, 10) === parseInt(selectedId, 10)) {
        selectedSubOfferingTree = each;

        subofferingData.push(each);
      }
    } else {
      const levelOneData = each.SubLevel;
      levelOneData.forEach((levelOne) => {
        if (levelOne.IsSubOffering) {
          allLeafNodes.push(levelOne);
          if (!firstSuboffering.length) {
            firstSuboffering.push(levelOne);
          }
          if (parseInt(levelOne.OfferingID, 10) === parseInt(selectedId, 10)) {
            selectedSubOfferingTree = each;
            selectedSubOfferingTree.SubLevel = [{ ...levelOne }];

            subofferingData.push(levelOne);
          }
        } else {
          const levelTwoData = levelOne.SubLevel;
          levelTwoData.forEach((levelTwo) => {
            if (levelTwo.IsSubOffering) {
              allLeafNodes.push(levelTwo);
              if (!firstSuboffering.length) {
                firstSuboffering.push(levelTwo);
              }
              if (parseInt(levelTwo.OfferingID, 10) === parseInt(selectedId, 10)) {
                // let levelTwoEach  = levelTwo;
                selectedSubOfferingTree = each;
                selectedSubOfferingTree.SubLevel = [{ ...levelOne }];
                selectedSubOfferingTree.SubLevel[0].SubLevel = [
                  { ...levelTwo },
                ];

                subofferingData.push(levelTwo);
              }
            } else {
              const levelThreeData = levelTwo.SubLevel;
              levelThreeData.forEach((levelThree) => {
                if (levelThree.IsSubOffering) {
                  allLeafNodes.push(levelThree);
                  if (!firstSuboffering.length) {
                    firstSuboffering.push(levelThree);
                  }
                  if (
                    parseInt(levelThree.OfferingID, 10) === parseInt(selectedId, 10)
                  ) {
                    selectedSubOfferingTree = each;
                    selectedSubOfferingTree.SubLevel = [{ ...levelOne }];
                    selectedSubOfferingTree.SubLevel[0].SubLevel = [
                      { ...levelTwo },
                    ];
                    selectedSubOfferingTree.SubLevel[0].SubLevel[0].SubLevel = [
                      { ...levelThree },
                    ];

                    subofferingData.push(levelThree);
                  }
                } else {
                  const levelFourData = levelThree.SubLevel;
                  levelFourData.forEach((levelFour) => {
                    if (levelFour.IsSubOffering) {
                      allLeafNodes.push(levelFour);
                      if (!firstSuboffering.length) {
                        firstSuboffering.push(levelFour);
                      }
                      if (
                        parseInt(levelFour.OfferingID, 10) === parseInt(selectedId, 10)
                      ) {
                        selectedSubOfferingTree = each;
                        selectedSubOfferingTree.SubLevel = [levelOne];
                        selectedSubOfferingTree.SubLevel[0].SubLevel = [
                          { ...levelTwo },
                        ];
                        selectedSubOfferingTree.SubLevel[0].SubLevel[0].SubLevel = [
                          { ...levelThree },
                        ];
                        selectedSubOfferingTree.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel = [
                          { ...levelFour },
                        ];

                        subofferingData.push(levelFour);
                      }
                    } else {
                      const levelFiveData = levelFour.SubLevel;
                      levelFiveData.forEach((levelFive) => {
                        if (levelFive.IsSubOffering) {
                          allLeafNodes.push(levelFive);
                          if (!firstSuboffering.length) {
                            firstSuboffering.push(levelFive);
                          }
                          if (
                            parseInt(levelFive.OfferingID, 10)
                            === parseInt(selectedId, 10)
                          ) {
                            selectedSubOfferingTree = { ...each };
                            selectedSubOfferingTree.SubLevel = [{ ...levelOne }];
                            selectedSubOfferingTree.SubLevel[0].SubLevel = [
                              { ...levelTwo },
                            ];
                            selectedSubOfferingTree.SubLevel[0].SubLevel[0].SubLevel = [
                              { ...levelThree },
                            ];
                            selectedSubOfferingTree.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel = [
                              { ...levelFour },
                            ];
                            selectedSubOfferingTree.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel[0].SubLevel = [
                              { ...levelFive },
                            ];
                            subofferingData.push(levelFive);
                          }
                        }
                      });
                      // if(levelFiveData.SubLevel.length){
                      //   return
                      // }
                    }
                  });
                }
              });
            }
          });
        }
      });
    }
  });
  return {
    selectedSubOfferingTree: [selectedSubOfferingTree],
    firstSuboffering,
    subofferingData,
    allLeafNodes,
  };
};

export const createBreadCrumbs = (DashboardSubOfferingData, offeringId, subOfferingId, DasboardOfferingData) => {
  const { selectedSubOfferingTree } = filterSelectedSubOffering([...DashboardSubOfferingData], subOfferingId);
  const selectedSubOfferingTreeData = selectedSubOfferingTree && selectedSubOfferingTree.length ? selectedSubOfferingTree[0] : {};
  const offeringData = DasboardOfferingData.length ? DasboardOfferingData.filter((each) => parseInt(each.OfferingID, 10) === parseInt(offeringId, 10)) : [];
  return selectedSubOfferingTreeData && selectedSubOfferingTree.length
    && (
      <p className="breadcrumbs">
        {offeringData.length ? <span>{offeringData[0].OfferingName}</span> : ''}
        {offeringId === 'All' ? <span>{i18n.t('All_offering')}</span> : ''}
        {subOfferingId === 'All' ? (
          <span>
            {' '}
            {i18n.t('All_sub_offering')}
          </span>
        ) : ''}
        {(selectedSubOfferingTreeData.IsSubOffering || selectedSubOfferingTreeData.Process) ? <span>{selectedSubOfferingTreeData.Process}</span> : null}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].Process}</span> : ''}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && !selectedSubOfferingTreeData.SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].Process}</span> : ''}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].Process}</span> : ''}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel.length && !selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].Process}</span> : ''}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].Process}</span> : ''}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel.length && !selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].Process}</span> : ''}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel[0].Process}</span> : ''}
        {selectedSubOfferingTreeData.SubLevel && selectedSubOfferingTreeData.SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel.length && selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel.length && !selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel[0].IsSubOffering ? <span>{selectedSubOfferingTreeData.SubLevel[0].SubLevel[0].SubLevel[0].SubLevel[0].Process}</span> : ''}
      </p>
    );
};

export const filterAllSelectedSubOfferings = (list, selectedIds) => {
  const updatedList = _.cloneDeep(list).filter((eachList) => {
    let eachOff = _.cloneDeep(eachList.ChildOfferings);
    eachOff = _.cloneDeep(eachOff).filter((each) => {
      if (each.IsSubOffering && selectedIds.includes(parseInt(each.OfferingID, 10))) {
        return each;
      }
      let levelOneData = _.cloneDeep(each.SubLevel);
      levelOneData = levelOneData.filter((levelOne) => {
        if (levelOne.IsSubOffering && selectedIds.includes(parseInt(levelOne.OfferingID, 10))) {
          return levelOne;
        }
        let levelTwoData = levelOne.SubLevel;
        levelTwoData = levelTwoData.filter((levelTwo) => {
          if (levelTwo.IsSubOffering && selectedIds.includes(parseInt(levelTwo.OfferingID, 10))) {
            return levelTwo;
          }
          let levelThreeData = levelTwo.SubLevel;
          levelThreeData = levelThreeData.filter((levelThree) => {
            if (levelThree.IsSubOffering && selectedIds.includes(parseInt(levelThree.OfferingID, 10))) {
              return levelThree;
            }
            let levelFourData = levelThree.SubLevel;
            levelFourData = levelFourData.filter((levelFour) => {
              if (levelFour.IsSubOffering && selectedIds.includes(parseInt(levelFour.OfferingID, 10))) {
                return levelFour;
              }
              let levelFiveData = levelFour.SubLevel;
              levelFiveData = levelFiveData.filter((levelFive) => {
                if (levelFive.IsSubOffering && selectedIds.includes(parseInt(levelFive.OfferingID, 10))) {
                  return levelFive;
                }
              });
              if (levelFiveData.length) {
                const levelSubLevelTree = levelFour;
                levelSubLevelTree.SubLevel = levelFiveData;
                return levelSubLevelTree;
              }
            });
            if (levelFourData.length) {
              const levelSubLevelTree = levelThree;
              levelSubLevelTree.SubLevel = levelFourData;
              return levelSubLevelTree;
            }
          });
          if (levelThreeData.length) {
            const levelSubLevelTree = levelTwo;
            levelSubLevelTree.SubLevel = levelThreeData;
            return levelSubLevelTree;
          }
        });
        if (levelTwoData.length) {
          const levelSubLevelTree = levelOne;
          levelSubLevelTree.SubLevel = levelTwoData;
          return levelSubLevelTree;
        }
      });
      if (levelOneData && levelOneData.length) {
        const levelSubLevelTree = each;
        levelSubLevelTree.SubLevel = levelOneData;
        return levelSubLevelTree;
      }
    });
    if (eachOff.length) {
      const levelSubLevelTree = eachList;
      levelSubLevelTree.ChildOfferings = eachOff;
      return levelSubLevelTree;
    }
  });
  return {
    updatedList,
  };
};
export  const getDayStatusFromTimestamp = (dateStrUTC) => {
  const dateFormat = 'YYYY-MM-DD';
  const nowMoment = Moment();
  const nowDateOnly = nowMoment.format(dateFormat);

  const yesterdayMoment = Moment(nowMoment).subtract(1, 'day');
  const yesterdayDateOnly = yesterdayMoment.format(dateFormat);

  // convert to local
  const convertedToLocalMoment = Moment.utc(dateStrUTC).local();
  const convertedToLocalDateOnly = convertedToLocalMoment.format(dateFormat);

  let returnStr = '';
  if (convertedToLocalDateOnly === nowDateOnly) {
    returnStr = 'Today';
  } else if (convertedToLocalDateOnly === yesterdayDateOnly) {
    returnStr = 'Yesterday';
  } else {
    returnStr = convertedToLocalMoment.format('MMM DD, ddd'); // format as ex: Feb 24, Wed
  }

  return returnStr;
}

//FOR BEOFRE AND AFTER CLIENT STORY TIME SERIES
export const dateFormatMethodTimeLineSeries = (dateTime) => {
  const conv = dateTime.replace('T', ' ');

  const tempData = Moment.utc(conv)
    .local()
    .format("MMM'YY");
  return tempData;
};
//CONVERTING BACKEND DATETIME TO JS DATE OBJECT
export const backendDateToJsDate = (dateTime) => {
  if (dateTime) {
    let date = dateTime.split('T')[0];
    date = date.replaceAll('-', '/');
    const jsDate = new Date(date);
    return jsDate;
  }
  return dateTime

};
export const RemoveDuplicates = (arr, key) => {/*NEW CODE WHICH REMOVES DUPLICATES DATA FROM API */
  const map = new Map();
  arr.forEach(v => map.set(v[key], v))
  return [...map.values()];
};

// VALIDATES WHETHER THE GIVEN URL(WITH DOMAIN OR SUBDOMAIN) IS VALID AS PER THE PROVIDED ARRAY OF ALLOWED DOMAINS
export function validateIsAllowedDomainFromURL(urlStr, allowedDomainsArray) {
  let isDomainAllowed = false;

  if (_.isEmpty(urlStr) || _.isEmpty(allowedDomainsArray)) {
    isDomainAllowed = true;
  } else if (!_.isEmpty(urlStr)) {
    const url = new URL(urlStr);
    const hostName = url.host.replace('www.', '');

    const parsed = psl.parse(hostName);
    const { domain, subdomain } = parsed;

    if (!_.isEmpty(allowedDomainsArray)) {
      const mappedAllowedDomainsArray = _.map(allowedDomainsArray, (eachItem) => _.toLower(eachItem));
      // WHEN SUBDOMAIN IS NOT EMPTY THEN CHECK THE MATCH FOR EXACT SUBDOMAIN AND DOMAIN STRING OR CHECK THE DOMAIN WITH * AS PRE-PENDED WHICH INDICATES THAT ALL SUBDOMAINS ARE ALLOWED
      if (!_.isEmpty(subdomain)) {
        if (
          _.indexOf(mappedAllowedDomainsArray, _.toLower(`${subdomain}.${domain}`)) !== -1
          || _.indexOf(mappedAllowedDomainsArray, _.toLower(`*.${domain}`)) !== -1
        ) {
          isDomainAllowed = true;
        }
      } else if (_.indexOf(mappedAllowedDomainsArray, _.toLower(domain)) !== -1) {
        isDomainAllowed = true;
      }
    }

  }

  return isDomainAllowed;
}

// EXECUTES THE API CALL TO FETCH THE WHITELISTED DOMAINS
export function fetchAllowedDomains(reqData) {
  const OrgEntityID = _.get(reqData, 'OrgEntityID', null);

  let apiEndpoint = `${LocalApiBaseUrl}Common/GetWhiteListUrl`;
  if (!_.isNil(OrgEntityID) && !_.isEmpty(String(OrgEntityID))) {
    apiEndpoint = `${apiEndpoint}?OrgEntityId=${OrgEntityID}`;
  }


  return new Promise((resolve, reject) => {
    axiosInstance
      .get(apiEndpoint)
      .then((response) => {
        const responseData = response.data;
        if (!_.isEmpty(responseData)) {
          const allowedDomains = _.map(_.filter(responseData, { IsActive: true }), 'Domain');
          resolve(allowedDomains);
        }
      })
      .catch((error) => {
        trycatchAlertPopup(error);
        reject(error);
      });
  });
}

export function isValidEmbedLink(embeddedURLStr) {
  let result = {
    isValid: true,
    errMsg: '',
  };
  const urls = extractUrls(embeddedURLStr);
  const totalURLsInString = _.size(urls);
  // THERE HAS TO BE AT LEAST ONE URL IN THE GIVEN EMBEDDED LINK FOR IT TO BE VALID
  if (totalURLsInString <= 0) {
    result = {
      isValid: false,
      errMsg: i18n.t('invalid_embed_link'),
    };
  }

  return result;
}
export const isValidText = (text) => {
  return regexForStartWithSpecialCharcterinText.test(text);
};


/* export function getValidUserDetails(data) {
  return new Promise((resolve, reject) => {
    axiosInstance.post(`${LocalApiBaseUrl}SOFilter/GetValidUser`, data, {
      params: {},
    })
      .then((response) => {
        const responseData = response.data;
        resolve(responseData);
      })
      .catch((error) => {
        reject(error);
      });
  });
} */
export function getValidUserDetails(userData,isDomainAppend=false) {

  let userdetails=_.isArray(userData)?{users:userData,isDomainAppend}:{users:[userData],isDomainAppend}
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(
        //`http://localhost:54510/api/SOFilter/ValidateEmail`,userdetails
        `${LocalApiBaseUrl}SOFilter/ValidateEmail`,userdetails
      ).then((response) => {
        resolve(response.data)
      })
      .catch((error) =>{
        reject(error)
      })
})
}

export const getErrorMessageByData=(userValidData,individualData)=>{
  let errorMessage="";
  let formValid=true;
  let inValidAccentureUsers=[],individualDataInvalidusers=[];
  if(!_.isNil(userValidData) && _.isArray(userValidData) && !_.isNil(individualData) && _.isArray(individualData)){
    inValidAccentureUsers=userValidData.filter(a=>!a.IsValid);
    individualDataInvalidusers=individualData.filter(a=>inValidAccentureUsers.some(b=>b.UserName===a && !b.IsValid));

    if(individualDataInvalidusers.length>0){
    formValid=false;
    let userlist=[...new Set([individualDataInvalidusers])].join(',');
    let error=individualDataInvalidusers.length>1?i18n.t('Invalid_Users'):i18n.t('Invalid_User');
    errorMessage = `${userlist} ${error}`;
}
  }
return {errorMessage,formValid};
}

export const InvalidDescription = () => i18n.t('Invalid_Description');

export const openURLInNewWindow = (url) => {
  const urlRegex = /^(http|https):\/\/[^\s/$.?#].[^\s]*$/;
  if(url && urlRegex.test(url)){
    window.open(url, '_blank');
  }
  else{
    console.error('Invalid URL');
  }
};

export const isValidToken = (token) => {
  let isValid = false;
  if(token){
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if(decodedToken && decodedToken.exp && decodedToken.exp > currentTime){
      isValid = true;
    }
  }
  return isValid;
};