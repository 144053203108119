import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import i18n from "i18next";
import { includes } from "lodash";

const allOmids = [
  { location: "Global - North America", omids: ["9556","9557", "4321"] },
  { location: "Bangalore", omids: ["1234"] },
];

function OMIDDropdown({ filterDetails, onIojOmidSelection }) {
  const [searchedOMID, setSearchedOMID] = useState("");
  // const [selectedOMIDs, setSelectedOMIDs] = useState([]);

  const clearSearchOMID = () => {
    setSearchedOMID("");
  };

  const searchOMID = (e) => {
    setSearchedOMID(e.target.value);
  };

  const handleChecked = (e) => {
    let selectedOMIDs;

    if (e.target.checked) selectedOMIDs = [...filterDetails.selectedIojOmids, e.target.value];
    else selectedOMIDs = filterDetails.selectedIojOmids.filter((item) => item !== e.target.value);

    console.log(selectedOMIDs);

    onIojOmidSelection(selectedOMIDs);
  };

  return (
    <DropdownButton
      menuAlign="left"
      id="OmidLocationbutton"
      title={
        filterDetails.selectedIojOmids.length > 0
          ? filterDetails.selectedIojOmids.join()
          : i18n.t("Select Location")
      }
      className={
        filterDetails.selectedIojOmids.length > 0
          ? "custom-headerdropdown selected mr-3"
          : "custom-headerdropdown mr-3"
      }
      // disabled={filterDetails.selectedSubOffering === 'All'}
    >
      <div className="offering-droplistwrapper">
        <Dropdown.ItemText>
          <div className="searchFilter">
            <input
              placeholder="Search ..."
              type="search"
              value={searchedOMID}
              onChange={searchOMID}
            />
            <i className="fal fa-times clear-search" onClick={clearSearchOMID} />
          </div>
        </Dropdown.ItemText>
        <Dropdown.ItemText
          onClick={() => {
            const omids = [];
            allOmids.forEach((item) => {
              item.omids.forEach((omid) => {
                omids.push(omid);
              });
            });
            onIojOmidSelection(omids);
          }}
        >
          <div className="offering-item omid-select">All Locations</div>
        </Dropdown.ItemText>
        {/* <Dropdown.ItemText>
          <div className="offering-item omid-select">Bangalore</div>
        </Dropdown.ItemText> */}
        {/* <Dropdown.ItemText
          onClick={() => {
            // setSelectedOMIDs(options.map(opt => opt.value))
            onIojOmidSelection(allOmids.map((opt) => opt.value));
          }}
        >
          <div className="offering-item omid-select">Global - North America</div>
        </Dropdown.ItemText> */}

        {/* {allOmids
          .filter((item) => item.value.includes(searchedOMID))
          .map((item) => (
            <Dropdown.ItemText
              className={filterDetails.selectedIojOmids.includes(item.value) ? "active" : ""}
              key={item.value}
            >
              <div className={filterDetails.selectedIojOmids.includes(item.value) ? "omid-selected": ""}>
                <input
                  type="checkbox"
                  id={item.value}
                  name={item.value}
                  value={item.value}
                  checked={filterDetails.selectedIojOmids.includes(item.value)}
                  onChange={handleChecked}
                />
                <label className='omid-check-title' htmlFor={item.value}>{item.label}</label>
              </div>
            </Dropdown.ItemText>
          ))} */}

          {allOmids.map((item) => (
            <>
            <Dropdown.ItemText
            key={item.location}
            onClick={() => {
              const omids = [];
              allOmids.filter(subitem => subitem.location === item.location).forEach((item) => {
                item.omids.forEach((omid) => {
                  omids.push(omid);
                });
              });
              onIojOmidSelection([...filterDetails.selectedIojOmids, ...omids]);
            }}
            >
            <div className="offering-item omid-select">{item.location}</div>
          </Dropdown.ItemText>
          
            {item.omids.map((omid) => (
              <Dropdown.ItemText
              className={filterDetails.selectedIojOmids.includes(omid) ? "active" : ""}
              key={omid}
            >
              <div className={filterDetails.selectedIojOmids.includes(omid) ? "omid-selected": ""}>
                <input
                  type="checkbox"
                  id={omid}
                  name={omid}
                  value={omid}
                  checked={filterDetails.selectedIojOmids.includes(omid)}
                  onChange={handleChecked}
                />
                <label className='omid-check-title' htmlFor={omid}>{omid}</label>
              </div>
            </Dropdown.ItemText>
            ))}
          </>
          ))}
      </div>
    </DropdownButton>
  );
}

export default OMIDDropdown;
