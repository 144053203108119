import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import axiosInstance from '../Shared/interceptor';
import {idTokenstring} from '../Shared/Constant';

axiosInstance.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem(idTokenstring)}`;

export default class DayWiseGraph extends Component {
  handleSave() {
    if (this.validateForm()) {
      this.GetWeekData();
    }
  }

  render() {
    const { dsKpiData } = this.props;

    return (
      <div className="whiteBgGraph mt-2 mb-4">
        <Line
          data={dsKpiData}
          options={{
            maintainAspectRatio: true,
            responsive: true,
            scales: {
              yAxes: [
                {
                  ticks: {
                    precision: 1,
                  },
                  gridLines: {
                    drawOnChartArea: false,
                  },

                  scaleLabel: {
                    display: true,
                    labelString: 'Number of Visitor',
                  },
                  datalabels: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  gridLines: {
                    drawOnChartArea: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Time',
                  },
                  datalabels: {
                    display: false,
                  },
                  stripLines: [
                    {
                      startValue: 1935,
                      endValue: 1945,
                      color: '#d8d8d8',
                    },
                  ],
                },
              ],
            },
            legend: { display: false },
          }}
        />
      </div>
    );
  }
}

DayWiseGraph.propTypes = {
  dsKpiData: PropTypes.array.isRequired,
};
